import {useEffect, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";

export const TabController = {
  param: 'section',
  changeTab: null,
  isGoBack: false
};

const useAppTab = ({defaultTab = 0, tabList = {}, param = TabController.param} = {}) => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const pathSearchParams = new URLSearchParams(search);
  const tabPlain = pathSearchParams.get(param);
  const selectedTab = tabPlain? Array.isArray(tabList)? tabList.includes(tabPlain)? tabPlain : null : tabList[pathSearchParams.get(param)] : null;

  const [tab, setTab] = useState(selectedTab ?? defaultTab);

  const changeTab = (_, newValue, isGoingBack = false) => {
    newValue = newValue || defaultTab;
    if (Array.isArray(tabList)) {
      if (tabList.includes(newValue)) pathSearchParams.set(param, newValue);
      setTab(newValue);
    } else {
      if (isGoingBack) {
        if (Number.isFinite(newValue)) setTab(Number.parseInt(newValue));
        else setTab(tabList[newValue]);
      }
      else {
        pathSearchParams.set(param, tabList[newValue]);
        setTab(newValue);
      }
    }
    if (!isGoingBack) {
      history.push(`${pathname}${[...pathSearchParams.entries()].length? '?':''}${pathSearchParams.toString()}`);
    }
  }

  TabController.changeTab = changeTab;

  useEffect(() => {
    return () => {
      TabController.changeTab = null;
    }
  }, []);

  return [
    tab,
    changeTab
  ];
};

export default useAppTab;