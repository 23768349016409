import { CHARTS_COLOR_PALETTE } from '@View/constants';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';


const DqLineChart = ({ data }) => {
  let arr = []
  let keys = []
  let trsData = () => {
    data.rules.map((el) => {
      keys.push(el.title)
      el.values.map(e => {
        let replaceKoma = e.value.replace(/,/gm, ".")
        let obj = {
          [el.title]: parseFloat(replaceKoma),
          date: e.column
        }
        arr.push(obj)

      })
    })
  }
  trsData()



  const { i18n } = useTranslation()
  const legendRef = useRef();
  const [legendHeight, setLegendHeight] = React.useState()


  const CustomizedLegend = (props) => {
    const { payload } = props;

    return (
      <div style={{display: 'flex', flexWrap: 'wrap'}} ref={legendRef}>
        {
          payload.map((entry, index) => (
            <div style={{display: 'flex', alignItems: 'center', marginRight: '15px'}}>
              <div style={{ background: CHARTS_COLOR_PALETTE[index], minWidth: '7px', height: '7px', borderRadius: '50%', display: 'flex', marginRight: '5px' }}></div>
              <div style={{color: '#46515D', fontSize: '15px'}}>{entry.value}</div>
            </div>
          ))
        }
      </div>
    )
  }

  useEffect(() => {
    setLegendHeight(legendRef?.current?.clientHeight)
  }, [legendRef])



  const data2 = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  return (

    <div style={{ borderRadius: '9px', padding: '18px', display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
      <div style={{ textAlign: 'center', marginBottom: legendHeight + 30, color: '#46515D', fontWeight: 400, fontSize: '18px', lineHeight: '16px', marginTop: '20px', maxWidth: '500px' }}>{data.titleCheck}</div>
      <LineChart
        width={660}
        height={legendHeight ? 300 + legendHeight : 300}
        data={arr}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          tickLine={false}
          dataKey={`date`}
        />

        <YAxis
          axisLine={true}
          tickLine={true}
          width={50}
          label={{ value: `%`, angle: -90, position: 'insideLeft' }}
          tickFormatter={(number) => {
            if (i18n.language === "en") return new Intl.NumberFormat().format(number)
            else return new Intl.NumberFormat('ru', { style: "decimal" }).format(number)
          }}
        />
        <Tooltip
          formatter={(number) => {
            if (i18n.language === "en") return new Intl.NumberFormat().format(number)
            else return new Intl.NumberFormat('ru', { style: "decimal" }).format(number)
          }}
        />
        <Legend
          verticalAlign="top"
          content={<CustomizedLegend />}
          wrapperStyle={{
            top: -legendHeight - 15,
            left: 80
          }}
        />
        {
          keys.map((el, idx) => {
            return <Line strokeWidth={3} type="monotone" dataKey={el} stroke={CHARTS_COLOR_PALETTE[idx]} />
          })
        }
      </LineChart>
    </div>
  )
}

export default DqLineChart