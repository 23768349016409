import React, { useEffect, useState } from "react";
import { TableRow, TableCell } from "@material-ui/core";
import { CollapseHeadClickableCell } from "../cells/CollapseHeadClickableCell";
import { TextCell } from "../cells/TextCell";
import { useTranslation } from "react-i18next";

export const CollapseGroupRows = ({
  k,
  EditRow,
  isEdit,
  clickableCellText,
  noClickableCellText,
  otherCellsTexts = [],
  spanCell = false,
  level = 0,
  children,
  isOpen,
  colapse,
  emptryArr,
  cellStyles = {},
  rowStyles = {},
  colspan = 1,
}: CollapseGroupRowsProps | any): any => {
  const [isCollapsed, setCollapsed] = useState(true);

  useEffect(() => {
    if (isOpen != undefined) {
      setCollapsed(isOpen);
    }
  }, [isOpen]);
  const [idx, setIdx] = React.useState(1);
  if (emptryArr != undefined && idx == 1) {
    if (otherCellsTexts[0] !== "") {
      setIdx(2);
      otherCellsTexts.unshift("");
    }
  }
  return (
    <React.Fragment>
      <TableRow
        onClick={() => (isEdit ? null : setCollapsed(!isCollapsed))}
        style={{ cursor: noClickableCellText ? "" : "pointer", ...rowStyles }}
        className={level > 0 ? "NestedRow" : undefined}
      >
        {noClickableCellText ? (
          <TableCell style={cellStyles} colSpan={colspan}>
            {noClickableCellText}
          </TableCell>
        ) : (
          <CollapseHeadClickableCell
            style={cellStyles}
            text={clickableCellText}
            isCollapsed={isCollapsed}
            colspan={colspan}
            collapseFn={() => setCollapsed(!isCollapsed)}
            level={level}
          />
        )}
        {EditRow ? (
          <EditRow isEdit={isEdit} level={2} k={k} finData={otherCellsTexts} />
        ) : (
          otherCellsTexts.map((text: any, index: any, arr: any) => {
            return (
              <TableCell
                style={{
                  overflow: "hidden",
                  minWidth: 0,
                  textOverflow: "ellipsis",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                  letterSpacing: "0.0075em",
                  color: "#54606F",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  textAlign: "right",
                }}
                key={index}
              >
                {text}
              </TableCell>
            );
          })
        )}
        {spanCell && <TextCell colSpan={1000} />}
      </TableRow>
      {!isCollapsed && (
        <React.Fragment>
          {children}
          <TableRow
            style={{ display: colapse ? "none" : "table-row" }}
            className="CollapseGroupRowsFooterRow"
          ></TableRow>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

type CollapseGroupRowsProps = {
  clickableCellText: string;
  noClickableCellText: string;
  otherCellsTexts?: string[];
  spanCell?: boolean;
  level?: number;
  children: React.ReactNode;
  colapse: any;
  isEdit: any;
  colspan?: number;
};
