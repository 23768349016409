import { postNewClient } from "@State/index";
import ButtonSave from "@View/components/buttons/ButtonSave";
import DefaultInput from "@View/components/formInputs/DefaultInput";
import DefaultSelect from "@View/components/formInputs/DefaultSelect";
import ModalTittle from "@View/components/ModalTittle";
import ModalWrapper from "@View/components/ModalWrapper";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ClientRoles, ClientRolesForSelect } from "@Utils/types";

const NewClientPage = ({ customerId, open, handleOpen, handleClose }) => {
  const history = useHistory();
  const types = [{ value: "Company" }, { value: "Entrepreneur" }];
  const groupsList = useSelector((state) => state.customer.groups);
  const groupsListValue = useMemo(
    () => groupsList.map((i) => ({ name: i.name, value: i.id })),
    [groupsList]
  );

  const dispatch = useDispatch();
  const [newData, setNewData] = React.useState({
    externalId: "",
    type: "Company",
    name: "",
    email: "",
    customerId,
    role: null,
    customerGroupId: null,
  });

  const handleChange = (value, label) => {
    setNewData({ ...newData, [label]: value });
  };

  const handleClick = (event) => {
    event.preventDefault();
    dispatch(postNewClient(newData, history));
  };
  return (
    <ModalWrapper open={open} handleOpen={handleOpen} handleClose={handleClose}>
      <form onSubmit={(event) => handleClick(event)}>
        <ModalTittle>h1.New Client</ModalTittle>
        <DefaultInput
          value={newData.email}
          label="inputs.Email"
          onChange={(value) => handleChange(value, "email")}
        />
        <DefaultInput
          value={newData.externalId}
          label="inputs.External Id"
          onChange={(value) => handleChange(value, "externalId")}
        />
        <DefaultInput
          value={newData.name}
          label="inputs.CompanyName"
          onChange={(value) => handleChange(value, "name")}
        />
        <DefaultSelect
          value={newData.type}
          label="inputs.Type"
          dropdowns={types}
          onChange={(value) => handleChange(value, "type")}
        />
        <DefaultSelect
          value={newData.role}
          label="inputs.role"
          dropdowns={ClientRolesForSelect}
          onChange={(value) => handleChange(value, "role")}
        />
        <DefaultSelect
          value={newData.customerGroupId}
          label="inputs.group"
          dropdowns={groupsListValue}
          onChange={(value) => handleChange(value, "customerGroupId")}
        />
        <ButtonSave>buttons.Save</ButtonSave>
      </form>
    </ModalWrapper>
  );
};

export default NewClientPage;
