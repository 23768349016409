import { Button, Fade, Grid, Modal, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from "react-i18next";

const CookieModal = () => {

    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        localStorage.setItem("isCookieDisplayed", "true")
        setOpen(false);
    };

    React.useEffect(() => {
        setTimeout(() => {
            if(localStorage.getItem('isCookieDisplayed') !== 'true') handleOpen()
        }, 2000)
    }, [])

    return (
        <Modal
            // hideBackdrop
            disableAutoFocus
            disableEnforceFocus
            open={open}
            onClose={handleClose}
            closeAfterTransition
            style={{border: 'none', zIndex: '100', pointerEvents: 'none'}}
        >
            <Fade style={{border: 'none', zIndex: '100', cursor: 'pointer', pointerEvents: 'auto'}} in={open}>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    onClick={handleClose}
                    className='MuiToolbar-gutters'

                    style={{
                        width: '100%', borderTop: '2px solid #5195E6', flexWrap: 'nowrap', paddingTop: '20px', paddingBottom: '20px', zIndex: 10,
                        position: 'absolute', left: 0, bottom: 0, background: '#fff'
                    }}
                >
                    <Grid item style={{ fontWeight: 'normal', fontSize: '19px', marginRight: '30px', }}>
                        <Typography>
                            {t("cookie.Txt")}
                        </Typography>
                    </Grid>
                    <div style={{ flexGrow: 1 }}></div>
                    <Grid item>
                        <Button style={{ color: '#46515D', border: '0.5px solid #6FA4F2', fontWeight: 500, fontSize: '14px', lineHeight: '22px' }} variant="outlined">{t("cookie.Btn")}</Button>
                    </Grid>
                </Grid>
            </Fade>
        </Modal>
    )
}

export default CookieModal