import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  titleContainer: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#1A1E24",
    marginTop: "30px",
    marginLeft: "26px",
  },
}));

const Title = ({ value, position }) => {
  const classes = useStyles({ position });
  return <div className={classes.titleContainer}>{value}</div>;
};

Title.propTypes = {
  value: PropTypes.string,
  position: PropTypes.string,
};

export default Title;
