import { Init, LoginSuccess } from "@State/actions/actionCreators";
import { loadClient } from "@State/effects/client/loadClient";
import { loadCustomer } from "@State/effects/customer/loadCustomer";
import { Middleware } from "redux";
import { getClientId, getCustomerId } from "@Security/stateHelpers";

export const middleware: Middleware = (store) => (next) => (action) => {
  if (Init.match(action) || LoginSuccess.match(action)) {
    const customerId = getCustomerId();
    if (customerId) {
      setTimeout(() => {
        loadCustomer(customerId)(store.dispatch);
      });
    }
    const clientId = getClientId();
    if (clientId !== null) {
      loadClient(clientId)(store.dispatch);
    }
  }

  return next(action);
};
