import { RecursiveTableData } from "@Dao/responseTypes";
import { useTranslation } from "react-i18next";
import React from "react";
import { CollapseGroupRows } from "@View/common/table/collapse/CollapseGroupRows";
import { numberWithSpaces } from "@Utils/format";
import { FirstCell, NestedRow } from "@Components/table";
import { TableCell, TableRow } from "@material-ui/core";
import pickColor from "@Utils/colorPicker";

export function RecursiveTableBody({
  data,
  dates,
  level = 0,
}: RecursiveTableBodyProps): JSX.Element {
  const { t } = useTranslation();
  const fA = formatAmmount;

  if (
    data.values instanceof Array &&
    data.values.length > 0 &&
    data.itemName === undefined &&
    isObject(data.total) &&
    level === 0
  ) {
    return (
      <React.Fragment>
        {data.values.map((el, index) => (
          <RecursiveTableBody
            key={index}
            data={el}
            dates={dates}
            level={level}
          />
        ))}
        <TableRow>
          <FirstCell>{t("total")}</FirstCell>
          {dates.map((date, index) => (
            <TableCell
              style={{
                overflow: "hidden",
                minWidth: 0,
                textOverflow: "ellipsis",
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "16px",
                letterSpacing: "0.0075em",
                color: "#54606F",
                paddingRight: "20px",
                paddingLeft: "20px",
                textAlign: "right",
              }}
              key={index}
            >
              {fA(data.total![date])}
            </TableCell>
          ))}
        </TableRow>
      </React.Fragment>
    );
  }

  if (
    data.values instanceof Array &&
    data.values.length > 0 &&
    data.itemName !== undefined &&
    isObject(data.total)
  ) {
    const lastDate: string = dates[dates.length - 1];
    const values = [...data.values].sort((l, r) => {
      const lastDateValL = (l.values as Record<string, number>)[lastDate] ?? 0;
      const lastDateValR = (r.values as Record<string, number>)[lastDate] ?? 0;
      return lastDateValR - lastDateValL;
    });

    return (
      <CollapseGroupRows
        clickableCellText={data.itemName}
        otherCellsTexts={dates.map((date) => fA(data.total![date]))}
        level={level}
        cellStyles={{ paddingLeft: "20px" }}
      >
        {values.map((el, index) => (
          <RecursiveTableBody
            key={index}
            data={el}
            dates={dates}
            level={level + 1}
          />
        ))}
      </CollapseGroupRows>
    );
  }

  if (isObject(data.values) && data.title !== undefined && level > 0) {
    return (
      <NestedRow>
        <FirstCell level={level}>{data.title}</FirstCell>
        {dates.map((date, index) => (
          <TableCell
            style={{
              textAlign: "right",
              overflow: "hidden",
              minWidth: 0,
              textOverflow: "ellipsis",
              fontWeight: 500,
              fontSize: "13px",
              lineHeight: "16px",
              letterSpacing: "0.0075em",
              color: "#54606F",
              paddingRight: "20px",
              paddingLeft: "20px",
              ...(data.colors[date]
                ? { background: pickColor(data.colors[date]) }
                : {}),
            }}
            key={index}
          >
            {fA((data.values as Record<string, number>)[date])}
          </TableCell>
        ))}
      </NestedRow>
    );
  }

  if (
    data.values instanceof Array &&
    data.values.length === 0 &&
    data.itemName !== undefined &&
    isObject(data.total) &&
    level === 0
  ) {
    return (
      <TableRow>
        <FirstCell>{data.itemName}</FirstCell>
        {dates.map((date, index) => (
          <TableCell
            style={{
              textAlign: "right",
              overflow: "hidden",
              minWidth: 0,
              textOverflow: "ellipsis",
              fontWeight: 500,
              fontSize: "13px",
              lineHeight: "16px",
              letterSpacing: "0.0075em",
              color: "#54606F",
              paddingRight: "20px",
              paddingLeft: "20px",
            }}
            key={index}
          >
            {fA(data.total![date])}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  return <React.Fragment></React.Fragment>;
}

function isObject(val?: any): val is Record<string, number> {
  return (
    val !== undefined &&
    typeof val === "object" &&
    !Array.isArray(val) &&
    Object.keys(val).every((key) => typeof key === "string")
  );
}

function formatAmmount(value?: number, color = null): string {
  return value !== undefined ? numberWithSpaces(value) : "0";
}

type RecursiveTableBodyProps = {
  data: RecursiveTableData;
  dates: Array<string>;
  level?: number;
};
