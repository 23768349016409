import DefaultInput from "@View/components/formInputs/DefaultInput";
import ModalTittle from "@View/components/ModalTittle";
import ModalWrapper from "@View/components/ModalWrapper";
import { formatDate } from "@Localization/utils";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CopyIcon } from "@View/components/svgComponents/CopyIcon";
import { getRole } from "@Security/stateHelpers";

const ClientsDetails = ({ open, handleOpen, handleClose, client }) => {
  const role = getRole();

  const { t } = useTranslation();

  const handleCopyText = () => {
    navigator.clipboard.writeText(client.apiKey);
  };

  return (
    <ModalWrapper
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
      btnTxt={"Save"}
    >
      <ModalTittle children2={client.name}>h1.Details of</ModalTittle>
      <form>
        <DefaultInput
          label="inputs.External Id"
          value={client.externalId}
          onChange={() => null}
        />
        {role !== "ClientManager" && role !== "ClientUser" && (
          <DefaultInput
            label="Scoring Result"
            value={client.scoringResult}
            onChange={(value) => null}
          />
        )}
        <DefaultInput label="Id" value={client.id} onChange={() => null} />
        <DefaultInput
          label="inputs.Create Date"
          value={formatDate(client.createdAt)}
          onChange={() => null}
        />
        <DefaultInput
          label="inputs.Type"
          value={client.type}
          onChange={() => null}
        />
        <DefaultInput
          label="inputs.Email"
          value={client.email}
          onChange={() => null}
        />
        <DefaultInput
          label="inputs.defaultLanguage"
          value={client.defaultLanguage}
          onChange={() => null}
        />
        {/* <DefaultInput
          label="inputs.CompanyName" value={client.name}
          onChange={() => null} /> */}
        <DefaultInput
          label="inputs.Updated At"
          value={formatDate(client.updatedAt)}
          onChange={() => null}
        />
        <DefaultInput
          label="inputs.Status"
          value={client.status}
          onChange={() => null}
        />
        <FormControl className={"PassInput"}>
          <InputLabel htmlFor="standard-adornment-password">
            {t("ApiKey")}
          </InputLabel>
          <Input
            id="standard-adornment-password"
            type={"text"}
            value={client.apiKey}
            onChange={() => null}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleCopyText}
                  onMouseDown={null}
                >
                  <CopyIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </form>
    </ModalWrapper>
  );
};

export default ClientsDetails;
