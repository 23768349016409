import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useStyles } from "../../../style/components/formStyle";
import { TextField } from "@material-ui/core";

//component: label + default input

const FormOnlyReadRow = ({ label, value, big }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid item className={`${big ? classes.rowBigRow : classes.row} ${classes.textableRow}`}>
      <Typography className={big ? "" : classes.label} component="p">
        {t(label)}
      </Typography>
      <TextField
        className={classes.inputOnlyRead + ' ' + 'detailInput' }
        variant="outlined"
        value={value ? value : ""}
        InputProps={{ readOnly: true }}
      />
    </Grid>
  );
};

FormOnlyReadRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  big: PropTypes.bool
};

export default FormOnlyReadRow;
