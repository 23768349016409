import AppAPI from "@API/index";

export const deleteClient = (id) => {
  return (dispatch) => {
    AppAPI.request({
      method: "delete",
      url: `/api/Client/${id}`,
    })
      .then(() => {
        window.location.reload();
      })
  };
};
