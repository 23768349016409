import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import RequestDetailsPage from "@View/pages/AdminPO/request/RequestDetailsPage";
import {
  requestCloseDetailsAction,
  requestOpenDetailsAction,
} from "@State/actions/actionsGet/actionGetFinDataRequest";
import { changeAppLanguage } from "@Localization/utils";

const ClientDetailsPage = () => {
  const { id: requestId } = useParams();

  useEffect(() => {
    const root = document.querySelector("#root");
    root.style.display = "none";
    return () => (root.style.display = "block");
  }, []);

  const dispatch = useDispatch();
  const client = useSelector((state) => state.client.client);

  React.useEffect(() => {
    if (client?.defaultLanguage) {
      changeAppLanguage(client.defaultLanguage);
    }
  }, [client]);

  const requestOpenDetails = () => {
    dispatch(requestOpenDetailsAction());
  };

  const history = useHistory();
  const requestCloseDetails = () => {
    dispatch(requestCloseDetailsAction());
    if (history.length) history.goBack();
    else {
      const URLParams = new URLSearchParams(window.location.search);
      if (URLParams.get("returnUrl")) {
        history.push(URLParams.get("returnUrl"));
      } else {
        window.location.href = "/";
      }
    }
  };

  return (
    <div style={{ width: "100%" }} className="clientDetails__page">
      <RequestDetailsPage
        isAdaptive={false}
        requestId={requestId}
        open={true}
        handleOpen={requestOpenDetails}
        handleClose={requestCloseDetails}
      />
    </div>
  );
};

export default ClientDetailsPage;
