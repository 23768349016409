import { getClient } from "../actionsGet/actionGetClient";
import { getCustomer } from "../actionsGet/actionGetCustomer";
import { geAlltUsersRequest } from "../actionsGet/actionGetUser";
import AppAPI from "@API/index";

export const deleteUser = (id, initiatorId) => {
  return (dispatch) => {
    AppAPI.request({
      method: "delete",
      url: `/api/Account/${id}`,
    })
      .then(() => {
        if(window.location.href.indexOf("customer") !== -1) {
          if (initiatorId === undefined) {
            dispatch(geAlltUsersRequest())
          } else {
            dispatch(getCustomer(initiatorId))
          }

        } else if (window.location.href.indexOf("client") != -1) {
          dispatch(getClient(initiatorId))
        } else {
          window.location.reload()
        }
      })
  };
};
