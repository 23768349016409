import _ from "lodash";
import { addDays, isAfter, isBefore } from "date-fns";

const sortByDate = (data = []) => data
  .sort(([a], [b]) => new Date(a).getTime() - new Date(b).getTime());

const filterByDate = (data = [], filters) => data
  .filter(([date]) => {
    const parsedDate = new Date(date);
    return (
      !isBefore(parsedDate, filters.startDate) &&
      !isAfter(parsedDate, addDays(filters.endDate, 1))
    )
  });

const formatData = (data, filters) => {
  if (_.isEmpty(filters)) return data;
  return sortByDate(filterByDate(Object.entries(data), filters))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
};

export default formatData;
