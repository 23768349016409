
import { SET_HEADER_LEFT_COMPONENT } from "@State/actions/actionTypes";

const initialState = {
  leftComponent: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HEADER_LEFT_COMPONENT: 
        return {
            ...state,
            leftComponent: action.payload
        };
    default:
      return state;
  }
};

export default reducer;