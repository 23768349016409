import { RecursiveTableData } from "@Dao/index";
import { TextCell, DefaultTable } from "@Components/table";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { format, isBefore, isAfter, isEqual } from "date-fns";
import { RecursiveTableBody } from "./RecursiveTableBody";
import React from "react";
import { RangeDatePicker } from "@View/common/form/control/RangeDatePicker";

export function NestedTable({ fetchEffect }: NestedTableProps): any {
  const [error, setError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tableData, setTableData] = useState<RecursiveTableData | null>(null);
  const [dates, setDates] = useState<Date[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [startMinDate, setStartMinDate] = useState<Date | null>(null);
  const [startMaxDate, setStartMaxDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [endMinDate, setEndMinDate] = useState<Date | null>(null);
  const [endMaxDate, setEndMaxDate] = useState<Date | null>(null);
  const [filteredTableData, setFilteredTableData] =
    useState<RecursiveTableData | null>(null);
  const [filteredDates, setFilteredDates] = useState<Date[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const tableData = await fetchEffect();
        const dates =
          tableData.dates
            ?.map((el) => new Date(el.date))
            .sort((l, r) => (isBefore(l, r) ? -1 : isAfter(l, r) ? 1 : 0)) ??
          [];
        setDates(dates);
        setIsLoaded(true);
        setError(false);
        setTableData(tableData);
        if (dates?.length > 0) {
          setStartDate(dates[0]);
          setStartMinDate(dates[0]);
          setStartMaxDate(dates[dates.length - 1]);
          setEndDate(dates[dates.length - 1]);
          setEndMinDate(dates[0]);
          setEndMaxDate(dates[dates.length - 1]);
        } else {
          setStartDate(null);
          setStartMinDate(null);
          setStartMaxDate(null);
          setEndDate(null);
          setEndMinDate(null);
          setEndMaxDate(null);
        }
      } catch (e) {
        setIsLoaded(true);
        setError(true);
      }
    };

    fetchTableData();
  }, [fetchEffect]);

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      const fromDateIndex = dates.findIndex(
        (date) => isAfter(date, startDate) || isEqual(date, startDate)
      );
      let toDateIndex = dates
        .slice()
        .reverse()
        .findIndex((date) => isBefore(date, endDate) || isEqual(date, endDate));
      toDateIndex =
        toDateIndex >= 0 ? dates.length - 1 - toDateIndex : toDateIndex;
      setFilteredDates(dates.slice(fromDateIndex, toDateIndex + 1));
    }
  }, [startDate, endDate, dates]);

  useEffect(() => {
    if (tableData !== null) {
      setFilteredTableData(filter(tableData!, filteredDates));
    }
  }, [filteredDates, tableData]);

  if (error) {
    return <div>Ошибка</div>; //todo
  } else if (filteredTableData === null || filteredDates.length === 0) {
    return <div>{t("NoData")}</div>;
  }
  return (
    <div style={{ background: "#fff" }}>
      <RangeDatePicker
        views={["year", "month"]}
        label={t("Analysis period")}
        minValue={startMinDate}
        maxValue={endMaxDate}
        startValue={startDate}
        endValue={endDate}
        onChangeStart={setStartDate}
        onChangeEnd={setEndDate}
        vertical
      />
      <DefaultTable>
        <TableHead
          style={{
            background: "#F3F5F7",
            height: "46px",
          }}
        >
          <TableRow>
            <TextCell></TextCell>
            {filteredDates.map((el, index) => (
              <TableCell
                style={{
                  fontWeight: 600,
                  fontSize: "13px",
                  lineHeight: "16px",
                  letterSpacing: "0.0075em",
                  color: "#54606F",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                }}
                component="th"
                key={index}
              >
                {formatDate(el)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <RecursiveTableBody
            data={filteredTableData}
            dates={filteredDates.map((el) =>
              format(el, "yyyy-MM-dd'T'HH:mm:ss")
            )}
          />
        </TableBody>
      </DefaultTable>
    </div>
  );
}

function isObject(val?: any): val is Record<string, number> {
  return (
    val !== undefined &&
    typeof val === "object" &&
    !Array.isArray(val) &&
    Object.keys(val).every((key) => typeof key === "string")
  );
}

function formatDate(date: Date): string {
  return format(date, "dd.MM.yyyy");
}

function filter(
  tableData: RecursiveTableData,
  filteredDates: Date[]
): RecursiveTableData {
  const dates = tableData.dates?.filter(
    (el) => !filteredDates.includes(new Date(el.date))
  );

  const total =
    tableData.total !== undefined
      ? Object.keys(tableData.total!)
          .filter((key) => !filteredDates.includes(new Date(key)))
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: tableData.total![key] });
          }, {})
      : undefined;

  const values = isObject(tableData.values)
    ? Object.keys(tableData.values!)
        .filter((key) => !filteredDates.includes(new Date(key)))
        .reduce((cur, key) => {
          return Object.assign(cur, {
            [key]: (tableData.values as Record<string, number>)[key],
          });
        }, {})
    : tableData.values.map((value) => filter(value, filteredDates));

  return {
    colors: tableData?.colors || {},
    dates: dates,
    total: total,
    itemName: tableData.itemName,
    title: tableData.title,
    values: values,
  };
}

type NestedTableProps = {
  fetchEffect: () => Promise<RecursiveTableData>;
};
