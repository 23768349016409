import { applyMiddleware, createStore } from "redux";
import rootReducer from "./reducers/rootReducer";
import thunk from "redux-thunk";
import { middleware } from "./middleware";
import { Init } from "./actions/actionCreators";

export const store = createStore(
  rootReducer,
  applyMiddleware(middleware, thunk)
)

store.dispatch(Init())
