import { forwardRef } from "react";
import { Box, Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const ModalWrapper = (
  {
    children,
    open,
    handleClose,
    maxWidth,
    maxHeight = "85%",
    isFullModal = false,
  },
  ref
) => {
  const style = {
    ...(isFullModal
      ? { height: "100%" }
      : {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: maxWidth ? maxWidth : "442px",
          maxHeight,
        }),
    background: "#FFFFFF",
    outline: "none",
    borderRadius: "10px",
    width: "100%",
    overflow: "auto",
    padding: "30px",
    boxSizing: "border-box",
  };

  const close = {
    position: "absolute",
    top: "15px",
    right: "15px",
    cursor: "pointer",
    zIndex: 9999,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropProps={{
        className: "MuiBackDropClass",
      }}
    >
      <Box style={style} ref={ref}>
        {!isFullModal && <CloseIcon onClick={handleClose} style={close} />}
        {children}
      </Box>
    </Modal>
  );
};

export default forwardRef(ModalWrapper);
