import React, { memo } from "react";
import NoData from "@View/style/components/NoData";
import { CHARTS_COLOR_PALETTE } from "@View/constants";
import { CircleChart } from "@View/pages/AdminPO/request/finDataResult/tabs/txanalysis/charts/InAndOutChart";

const RoundCompareChart = ({
  tittle,
  label1,
  label2,
  centerValue1,
  centerValue2,
  graph1,
  graph2,
  symbol,
  colors = CHARTS_COLOR_PALETTE,
  dynamicFilter = 0,
}) => {
  if (graph1.length < 1 || graph2.length < 1 || !label1 || !label2) {
    return <NoData style={{ position: "static", minHeight: "250px" }} />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row",
        background: "#FFFFFF",
        borderRadius: "10px",
        paddingBottom: "15px",
        position: "relative",
        width: "100%",
        justifyContent: "space-around",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "0",
          width: "100%",
          padding: "20px 20px 0 20px",
          textAlign: "center",
          fontWeight: 600,
          fontSize: "18px",
          color: "#606a74",
          zIndex: 5,
          boxSizing: "border-box",
        }}
      >
        {tittle}
      </div>
      <CircleChart
        data={graph1}
        title={label1}
        primaryColor={colors[1]}
        activeColor={colors[0]}
        centerValue={centerValue1}
        dynamicFilter={dynamicFilter}
        symbol={symbol}
      />
      <CircleChart
        data={graph2}
        title={label2}
        primaryColor={colors[1]}
        activeColor={colors[0]}
        centerValue={centerValue2}
        dynamicFilter={dynamicFilter}
        symbol={symbol}
      />
    </div>
  );
};

export default memo(RoundCompareChart);
