import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "0px 15px",
    border: "1px solid #9097b4",
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#42539b",
    opacity: 0.7,
  },
  text: {
    zIndex: 1,
    color: "#dce4ff",
    opacity: 1,
  },
}));

const Tooltip = ({ active, payload, label, formatLabel, formatMessage }) => {
  const classes = useStyles();
  if (!active || !payload || !payload[0]) return null;
  const formattedLabel = formatLabel({
    label,
    active,
    payload,
  });
  return (
    <div className={classes.container}>
      <p className={classes.text}>
        {formatMessage
          ? formatMessage(formattedLabel, payload)
          : `${formattedLabel} : ${payload[0].value}`}
      </p>
      <div className={classes.background} />
    </div>
  );
};

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string,
  formatLabel: PropTypes.func.isRequired,
};

export default Tooltip;
