import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Sales as SalesResp } from "@Dao/responseTypes";
import { useTranslation } from "react-i18next";
import { i18n } from "i18next";
import {
  DefaultTable,
  FirstCell,
  NestedRow,
  PaddingContainer,
  TextCell,
  CollapseGroupRows,
} from "@View/common/table";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { isAfter, isBefore, compareAsc, format } from "date-fns";
import { numberWithSpaces, numberUsFormat } from "@Utils/format";
import ruLocale from "date-fns/locale/ru";
import enUSLocale from "date-fns/locale/en-US";
import { useParams } from "react-router-dom";
import NoData from "@View/style/components/NoData";
import { RangeDatePicker } from "@View/common/form/control/RangeDatePicker";
import AppAPI from "@API/index";
import { DateType } from "@date-io/type";
import { CHARTS_COLOR_PALETTE } from "@View/constants";
import OneBarChart from "@View/pages/AdminPO/request/finDataResult/tabs/txanalysis/charts/OneBarChart";
import RoundCompareChart from "@View/pages/AdminPO/request/finDataResult/tabs/txanalysis/charts/RoundCompareChart";
import { UIToggle, UIToggleWithLabel } from "@View/components/common/UIToggle";

function getSumFromObject<T extends object>(obj: T) {
  return Object.keys(obj).reduce((sum, key) => {
    if (key === "date") return sum;
    // @ts-ignore
    return sum + (+obj[key] || 0);
  }, 0);
}

function transformObjectPropsToArray<T extends object>(obj: T) {
  return Object.entries(obj)
    .filter(([name]) => name !== "date")
    .map(([name, value]) => ({
      name,
      value,
    }));
}

export function Sales() {
  const { t, i18n } = useTranslation();
  const { id: finDataRequestId } = useParams<{ id: string }>();
  const [sales, setSales] = useState<SalesResp | null | any>(null);
  const [showDashboard, setShowDashboard] = useState(false);

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  const [chartData, setChartData] = useState(null);

  const wrapper: any = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    if (sales?.dates.length > 0) {
      const debtBurdenDates = sales.dates.map(
        ({ date }: any) => new Date(date)
      );
      const getDates = (arr: any[]) => {
        const sortedDates: any = arr.sort((a, b) => a.getTime() - b.getTime());

        let startDate: any = new Date(Date.parse(sortedDates[0]));
        startDate = startDate.setDate(1);
        startDate = new Date(startDate);

        let endDate = new Date(Date.parse(sortedDates[sortedDates.length - 1]));
        let lastDayOfMonthDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth() + 1,
          0
        );
        endDate = lastDayOfMonthDate;

        return [startDate, endDate];
      };
      const [minDate, maxDate] = getDates(debtBurdenDates);

      setMinDate(minDate);
      setMaxDate(maxDate);

      setStartDate(minDate);
      setEndDate(maxDate);
    }
  }, [sales]);

  useEffect(() => {
    if (sales?.dates.length > 0 && startDate && endDate) {
      // dashboard
      const filteredDates = filterDates(sales.dates, startDate, endDate);
      const dashboardCharts = JSON.parse(JSON.stringify(sales.values)).filter(
        (item: any) => Array.isArray(item.values)
      );
      dashboardCharts.forEach((item: any) => {
        item.values.forEach((counterparty: any) => {
          const newValues = {};
          Object.keys(counterparty.values)
            .filter((date) =>
              // @ts-ignore
              filteredDates.find((d) => d.date === date)
            )
            .forEach((dateKey) => {
              // @ts-ignore
              newValues[dateKey] = counterparty.values[dateKey];
            });
          counterparty.values = newValues;
          counterparty.sum = Math.round(
            Object.entries(counterparty.values).reduce((sum, current) => {
              // @ts-ignore
              return sum + current[1];
            }, 0) || 0
          );
        });
        item.values.sort((c1: any, c2: any) => c1.sum - c2.sum);
        item.top5 = item.values.splice(-5);
        item.other = {
          title: t("report.other"),
          values: {},
        };
        filteredDates.forEach((dateItem) => {
          // @ts-ignore
          item.other.values[dateItem.date] = Math.round(
            item.values.reduce(
              // @ts-ignore
              (sum, current) => {
                // @ts-ignore
                return sum + (current.values[dateItem.date] || 0);
              },
              0
            )
          );
        });
        const graphYoYDateNames = sales.graph.map(
          (item: { date: string }) => item.date
        );
        item.dashboard = filteredDates
          .filter((item) => graphYoYDateNames.includes(item.columnName))
          .map((date) => {
            const element = {
              date: date.columnName,
            };
            item.top5.forEach((top5Item: any) => {
              // @ts-ignore
              element[top5Item.title] = top5Item.values[date.date] || 0;
            });
            // @ts-ignore
            element[item.other.title] = item.other.values[date.date] || 0;
            return element;
          });
      });
      if (Array.isArray(dashboardCharts) && dashboardCharts.length) {
        setChartData(
          // @ts-ignore
          dashboardCharts.map((item) => ({
            title: item.itemName,
            data: item.dashboard,
          }))
        );
      } else {
        setChartData(null);
      }
    }
  }, [sales, startDate, endDate]);

  useEffect(() => {
    (async () => {
      const sales = await (i18n.language === "ru"
        ? AppAPI.request({
            url: `/api/FinDataRequest/${finDataRequestId}/sales`,
          })
        : AppAPI.request({
            url: `/api/FinDataRequest/${finDataRequestId}/sales/en`,
          }));
      setSales(sales);
    })();
  }, [finDataRequestId, i18n.language]);

  if (
    !sales ||
    startDate === null ||
    endDate === null ||
    minDate === null ||
    maxDate === null
  )
    return (
      <div
        style={{
          marginTop: "200px",
          height: "100px",
          transform: "translateX(-20px)",
        }}
      >
        <NoData />
      </div>
    );
  const transformedSales = transformData(sales, startDate, endDate, i18n);

  return (
    <Grid>
      <Grid
        style={{
          display: "flex",
          alignItems: "flex-end",
          background: "#FFFFFF",
          borderRadius: "10px 10px 0px 0px",
          padding: "15px 20px",
        }}
      >
        <div style={{ marginRight: "10px" }}>
          <RangeDatePicker
            views={["year", "month"]}
            label={t("Analysis period")}
            minValue={minDate}
            maxValue={maxDate}
            startValue={startDate}
            endValue={endDate}
            onChangeStart={setStartDate}
            onChangeEnd={setEndDate}
            vertical
          />
        </div>
      </Grid>
      <Grid
        style={{
          display: "flex",
          alignItems: "flex-end",
          background: "#FFFFFF",
          borderRadius: "0px 0px 10px 10px",
          padding: "15px 20px",
          justifyContent: "space-between",
          borderTop: "1px solid #E1E6F1",
        }}
      >
        <UIToggleWithLabel
          sx={{ fontSize: "14px" }}
          control={
            <UIToggle
              checked={showDashboard}
              onChange={(event) => setShowDashboard(event.target.checked)}
            />
          }
          label={t("ShowDashboard")}
          labelPlacement="start"
        />
      </Grid>
      {showDashboard ? (
        Array.isArray(chartData) ? (
          <Grid
            ref={wrapper}
            style={{
              display: "grid",
              gridTemplateColumns: "60% auto",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            <Grid
              ref={wrapper}
              style={{
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: "20px",
              }}
            >
              {
                // @ts-ignore
                chartData.map((chart: any) => (
                  <div>
                    <OneBarChart
                      key={chart.title}
                      Tittle={chart.title}
                      data={chart.data}
                      colors={CHARTS_COLOR_PALETTE}
                    />
                  </div>
                ))
              }
            </Grid>
            <Grid
              style={{
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: "20px",
              }}
            >
              {
                // @ts-ignore
                chartData.map((chart: any) => (
                  <RoundCompareChart
                    tittle={chart.title}
                    label1={chart.data[0]?.date || "-"}
                    label2={chart.data[chart.data.length - 1]?.date || "-"}
                    centerValue1={getSumFromObject(chart.data[0])}
                    centerValue2={getSumFromObject(
                      chart.data[chart.data.length - 1]
                    )}
                    graph1={transformObjectPropsToArray(chart.data[0])}
                    graph2={transformObjectPropsToArray(
                      chart.data[chart.data.length - 1]
                    )}
                    symbol={sales.currency?.symbol || ""}
                  />
                ))
              }
            </Grid>
          </Grid>
        ) : (
          <NoData />
        )
      ) : (
        <PaddingContainer top>
          <DefaultTable>
            <TableHead
              style={{
                background: "#F3F5F7",
                height: "46px",
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: 600,
                    fontSize: "13px",
                    lineHeight: "19px",
                    color: "#54606F",
                    paddingLeft: "calc(2em + 6px)",
                    textAlign: "left",
                    border: "none",
                  }}
                >
                  {t("Counterpartie")}
                </TableCell>
                {transformedSales.dates.map((el, index) => (
                  <TableCell
                    style={{
                      fontWeight: 600,
                      fontSize: "13px",
                      lineHeight: "16px",
                      letterSpacing: "0.0075em",
                      color: "#54606F",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                    }}
                    component="th"
                    key={index}
                  >
                    {el}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {transformedSales.counterparties.map((item) => {
                return (
                  <CollapseGroupRows
                    level={2}
                    clickableCellText={item.name}
                    otherCellsTexts={item.data}
                  >
                    {item.sub.map((item2) => {
                      return (
                        <NestedRow style={{ height: "50px" }}>
                          {item2.map((itemText, index) => {
                            if (index === 0) {
                              return (
                                <FirstCell level={2}>{itemText}</FirstCell>
                              );
                            }
                            return <TextCell>{itemText}</TextCell>;
                          })}
                        </NestedRow>
                      );
                    })}
                  </CollapseGroupRows>
                );
              })}
            </TableBody>
          </DefaultTable>
        </PaddingContainer>
      )}
    </Grid>
  );
}

function filterDates(
  dates: Array<{ date: DateType; columnName: string; recordType: string }>,
  startDate: Date,
  endDate: Date
) {
  return dates
    .filter(
      (date) =>
        !isBefore(new Date(date.date), startDate) &&
        !isAfter(new Date(date.date), endDate)
    )
    .sort((l, r) => {
      const lDate = new Date(l.date);
      const rDate = new Date(r.date);
      return compareAsc(lDate, rDate);
    });
}

function transformData(
  sales: SalesResp,
  startDate: Date,
  endDate: Date,
  i18n: i18n
) {
  const filteredDates = sales.dates
    .map((el) => el.date)
    .filter(
      (date) =>
        !isBefore(new Date(date), startDate) &&
        !isAfter(new Date(date), endDate)
    )
    .sort((l, r) => {
      const lDate = new Date(l);
      const rDate = new Date(r);
      return compareAsc(lDate, rDate);
    });

  return {
    dates: [...filteredDates.map((date) => formatDate(new Date(date), i18n))],
    counterparties: sales.values.map((counterpart) => ({
      name: counterpart.itemName,
      data: [
        ...filteredDates.map((date) =>
          formatAmmount(counterpart.total[date], i18n)
        ),
      ],
      sub: counterpart.values.map((item) => [
        item.title,
        ...filteredDates.map((date) => formatAmmount(item.values[date], i18n)),
      ]),
    })),
  };
}

function formatDate(date: Date, i18n: i18n) {
  return format(date, "LLLL yyyy", {
    locale: i18n.language === "ru" ? ruLocale : enUSLocale,
  });
}

function formatAmmount(value: number, i18n: i18n) {
  return Number.isNaN(Number(value)) || value == null
    ? "0"
    : i18n.language === "ru"
    ? numberWithSpaces(value)
    : numberUsFormat(value);
}
