import React, { useEffect, useState } from "react";
import {
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  CollapseGroupRows,
  DefaultTable,
  FirstCell,
  NestedRow,
  PaddingContainer,
  TextCell,
} from "@View/common/table";
import { Counterpart } from "@Dao/responseTypes";
import { useTranslation } from "react-i18next";
import { compareAsc, format, isAfter, isBefore } from "date-fns";
import { numberUsFormat, numberWithSpaces } from "@Utils/format";
import { i18n } from "i18next";
import ruLocale from "date-fns/locale/ru";
import enUSLocale from "date-fns/locale/en-US";
import { useParams } from "react-router-dom";
import NoData from "@View/style/components/NoData";
import { RangeDatePicker } from "@View/common/form/control/RangeDatePicker";
import AppAPI from "@API/index";
import Selector from "@Components/form/control/Selector";
import { translatedFrequencies } from "@Constants/functions";
import { Frequency, RecordType, RequestTab } from "@Constants/enums";
import { FILTER_TYPES, FILTERS_BUTTONS } from "@View/constants";

export function Counterparties() {
  const { t, i18n } = useTranslation();
  const { id: finDataRequestId } = useParams<{ id: string }>();
  const frequencyItems = translatedFrequencies();

  const [frequency, setFrequency] = useState(Frequency.Month);
  const [counterpart, setCounterpart] = useState<Counterpart>();

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [transformedCounterpart, setTransformedCounterpart] = useState(null);

  useEffect(() => {
    if (counterpart && counterpart?.dates.length > 0) {
      const debtBurdenDates = counterpart.dates.map(
        ({ date }: any) => new Date(date)
      );
      const [minDate, maxDate] = getDates(debtBurdenDates);

      if (!startDate) {
        setMinDate(minDate);
        setMaxDate(maxDate);

        setStartDate(minDate);
        setEndDate(maxDate);
      }

      const dateFilterMin = startDate || minDate;
      const dateFilterMax = endDate || maxDate;

      setTransformedCounterpart(
        // @ts-ignore
        transformData(
          counterpart,
          dateFilterMin,
          dateFilterMax,
          frequency,
          i18n
        )
      );
    }
  }, [counterpart, startDate, endDate, frequency]);

  useEffect(() => {
    (async () => {
      await setCounterpart(
        await (i18n.language === "ru"
          ? AppAPI.request({
              url: `/api/FinDataRequest/${finDataRequestId}/counterparies`,
            })
          : AppAPI.request({
              url: `/api/FinDataRequest/${finDataRequestId}/counterparies/en`,
            }))
      );
    })();
  }, [finDataRequestId, i18n.language]);

  if (
    !counterpart ||
    !transformedCounterpart ||
    startDate === null ||
    endDate === null ||
    minDate === null ||
    maxDate === null
  )
    return (
      <div
        style={{
          height: "100px",
          transform: "translateX(-20px)",
          marginTop: "200px",
        }}
      >
        <NoData />
      </div>
    );

  return (
    <React.Fragment>
      {/*<Filters*/}
      {/*  page={RequestTab.Counterparties}*/}
      {/*  dates={counterpart.dates.map(({ date }) => new Date(date))}*/}
      {/*  excludedFilters={[*/}
      {/*    FILTER_TYPES.ratio,*/}
      {/*    FILTER_TYPES.frequency,*/}
      {/*    FILTER_TYPES.vertical,*/}
      {/*    FILTER_TYPES.horizontal,*/}
      {/*  ]}*/}
      {/*  excludedButtons={[*/}
      {/*    FILTERS_BUTTONS.exportButton,*/}
      {/*    FILTERS_BUTTONS.showDashboardButton,*/}
      {/*  ]}*/}
      {/*/>*/}

      <Grid
        style={{
          display: "flex",
          alignItems: "flex-end",
          background: "#FFFFFF",
          borderRadius: "10px 10px 0px 0px",
          padding: "15px 20px",
        }}
      >
        <div>
          <Selector
            id="frequency"
            label={t("Frequency")}
            value={frequency}
            values={frequencyItems}
            onChange={setFrequency}
          />
        </div>
        <div style={{ margin: "0 10px" }}>
          <RangeDatePicker
            views={["year", "month"]}
            label={t("Analysis period")}
            minValue={minDate}
            maxValue={maxDate}
            startValue={startDate}
            endValue={endDate}
            onChangeStart={setStartDate}
            onChangeEnd={setEndDate}
            vertical
          />
        </div>
      </Grid>

      {i18n.language === "en" && (
        <Typography style={{ marginTop: "20px" }}>This is stub data</Typography>
      )}
      <PaddingContainer top>
        <DefaultTable>
          <TableHead
            style={{
              background: "#F3F5F7",
              height: "46px",
            }}
          >
            <NestedRow>
              <TableCell
                style={{
                  paddingLeft: "calc(2em + 6px)",
                  textAlign: "left",
                  border: "none",
                  fontWeight: 600,
                  fontSize: "13px",
                  lineHeight: "16px",
                  letterSpacing: "0.0075em",
                  color: "#54606F",
                }}
              >
                {t("Counterpartie")}
              </TableCell>
              <TextCell component="th">
                {counterpart.currency.localName}
              </TextCell>
              <TextCell component="th">
                {t(
                  "finDataRequest.tabs.Counterparties.tables.Counterparties.inn"
                )}
              </TextCell>
              {
                // @ts-ignore
                transformedCounterpart?.dates.map((el, index) => (
                  <TextCell component="th" key={index}>
                    {el}
                  </TextCell>
                ))
              }
            </NestedRow>
          </TableHead>

          {/* <TableHead>
            <TableRow>
              <FirstCell component="th">{i18n.t("Currency")}</FirstCell>
              <TextCell component='th'>{i18n.t("INN")}</TextCell>
              {transformedCounterpart.dates.map((el, index) => <TextCell component="th" key={index}>{el}</TextCell>)}
            </TableRow>
          </TableHead> */}
          <TableBody>
            <CollapseGroupRows
              level={2}
              clickableCellText={t(
                "finDataRequest.tabs.Counterparties.tables.Counterparties.buyers"
              )}
              otherCellsTexts={[
                "",
                "",
                // @ts-ignore
                ...(transformedCounterpart?.buyers
                  // @ts-ignore
                  .find((el) => !el[1])
                  .slice(2) ||
                  [
                    // @ts-ignore
                    ...new Array(transformedCounterpart?.dates.length || 0),
                  ].map((v) => "")),
              ]}
            >
              {
                // @ts-ignore
                transformedCounterpart?.buyers
                  // @ts-ignore
                  .filter((el) => el[1])
                  .map(
                    // @ts-ignore
                    (counterpartElement, index) => (
                      <NestedRow key={index}>
                        <FirstCell level={2}>{counterpartElement[0]}</FirstCell>
                        <TextCell>&nbsp;</TextCell>
                        <td
                          style={{
                            display: "table-cell",
                            justifyContent: "flex-end",
                          }}
                        >
                          {
                            counterpart.buyers.filter((b) => b.externalId)[
                              index
                            ].inn
                          }
                        </td>
                        {
                          // @ts-ignore
                          counterpartElement.slice(2).map((el, index) => (
                            <TextCell key={index}>{el}</TextCell>
                          ))
                        }
                      </NestedRow>
                    )
                  )
              }
            </CollapseGroupRows>
            <CollapseGroupRows
              level={2}
              clickableCellText={t(
                "finDataRequest.tabs.Counterparties.tables.Counterparties.suppliers"
              )}
              otherCellsTexts={[
                "",
                "",
                // @ts-ignore
                ...(transformedCounterpart?.suppliers
                  // @ts-ignore
                  .find((el) => !el[1])
                  .slice(2) ||
                  [
                    // @ts-ignore
                    ...new Array(transformedCounterpart?.dates.length || 0),
                  ].map((v) => "")),
              ]}
            >
              {
                // @ts-ignore
                transformedCounterpart?.suppliers
                  // @ts-ignore
                  .filter((el) => el[1])
                  .map(
                    // @ts-ignore
                    (counterpartElement, index) => (
                      <NestedRow key={index}>
                        <FirstCell level={2}>{counterpartElement[0]}</FirstCell>
                        <TextCell>&nbsp;</TextCell>
                        <td
                          style={{
                            display: "table-cell",
                            justifyContent: "flex-end",
                          }}
                        >
                          {
                            counterpart.suppliers.filter((b) => b.externalId)[
                              index
                            ].inn
                          }
                        </td>
                        {
                          // @ts-ignore
                          counterpartElement.slice(2).map((el, index) => (
                            <TextCell key={index}>{el}</TextCell>
                          ))
                        }
                      </NestedRow>
                    )
                  )
              }
            </CollapseGroupRows>
          </TableBody>
        </DefaultTable>
      </PaddingContainer>
    </React.Fragment>
  );
}

function transformData(
  counterpart: Counterpart,
  startDate: Date,
  endDate: Date,
  frequency: Frequency,
  i18n: i18n
): { dates: string[]; buyers: string[][]; suppliers: string[][] } {
  const filteredDates = counterpart.dates
    .filter((el) => {
      if (frequency === Frequency.Month) {
        return el.recordType === RecordType[0]; // Month
      }
      if (frequency === Frequency.Year) {
        return el.recordType === RecordType[4]; /// Year
      }
      if (frequency === 1) {
        return [
          RecordType[5],
          RecordType[6],
          RecordType[7],
          RecordType[8],
        ].includes(el.recordType); /// Quarter
      }
      return false;
    })
    .filter(
      (date) =>
        !isBefore(new Date(date.date), startDate) &&
        !isAfter(new Date(date.date), endDate)
    )
    .sort((l, r) => {
      const lDate = new Date(l.date);
      const rDate = new Date(r.date);
      return compareAsc(lDate, rDate);
    })
    // @TODO SAD-537 replace commented code block with uncommented
    .map((el) => el.columnName);
  // .map((el) => el.date);

  const lastDate = filteredDates[filteredDates.length - 1];

  const sortedCounterparties_buyers = counterpart.buyers.sort((l, r) => {
    // @TODO SAD-537 replace commented code block with uncommented
    const lastDateValL: number =
      l.fundsMovements.find((e) => e.column === lastDate)?.value ?? 0;
    // const lastDateValL: number = l.fundsMovements[lastDate] ?? 0;
    // @TODO SAD-537 replace commented code block with uncommented
    const lastDateValR: number =
      r.fundsMovements.find((e) => e.column === lastDate)?.value ?? 0;
    // const lastDateValR: number = r.fundsMovements[lastDate] ?? 0;
    return lastDateValR - lastDateValL;
  });
  const sortedCounterparties_suppliers = counterpart.suppliers.sort((l, r) => {
    // @TODO SAD-537 replace commented code block with uncommented
    const lastDateValL: number =
      l.fundsMovements.find((e) => e.column === lastDate)?.value ?? 0;
    // const lastDateValL: number = l.fundsMovements[lastDate] ?? 0;
    // @TODO SAD-537 replace commented code block with uncommented
    const lastDateValR: number =
      r.fundsMovements.find((e) => e.column === lastDate)?.value ?? 0;
    // const lastDateValR: number = r.fundsMovements[lastDate] ?? 0;
    return lastDateValR - lastDateValL;
  });

  const fA = formatAmmount;

  return {
    // @TODO SAD-537 replace commented code block with uncommented
    dates: filteredDates,
    // dates: [...filteredDates.map((date) => formatDate(new Date(date), i18n))],
    buyers: sortedCounterparties_buyers.map((counterpart) => [
      counterpart.companyName,
      counterpart.externalId,
      // @TODO SAD-537 replace commented code block with uncommented
      ...filteredDates.map((el) =>
        fA(
          counterpart.fundsMovements.find((e) => e.column === el)?.value || 0,
          i18n
        )
      ),
      // ...filteredDates.map((el) => fA(counterpart.fundsMovements[el], i18n)),
    ]),
    suppliers: sortedCounterparties_suppliers.map((counterpart) => [
      counterpart.companyName,
      counterpart.externalId,
      // @TODO SAD-537 replace commented code block with uncommented
      ...filteredDates.map((el) =>
        fA(
          counterpart.fundsMovements.find((e) => e.column === el)?.value || 0,
          i18n
        )
      ),
      // ...filteredDates.map((el) => fA(counterpart.fundsMovements[el], i18n)),
    ]),
  };
}

function formatDate(date: Date, i18n: i18n) {
  return format(date, "LLLL yyyy", {
    locale: i18n.language === "ru" ? ruLocale : enUSLocale,
  });
}

function formatAmmount(value: number, i18n: i18n) {
  return Number.isNaN(Number(value)) || value == null
    ? "0"
    : i18n.language === "ru"
    ? numberWithSpaces(value)
    : numberUsFormat(value);
}

const getDates = (arr: any[]) => {
  const sortedDates: any = arr.sort((a, b) => a.getTime() - b.getTime());

  let startDate: any = new Date(Date.parse(sortedDates[0]));
  startDate = startDate.setDate(1);
  startDate = new Date(startDate);

  let endDate = new Date(Date.parse(sortedDates[sortedDates.length - 1]));
  endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);

  return [startDate, endDate];
};
