import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((_theme) => ({
  nBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "47px",
    borderRadius: "8px",
    fontSize: "15px",
    fontWeight: "600",
    textAlign: "center",
    padding: "5px",
    "&.active": {
      cursor: "pointer",
      "&.v1": {
        background: "rgba(111, 164, 242, 1)",
        color: "white",
      },
      "&.v2": {
        background: "rgba(111, 164, 242, 0.2)",
        color: "rgba(111, 164, 242, 1)",
      },
      "&:hover": {
        background: "rgba(52, 94, 155, 1)",
        color: "white",
      },
    },
    "&.disabled": {
      cursor: "not-allowed",
      background: "rgba(243, 245, 247, 1)",
      color: "rgba(84, 96, 111, 1)",
    },
  },
}));

const NButton = ({
  variant = 1,
  disabled = false,
  children = "",
  onClick,
  styles = {},
}) => {
  const classes = useStyles();
  return (
    <div
      className={[
        classes.nBtn,
        `v${variant}`,
        disabled ? "disabled" : "active",
      ].join(" ")}
      onClick={() => (!disabled && onClick ? onClick() : null)}
      style={styles}
    >
      {children}
    </div>
  );
};

export default NButton;
