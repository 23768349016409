import ButtonBB from "@View/components/buttons/ButtonBB";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import AppAPI from "@API/index";
import NoData from "@View/style/components/NoData";
import { UIToggle, UIToggleWithLabel } from "@View/components/common/UIToggle";
import DefaultSelect from "@View/components/formInputs/DefaultSelect";
import { downloadFileFunc } from "@Utils/downloadLinks";
import { ColorButton } from "@Components/button/ColorButton";
import { useCTAButtonStyle } from "@View/style/components/tooltipStyles";

const statusOptions = [
  { value: "Да" },
  { value: "Нет" },
  { value: "Нет данных" },
];

const RiskSignals = () => {
  const { t } = useTranslation();
  const [row, setRow] = React.useState();
  const [isEdit, setIsEdit] = React.useState(false);
  const { id: clientId } = useParams();

  React.useEffect(() => {
    (async () => {
      const data = await AppAPI.request({
        url: `/api/Client/${clientId}/risk-signals`,
      });
      setRow(
        data?.items?.map((item) => ({
          ...item,
          locallyChanged: false,
        }))
      );
    })();
  }, []);

  const onSubmit = async () => {
    if (!isEdit || !row) return;
    const changedElements = row.filter((item) => item.locallyChanged);
    if (!changedElements.length) return;
    await AppAPI.request({
      url: `/api/Client/${clientId}/risk-signals`,
      method: "POST",
      data: {
        items: changedElements.map((item) => ({
          key: item.key,
          status: item.status,
        })),
      },
    });
    window.location.reload();
  };

  const changeStatus = (newRow, value) => {
    setRow(
      row.map((item) => {
        if (item.key === newRow.key) {
          item.status = value;
          item.locallyChanged = true;
        }
        return item;
      })
    );
  };

  const btnStyles = useCTAButtonStyle();

  if (!row) return <NoData />;

  return (
    <div style={{ marginBottom: "100px" }} className="listOfCustomers__page">
      <div style={{ background: "#fff", borderRadius: "10px" }}>
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
            padding: "15px 20px",
          }}
        >
          <ButtonBB
            style={{
              width: "162px",
              opacity: isEdit ? "1" : "0.2",
              cursor: isEdit ? "pointer" : "default",
            }}
            className={btnStyles.default}
            onClick={onSubmit}
          >
            {t("buttons.Save")}
          </ButtonBB>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto",
            }}
          >
            <ButtonBB onClick={() => downloadFileFunc(clientId)}>
              {t("buttons.ExportToExcelSignals")}
            </ButtonBB>
          </div>
        </div>
        <div
          style={{
            borderTop: "1px solid #E1E6F1",
            padding: "15px 20px",
            textAlign: "left",
          }}
        >
          <UIToggleWithLabel
            sx={{ fontSize: "14px" }}
            control={
              <UIToggle
                checked={isEdit}
                onChange={(event) => setIsEdit(event.target.checked)}
              />
            }
            label={t("buttons.EditMode")}
            labelPlacement="start"
          />
        </div>
      </div>

      <Box
        sx={{
          textTransform: "uppercase",
          color: "#000",
          fontWeight: 600,
          fontSize: "16px",
          padding: "3em 0 0 3em",
        }}
      >
        {t("RiskSignals.pageTitle")}
      </Box>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "100%",
          gap: "30px",
          marginTop: 20,
        }}
      >
        <div
          style={{
            background: "#fff",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          {row.map((categoryItem) => (
            <div
              style={{
                padding: "30px 50px",
                fontSize: "13px",
                color: "#1A1E24",
                display: "flex",
                flexDirection: "column",
                gap: 25,
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "50px 2fr 1fr 1fr",
                  gap: "30px",
                  alignItems: "center",
                }}
              >
                <div>
                  <ColorButton
                    style={{
                      color: (categoryItem.status === "Да" && "red") || "gray",
                    }}
                    customStyle={{ marginLeft: 0 }}
                    disabled={true}
                  />
                </div>
                <div>{categoryItem.displayName}</div>
                <div>{categoryItem.value || "-"}</div>
                <div>
                  {categoryItem.type === "Manual" && isEdit ? (
                    <DefaultSelect
                      value={categoryItem.status}
                      dropdowns={statusOptions}
                      noMargin
                      onChange={(value) => changeStatus(categoryItem, value)}
                    />
                  ) : (
                    categoryItem.status
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RiskSignals;
