import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  button: {
    marginRight: (p) => (p.marginRight ? `${p.marginRight}%` : "10px"),
    textTransform: (p) => p.textTransform || "uppercase", // uppercase is default in MUI
  },
  middleBtn: {
    background: '#fff',
    color: '#5195E6',
    border: '2px solid #5195E6',
    boxShadow: 'none',
    transition: '0.3s ease',
    padding: '5px 15px',
    lineHeight: '27px',
    fontWeight: '400',
    fontSize: '22px'
  }
}));
