import { Role } from "@Constants/types";

export function isAuth(): boolean {
  return localStorage.getItem("token") !== null;
}

export function getAuthToken(): string {
  return localStorage.getItem("token") || "";
}

export function getAppLang(): string {
  return localStorage.getItem("appLang") || "ru";
}

export function getHeaderLogoImg(): string {
  return localStorage.getItem("headerLogoImg") || "";
}

export function getFooterLogoImg(): string {
  return localStorage.getItem("footerLogoImg") || "";
}

export function getUserId(): string {
  return localStorage.getItem("userId") || "";
}

export function getUserName(): string {
  return localStorage.getItem("userName") || "";
}

export function getClientId(): string {
  return localStorage.getItem("clientId") || "";
}

export function getCustomerId(): string {
  return localStorage.getItem("customerId") || "";
}

export function getRole(): Role {
  if (!isAuth()) {
    throw Error("Unathorized role access");
  }
  return localStorage.getItem("role") as Role;
}

export function canCreateRequest() {
  const exceptRoles = ["CustomerGuest"];
  return !exceptRoles.includes(getRole());
}

export function canViewUsers() {
  const exceptRoles = ["CustomerGuest"];
  return !exceptRoles.includes(getRole());
}

export function canAddNewUser() {
  const exceptRoles = ["CustomerGuest", "ClientUser", "ClientGuest"];
  return !exceptRoles.includes(getRole());
}

// edit and delete
export function canEditEverything() {
  const exceptRoles = ["CustomerGuest", "ClientGuest"];
  return !exceptRoles.includes(getRole());
}
