import { makeStyles } from "@material-ui/core/styles";

//style of login/signup pages
export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(30),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .MuiInputAdornment-positionStart": {
      marginRight: "0",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0",
    },
  },
  buttons: {
    marginTop: theme.spacing(3),
  },
  link: {
    justifyContent: "flex-end",
    display: "flex",
  },
  disabled: {
    width: "100%",
    pointerEvents: "none",
  },
  active: {
    width: "100%",
  },
}));
