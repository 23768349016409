import { signup } from "@State/";
import ButtonSave from "@View/components/buttons/ButtonSave";
import DefaultInput from "@View/components/formInputs/DefaultInput";
import DefaultPassword from "@View/components/formInputs/DefaultPassword";
import Loader from "@View/components/loader/Loader";
import Localization from "@View/components/Localization";
import ModalTittle from "@View/components/ModalTittle";
import Logo from "@View/components/svgComponents/Logo";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Selector from "@Components/form/control/Selector";
import { translatedCompanyTypes } from "@Constants/functions";
import { signupCompany } from "@State/actions/actionSignup";
import { Checkbox } from "@Components/form/control/Checkbox";
import { Checkbox as MUiCheckbox, FormControlLabel } from "@material-ui/core";

const box = {
  position: "relative",
  margin: "0 auto",
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  background: "#FEFFFF",
  outline: "none",
  borderRadius: "9px",
  maxWidth: "360px",
  width: "100%",
  padding: "20px 40px",
  boxSizing: "border-box",
};

const SignupCompanyPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [agreement, setAgreement] = useState(false);
  const [adds, setAdds] = useState(false);

  const typeofEntity = translatedCompanyTypes();

  const [body, setBody] = useState({
    externalId: "",
    name: "",
    userName: "",
    fullName: "",
    password: "",
    email: "",
    type: 0,
    language: 0,
  });

  const [password2, setPassword2] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!agreement) {
      alert(t("agreement.error"));
      return;
    }
    if (body.password === password2 && password2.trim().length) {
      if (
        body.externalId.trim() &&
        body.name.trim() &&
        body.userName.trim() &&
        body.fullName.trim() &&
        body.email.trim()
      ) {
        signupCompany(body, history);
      } else alert(t("inputs.NotFullData"));
    } else alert(t("inputs.PasswordRepeatError"));
  };

  const handleChange = (event, key) => {
    setBody({ ...body, [key]: event });
  };

  return (
    <div style={{ padding: "30px 10px" }}>
      <Loader />
      <div style={box}>
        <form
          onSubmit={(event) => handleSubmit(event)}
          style={{
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ marginTop: "20px" }}></div>
          <div style={{ position: "absolute", right: "5px", top: "0px" }}>
            <Localization />
          </div>

          <ModalTittle children2={""}>{t("h1.Registration")}</ModalTittle>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <Logo width="126" height="24" />
          </div>

          <Selector
            id="frequency"
            label={t("inputs.EntityType")}
            value={body.type}
            values={typeofEntity}
            onChange={(v) => handleChange(v, "type")}
            vertical
            style={{ display: "block", width: "100%", marginBottom: "20px" }}
          />
          <DefaultInput
            label={"inputs.INN"}
            value={body.externalId}
            onChange={(v) => handleChange(v, "externalId")}
          />
          <DefaultInput
            label={"inputs.Company"}
            value={body.name}
            onChange={(v) => handleChange(v, "name")}
          />
          <DefaultInput
            label={"inputs.NameClient"}
            value={body.userName}
            onChange={(v) => handleChange(v, "userName")}
          />
          <DefaultInput
            label={"inputs.NameCustomer"}
            value={body.fullName}
            onChange={(v) => handleChange(v, "fullName")}
          />
          <DefaultInput
            label={"inputs.Email"}
            value={body.email}
            onChange={(v) => handleChange(v, "email")}
          />
          <DefaultPassword
            value={body.password}
            onChange={(v) => handleChange(v, "password")}
          />
          <DefaultPassword
            value={password2}
            onChange={setPassword2}
            repeat={true}
          />

          <FormControlLabel
            className={"CheckboxPlain"}
            onChange={(_, checked) => setAgreement(checked)}
            label={
              <span>
                {t("agreement.f1")}
                <a
                  href="https://xn--80aa8agndk.xn--p1ai/page42653878.html"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "rgb(111, 164, 242)" }}
                >
                  {t("agreement.f11")}
                </a>
                {t("agreement.f12")}
              </span>
            }
            control={
              <MUiCheckbox
                checked={agreement}
                name={t("agreement.f1")}
                color="primary"
              />
            }
          />
          <Checkbox
            label={t("agreement.f2")}
            value={adds}
            onChange={setAdds}
            plain
          />

          <div>
            <ButtonSave marginBottom={"20px"}>{t("Sign Up")}</ButtonSave>
          </div>
          <Link style={{ textDecoration: "none" }} to={`/`}>
            <div
              style={{
                textAlign: "center",
                color: "#6FA4F2",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
              }}
            >
              {t("buttons.AlrdyHave")}
            </div>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default SignupCompanyPage;
