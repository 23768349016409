import { LoginRequest, RestoreRequest } from "@Dao/requestTypes";
import { LoginResponse } from "@Dao/responseTypes";
import { LoginSuccess } from "@State/actions/actionCreators";
import { Dispatch } from "redux";
import AppAPI from "@API/index";
import { changeAppLanguage } from "@Localization/utils";
import { Role } from "@Constants/types";

type AuthCredentials = {
  token: string;
  userId: string;
  userName: string;
  role: string;
  customerId?: string;
  clientId?: string;
  defaultLanguage?: string;
  headerLogoImg?: string;
  ["18nLngCustom"]?: string;
};

export function saveAuthCredentials(config: AuthCredentials) {
  let prop: keyof typeof config;
  for (prop in config) {
    localStorage.setItem(prop, config[prop] || "");
  }
}

export function clearAuthSession() {
  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  localStorage.removeItem("userName");
  localStorage.removeItem("role");
  localStorage.removeItem("clientId");
  localStorage.removeItem("customerId");
}

export async function loginByAccessToken(accessToken: string) {
  clearAuthSession();
  const data = { accessToken };
  try {
    const response: LoginResponse = await AppAPI.request<LoginResponse>({
      method: "post",
      url: "/api/Auth/LoginByToken",
      data,
      errorText: "snackbar.incorrectToken",
    });
    processAuth(response);
  } catch (e) {
    // do nothing
  }
}

function processAuth(response: LoginResponse) {
  const authConfig: AuthCredentials = {
    token: response.accessToken,
    userId: response.userId,
    userName: response.userName,
    role: response.role,
    headerLogoImg: response.headerLogoImg,
  };
  if (response.customerId !== null) {
    authConfig.customerId = response.customerId;
  }
  if (response.clientId !== null) {
    authConfig.clientId = response.clientId;
  }

  changeAppLanguage(response.defaultLanguage);
  saveAuthCredentials(authConfig);
}

export function login(data: LoginRequest) {
  return async (dispatch: Dispatch): Promise<void> => {
    const response: LoginResponse = await AppAPI.request<LoginResponse>({
      method: "post",
      url: "/api/Auth/Login",
      data,
      errorText: "snackbar.incorrectCredentials",
    });
    processAuth(response);
    dispatch(LoginSuccess());
  };
}

export async function restore(data: RestoreRequest) {
  await AppAPI.request<LoginResponse>({
    method: "post",
    url: "/api/Account/forgot-password",
    data,
    successText: "snackbar.passwordResetLinkSent",
    errorText: "snackbar.incorrectEmail",
  });
}
