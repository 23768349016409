import { ClientSearch } from "@Dao/responseTypes";
import AppAPI from "@API/index";
import { getRole } from "@Security/stateHelpers";

export const searchClients = async () => {
  const userRole = getRole() || "ClientUser";
  if (userRole === "ClientUser") return [];

  const result = await AppAPI.request<ClientSearch[]>({
    url: "/api/Client/search",
  });
  return result.map((e) => ({ id: e.guid, name: e.name }));
};
