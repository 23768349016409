import React from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import PageTitle from "./PageTitle";
import { useTranslation } from "react-i18next";
import ButtonDefault from "./buttons/ButtonDefault";
import { useStyles } from "../style/pages/loginStyle";

const NotFound = () => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const handleClick = (event) => {
    event.preventDefault();
    history.goBack();
  };
  return (
    <Grid className={classes.paper}>
      <PageTitle>Page Not Found!</PageTitle>
      <ButtonDefault onClick={handleClick}>{t("Go Back")}</ButtonDefault>
    </Grid>
  );
};

export default NotFound;
