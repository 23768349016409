import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { useCTAButtonStyle } from "@View/style/components/tooltipStyles";

const ButtonSave = ({ children, marginBottom, marginTop }) => {
  const { t } = useTranslation();
  const btnStyles = useCTAButtonStyle();

  const btn = {
    borderRadius: "8px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    padding: "15px 20px",
    margin: "0 auto",
    width: "100%",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: marginTop ? marginTop : "20px",
        marginBottom: marginBottom ? marginBottom : "0px",
      }}
    >
      <Button type={"submit"} style={btn} className={btnStyles.default}>
        {t(children)}
      </Button>
    </div>
  );
};

export default ButtonSave;
