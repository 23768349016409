import React from "react";
import PropTypes from "prop-types";
import {
  Bar,
  XAxis,
  Tooltip,
  Legend,
  BarChart as BaseBarChart,
  YAxis,
  LabelList,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { makeStyles } from "@material-ui/core";
import { transformDataForChartInterface } from "../../../../utils/charts";
import { numberWithSpaces } from "../../../../utils/format";
import Title from "../Title";
import CustomTooltip from "../Tooltip";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  legendItem: {
    fontSize: "14px",
    color: ({ chartStyle }) => chartStyle.colors.text,
  },
}));

const BarChart = ({
  data,
  dataProps,
  title,
  chartStyle,
  useTooltip,
  useLabel,
  legendContent,
  yAxisLabel,
  formatTooltipMessage,
  currency,
}) => {
  const classes = useStyles({ chartStyle });
  const { t, i18n } = useTranslation();
  return (
    <div
      style={{
        background: "#fff",
        width: "100%",
        display: "flex",
        flexFlow: "column",
        borderRadius: "10px",
      }}
    >
      <Title value={title} />
      <ResponsiveContainer width="100%" height={chartStyle.height}>
        <BaseBarChart
          margin={{
            top: 29,
            left: 25,
            right: 20,
            bottom: 0,
          }}
          data={transformDataForChartInterface(data, dataProps)}
          width={150}
          height={40}
          barSize={chartStyle.barSize}
          // margin={chartStyle.margin}
          barGap={chartStyle.barGap}
        >
          <YAxis
            axisLine={true}
            tickLine={true}
            width={20}
            tickFormatter={(tickValue) =>
              tickValue === 0 ? "" : numberWithSpaces(tickValue)
            }
            label={() => {
              return (
                <g>
                  <text
                    x={-23}
                    y={145}
                    dy={25}
                    dx={chartStyle.margin.left}
                    fill={"#A1ADBA"}
                    fontSize={"12px"}
                  >
                    {currency}
                  </text>
                </g>
              );
            }}
          />
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis
            dataKey={dataProps.name}
            tickLine={false}
            tick={({ x, y, payload, ...props }) => {
              if (props.index % 2 === 1) return null;
              return (
                <g transform={`translate(${x},${y})`}>
                  <text
                    x={0}
                    y={0}
                    dy={16}
                    textAnchor="middle"
                    fill={chartStyle.colors.text}
                  >
                    {payload.value}
                  </text>
                </g>
              );
            }}
          />
          {useTooltip && (
            <Tooltip
              formatter={(number) => {
                if (i18n.language === "en")
                  return new Intl.NumberFormat().format(number);
                else
                  return new Intl.NumberFormat("ru", {
                    style: "decimal",
                  }).format(number);
              }}
              cursor={false}
              content={
                <CustomTooltip
                  formatMessage={formatTooltipMessage}
                  formatLabel={({ label }) => label}
                />
              }
            />
          )}
          <Legend
            layout="vertical"
            iconType="square"
            wrapperStyle={{
              top: chartStyle.legendLayout?.top ?? "",
              right: chartStyle.legendLayout?.right ?? "",
              left: chartStyle.legendLayout?.left ?? "",
              bottom: chartStyle.legendLayout?.bottom ?? "",
            }}
            formatter={(value) => (
              <span className={classes.legendItem}>{value}</span>
            )}
            content={legendContent ?? null}
          />
          {dataProps.keys.map((key, i) => {
            return (
              <Bar
                key={i}
                dataKey={key}
                barSize={chartStyle.barSize}
                fill={chartStyle.colors.bars[i]}
              >
                {useLabel && (
                  <LabelList
                    dataKey={key}
                    position="top"
                    formatter={(v) => `${v}%`}
                  />
                )}
              </Bar>
            );
          })}
        </BaseBarChart>
      </ResponsiveContainer>
    </div>
  );
};

BarChart.propTypes = {
  data: PropTypes.object,
  dataProps: PropTypes.object,
  title: PropTypes.string,
  chartStyle: PropTypes.object,
  useTooltip: PropTypes.bool,
};

export default BarChart;
