import { makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import LoadingIcon from "@View/components/svgComponents/LoadingIcon";

const useStyles = makeStyles((_theme) => ({
  loading: {
    padding: "25px 0",
    textAlign: "center",
    color: "rgba(84, 96, 111, 1)",
    fontSize: "13px",
    "& > div": {
      paddingBottom: "25px",
    },
  },
}));

const FileLoadingProcess = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.loading}>
      <div>{t("attach.processing")}</div>
      <LoadingIcon />
    </div>
  );
};

export default FileLoadingProcess;
