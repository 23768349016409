import React, { useState } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import PropTypes from "prop-types";
import PasswordIcon from "./PasswordIcon";
import { useStyles } from "../../style/components/formStyle";
import { useTranslation } from "react-i18next";

const PasswordInput = ({
  onChange,
  value,
  label,
  customValidatorNames = [],
  ...other
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();

  return (
    <TextValidator
      className={classes.password}
      label={label ? label : ""}
      margin="normal"
      value={value}
      variant="outlined"
      validators={["minStringLength:6", "required", ...customValidatorNames]}
      errorMessages={[
        t("MinPassLength"),
        t("FieldRequired"),
        t("PasswordMismatch"),
      ]}
      fullWidth
      name="password"
      type={showPassword ? "text" : "password"}
      onChange={(event) => onChange(event.target.value)}
      InputProps={{
        endAdornment: (
          <PasswordIcon
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        ),
      }}
      {...other}
    />
  );
};

PasswordInput.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default PasswordInput;
