import { makeStyles } from "@material-ui/core";
import { Snackbar } from "@State/actions/actionCreators";
import ButtonBB from "@View/components/buttons/ButtonBB";
import ModalWrapper from "@View/components/ModalWrapper";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AppAPI from "@API/index";
import ModalTittle from "@View/components/ModalTittle";
import { downloadFinDataV1TemplateFileFunc } from "@Utils/downloadLinks";
import CommonError from "@View/pages/AdminPO/customer/customerDetails/bankStatement/CommonError";
import LoadingIcon from "@View/components/svgComponents/LoadingIcon";
import { useFileLoader } from "@Utils/useFileLoader";

const UploadFileModal = ({
  open,
  handleOpen,
  handleClose,
  openBankStatements,
  openBankStatements1C,
  customer = null,
  client = null,
}) => {
  const reportId = (customer || client)?.id;

  const { t } = useTranslation();
  const fileInput = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const useStyles = makeStyles((_theme) => ({
    input: {
      "&": {
        transition: "0.3s ease",
      },
      "&:hover": {
        background: "rgba(0, 0, 0, 0.04) !important",
      },
    },
    loading: {
      padding: "25px 0",
      textAlign: "center",
      color: "rgba(84, 96, 111, 1)",
      fontSize: "13px",
      "& > div": {
        paddingBottom: "25px",
      },
    },
  }));

  const classes = useStyles();

  const sendFileFunc = async () => {
    let file = fileInput?.current?.files[0];
    if (file !== undefined) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("clientId", reportId);
      let res = await AppAPI.request({
        url: "/api/FinDataRequest/upload-finstatement/xlsx",
        method: "POST",
        data: formData,
      });

      if (res.requestId) {
        history.push(`/findatarequest/${res.requestId}/data`);
        return;
      }

      if (typeof res === "string") {
        dispatch(Snackbar({ message: "snackbar.success", open: true }));
      }
    }
  };

  const { openChooseFileWindow } = useFileLoader();

  const openBankStatementsLocal = () => {
    handleClose();
    openBankStatements();
  };
  const openBankStatements1CLocal = () => {
    if (
      Array.isArray(client.finDataRequests) &&
      client.finDataRequests.length
    ) {
      handleClose();
      openBankStatements1C();
    } else {
      openChooseFileWindow({ accept: ".txt" }, async (files) => {
        setIsLoading(true);

        const formData = new FormData();
        formData.append("file", files[0]);
        formData.append("clientId", reportId);

        try {
          await AppAPI.request({
            url: "/api/FinDataRequest/upload-transactions/txt",
            method: "POST",
            data: formData,
          });
          handleClose();
        } catch (e) {
          setIsError(true);
        }
        setIsLoading(false);
      });
    }
  };

  return (
    <ModalWrapper open={open} handleOpen={handleOpen} handleClose={handleClose}>
      <ModalTittle>buttons.Upload Financial Data</ModalTittle>
      {isError ? (
        <CommonError closeError={() => setIsError(false)} />
      ) : isLoading ? (
        <div className={classes.loading}>
          <div>{t("attach.processing")}</div>
          <LoadingIcon />
        </div>
      ) : (
        <>
          <div style={{ marginBottom: "30px", marginTop: "5px" }}>
            <div
              className={classes.input}
              style={{
                marginTop: "15px",
                width: "100%",
                display: "flex",
                border: "1px solid #E1E6F1",
                borderRadius: "8px",
                textTransform: "none",
                transition: "0.3s ease",
                backgroundColor: "#fff",
              }}
            >
              <label
                style={{
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: "#54606F",
                  width: "100%",
                  height: "100%",
                  padding: "15px 20px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <input
                  onChange={(e) => sendFileFunc()}
                  style={{
                    display: "none",
                    fontSize: 0,
                    background: "transparent",
                    visibility: "hidden",
                  }}
                  ref={fileInput}
                  type="file"
                />
                {t("buttons.UploadStartFile")}
              </label>
            </div>
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <a
                href="#"
                style={{ width: "100%" }}
                onClick={() => downloadFinDataV1TemplateFileFunc()}
              >
                {t("buttons.fileExample")}
              </a>
            </div>
          </div>
          <ButtonBB
            style={{ width: "100%", marginBottom: "15px" }}
            fullWidth
            onClick={() => openBankStatements1CLocal()}
          >
            {t("buttons.bankStatements1C")}
          </ButtonBB>
          <ButtonBB
            disabled={false}
            style={{ width: "100%", marginBottom: "15px" }}
            fullWidth
            onClick={() => openBankStatementsLocal()}
          >
            {t("finData1C.title")}
          </ButtonBB>
        </>
      )}
    </ModalWrapper>
  );
};

export default UploadFileModal;
