import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { languageList } from "@View/components/svgComponents/languageList";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@material-ui/core/Box";
import { changeAppLanguage } from "@Localization/utils";

const useLanguageStyles = makeStyles(() => ({
  root: {
    "&": {
      border: "1px solid #ddd !important",
      boxShadow: "0 3px 10px rgba(0,0,0,0.5) !important",
    },
  },
}));

const Localization = () => {
  const { i18n } = useTranslation();
  const langStyles = useLanguageStyles();

  const handleChange = (lang) => {
    changeAppLanguage(lang);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="localClass">
      <Button
        startIcon={languageList[i18n.language.toLowerCase()]}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
      >
        {i18n.language.toUpperCase()}
      </Button>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={langStyles}
      >
        <MenuItem
          onClick={() => {
            handleChange("ru");
            handleClose();
          }}
          disableRipple
          sx={{ display: "flex", gap: "10px" }}
        >
          {languageList.ru}
          <Box>Ru</Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleChange("en");
            handleClose();
          }}
          disableRipple
          sx={{ display: "flex", gap: "10px" }}
        >
          {languageList.en}
          <Box>En</Box>
        </MenuItem>
      </Menu>
      {/*<RadioGroup*/}
      {/*  className={classes.group}*/}
      {/*  value={i18n.language}*/}
      {/*  onChange={handleChange}*/}
      {/*  style={{display: 'flex',}}*/}
      {/*>*/}
      {/*  <FormControlLabel*/}
      {/*    value="en"*/}
      {/*    control={<Radio style={{display: 'none'}} color="default" />}*/}
      {/*    label="EN"*/}
      {/*    className={classes.label}*/}
      {/*  />*/}
      {/*  <FormControlLabel*/}
      {/*    value="ru"*/}
      {/*    control={<Radio style={{display: 'none'}} color="default" />}*/}
      {/*    label="RU"*/}
      {/*    className={classes.label}*/}
      {/*  />*/}
      {/*</RadioGroup>*/}
    </div>
  );
};

export default Localization;
