const colorMap = {
  green: "rgba(1,138,44,0.1)",
  yellow: "rgba(253,231,64,0.1)",
  red: "rgba(219,10,19,0.1)",
  gray: "rgba(0,0,0,0.3)",
  black: "rgba(0,0,0,0.1)",
};

export default function pickColor(num = "") {
  return colorMap[(num || "").toLowerCase()] ?? "transparent";
}

export function getHighlightColor(value, brightColors = false) {
  let color = "";
  if (parseInt(value) > 0) {
    color = brightColors ? "green" : pickColor("green");
  } else if (parseInt(value) < 0) {
    color = brightColors ? "red" : pickColor("red");
  } else {
    color = brightColors ? "black" : pickColor("black");
  }
  return color;
}

export function getHighlightColorForLastIndex(
  value,
  index,
  arr,
  condition = true,
  brightColors = false
) {
  return index + 1 === arr.length && condition
    ? getHighlightColor(value, brightColors)
    : "";
}

export function getPointColorFromString(value) {
  value = (value || "gray").toLowerCase();
  if (value === "yellow") return "#ffa500";
  return value;
}
