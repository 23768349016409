import {
  GET_CODE_FAILURE,
  GET_CODE_REQUEST,
  GET_CODE_SUCCESS,
} from "../../actions/actionTypes";

const initialState = {
  loading: false,
  code: {},
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CODE_REQUEST:
      return {
        ...state,
        code: {},
        loading: true,
      };
    case GET_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload,
        error: "",
      };
    case GET_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        code: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
