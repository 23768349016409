import {
  GET_ALL_CUSTOMERS_REQUEST,
  GET_ALL_CUSTOMERS_SUCCESS,
} from "../actionTypes";
import AppAPI from "@API/index";

//fetch all customers
export const getAllCustomersRequest = () => {
  return {
    type: GET_ALL_CUSTOMERS_REQUEST,
  };
};

const getAllCustomersSuccess = (customers) => {
  return {
    type: GET_ALL_CUSTOMERS_SUCCESS,
    payload: customers,
  };
};

export const getAllCustomers = () => {
  return async (dispatch) => {
    await dispatch(getAllCustomersRequest());
    AppAPI.request({
      method: "get",
      url: `/api/Customer`,
    })
      .then( async (data) => {
        let customers = [];
        data.forEach((element, index) => {
          customers[index] = {};
          customers[index].id = element.id;
          customers[index].name = element.name;
          customers[index].status = element.status;
          customers[index].externalId = element.externalId;
          customers[index].createdAt = element.createdAt.split("T")[0];
          customers[index].subscriptionUntil =
            element.subscriptionUntil.split("T")[0];
        });
        await dispatch(getAllCustomersSuccess(customers));
      })
  };
};
