import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  title: {
    color: "rgba(53, 70, 89, 1)",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "28.13px",
    margin: "0 0.3em 0.7em 0",
  },
}));

export default function PageTitle(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Typography className={classes.title} gutterBottom>
      {t(props.children)}
    </Typography>
  );
}

PageTitle.propTypes = {
  children: PropTypes.node,
};
