import DefaultInput from "@View/components/formInputs/DefaultInput";
import ModalTittle from "@View/components/ModalTittle";
import ModalWrapper from "@View/components/ModalWrapper";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  canEditEverything,
  getClientId,
  getRole,
} from "@Security/stateHelpers";
import FormDateRow from "@View/components/formRows/FormDateRow";
import ButtonBB from "@View/components/buttons/ButtonBB";
import { useLocation, useParams } from "react-router-dom";
import quickBooks from "./img/quickBooks.png";
import zoho from "./img/zoho.png";
import netSuite from "./img/netSuite.png";
import Xero from "./img/Xero.png";
import delo from "./img/delo.png";
import kontur from "./img/kontur.png";
import oneC from "./img/1c.png";
import elba from "./img/elba.webp";
import point from "./img/tochka.png";
import { useDispatch } from "react-redux";
import RequestDetailsLogPassModal from "./RequestDetailsLogPassModal";
import { Button, TextField } from "@material-ui/core";
import { deleteFinDataRequest } from "@State/index";
import { DeleteModal } from "@View/components/DeleteModal";
import { styled } from "@mui/material/styles";
import AppAPI from "@API/index";
import { downloadFileWithNotification } from "@State/effects";
import OneClogo from "./img/1clogo.png";
import { downloadFile, downloadFileByPath } from "@Dao/api";
import CloseIcon from "@material-ui/icons/Close";

const ModalContent = styled("div")(({ theme, isAdaptive }) => ({
  maxWidth: isAdaptive ? "700px" : "100%",
  margin: "auto",
  position: "relative",
}));

const SystemCover = styled("div")(({ theme, isActive }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  justifyContent: "start",
  gap: "15px",
  "@media (max-width: 800px)": {
    gap: "10px",
    gridTemplateColumns: "1fr 1fr",
  },
}));

const SystemIcon = styled("div")(({ theme, isActive }) => ({
  padding: "20px 0 10px 0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: `3px solid ${isActive ? "#4ad42d" : "transparent"}`,
  background: "#F3F5F7",
  borderRadius: "20px",
  cursor: "pointer",
  flexFlow: "column",
}));

const CustomModal = ({ children }) => {
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 20,
      }}
    >
      <div
        style={{
          borderRadius: "10px",
          background: "white",
          padding: "40px",
          textAlign: "center",
          fontSize: "24px",
        }}
      >
        {children || "-"}
      </div>
    </div>
  );
};

export function Owners(props) {
  const { t } = useTranslation();
  switch (getRole()) {
    case "CustomerUser":
    case "CustomerGuest":
    case "CustomerManager":
      return (
        <DefaultInput
          label={t("Client")}
          value={props.request.clientName}
          onChange={() => null}
        />
      );

    case "ClientUser":
    case "ClientGuest":
    case "ClientManager":
      return (
        <DefaultInput
          label={t("Customer")}
          value={props.request.customerName}
          onChange={() => null}
        />
      );
    case "Admin":
      return (
        <React.Fragment>
          <DefaultInput
            label={t("Client")}
            value={props.request.clientName}
            onChange={() => null}
          />
          <DefaultInput
            label={t("Customer")}
            value={props.request.customerName}
            onChange={() => null}
          />
        </React.Fragment>
      );
  }
}

function goBack() {
  if (window.history.length > 1) {
    window.history.back();
  }
}

const newPageUserUrl = "request-new/details";
const fixedURLs = ["request/details/", newPageUserUrl];

const closeIconStyles = {
  position: "absolute",
  top: "15px",
  right: "15px",
  cursor: "pointer",
  zIndex: 9999,
};

const OneCSelectedModal = ({ clientId, handleClose }) => {
  const { t } = useTranslation();

  const downloadManual = async (e) => {
    e.preventDefault();
    try {
      await downloadFile(`/api/Client/${clientId}/onesintegrationfile`, true);
    } catch (e) {
      downloadFileByPath(
        "/docs/request-instruction.zip",
        "request-instruction.zip"
      );
    }
  };

  return (
    <div
      className="nb-box-sizing"
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        background: "white",
        zIndex: 30,
        padding: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "auto",
      }}
    >
      <CloseIcon onClick={handleClose} style={closeIconStyles} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 20,
          maxWidth: 1200,
          margin: "auto",
        }}
      >
        <div style={{ fontSize: 24 }}>
          <b>{t("NewRequestModal.1c.title")}</b>
        </div>
        <div style={{ fontSize: 18 }}>{t("NewRequestModal.1c.desc")}</div>
        <div style={{ padding: "20px 0" }}>
          <a
            href="/docs/request-instruction.zip"
            download
            target="_blank"
            style={{
              background: "linear-gradient(45deg, #a5e3f2, #b187f1)",
              borderRadius: 40,
              padding: 20,
              fontSize: 18,
              color: "black",
              fontWeight: 600,
              textDecoration: "none",
              filter: "drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3))",
            }}
            onClick={downloadManual}
          >
            {t("NewRequestModal.1c.btn")}
          </a>
        </div>
        <div style={{ fontSize: 20, padding: "20px 0 0 0" }}>
          {t("NewRequestModal.1c.howUse")}
        </div>
        <div style={{ padding: "0 0 20px 0" }}>
          <ol
            style={{
              display: "grid",
              gap: 30,
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
            }}
          >
            <li>{t("NewRequestModal.1c.use_1")}</li>
            <li>{t("NewRequestModal.1c.use_2")}</li>
            <li>{t("NewRequestModal.1c.use_3")}</li>
            <li>{t("NewRequestModal.1c.use_4")}</li>
          </ol>
        </div>
        <div style={{ fontSize: 14 }}> {t("NewRequestModal.1c.warning")}</div>
        <div>
          <img src={OneClogo} alt="" style={{ width: "150px" }} />
        </div>
      </div>
    </div>
  );
};

const OneCFreshSelectedModal = ({ clientId, handleClose }) => {
  const { t } = useTranslation();

  const downloadManual = async (e) => {
    e.preventDefault();
    try {
      await downloadFile(
        `/api/Client/${clientId}/onesintegrationfile/fresh`,
        true
      );
    } catch (e) {
      downloadFileByPath(
        "/docs/request-instruction.zip",
        "request-instruction.zip"
      );
    }
  };

  return (
    <div
      className="nb-box-sizing"
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        background: "white",
        zIndex: 30,
        padding: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "auto",
      }}
    >
      <CloseIcon onClick={handleClose} style={closeIconStyles} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 20,
          maxWidth: 1200,
          margin: "auto",
        }}
      >
        <div style={{ fontSize: 24 }}>
          <b>{t("NewRequestModal.1cFresh.title")}</b>
        </div>
        <div style={{ fontSize: 18 }}>{t("NewRequestModal.1c.desc")}</div>
        <div style={{ padding: "20px 0" }}>
          <a
            href="/docs/request-instruction.zip"
            download
            target="_blank"
            style={{
              background: "linear-gradient(45deg, #a5e3f2, #b187f1)",
              borderRadius: 40,
              padding: 20,
              fontSize: 18,
              color: "black",
              fontWeight: 600,
              textDecoration: "none",
              filter: "drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3))",
            }}
            onClick={downloadManual}
          >
            {t("NewRequestModal.1c.btn")}
          </a>
        </div>
        <div style={{ fontSize: 20, padding: "20px 0 0 0" }}>
          {t("NewRequestModal.1c.howUse")}
        </div>
        <div style={{ padding: "0 0 20px 0" }}>
          <ol
            style={{
              display: "grid",
              gap: 30,
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
            }}
          >
            <li>{t("NewRequestModal.1c.use_1")}</li>
            <li>{t("NewRequestModal.1c.use_2")}</li>
            <li>{t("NewRequestModal.1c.use_3")}</li>
            <li>{t("NewRequestModal.1c.use_4")}</li>
          </ol>
        </div>
        <div style={{ fontSize: 14 }}> {t("NewRequestModal.1c.warning")}</div>
        <div>
          <img src={OneClogo} alt="" style={{ width: "150px" }} />
        </div>
      </div>
    </div>
  );
};

const RequestDetailsPage = ({
  requestId,
  open,
  handleOpen,
  handleClose,
  isAdaptive = false,
}) => {
  const [system, setSystem] = React.useState();
  const [request, setRequest] = React.useState(null);
  const [deletedId, setDeletedId] = React.useState();
  const [customModal, setCustomModal] = React.useState("");
  const [is1CSelected, setIs1CSelected] = React.useState(false);
  const [is1CFreshSelected, setIs1CFreshSelected] = React.useState(false);
  const [isNewPageUserUrlSelected, setIsNewPageUserUrlSelected] =
    React.useState(false);

  let isNewPageUserUrl =
    window.location.pathname.includes(newPageUserUrl) ||
    isNewPageUserUrlSelected;

  const role = getRole();

  useEffect(() => {
    if (system === "1c") {
      setIs1CSelected(true);
      setIsNewPageUserUrlSelected(true);
    } else if (system === "1cFresh") {
      setIs1CFreshSelected(true);
      setIsNewPageUserUrlSelected(true);
    } else if (
      system &&
      (role === "Admin" || role === "ClientUser" || role === "ClientManager")
    ) {
      getFromQuickBooks();
    }
  }, [system]);

  const appLocation = useLocation();
  const returnToInitiator = useCallback(() => {
    if (fixedURLs.some((url) => appLocation.pathname.includes(url))) {
      const returnURL = window.localStorage.getItem("returnURL");
      if (returnURL) {
        window.localStorage.removeItem("returnURL");
        window.location.href = returnURL;
      }
    }
  }, [appLocation]);

  const { t } = useTranslation();
  // Admin, CustomerManager, CustomerUser, ClientManager, ClientUser
  const fetch = async () => {
    setRequest(
      await AppAPI.request({ url: `/api/FinDataRequest/${requestId}` })
    );
  };

  const [openSystem, setOpenSystem] = React.useState(false);

  const handleSystemOpen = () => {
    setOpenSystem(true);
  };

  const handleSystemClose = (isSubmitted = false) => {
    setOpenSystem(false);
    if (isSubmitted === true) {
      setTimeout(returnToInitiator);
    }
  };

  useEffect(() => {
    if (requestId) {
      fetch();
    }
  }, [open]);

  const modalWrapper = React.useRef(null);

  if (request === null || requestId === undefined) {
    return <></>;
  }

  const getFromQuickBooks = () => {
    if (system === "MoeDelo" || system === "Kontur" || system === "elba") {
      handleSystemOpen();
    } else {
      AppAPI.request({
        url: `/api/findatarequest/${requestId}/connectAccountingSystem?redirectUrl=https://${window.location.hostname}/api/client/details/${request.clientId}&accountingSystem=${system}`,
      }).then((url) => {
        setCustomModal(t("success"));
        setTimeout(() => {
          setCustomModal("");
          // if (request.redirectUrl) {
          if (url) {
            // window.location.href = request.redirectUrl;
            window.location.href = url;
          } else {
            goBack();
          }
        }, 3000);
      });
    }
  };

  const rejectFunc = async () => {
    await AppAPI.request({
      url: "/api/FinDataRequest",
      method: "PUT",
      data: {
        id: request?.id,
        comment: "...",
        status: "RejectedByClient",
      },
    });
    await handleClose();
    returnToInitiator();
  };

  const handleDelete = (event) => {
    event.preventDefault();
    deleteFinDataRequest(deletedId);
    setDeletedId();
  };

  const handleOpenModal = (event, finDataRequestId) => {
    event.preventDefault();
    setDeletedId(finDataRequestId);
  };

  const handleCloseModal = (event) => {
    event.preventDefault();
    setDeletedId();
  };

  const fullModal =
    window.location.pathname.includes("client/details") ||
    window.location.pathname.includes("request/details") ||
    window.location.pathname.includes(newPageUserUrl);

  const FormCover = !isAdaptive ? (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        maxWidth: request.status === "New" ? "321px" : "none",
        minWidth: isNewPageUserUrl ? "240px" : "none",
      }}
    >
      <form>
        {customModal && <CustomModal>{customModal}</CustomModal>}
        {!isNewPageUserUrl && (
          <DefaultInput label={"Id"} value={request.id} onChange={() => null} />
        )}

        <Owners request={request} />

        {!isNewPageUserUrl && (
          <>
            {request.status !== "New" && (
              <DefaultInput
                label={"Comment"}
                value={request.comment}
                onChange={() => null}
              />
            )}
            <DefaultInput
              label={"Report Type"}
              value={t(request.reportType)}
              onChange={() => null}
            />
            <DefaultInput
              label={"Transfer Type"}
              value={t(request.transferType)}
              onChange={() => null}
            />
            <DefaultInput
              label={"inputs.Status"}
              value={t(request.status)}
              onChange={() => null}
            />
            <FormDateRow
              disabled={true}
              label={t("inputs.Start Date")}
              value={new Date(request.startDate)}
              onChange={() => null}
            />
            <FormDateRow
              disabled={true}
              label={t("inputs.End Date")}
              value={new Date(request.endDate)}
              onChange={() => null}
            />
            {request.transferType === "Json" &&
            request.status === "SentByClient" ? (
              <ButtonBB
                style={{ width: "100%" }}
                url={`/findatarequest/${request.id}/data`}
              >
                {t("buttons.View data")}
              </ButtonBB>
            ) : null}
            {fullModal && canEditEverything() ? (
              <Button
                onClick={(event) => handleOpenModal(event, request.id)}
                style={{
                  background: "#A4241C",
                  borderRadius: "8px",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: "#F3F5F7",
                  padding: "15px 20px",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                {t("buttons.Delete")}
              </Button>
            ) : null}
          </>
        )}
      </form>
    </div>
  ) : null;

  return (
    <ModalWrapper
      maxWidth={fullModal ? request.status === "New" && 1150 : 400}
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
      maxHeight={isAdaptive ? "100%" : undefined}
      ref={modalWrapper}
      isFullModal={isNewPageUserUrl}
    >
      <ModalContent isAdaptive={isAdaptive}>
        {is1CSelected && (
          <OneCSelectedModal
            clientId={request.clientId}
            handleClose={handleClose}
          />
        )}
        {is1CFreshSelected && (
          <OneCFreshSelectedModal
            clientId={request.clientId}
            handleClose={handleClose}
          />
        )}
        <ModalTittle>Request Details.h1</ModalTittle>
        <div style={{ display: "flex", flexFlow: "row", gap: "33px" }}>
          {FormCover}
          {fullModal && request.status === "New" ? (
            <div style={{ flexGrow: 1 }}>
              <div
                style={{
                  color: "#54606F",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "17px",
                  marginBottom: "15px",
                  marginLeft: 0,
                }}
              >
                {isNewPageUserUrl ? (
                  <div>
                    <div
                      style={{
                        fontSize: 20,
                        color: "black",
                        paddingBottom: "10px",
                      }}
                    >
                      {t("Request Details.newPageTitle")}
                    </div>
                    <div>
                      {t("Request Details.newPageDesc")}{" "}
                      <b style={{ color: "black" }}>
                        {t("Request Details.newPageClient")}
                      </b>{" "}
                      {t("Request Details.newPageDesc2")} <div></div>
                      {t("Request Details.newPageDesc3")}{" "}
                    </div>
                  </div>
                ) : (
                  <>
                    {t("Request Details.p")}
                    {canEditEverything() && (
                      <>
                        {t("Request Details.or")}
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={rejectFunc}
                        >
                          {t("Request Details.orReject")}
                        </span>
                      </>
                    )}
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexFlow: "column",
                  justifyContent: "flex-start",
                  marginTop: "20px",
                }}
              >
                <SystemCover>
                  {(request.language?.toLowerCase() || "ru") === "ru" ? (
                    <>
                      <SystemIcon
                        onClick={() => setSystem("1c")}
                        isActive={system === "1c"}
                      >
                        <img
                          style={{ width: "60%", marginBottom: "10px" }}
                          src={oneC}
                        />
                        <div>{t("Request Details.oneC")}</div>
                      </SystemIcon>
                      <SystemIcon
                        isActive={system === "1cFresh"}
                        onClick={() => setSystem("1cFresh")}
                      >
                        <img
                          style={{ width: "60%", marginBottom: "10px" }}
                          src={oneC}
                        />
                        <div>{t("Request Details.oneCFresh")}</div>
                      </SystemIcon>
                      <SystemIcon
                        onClick={() => setSystem("MoeDelo")}
                        isActive={system === "MoeDelo"}
                      >
                        <img
                          style={{ width: "60%", marginBottom: "10px" }}
                          src={delo}
                        />
                        <div>{t("Request Details.Delo")}</div>
                      </SystemIcon>
                      <SystemIcon
                        onClick={() => setSystem("Kontur")}
                        isActive={system === "Kontur"}
                      >
                        <img
                          style={{ width: "60%", marginBottom: "10px" }}
                          src={kontur}
                        />
                        <div>{t("Request Details.kontur")}</div>
                      </SystemIcon>
                      <SystemIcon
                        onClick={() => setSystem("elba")}
                        isActive={system === "elba"}
                      >
                        <img
                          style={{ width: "60%", marginBottom: "10px" }}
                          src={elba}
                        />
                        <div>{t("Request Details.elba")}</div>
                      </SystemIcon>
                      <SystemIcon
                        isActive={false}
                        style={{ opacity: "0.3", cursor: "default" }}
                      >
                        <img
                          style={{ width: "60%", marginBottom: "10px" }}
                          src={point}
                        />
                        <div>{t("Request Details.Point")}</div>
                      </SystemIcon>
                    </>
                  ) : (
                    <>
                      <SystemIcon
                        onClick={() => setSystem("QuickBooks")}
                        isActive={system === "QuickBooks"}
                      >
                        <img
                          style={{ width: "60%", marginBottom: "10px" }}
                          src={quickBooks}
                        />
                        <div>{t("Request Details.QuickBooks")}</div>
                      </SystemIcon>

                      <SystemIcon
                        onClick={() => setSystem("ZohoBooks")}
                        isActive={system === "ZohoBooks"}
                      >
                        <div
                          style={{
                            height: "120px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            style={{
                              width: "100px",
                              height: "35px",
                              marginTop: "30px",
                            }}
                            src={zoho}
                          />
                        </div>
                        <div>{t("Request Details.ZohoBooks")}</div>
                      </SystemIcon>

                      <SystemIcon
                        onClick={() => setSystem("netSuite")}
                        isActive={system === "netSuite"}
                      >
                        <img
                          style={{ width: "60%", marginBottom: "10px" }}
                          src={netSuite}
                        />
                        <div>{t("Request Details.NetSuite")}</div>
                      </SystemIcon>

                      <SystemIcon
                        onClick={() => setSystem("Xero")}
                        isActive={system === "Xero"}
                      >
                        <img
                          style={{ width: "60%", marginBottom: "10px" }}
                          src={Xero}
                        />
                        <div>{t("Request Details.Xero")}</div>
                      </SystemIcon>
                    </>
                  )}
                </SystemCover>
                {isNewPageUserUrl && (
                  <div style={{ marginTop: 30 }}>
                    Внимание: <b>Заказчик</b> пользуется новым сервисом Смартан
                    для упрощенной передачи финансовой отчетности и данных по
                    счетам. Выгрузка осуществляется сертифицированным продуктом{" "}
                    <b>Смартан: Пакет документов для банков</b>. Программа
                    прошла в «1С» тщательное тестирование на корректность
                    совместной работы и удобство применения «1С:Предприятие» и
                    «1С:Бухгалтерия». Выгружать данные Вы можете только после
                    Вашего согласования.
                  </div>
                )}
              </div>
              <RequestDetailsLogPassModal
                requestId={requestId}
                system={system}
                handleSystemOpen={handleSystemOpen}
                openSystem={openSystem}
                handleSystemClose={handleSystemClose}
              />
            </div>
          ) : null}
        </div>
      </ModalContent>
      <DeleteModal
        handleDelete={handleDelete}
        openModal={deletedId !== undefined}
        handleCloseModal={handleCloseModal}
      />
    </ModalWrapper>
  );
};

export default RequestDetailsPage;
