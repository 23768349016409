import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "@Localization/i18n";
import NoData from "@View/style/components/NoData";
import { Button, Grid, Typography } from "@material-ui/core";
import { Checkbox } from "@View/common/form/control/Checkbox";
import AnalysisDashboard from "./AnalysisDashboard";
import { translatedFrequencies } from "@Constants/functions";
import AnalysisTable from "./AnalysisTable";
import { DynamincsOfPaymentsFilters } from "@Constants/enums";
import { translatedDynamincsOfPaymentsFilters } from "@Constants/functions";
import { Frequency, RecordType } from "@Constants/enums";
import { isAfter, isBefore } from "date-fns";
import { RangeDatePicker } from "@View/common/form/control/RangeDatePicker";
import Selector from "@View/common/form/control/Selector";
import { getMinAndMaxDates } from "@Utils/dateActions";
import { filterDataByRange, TransformData } from "@Utils/dashboardUtils";
import AppAPI from "@API/index";
import { TransactionalMonitoring } from "@View/pages/AdminPO/request/finDataResult/tabs/txanalysis/TransactionalMonitoring";
import { UIToggle, UIToggleWithLabel } from "@View/components/common/UIToggle";
import style from "@View/components/buttons/Buttons.module.css";
import UIExportIcon from "@View/components/common/icons/UIExportIcon";
import { downloadSheetWithNotification } from "@State/effects";
import {downloadFile} from "@Dao/api";

const NoDataLocal = () => {
  return (
    <div
      style={{
        marginTop: "200px",
        height: "100px",
        transform: "translateX(-20px)",
      }}
    >
      <NoData />
    </div>
  );
};

const AnalysisTab2 = () => {
  const { t } = useTranslation();
  const { id: finDataRequestId } = useParams();

  const [row, setRow] = useState();

  /*  loading data  */
  useEffect(() => {
    (async () => {
      setRow(
        await (i18n.language === "ru"
          ? AppAPI.request({
              url: `/api/FinDataRequest/${finDataRequestId}/txanalysis`,
            })
          : AppAPI.request({
              url: `/api/FinDataRequest/${finDataRequestId}/txanalysis/en`,
            }))
      );
    })();
  }, [finDataRequestId]);

  const [data, setData] = useState();
  const [dates, setDates] = useState();
  const [frequency, setFrequency] = useState(Frequency.Month);
  const [dynamicFilter, setDynamicFilter] = useState(
    DynamincsOfPaymentsFilters.Currency
  );
  const [dashboard, setDashboard] = useState();

  const [startDate, setStartDate] = useState(null);
  const [minStartDate, setMinStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [maxEndDate, setMaxEndDate] = useState(null);

  /*  init start/end range  */
  useEffect(() => {
    if (row?.dates?.length > 0) {
      const debtBurdenDates = row.dates.map(({ date }) => new Date(date));
      const [minDate, maxDate] = getMinAndMaxDates(debtBurdenDates);

      setMinStartDate(minDate);
      setMaxEndDate(maxDate);

      setStartDate(minDate);
      setEndDate(maxDate);
    }
  }, [row]);

  /*  transforming data for view  */
  useEffect(() => {
    if (!row) return;
    const trsData = TransformData(row);

    if (dynamicFilter === 0 || dynamicFilter === "Currency") {
      setData(
        filterDataByRange(
          trsData.currenciesTable,
          frequency,
          startDate,
          endDate
        )
      );
    } else if (dynamicFilter === "Counterparties" || dynamicFilter === 1) {
      setData(
        filterDataByRange(
          trsData.counterpartiesTable,
          frequency,
          startDate,
          endDate
        )
      );
    } else if (dynamicFilter === "Banks" || dynamicFilter === 2) {
      setData(
        filterDataByRange(trsData.banksTable, frequency, startDate, endDate)
      );
    } else if (dynamicFilter === "AffiliatedEntities" || dynamicFilter === 3) {
      // setData(filterDataByRange(trsData.affiliatedEntitiesTable, frequency, startDate, endDate))
      setData(null);
    } else if (
      dynamicFilter === "typesOperationsTable" ||
      dynamicFilter === 4
    ) {
      setData(
        filterDataByRange(
          trsData.typesOperationsTable,
          frequency,
          startDate,
          endDate
        )
      );
    } else if (
      dynamicFilter === "numberOfTransactionsByTypesOperationsTable" ||
      dynamicFilter === 5
    ) {
      setData(
        filterDataByRange(
          trsData.numberOfTransactionsByTypesOperationsTable,
          frequency,
          startDate,
          endDate
        )
      );
    } else if (
      dynamicFilter === "numberOfTransactionsByCounterpartiesTable" ||
      dynamicFilter === 6
    ) {
      setData(
        filterDataByRange(
          trsData.numberOfTransactionsByCounterpartiesTable,
          frequency,
          startDate,
          endDate
        )
      );
    } else if (
      dynamicFilter === "numberOfUniqueCounterpartiesTable" ||
      dynamicFilter === 8
    ) {
      setData(
        filterDataByRange(
          trsData.numberOfUniqueCounterpartiesTable,
          frequency,
          startDate,
          endDate
        )
      );
    }
    // else if (dynamicFilter == "transactionMonitoringTable" || dynamicFilter == 7) {
    //     setData(filterDataByRange(trsData.transactionMonitoringTable, frequency, startDate, endDate))
    // }
  }, [row, frequency, endDate, startDate, dynamicFilter]);

  /* setting days list */
  const [frequencyBefore, setFrequencyBefore] = useState(null);
  useEffect(() => {
    if (!row?.dates || !startDate || !endDate) return;
    let datesList;
    if (frequency === 0) {
      datesList = row.dates.filter((e) => e.recordType === RecordType[0]); // Month
    } else if (frequency === 2 && frequencyBefore !== frequency) {
      datesList = row.dates.filter((e) => e.recordType === RecordType[4]); /// Year
    } else if (frequency === 1) {
      datesList = row.dates.filter(
        (e) =>
          e.recordType === RecordType[5] ||
          e.recordType === RecordType[6] ||
          e.recordType === RecordType[7] ||
          e.recordType === RecordType[8]
      ); /// Quarter
    } else {
      datesList = row.dates;
    }
    setFrequencyBefore(frequency);

    const datesListInRange = datesList.filter((el) => {
      const date = new Date(el.date);
      return !isBefore(date, startDate) && !isAfter(date, endDate);
    });
    setDates(datesListInRange);
  }, [row, frequency, startDate, endDate]);

  if (!row || !startDate || !minStartDate || !maxEndDate || !endDate) {
    return (
      <div
        style={{
          height: "100px",
          transform: "translateX(-20px)",
          marginTop: "200px",
        }}
      >
        <NoData />
      </div>
    );
  }

  const frequencyItems = translatedFrequencies();
  const dynamicFilterItems = translatedDynamincsOfPaymentsFilters();

  const exportToFile = async () => {
    await downloadFile(`/api/FinDataRequest/${finDataRequestId}/overdraft/file`);
    // downloadSheetWithNotification(
    //   finDataRequestId,
    //   startDate,
    //   endDate,
    //   frequency
    // );
  };

  return (
    <Grid>
      <Grid
        style={{
          display: "flex",
          alignItems: "flex-end",
          background: "#FFFFFF",
          borderRadius: "10px 10px 0px 0px",
          padding: "15px 20px",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          <div>
            <Selector
              id="frequency"
              label={t("Frequency")}
              value={frequency}
              values={frequencyItems}
              onChange={setFrequency}
              vertical
            />
          </div>
          <div style={{ maxWidth: "200px" }}>
            <Selector
              id="dynamicFilter"
              label={t(
                "finDataRequest.tabs.TransactionAnalysis.tables.Filters"
              )}
              value={dynamicFilter}
              values={dynamicFilterItems}
              onChange={setDynamicFilter}
              vertical
            />
          </div>
          <RangeDatePicker
            views={["year", "month"]}
            label={t("Analysis period")}
            minValue={minStartDate}
            maxValue={maxEndDate}
            startValue={startDate}
            endValue={endDate}
            onChangeStart={setStartDate}
            onChangeEnd={setEndDate}
            vertical
          />
        </div>
        <div>
          <Button
            onClick={() => exportToFile()}
            className={style.ButtonBB}
            startIcon={<UIExportIcon />}
          >
            {t("buttons.Export")}
          </Button>
        </div>
      </Grid>
      <Grid
        style={{
          display: "flex",
          alignItems: "flex-end",
          background: "#FFFFFF",
          borderRadius: "0px 0px 10px 10px",
          padding: "15px 20px",
          justifyContent: "space-between",
          borderTop: "1px solid #E1E6F1",
        }}
      >
        <UIToggleWithLabel
          sx={{ fontSize: "14px" }}
          control={
            <UIToggle
              checked={dashboard}
              onChange={(event) => setDashboard(event.target.checked)}
            />
          }
          label={t("ShowDashboard")}
          labelPlacement="start"
        />
      </Grid>
      {i18n.language === "en" && (
        <Typography style={{ marginTop: "20px" }}>This is stub data</Typography>
      )}
      {data && dates ? (
        dashboard ? (
          <AnalysisDashboard
            dynamicFilter={dynamicFilter}
            frequency={frequency}
            row={row}
            dates={dates}
            range={{ start: startDate, end: endDate }}
          />
        ) : (
          <AnalysisTable
            curency={row.currency.localName}
            dates={dates}
            data={data}
          />
        )
      ) : (
        <NoDataLocal />
      )}
      {data && dates && row?.transactionMonitoringTable ? (
        <TransactionalMonitoring
          trsMonitoring={row.transactionMonitoringTable}
        />
      ) : null}
    </Grid>
  );
};

export default AnalysisTab2;
