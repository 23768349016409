import React from "react";

const RemoveIcon = ({ onClick = null }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClick && onClick()}
    >
      <path
        d="M4.66663 14C4.29996 14 3.98618 13.8696 3.72529 13.6087C3.4644 13.3478 3.33374 13.0338 3.33329 12.6667V4H2.66663V2.66667H5.99996V2H9.99996V2.66667H13.3333V4H12.6666V12.6667C12.6666 13.0333 12.5362 13.3473 12.2753 13.6087C12.0144 13.87 11.7004 14.0004 11.3333 14H4.66663ZM11.3333 4H4.66663V12.6667H11.3333V4ZM5.99996 11.3333H7.33329V5.33333H5.99996V11.3333ZM8.66663 11.3333H9.99996V5.33333H8.66663V11.3333Z"
        fill="#6FA4F2"
      />
    </svg>
  );
};

export default RemoveIcon;
