import {
  GET_ALL_CODES_REQUEST,
  GET_ALL_CODES_SUCCESS,
} from "../actionTypes";
import AppAPI from "@API/index";


//fetch all codes
export const getAllCodesRequest = () => {
  return {
    type: GET_ALL_CODES_REQUEST,
  };
};

const getAllCodesSuccess = (codes) => {
  return {
    type: GET_ALL_CODES_SUCCESS,
    payload: codes,
  };
};

export const getAllCodes = () => {
  return (dispatch) => {
    dispatch(getAllCodesRequest());
    AppAPI.request({
      method: "get",
      url: `/api/code`,
    })
      .then((data) => {
        dispatch(getAllCodesSuccess(data));
      })
  };
};
