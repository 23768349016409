import { RequestTab } from "@Constants/enums";
import { requestActionType, successActionType } from "../actionTypes";
import AppAPI from "@API/index";

function requestAction(payload) {
  return {
    type: requestActionType,
    payload: payload,
  }
}

function successAction(payload) {
  return {
    type: successActionType,
    payload: payload,
  }
}

export const getFinDataRequestPageData = (id, page) => (dispatch) => {
  dispatch(requestAction({ page }));
  AppAPI.request({
    method: "get",
    url: `api/FinDataRequest/${id}/${page === RequestTab.Analysis ? "txanalysis" : page === RequestTab.Summary ? "summary" : page}`,
  })
    .then((data) => {
      let obj = {
        [page]: data
      }
      dispatch(successAction(obj));
    })
};
