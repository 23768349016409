import React from "react";

const LoadingIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 40 40"
          to="360 40 40"
          dur="1.5s"
          repeatCount="indefinite"
        />
        <path
          d="M40.0008 12.8444C36.4453 12.8444 33.5564 9.95555 33.5564 6.44444C33.5564 2.88889 36.4453 0 40.0008 0C43.5564 0 46.4453 2.88889 46.4453 6.44444C46.4453 9.95555 43.5564 12.8444 40.0008 12.8444Z"
          fill="#6FA4F2"
        />
        <path
          opacity="0.1"
          d="M69.0673 29.6442C66.8007 29.6442 64.6673 28.3998 63.5118 26.4442C62.6673 24.9331 62.4451 23.1998 62.8895 21.5554C63.334 19.9109 64.3562 18.4887 65.8673 17.6442C66.8451 17.0665 67.9562 16.7998 69.0673 16.7998C71.3784 16.7998 73.5118 17.9998 74.6229 19.9998C75.5118 21.4665 75.734 23.1998 75.2895 24.8887C74.8451 26.5331 73.7784 27.9109 72.2673 28.7998C71.334 29.3331 70.2229 29.6442 69.0673 29.6442Z"
          fill="#6FA4F2"
        />
        <path
          opacity="0.2"
          d="M73.5553 46.4445C70.0442 46.4445 67.1553 43.5557 67.1553 40.0001C67.1553 36.4446 70.0442 33.5557 73.5553 33.5557C77.1108 33.5557 79.9997 36.4446 79.9997 40.0001C79.9997 43.5557 77.1108 46.4445 73.5553 46.4445Z"
          fill="#6FA4F2"
        />
        <path
          opacity="0.3"
          d="M69.0673 63.1999C67.9562 63.1999 66.8451 62.9332 65.8673 62.3555C64.3562 61.511 63.334 60.0888 62.8895 58.4444C62.4451 56.7999 62.6673 55.0666 63.5118 53.5555C64.6673 51.5999 66.8007 50.3555 69.0673 50.3555C70.2229 50.3555 71.334 50.6666 72.2673 51.1999C75.3784 52.9777 76.4006 56.9332 74.6229 59.9999C73.5118 61.9999 71.3784 63.1999 69.0673 63.1999Z"
          fill="#6FA4F2"
        />
        <path
          opacity="0.4"
          d="M56.7997 75.5112C54.4886 75.5112 52.3553 74.2667 51.1997 72.2667C49.4664 69.2001 50.4886 65.289 53.5553 63.5112C54.5331 62.9334 55.6442 62.6667 56.7997 62.6667C59.0664 62.6667 61.1997 63.8667 62.3553 65.8667C63.1997 67.3334 63.4219 69.0667 62.9775 70.7556C62.5331 72.4001 61.4664 73.7779 59.9997 74.6223C59.022 75.2001 57.9108 75.5112 56.7997 75.5112Z"
          fill="#6FA4F2"
        />
        <path
          opacity="0.5"
          d="M40.0008 80C36.4453 80 33.5564 77.1111 33.5564 73.5555C33.5564 71.8666 34.2231 70.2222 35.4675 69.0222C36.6675 67.8222 38.2675 67.1555 40.0008 67.1555C43.5564 67.1555 46.4453 70.0444 46.4453 73.5555C46.4453 77.1111 43.5564 80 40.0008 80Z"
          fill="#6FA4F2"
        />
        <path
          opacity="0.6"
          d="M23.1996 75.5112C22.0885 75.5112 20.9774 75.2001 19.9996 74.6223C16.9329 72.889 15.8663 68.9334 17.644 65.8667C18.7996 63.8667 20.9329 62.6667 23.1996 62.6667C24.3551 62.6667 25.4663 62.9334 26.444 63.5112C29.5107 65.289 30.5329 69.2001 28.7996 72.2667C27.644 74.2667 25.5107 75.5112 23.1996 75.5112Z"
          fill="#6FA4F2"
        />
        <path
          opacity="0.7"
          d="M10.9332 63.1999C8.62209 63.1999 6.48876 61.9999 5.37765 59.9999C3.59987 56.9332 4.62209 52.9777 7.7332 51.1999C8.66653 50.6666 9.77764 50.3555 10.9332 50.3555C13.1999 50.3555 15.3332 51.5999 16.4888 53.5555C18.2665 56.6221 17.1999 60.5777 14.1332 62.3555C13.1554 62.9332 12.0443 63.1999 10.9332 63.1999Z"
          fill="#6FA4F2"
        />
        <path
          opacity="0.7"
          d="M6.44481 46.4445C2.88925 46.4445 0.000366211 43.5557 0.000366211 40.0001C0.000366211 36.4446 2.88925 33.5557 6.44481 33.5557C8.1337 33.5557 9.77813 34.2223 10.9781 35.4668C12.1781 36.6668 12.8448 38.2668 12.8448 40.0001C12.8448 43.5557 9.95592 46.4445 6.44481 46.4445Z"
          fill="#6FA4F2"
        />
        <path
          opacity="0.8"
          d="M10.9332 29.6442C9.77765 29.6442 8.66653 29.3331 7.7332 28.7998C4.62209 27.022 3.59987 23.0665 5.37765 19.9998C6.48876 17.9998 8.62209 16.7998 10.9332 16.7998C12.0443 16.7998 13.1554 17.0665 14.1332 17.6442C15.6443 18.4887 16.6665 19.9109 17.111 21.5554C17.5554 23.1998 17.3332 24.9331 16.4888 26.4442C15.3332 28.3998 13.1999 29.6442 10.9332 29.6442Z"
          fill="#6FA4F2"
        />
        <path
          opacity="0.9"
          d="M23.1996 17.3333C20.9329 17.3333 18.7996 16.1333 17.644 14.1333C15.8663 11.0667 16.9329 7.11111 19.9996 5.37778C20.9774 4.8 22.0885 4.48889 23.1996 4.48889C25.5107 4.48889 27.644 5.73334 28.7996 7.68889C29.644 9.2 29.8663 10.9333 29.4218 12.5778C28.9774 14.2667 27.9107 15.6444 26.444 16.4889C25.4663 17.0667 24.3551 17.3333 23.1996 17.3333Z"
          fill="#6FA4F2"
        />
      </g>
    </svg>
  );
};

export default LoadingIcon;
