import { Checkbox as MUiCheckbox, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { formatDate } from "@Localization/utils";
import React from "react";

const useStyles = makeStyles((_theme) => ({
  table: {
    "& > div:nth-child(even)": {
      background: "#F3F5F7",
    },
  },
  tableRow: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "50px 1fr 1fr 1fr",
    gap: "10px",
    "& > div": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

function RequestsTable({ items, selectedItems, changeSelection }) {
  const { t } = useTranslation();
  const classes = useStyles();

  function changeSelectionHandler(id) {
    changeSelection(
      selectedItems.includes(id)
        ? selectedItems.filter((i) => i !== id)
        : [...selectedItems, id]
    );
  }

  return (
    <div className={classes.table}>
      {items.map((item) => {
        return (
          <div className={classes.tableRow} key={item.id}>
            <div style={{ justifyContent: "center" }}>
              <MUiCheckbox
                checked={selectedItems.includes(item.id)}
                onChange={() => changeSelectionHandler(item.id)}
                color="primary"
              />
            </div>
            <div
              style={{
                color: "#1A1E24",
                fontWeight: 600,
                justifyContent: "start",
              }}
            >
              {item.customerName}
            </div>
            <div style={{ color: "#54606F", justifyContent: "center" }}>
              {formatDate(item.startDate)} - {formatDate(item.endDate)}
            </div>
            <div
              style={{
                color: "#A1ADBA",
                justifyContent: "right",
                paddingRight: "10px",
              }}
            >
              {t("bankStatement.updated")} {formatDate(item.updatedAt)}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default RequestsTable;
