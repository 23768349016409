import React, { useEffect, useState } from "react";
import AppRouter from "./router/AppRouter";
import Snackbar from "./components/snackbar/Snackbar";
import "./index.css";
import { loginByAccessToken } from "@State/effects/security/login";

const App = () => {
  const [isLoggingUserReady, setIsLoggingUserReady] = useState(false);
  useEffect(() => {
    const currentURL = new URL(document.location);
    if (currentURL.searchParams.has("accessToken")) {
      loginByAccessToken(currentURL.searchParams.get("accessToken")).then(
        () => {
          currentURL.searchParams.delete("accessToken");
          if (currentURL.searchParams.has("return")) {
            window.localStorage.setItem(
              "returnURL",
              currentURL.searchParams.get("return")
            );
          }
          window.history.replaceState(
            {},
            document.title,
            currentURL.toString()
          );
          window.location.reload();
        }
      );
    } else setIsLoggingUserReady(true);
  }, []);

  if (!isLoggingUserReady) return null;

  return (
    <React.Fragment>
      <AppRouter />
      <Snackbar />
    </React.Fragment>
  );
};

export default App;
