import {
  GET_FINDATA_FAILURE,
  GET_FINDATA_REQUEST,
  GET_FINDATA_SUCCESS,
  REQUEST_CLOSE_DETAILS,
  REQUEST_OPEN_DETAILS,
} from "../../actions/actionTypes";

const initialState = {
  loading: false,
  finDataRequest: {},
  error: "",
  openDetails: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FINDATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FINDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        finDataRequest: action.payload,
        error: "",
      };
    case GET_FINDATA_FAILURE:
      return {
        ...state,
        loading: false,
        finDataRequest: {},
        error: action.payload,
      };
    case REQUEST_OPEN_DETAILS:
      return {
        ...state,
        openDetails: true
      };
    case REQUEST_CLOSE_DETAILS:
      return {
        ...state,
        openDetails: false
      };
    default:
      return state;
  }
};

export default reducer;
