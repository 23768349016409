import AppAPI from "@API/index";
import { ROLES } from "@View/constants";

export const signup = (body, history) => {
  const newBody = {
    userName: body.username,
    email: body.email,
    role: ROLES.Admin,
    password: body.password,
  };
  return (dispatch) => {
    AppAPI.request({
      method: "post",
      url: `/api/Auth/register`,
      data: newBody,
    }).then((res) => {
      history.push("/");
    });
  };
};

export const signupCompany = (body, history) => {
  AppAPI.request({
    method: "post",
    url: `/api/Customer/selfRegistration`,
    data: body,
  }).then((res) => {
    history.push("/payment");
  });
};
