import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { PieChart, Pie, Sector, Cell, Tooltip } from "recharts";
import NoData from "@View/style/components/NoData";

function CreateRenderShapeComponent({
  centerValue,
  dynamicFilter,
  symbol,
  color,
}) {
  const { i18n } = useTranslation();

  return ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle }) => {
    return (
      <g>
        <text
          fill="#1A1E24"
          fontWeight="600"
          fontSize="14px"
          lineHeight="17px"
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          className={"centerText"}
        >
          {i18n.language === "en"
            ? new Intl.NumberFormat().format(centerValue)
            : new Intl.NumberFormat("ru", { style: "decimal" }).format(
                centerValue
              )}
          {dynamicFilter === "numberOfTransactionsByCounterpartiesTable" ||
          dynamicFilter === 6
            ? ""
            : symbol}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={color}
        />
      </g>
    );
  };
}

export const CircleChart = memo(
  ({
    data,
    title,
    primaryColor,
    activeColor,
    centerValue,
    dynamicFilter,
    symbol,
  }) => {
    return (
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          width: "50%",
        }}
      >
        <PieChart width={250} height={260}>
          <Pie
            data={data}
            cx={120}
            cy={160}
            innerRadius={60}
            outerRadius={80}
            fill={primaryColor}
            paddingAngle={0}
            dataKey="value"
            activeIndex={0}
            activeShape={CreateRenderShapeComponent({
              centerValue,
              dynamicFilter,
              symbol,
              color: activeColor,
            })}
            onMouseEnter={null}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={index % 2 ? primaryColor : activeColor}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
        <div
          style={{
            fontSize: "16px",
            color: "#666",
            fontFamily: "'Inter Tight', sans-serif",
          }}
        >
          {title}
        </div>
      </div>
    );
  }
);

const InAndOutChart = ({
  colors,
  centerDataFirst,
  centerDataLast,
  tittle,
  graphLast,
  graphFirst,
  marginBottom,
  dateFirst,
  dateLast,
  symbol,
  dynamicFilter,
}) => {
  if (
    !centerDataFirst ||
    !centerDataLast ||
    graphFirst.length < 1 ||
    graphLast.length < 1 ||
    dateLast === undefined ||
    dateFirst === undefined
  ) {
    return <NoData style={{ position: "static", minHeight: "250px" }} />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row",
        background: "#FFFFFF",
        borderRadius: "10px",
        paddingBottom: "15px",
        marginBottom,
        position: "relative",
        width: "100%",
        justifyContent: "space-around",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50% , 100%)",
          fontWeight: 600,
          fontSize: "18px",
          color: "#606a74",
        }}
      >
        {tittle}
      </div>
      <CircleChart
        data={graphFirst}
        title={dateFirst}
        primaryColor={colors[1]}
        activeColor={colors[0]}
        centerValue={centerDataFirst}
        dynamicFilter={dynamicFilter}
        symbol={symbol}
      />
      <CircleChart
        data={graphLast}
        title={dateLast}
        primaryColor={colors[1]}
        activeColor={colors[0]}
        centerValue={centerDataLast}
        dynamicFilter={dynamicFilter}
        symbol={symbol}
      />
    </div>
  );
};

export default memo(InAndOutChart);
