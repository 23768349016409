import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import PageTitle from "@View/components/PageTitle";
import ButtonSave from "@View/components/buttons/ButtonSave";
import { connect } from "react-redux";
import FormSelectRow from "@View/components/formRows/FormSelectRow";
import PropTypes from "prop-types";
import FormDefaultRow from "@View/components/formRows/FormDefaultRow";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useStyles } from "@View/style/pages/detailsStyle";
import { editCode, getCode } from "@State/index";
import { useHistory, useParams } from "react-router-dom";
import FormOnlyReadRow from "@View/components/formRows/onlyReadRows/FormOnlyReadRow";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormNumRow from "@View/components/formRows/FormNumRow";
import _ from "lodash";

const T_ROOT = "editCode";
const types = [{ value: "New" }, { value: "Used" }];
//redux
const mapStateToProps = (state) => {
  return {
    code: state.code.code,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCode: (codeId) => dispatch(getCode(codeId)),
    editCode: (newData, history) =>
      dispatch(editCode(newData, history)),
  };
};

const EditCodePageForm = ({ editCode, code, translate }) => {
  const [newData, setNewData] = useState({ ...code });
  const classes = useStyles();
  const history = useHistory();

  const handleChange = (value, label) => {
    setNewData({ ...newData, [label]: value });
  };

  const handleClick = (event) => {
    event.preventDefault();
    editCode(newData, history);
  };

  return (
    <ValidatorForm
      onSubmit={(event) => handleClick(event)}
      instantValidate={false}
    >
      <Grid container>
        <FormOnlyReadRow big label="Id" value={code.id} />
        <Grid className={classes.columns}>
          <Grid container  className={classes.column}>
            <FormDefaultRow
              big
              value={newData.code}
              label={translate("Code")}
              onChange={(value) => handleChange(value, "code")}
            />
           <FormNumRow
                value={newData.requestAmount}
                label={translate("RequestAmount")}
                onChange={(value) => handleChange(value, "requestAmount")}
              />
            <FormSelectRow
              value={newData.status}
              label={translate("inputs.Status")}
              dropdowns={types}
              onChange={(value) => handleChange(value, "status")}
            />
          </Grid>
        </Grid>
        <ButtonSave />
      </Grid>
    </ValidatorForm>
  );
};

EditCodePageForm.propTypes = {
  editCode: PropTypes.func.isRequired,
  code: PropTypes.object.isRequired,
};

const EditCodePage = ({
  code,
  getCode,
  editCode,
}) => {
  const { id: codeId } = useParams();
  const { t } = useTranslation();
  const translate = (prop, useRoot = true) =>
    t(useRoot ? `${T_ROOT}.${prop}` : prop);

  useEffect(() => {
    getCode(codeId);
  }, [getCode, codeId]);

  return (
    <React.Fragment>
      <PageTitle>{translate("EditCode")}</PageTitle>
      {_.isEmpty(code) ? (
        <LinearProgress />
      ) : (
        <EditCodePageForm
          code={code}
          editCode={editCode}
          translate = {translate}
        />
      )}
    </React.Fragment>
  );
};

EditCodePage.propTypes = {
  editCode: PropTypes.func.isRequired,
  code: PropTypes.object.isRequired,
  getCode: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCodePage);
