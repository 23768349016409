import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import IconSearch from "@View/components/icons/IconSearch";
import IconPlus from "@View/components/icons/IconPlus";

const useStyles = makeStyles((_theme) => ({
  searcher: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    marginBottom: "20px",
  },
  searchInput: {
    position: "relative",
    display: "flex",
    flexGrow: "1",
    "& *": {
      boxSizing: "border-box",
    },
    "& > svg": {
      display: "block",
      position: "absolute",
      top: "50%",
      left: "10px",
      transform: "translateY(-50%)",
      width: "20px",
    },
    "& > input": {
      display: "block",
      width: "100%",
      height: "44px",
      border: "none",
      padding: "0 12px 0 40px",
      borderRadius: "8px",
      background: "#F3F5F7",
      "&::placeholder": {
        color: "#8BA0B6",
      },
    },
  },
  searchBtn: {
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "44px",
    borderRadius: "8px",
    fontSize: "15px",
    fontWeight: "600",
    textAlign: "center",
    border: "1px solid #6FA4F2",
    color: "#6FA4F2",
    flexDirection: "row",
    gap: "10px",
    padding: "0 10px",
    cursor: "pointer",
  },
  iconSize: {
    width: "20px",
  },
}));

const RequestSearcher = ({ value, updateValue, selectAction }) => {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <div className={classes.searcher}>
      <div className={classes.searchInput}>
        <input
          type="text"
          value={value}
          onInput={(e) => updateValue(e.target.value.trim())}
          placeholder={t("bankStatement.input.placeholder")}
        />
        <IconSearch />
      </div>
      <div>
        <div className={classes.searchBtn} onClick={() => selectAction()}>
          <IconPlus className={classes.iconSize} />
          {t("bankStatement.newRequest")}
        </div>
      </div>
    </div>
  );
};

export default RequestSearcher;
