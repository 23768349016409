import { Client, Customer } from "@Dao/responseTypes";
import { ClientState } from "@State/reducers/getReducers/clientReducer";
import { CustomerState } from "@State/reducers/getReducers/customerReducer";
import { FetchOnceAutocomplete } from "@View/common/form/control/FetchOnceAutocomplete";
import { Text } from "@View/common/form/control/Text";
import DefaultInput from "@View/components/formInputs/DefaultInput";
import ModalTittle from "@View/components/ModalTittle";
import ModalWrapper from "@View/components/ModalWrapper";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReportType, TransferType } from "@Constants/enums";
import { isAfter, isSameDay, format } from "date-fns";
import ButtonSave from "@View/components/buttons/ButtonSave";
import DefaultSelect from "@View/components/formInputs/DefaultSelect";
import FormDateRow from "@View/components/formRows/FormDateRow";
import { getCustomer } from "@State/index";
import { searchClients } from "@Utils/commonAPI";
import AppAPI from "@API/index";
import { getClientId, getCustomerId, getRole } from "@Security/stateHelpers";

type IdName = {
  id: string;
  name: string;
};

function calcStartAndEndDate(reportType: string) {
  const now = new Date();
  const endDate = new Date(now.getFullYear(), now.getMonth(), 0);
  const endMonth = endDate.getMonth();
  const prevYear = now.getFullYear() - 1;
  let startDate;
  if (reportType === "All") {
    if (endMonth <= 5) {
      startDate = new Date(prevYear, 0, 1, 0, 0, 0);
    } else if (endMonth <= 8) {
      startDate = new Date(prevYear, 3, 1, 0, 0, 0);
    } else {
      startDate = new Date(prevYear, 6, 1, 0, 0, 0);
    }
  } else if (reportType === "Monitoring") {
    if (endMonth <= 2) {
      startDate = new Date(prevYear, 0, 1, 0, 0, 0);
    } else if (endMonth <= 5) {
      startDate = new Date(prevYear, 3, 1, 0, 0, 0);
    } else if (endMonth <= 8) {
      startDate = new Date(prevYear, 6, 1, 0, 0, 0);
    } else {
      startDate = new Date(prevYear, 9, 1, 0, 0, 0);
    }
  }
  return {
    start: startDate,
    end: endDate,
  };
}

const NewRequestPage = ({
  clientId,
  customerId,
  open,
  handleOpen,
  handleClose,
}: any): any => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [customer, setCustomer] = useState<IdName | null>(null);
  const currentCustomer = useSelector(
    (state) => (state as any).currentCustomer as CustomerState
  );

  useEffect(() => {
    if (customerId === getCustomerId()) {
      if (currentCustomer.data !== null) {
        setCustomer(currentCustomer.data);
      }
    } else {
      const userRole = getRole() || "ClientUser";
      if (userRole === "ClientUser") return;
      (async () => {
        const response = await AppAPI.request<Customer>({
          url: `/api/Customer/${customerId}`,
        });
        setCustomer(response);
      })();
    }
  }, [currentCustomer, open]);

  const [client, setClient] = useState<IdName | null | any>("");
  const currentClient = useSelector(
    (state) => (state as any).currentClient as ClientState
  );
  useEffect(() => {
    if (clientId !== undefined) {
      if (customerId === getClientId()) {
        if (currentClient.data !== null) {
          setClient(currentClient.data);
          setClientVal({
            id: currentClient.data.id,
            name: currentClient.data.name,
          });
        }
      } else {
        (async () => {
          const response = await AppAPI.request<Client>({
            url: `/api/Client/${clientId}`,
          });
          setClient(response);
        })();
      }
    }
  }, [currentClient, open, clientId]);
  let reportTypeItemsDropDown = [{ value: `All` }, { value: `Monitoring` }];
  const [reportType, setReportType] = useState<ReportType | any>(
    reportTypeItemsDropDown[0].value
  );

  const periodRange = calcStartAndEndDate(reportType);
  const [startDate, setStartDate] = useState<Date | any>(periodRange.start);
  const [endDate, setEndDate] = useState<Date | null>(periodRange.end);

  const now = new Date();
  const [minDate, setMinDate] = useState<any>(
    new Date(now.getFullYear() - 10, 0, 1, 0, 0, 0)
  );
  const [maxDate, setMaxDate] = useState<any>(
    new Date(now.getFullYear() + 5, 0, 1, 0, 0, 0)
  );

  useEffect(() => {
    setMinDate(new Date(now.getFullYear() - 10, 0, 1, 0, 0, 0));
    setMaxDate(new Date(now.getFullYear(), now.getMonth(), 0, 0, 0, 0));
  }, []);

  useEffect(() => {
    const { start, end } = calcStartAndEndDate(reportType);
    setStartDate(start);
    setEndDate(end);
  }, [reportType]);

  let transferTypeItemsDropdown = [{ value: "File" }, { value: "Json" }];
  const [transferType, setTransferType] = useState<TransferType | any>(
    transferTypeItemsDropdown[1].value
  );

  let [clientVal, setClientVal] = useState<any>([]);

  const [clientError, setClientError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const validate = () => {
    setClientError(client === null);
    setDateError(
      startDate === null ||
        endDate === null ||
        isAfter(startDate, endDate) ||
        isSameDay(startDate, endDate) ||
        endDate > new Date(Date.now())
    );
  };
  useEffect(validate, [startDate, endDate, client]);

  let isCaling = false;
  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isCaling) {
      return;
    }
    isCaling = true;
    try {
      await AppAPI.request({
        url: "/api/FinDataRequest",
        method: "POST",
        data: {
          reportType,
          startDate: format(startDate as Date, "dd.MM.yyyy"),
          endDate: format(endDate as Date, "dd.MM.yyyy"),
          transferType,
          clientId: clientVal?.id,
          customerId: customer?.id,
        },
      });
      if (window.location.href.indexOf("ustomer/details") !== -1) {
        dispatch(getCustomer(customerId));
      } else {
        window.location.reload();
      }
    } catch (e) {
      // do nothing
    }
    isCaling = false;
  };

  const onReportTypeChange = (reportType: ReportType) => {
    setReportType(reportType);
  };

  if (
    customer === null ||
    (clientId !== undefined && client === null && clientVal.length == 0)
  ) {
    return <></>;
  }
  return (
    <ModalWrapper maxWidth={undefined} open={open} handleClose={handleClose}>
      <form onSubmit={onSubmit}>
        <ModalTittle children2={""}>h1.New Request</ModalTittle>
        {Object.keys(clientVal).length > 0 || clientVal == "" ? (
          <FetchOnceAutocomplete
            value={clientVal.name !== undefined ? `${clientVal.name}` : ""}
            id="client"
            label={t("Client")}
            fetchOptions={searchClients}
            onChange={setClientVal}
            error={clientError}
          />
        ) : (
          <Text id="client" label={t("Client")} value={client.name} disabled />
        )}

        <FormDateRow
          disabled={false}
          value={startDate}
          minDate={minDate}
          maxDate={maxDate}
          label="inputs.Start Date"
          onChange={(date, value) => {
            setStartDate(
              new Date(value.getFullYear(), value.getMonth(), 1, 0, 0, 0)
            );
          }}
        />
        <FormDateRow
          disabled={false}
          value={endDate}
          minDate={minDate}
          maxDate={maxDate}
          label="inputs.End Date"
          onChange={(date, value) => {
            setEndDate(
              new Date(value.getFullYear(), value.getMonth() + 1, 0, 0, 0, 0)
            );
          }}
        />
        <DefaultInput
          value={customer.name}
          label="Customer"
          onChange={() => null}
        />

        <DefaultSelect
          value={reportType}
          label="Report Type"
          dropdowns={reportTypeItemsDropDown}
          onChange={(value: any) => onReportTypeChange(value)}
        />

        <DefaultSelect
          value={transferType}
          label="Transfer Type"
          dropdowns={transferTypeItemsDropdown}
          onChange={(value: any) => setTransferType(value)}
        />

        <ButtonSave marginTop={null} marginBottom={null}>
          {t("buttons.SentRequest")}
        </ButtonSave>
      </form>
    </ModalWrapper>
  );
};

export default NewRequestPage;
