import { TableCell } from "@material-ui/core";
import PropTypes from "prop-types";

export const TextCell = (props) => {
  if (props.level > 0) {
    props.style.paddingLeft = `${2 * props.level}em`;
  }
  const additionStyles = { ...(props.additionStyles || {}) };
  if (props.color) {
    Object.assign(additionStyles, {
      background: props.color,
    });
  }
  if (props.background) {
    Object.assign(additionStyles, {
      background: props.background,
    });
  }
  if (props.textColor) {
    Object.assign(additionStyles, {
      color: props.textColor,
    });
  }
  return (
    <TableCell
      component={props.component}
      align={props.textAlign || "center"}
      colSpan={props.colSpan}
      className={"TextCell" + " " + props.className}
      style={additionStyles}
      {...(props.importantTextAlign
        ? {
            ref: (node) =>
              node &&
              node.style.setProperty(
                "text-align",
                props.importantTextAlign,
                "important"
              ),
          }
        : {})}
    >
      {props.children}
    </TableCell>
  );
};

TextCell.propTypes = {
  level: PropTypes.number,
  colSpan: PropTypes.number,
  component: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
  fontSize: PropTypes.any,
  fontWeight: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.any,
  ]).isRequired, //todo
  textAlign: PropTypes.any,
  additionStyles: PropTypes.any,
  importantTextAlign: PropTypes.string,
};
