import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { FinStatementsRu, FinStatementsEn } from "./tabs/FinStatements";
import { DebtBurdens } from "./tabs/DebtBurdens";
import { Sales } from "./tabs/sales";
import { Counterparties } from "./tabs/Counterparties";
import SummaryTab from "./tabs/summary";
import { FinDataRequest } from "@Dao/responseTypes";
import { useTranslation } from "react-i18next";
import { Tabs, Tab as MuiTab } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { requestTabFromEnum } from "@Constants/functions";
import { AppLanguagesList, RequestTab } from "@Constants/enums";
import Dq from "./tabs/Dq/Dq";
import FinStatemantsEn from "./tabs/FinStatements/en/FinStatemantsEn";
import AnalysisTab2 from "./tabs/txanalysis/AnalysisTab2";
import { getHeaderLeftComponent } from "@View/components/headers/HeaderMain";
import ClientsDetails from "../../client/clientDetails/ClientsDeatils";
import { getClient } from "@State/index";
import useAppTab from "@Utils/useAppTab";
import { useTabStyle } from "@View/style/components/tabStyle";
import AppAPI from "@API/index";
import { getRedirectUrl } from "@View/pages/AdminPO/SigninPage";
import { changeAppLanguage } from "@Localization/utils";

function switchTab(tab: RequestTab, lang: any): JSX.Element | null | any {
  switch (tab) {
    case RequestTab.FinStatements:
      return lang === "en" ? <FinStatemantsEn /> : <FinStatementsRu />;
    case RequestTab.DebtBurdens:
      return <DebtBurdens />;
    case RequestTab.Sales:
      return <Sales />;
    case RequestTab.Counterparties:
      return <Counterparties />;
    case RequestTab.Analysis:
      return <AnalysisTab2 />;
    case RequestTab.Summary:
      return <SummaryTab />;
    case RequestTab.Dq:
      return <Dq />;
  }
}

export function FinDataResultPage() {
  const { i18n, t } = useTranslation();
  const { id: finDataRequestId } = useParams<{ id: string }>();
  const [finDataRequest, setFinDataRequest] = useState<FinDataRequest | null>(
    null
  );
  const tabClasses = useTabStyle();

  const [tab, setTab] = useAppTab({
    defaultTab: RequestTab.FinStatements,
    tabList: RequestTab,
  });
  const dispatch = useDispatch();
  const [openDetails, setOpenDetails] = React.useState(false);
  const client: any = useSelector((state: any) => state.client.client);

  const handleOpenDetails = () => {
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  useEffect(() => {
    let func = async () => {
      if (finDataRequest !== null) {
        await dispatch(getClient(finDataRequest?.clientId));
        await dispatch(getHeaderLeftComponent(LeftComponent));
      }
    };
    func();
  }, [finDataRequest]);

  useEffect(() => {
    const fetch = async () => {
      const response = await AppAPI.request<FinDataRequest>({
        url: `/api/FinDataRequest/${finDataRequestId}`,
      });
      const pageLanguage =
        response.language?.toLowerCase() || AppLanguagesList.Base;
      await changeAppLanguage(pageLanguage);
      setFinDataRequest(response);
    };
    fetch();
  }, [finDataRequestId]);

  if (!finDataRequest) return null;

  const LeftComponent = () => {
    return (
      <Link
        to={getRedirectUrl()}
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "24px",
          cursor: "pointer",
          textDecoration: "none",
        }}
      >
        <div
          style={{
            marginRight: "15px",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "19px",
            color: "#1A1E24",
          }}
        >
          {finDataRequest.clientName}
        </div>
        <div
          style={{ display: "flex" }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleOpenDetails();
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.25 4.25H8.75V5.75H7.25V4.25ZM8 11.75C8.4125 11.75 8.75 11.4125 8.75 11V8C8.75 7.5875 8.4125 7.25 8 7.25C7.5875 7.25 7.25 7.5875 7.25 8V11C7.25 11.4125 7.5875 11.75 8 11.75ZM8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5ZM8 14C4.6925 14 2 11.3075 2 8C2 4.6925 4.6925 2 8 2C11.3075 2 14 4.6925 14 8C14 11.3075 11.3075 14 8 14Z"
              fill="black"
            />
          </svg>
        </div>
      </Link>
    );
  };

  return (
    <div className="finDataResult__page">
      <div>
        <Tabs
          value={tab}
          onChange={setTab}
          indicatorColor="primary"
          variant="fullWidth"
          textColor="primary"
        >
          <MuiTab
            classes={tabClasses}
            label={t(requestTabFromEnum(RequestTab.FinStatements))}
            value={RequestTab.FinStatements}
          />
          <MuiTab
            classes={tabClasses}
            label={t(requestTabFromEnum(RequestTab.DebtBurdens))}
            value={RequestTab.DebtBurdens}
          />
          <MuiTab
            classes={tabClasses}
            label={t(requestTabFromEnum(RequestTab.Sales))}
            value={RequestTab.Sales}
          />
          <MuiTab
            classes={tabClasses}
            label={t(requestTabFromEnum(RequestTab.Counterparties))}
            value={RequestTab.Counterparties}
          />
          <MuiTab
            classes={tabClasses}
            label={t(requestTabFromEnum(RequestTab.Analysis))}
            value={RequestTab.Analysis}
          />
          <MuiTab
            classes={tabClasses}
            label={t(requestTabFromEnum(RequestTab.Dq))}
            value={RequestTab.Dq}
          />
          <MuiTab
            classes={tabClasses}
            label={t(requestTabFromEnum(RequestTab.Summary))}
            value={RequestTab.Summary}
          />
        </Tabs>
      </div>
      <div>
        {Object.keys(client).length > 0 ? (
          <ClientsDetails
            client={client}
            open={openDetails}
            handleOpen={handleOpenDetails}
            handleClose={handleCloseDetails}
          />
        ) : null}
        {switchTab(tab, i18n.language)}
      </div>
    </div>
  );
}
