import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import BarChart from "@View/components/charts/BarChart/BarChart";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import formatData from "./formatData";

const chartStyleConfig = {
  width: 385,
  height: 295,
  margin: {
    top: 0,
    right: 30,
    bottom: 0,
    left: 30,
  },
  colors: {
    bars: ["#8786D2", "#9BCFDF"],
    xAxis: "#d2d8f2",
    text: "#354659",
  },
  barSize: 25,
  barGap: 0,
  titlePosition: "start",
  legendLayout: {
    top: "-10%",
    right: "5%",
  },
  xAxis: {
    height: "50",
  },
  yAxis: {
    padding: {
      bottom: "0",
    },
  },
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: `${chartStyleConfig.width}px`,
    background: "#F5F7F9",
    borderRadius: "12px",
  },
}));

const ProfitabilityChart = ({ data, page }) => {
  const { t } = useTranslation();
  const filters = useSelector((state) => state.finDataRequest.filters[page]);
  const title = t("Charts.Profitability.title");
  const legendProps = {
    operatingProfitability: t(
      "Charts.Profitability.legend.operationalProfitability"
    ),
    netProfitability: t("Charts.Profitability.legend.netProfitability"),
  };
  const operatingProfitability = formatData(
    data["operatingProfitability"],
    filters
  );
  const netProfitability = formatData(data["netProfitability"], filters);
  return (
    <BarChart
      data={{
        [legendProps.operatingProfitability]: operatingProfitability,
        [legendProps.netProfitability]: netProfitability,
      }}
      currency={"" /*data.currency.localName*/}
      dataProps={{
        name: "name",
        keys: [
          legendProps.operatingProfitability,
          legendProps.netProfitability,
        ],
      }}
      title={title}
      chartStyle={chartStyleConfig}
      formatTooltipMessage={(label, payload) => {
        return (
          <React.Fragment>
            {`${label}`}
            <br />
            {`${payload[0].dataKey} : ${payload[0].value}%`}
            <br />
            {`${payload[1].dataKey} : ${payload[1].value}%`}
          </React.Fragment>
        );
      }}
      useTooltip
    />
  );
};

ProfitabilityChart.propTypes = {
  data: PropTypes.object,
};

export default ProfitabilityChart;
