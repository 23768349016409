import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "@View/App";
import StoreProvider from "@View/providers/StoreProvider";
import ThemeProvider from "@View/providers/ThemeProvider";
import GlobalStyles from "@View/style/GlobalStyles";
import Loader from "@View/components/loader/Loader";

const bootstrap = () => {
  if (process.env.REACT_APP_YA_METRIC === "true") {
    const script = document.createElement("script");
    script.textContent = ` 
        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();
        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
  
      ym(96494516, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
      });
    `;
    document.body.appendChild(script);
  }

  ReactDOM.render(
    <StoreProvider>
      <Suspense fallback={<Loader />}>
        <ThemeProvider>
          <GlobalStyles />
          <App />
        </ThemeProvider>
      </Suspense>
    </StoreProvider>,
    document.getElementById("root")
  );
};

bootstrap();
