import { TableCell, Button, makeStyles } from "@material-ui/core";
import { Add, Remove } from '@material-ui/icons';
import _ from "lodash";
import PropTypes from "prop-types";

export const CollapseHeadClickableCell = (props) => {

    const useStyles = makeStyles(() => ({
        btn: {
            width: '100%',
            '& span': {
                display: 'inline-block',
                textOverflow: 'ellipsis', 
                overflow: 'hidden',
                textAlign: 'left'
            }
        }
    }))

    const classes = useStyles()
    return (
        <TableCell
          onClick={() => props.collapseFn()}
          component="td"
          style={{  overflow: 'hidden', minWidth: 0, textOverflow: 'ellipsis', paddingLeft: props.level > 0 ? `${props.level}em` : '', fontWeight: 500, fontSize: '13px', lineHeight: '16px', letterSpacing: '0.0075em', color: '#1A1E24', ...(props.style || {}) }}
          className={`CollapseHeadClickableCell${_.isString(props.type) ? ' ' + props.type : ''}`}
          colSpan={props.colspan || 1}
        >
            <Button
              className={classes.btn}
              variant='text'
              size="small"
              endIcon={props.isCollapsed
                ? <div style={{ display: 'flex', position: 'absolute', left: '-14px', top: '50%', transform: 'translate(0, -50%)' }}>
                    <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.71 7.29L4.3 4.7C4.69 4.31 4.69 3.68 4.3 3.29L1.71 0.700002C1.08 0.0800017 0 0.520002 0 1.41V6.58C0 7.48 1.08 7.92 1.71 7.29Z" fill="#1A1E24" />
                    </svg>
                  </div>
                : <div style={{ display: 'flex', position: 'absolute', left: '-14px', top: '50%', transform: 'translate(0, -50%)' }}>
                    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.28997 1.71L4.69997 4.3C4.30997 4.69 3.67997 4.69 3.28997 4.3L0.699971 1.71C0.0799711 1.08 0.519971 4.78949e-09 1.40997 4.36926e-08L6.57997 2.69681e-07C7.47997 3.09021e-07 7.91997 1.08 7.28997 1.71Z" fill="#203CBC" />
                    </svg>
                  </div>
            }>
                {props.text}
            </Button>
        </TableCell>
    )
}

CollapseHeadClickableCell.propTypes = {
    text: PropTypes.string.isRequired,
    isCollapsed: PropTypes.bool.isRequired,
    collapseFn: PropTypes.func.isRequired,
    type: PropTypes.string,
    style: PropTypes.object,
    colspan: PropTypes.number,
    level: PropTypes.number.isRequired
}

CollapseHeadClickableCell.defaultProps = {
    level: 0
}