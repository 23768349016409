import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PageTitle from "@View/components/PageTitle";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getAllCodes, deleteCode } from "@State/index";
import ButtonDefault from "@View/components/buttons/ButtonDefault";
import { DeleteModal } from "@View/components/DeleteModal";
import { useStyles } from "@View/style/pages/detailsStyle";
import Grid from "@material-ui/core/Grid";
import LinkButton from "@View/components/links/LinkButton";
import LinkCell from "@View/components/links/LinkCell";
import LinearProgress from "@material-ui/core/LinearProgress";
import DataTable from "@View/components/table/DataTable";
import { usePageRoot } from "@View/hooks/usePageRoot";

const T_ROOT = "listOfCodes";
//redux
const mapStateToProps = (state) => {
  return {
    codes: state.codes.codes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCodes: () => dispatch(getAllCodes()),
    deleteCode: (id) =>
      dispatch(deleteCode(id)),
  };
};

const CodeButtons = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.buttons}>
      <ButtonDefault>
        <LinkButton path={`/code/new`} textLink="buttons.Add New" />
      </ButtonDefault>
    </Grid>
  );
};

const ListOfCodesPage = ({
  codes,
  deleteCode,
  getAllCodes,
}) => {
  const { t } = useTranslation();
  const translate = (prop, useRoot = true) =>
    t(useRoot ? `${T_ROOT}.${prop}` : prop);
  const [deletedId, setDeletedId] = useState();
  const pageRoot = usePageRoot();

  useEffect(() => {
    getAllCodes();
  }, [getAllCodes]);

  const handleOpenModal = (event, id) => {
    event.preventDefault();
    setDeletedId(id);
  };

  const handleCloseModal = (event) => {
    event.preventDefault();
    setDeletedId();
  };

  const handleDelete = (event) => {
    event.preventDefault();
    deleteCode(deletedId);
    setDeletedId();
  };

  const headCellsCodes = [
    {
      field: "id",
      headerName: translate("Id"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "customerName",
      headerName: translate("CustomerName"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: translate("inputs.Status"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cell) => {
        const status = cell.row.status;
        return translate(status);
      }
    },
    {
      field: "delete",
      headerName: " ",
      flex: 0.7,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (cell) => {
        return (
          <LinkCell
            onClick={(event) => handleOpenModal(event, cell.row.id)}
            textLink={translate("Delete")}
          />
        );
      },
    },
    {
      field: "edit",
      headerName: " ",
      flex: 0.7,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (cell) => {
        return (
          <LinkCell
            path={{
              pathname: `/code/edit/${cell.row.id}`,
              prevPage: pageRoot,
            }}
            textLink={translate("buttons.Edit")}
          />
        );
      },
    },
    {
      field: "details",
      headerName: " ",
      flex: 0.7,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (cell) => {
      },
    },
  ];

  return (
    <React.Fragment>
      <PageTitle>{translate("ListOfCodes")}</PageTitle>
      <CodeButtons />
      {codes.loading ? (
        <LinearProgress />
      ) : (
        <DataTable rows={codes} columns={headCellsCodes} />
      )}
      <DeleteModal
        handleDelete={handleDelete}
        openModal={deletedId !== undefined}
        handleCloseModal={handleCloseModal}
      />
    </React.Fragment>
  );
};

ListOfCodesPage.propTypes = {
  getAllCodes: PropTypes.func.isRequired,
  codes: PropTypes.array.isRequired,
  deleteCode: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListOfCodesPage);
