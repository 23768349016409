import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  password: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0",
    },
    "& .MuiInputAdornment-positionStart": {
      paddingRight: "0",
    },
  },
  textableRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  row: {
    margin: '16px 0px',
    display: "flex",
    width: "100%",

    "& .MuiFormControl-marginNormal": {
      margin: "0",
    },

    "& > div": {
      border: 0,
      margin: 0,
      display: "inline-flex",
      padding: 0,
      position: "relative",
      minWidth: 0,
      flexDirection: "column",
      verticalAlign: "top",
      width: "100%",
    },

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0",
    },
    "& .MuiInputAdornment-positionStart": {
      paddingRight: "0",
    },
  },
  rowBigRow: {
    margin: theme.spacing(1, 1),
    display: "flex",
    width: "100%",

    "& > div": {
      border: 0,
      margin: 0,
      display: "inline-flex",
      padding: 0,
      position: "relative",
      minWidth: 0,
      flexDirection: "column",
      verticalAlign: "top",
      width: "85.5%",
    },
  },
  inputOnlyRead: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0",
    },

    "& input": {
      cursor: "default !important",
      color: "#000",
      padding: '5px',
    },

    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      outline: 'none'
    },

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23) !important",
      borderWidth: "1px",
    },
    minWidth: "70.4%",
  },
  input: {
    minWidth: "70.4%",
  },
  label: {
    maxWidth: "30%",
    fontSize: '16px',
    lineHeight: '25px',
  },
  text: {
    border: "none",
  },
}));
