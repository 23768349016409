import React from "react";
import { makeStyles } from "@material-ui/core";
import UploadIcon from "@View/components/svgComponents/UploadIcon";
import CSVIcon from "@View/components/svgComponents/CSVIcon";
import RemoveIcon from "@View/components/svgComponents/RemoveIcon";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((_theme) => ({
  choose: {
    position: "relative",
    border: "1px dashed #6FA4F2",
    borderRadius: "10px",
    background: "rgba(84, 96, 111, 0.04)",
    minHeight: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginTop: "20px",
    "& div": {
      display: "flex",
      gap: "5px",
      color: "black",
      "& span": {
        color: "rgba(111, 164, 242, 1)",
      },
    },
    "&.disabled": {
      background: "rgba(84, 96, 111, 0.08)",
      color: "rgba(161, 173, 186, 1)",
      cursor: "default",
      "& div": {
        "& span": {
          color: "rgba(161, 173, 186, 1)",
        },
        "& svg path": {
          fill: "rgba(161, 173, 186, 1)",
        },
      },
    },
    "&:not(.disabled):hover": {
      background: "rgba(84, 96, 111, 0.12)",
      "& div": {
        "& span": {
          color: "rgba(52, 94, 155, 1)",
        },
        "& svg path": {
          fill: "rgba(52, 94, 155, 1)",
        },
      },
    },
    "& input": {
      display: "block",
      cursor: "pointer",
      opacity: 0,
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
    },
    "&.disabled input": {
      display: "none",
    },
  },
  limits: {
    marginTop: "10px",
    color: "rgba(161, 173, 186, 1)",
  },
  attached: {
    marginTop: "10px",
    "& > div": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      minHeight: "48px",
      "& > div": {
        flexGrow: 1,
        flexShrink: 1,
      },
      "& + div": {
        borderTop: "1px solid rgba(243, 245, 247, 1)",
      },
      "& svg:last-child": {
        cursor: "pointer",
      },
    },
  },
  errors: {
    margin: "25px 0",
    color: "rgba(164, 36, 28, 1)",
    textAlign: "center",
    "& > div + div": {
      marginTop: "15px",
    },
  },
}));

const FileListPreview = ({ files = [], removeFile = null }) => {
  const classes = useStyles();

  if (!files.length) return null;

  return (
    <div className={classes.attached}>
      {files.map((file, index) => (
        <div key={file.name + file.size + file.lastModified}>
          <CSVIcon />
          <div>{file.name}</div>
          <RemoveIcon onClick={() => removeFile && removeFile(file)} />
        </div>
      ))}
    </div>
  );
};

const FileUploader = ({
  acceptedFormats,
  maxFileSize,
  onChange,
  multiple = true,
  canPreview = true,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedFiles, setSelectedFiles] = React.useState(null);
  const [errorList, setErrorList] = React.useState([]);

  const uploadFilesLocal = (e) => {
    const secureFiles = [];
    const errors = [];

    const fileList = Array.from(e.target.files);
    if (fileList.length) {
      fileList.forEach((file) => {
        const fileSize = file.size / (1024 * 1024);
        if (fileSize > maxFileSize) {
          errors.push({ fileName: file.name, type: "maxFileSize" });
          return;
        }
        if (
          !acceptedFormats.includes(
            (file.name.split(".").pop() || "").toLowerCase()
          )
        ) {
          errors.push({ fileName: file.name, type: "fileType" });
          return;
        }
        secureFiles.push(file);
      });
      setErrorList(errors);
      setSelectedFiles(secureFiles);
      emitNewFileList(secureFiles);
    }
  };

  const removeFile = (file) => {
    const newFiles = selectedFiles.filter((i) => i !== file);
    setSelectedFiles(newFiles);
    emitNewFileList(newFiles);
  };

  const emitNewFileList = (list) => {
    if (multiple) {
      onChange(list);
    } else if (list[0]) {
      onChange(list[0]);
    }
  };

  return (
    <div>
      <div className={classes.choose}>
        <div>
          <UploadIcon />
          <span>{t("attach.choose")}</span>
          {t("attach.file")}
        </div>
        <input
          type="file"
          multiple={multiple}
          accept={acceptedFormats.map((i) => `.${i}`).join(", ")}
          onChange={(e) => uploadFilesLocal(e)}
        />
      </div>
      <div className={classes.limits}>
        {t("attach.acceptedFormats")} {acceptedFormats.join(", ")} |{" "}
        {t("attach.maxFileSize")} {maxFileSize} MB
      </div>
      {errorList.length > 0 && (
        <div className={classes.errors}>
          {errorList.map((error, index) => (
            <div key={error.type + index}>
              {t(`attach.errors.${error.type}`)}
              {error.type === "maxFileSize"
                ? `${maxFileSize} MB`
                : acceptedFormats.join(", ")}
            </div>
          ))}
        </div>
      )}
      {canPreview && selectedFiles && (
        <FileListPreview
          files={selectedFiles}
          removeFile={(e) => removeFile(e)}
        />
      )}
    </div>
  );
};

export default FileUploader;
