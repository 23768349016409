import { makeStyles } from "@material-ui/core";
import ModalWrapper from "@View/components/ModalWrapper";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AppAPI from "@API/index";
import ModalTittle from "@View/components/ModalTittle";
import { downloadFinDataTemplateFileFunc } from "@Utils/downloadLinks";
import RequestsTable from "@View/pages/AdminPO/customer/customerDetails/RequestsTable";
import { useButtonStyles } from "@View/pages/AdminPO/customer/customerDetails/bankStatement/styles";
import CommonError from "@View/pages/AdminPO/customer/customerDetails/bankStatement/CommonError";
import { useFileLoader } from "@Utils/useFileLoader";
import FileLoadingProcess from "@View/components/ui/FileLoadingProcess";
import RequestSearcher from "@View/components/ui/RequestSearcher";

const useStyles = makeStyles((_theme) => ({
  cover: {
    color: "#54606F",
    fontSize: "13px",
    marginTop: "15px",
    "& > div + div": {
      marginTop: "10px",
    },
    "& ol": {
      paddingLeft: "15px",
    },
  },
  requests: {
    maxHeight: "300px",
    overflow: "auto",
    boxSizing: "border-box",
  },
}));

const BankStatements1CModal = ({ open, handleClose, client }) => {
  const userId = client.id;

  const { t } = useTranslation();

  const [isError, setIsError] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const classes = useStyles();
  const btnClasses = useButtonStyles();

  const { openChooseFileWindow } = useFileLoader();

  const selectFile = () => {
    openChooseFileWindow({ accept: ".txt", multiple: true }, async (files) => {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("clientId", userId);
      Array.from(files).forEach((file) => {
        formData.append("file", file);
      });
      if (selectedItems.length) {
        selectedItems.forEach((requestId) => {
          formData.append("requestId", requestId);
        });
      }

      try {
        await AppAPI.request({
          url: "/api/FinDataRequest/upload-transactions/txt",
          method: "POST",
          data: formData,
        });
        handleClose();
      } catch (e) {
        setIsError(true);
      }
      setIsLoading(false);
    });
  };

  const tableItems = useMemo(() => {
    if (search.length < 2) return client.finDataRequests;

    return client.finDataRequests.filter((i) =>
      i.customerName.toLowerCase().includes(search.toLowerCase())
    );
  }, [client, search]);

  return (
    <ModalWrapper open={open} handleClose={handleClose} maxWidth={"720px"}>
      <ModalTittle>bankStatement.title</ModalTittle>
      {isError ? (
        <CommonError closeError={() => setIsError(false)} />
      ) : isLoading ? (
        <FileLoadingProcess />
      ) : (
        <div className={classes.cover}>
          <RequestSearcher
            value={search}
            updateValue={setSearch}
            selectAction={selectFile}
          />
          <div>{t("bankStatement.currentRequests")}</div>
          <div className={classes.requests}>
            <RequestsTable
              selectedItems={selectedItems}
              changeSelection={setSelectedItems}
              items={tableItems}
            />
          </div>
          <div style={{ marginTop: "35px" }}>
            <button
              className={btnClasses.addBtn}
              disabled={!selectedItems.length}
              onClick={() => selectFile()}
            >
              {t("bankStatement.add")}
            </button>
          </div>
          <div style={{ textAlign: "center", marginTop: "15px" }}>
            <a
              href="#"
              style={{
                color: "rgba(111, 164, 242, 1)",
                fontWeight: "600",
                fontSize: "15px",
                textDecoration: "none",
              }}
              onClick={() => downloadFinDataTemplateFileFunc(userId)}
            >
              {t("attach.getSamples")}
            </a>
          </div>
        </div>
      )}
    </ModalWrapper>
  );
};

export default BankStatements1CModal;
