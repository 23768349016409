import { formatDate } from "@Localization/utils";
import { getUser } from "@State/index";
import DefaultInput from "@View/components/formInputs/DefaultInput";
import ModalTittle from "@View/components/ModalTittle";
import ModalWrapper from "@View/components/ModalWrapper";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const UserDetailsPage = ({ userId, open, handleOpen, handleClose }) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    let getUsersAndLoader = async () => {
      await dispatch(getUser(userId));
    };
    if (userId && open === true) {
      getUsersAndLoader();
    }
  }, [userId, open]);

  if (Object.getOwnPropertyNames(user).length === 0) return <></>;

  return (
    <ModalWrapper open={open} handleOpen={handleOpen} handleClose={handleClose}>
      <ModalTittle children2={user.userName}>h1.Details of</ModalTittle>
      <form>
        <DefaultInput label="Id" value={user.id} onChange={() => null} />
        <DefaultInput
          label="inputs.Create Date"
          value={formatDate(user.createdAt)}
          onChange={() => null}
        />
        <DefaultInput
          label="FullName"
          value={user.fullName}
          onChange={() => null}
        />
        <DefaultInput
          label="Login"
          value={user.userName}
          onChange={() => null}
        />
        <DefaultInput
          label="inputs.Status"
          value={user.status}
          onChange={() => null}
        />
        <DefaultInput
          label="inputs.Updated At"
          value={formatDate(user.updatedAt)}
          onChange={() => null}
        />
        <DefaultInput
          label="inputs.Email"
          value={user.email}
          onChange={() => null}
        />
        <DefaultInput
          label="inputs.Role"
          value={user.role === null ? "" : t(`${user.role}`)}
          onChange={() => null}
        />
      </form>
    </ModalWrapper>
  );
};

export default UserDetailsPage;
