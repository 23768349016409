import { getRole } from "@Security/stateHelpers";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { login, restore } from "@State/effects/security/login";
import ModalTittle from "@View/components/ModalTittle";
import DefaultInput from "@View/components/formInputs/DefaultInput";
import ButtonSave from "@View/components/buttons/ButtonSave";
import Logo from "@View/components/svgComponents/Logo";
import DefaultPassword from "@View/components/formInputs/DefaultPassword";
import Localization from "@View/components/Localization";
import Loader from "@View/components/loader/Loader";
import { Snackbar } from "@State/actions/actionCreators";
import { getRedirectUrl } from "@View/pages/AdminPO/SigninPage";

const box: any = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#FEFFFF",
  outline: "none",
  borderRadius: "9px",
  maxWidth: "360px",
  width: "100%",
  height: "auto",
  padding: " 20px 40px",
  boxSizing: "border-box",
};

const RestorePage = () => {
  const isAuth: boolean = useSelector(
    (state) => (state as any).auth as boolean
  );
  const history = useHistory();

  if (isAuth) {
    history.push(getRedirectUrl());
  }

  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (email.trim().length) {
      await restore({ email });
      setEmail("");
      history.push("/");
    }
  };

  const changeEmail = (value: any) => {
    setEmail(value);
  };

  return (
    <>
      <Loader />
      <div style={box}>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div style={{ marginTop: "20px" }}></div>
          <div style={{ position: "absolute", right: "5px", top: "0px" }}>
            <Localization />
          </div>

          <div style={{ marginBottom: "30px", marginTop: "30px" }}>
            <ModalTittle children2={""}>{t("h1.Restore")}</ModalTittle>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <Logo width="126" height="24" />
          </div>

          <DefaultInput
            label={"inputs.Email"}
            value={email}
            onChange={(v: any) => changeEmail(v)}
          />
          <div style={{ width: "270px" }}>
            <ButtonSave marginTop={null} marginBottom={"20px"}>
              buttons.Restore
            </ButtonSave>
          </div>
          <Link style={{ textDecoration: "none" }} to={`/signup`}>
            <div
              style={{
                textAlign: "center",
                color: "#6FA4F2",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              {t("Sign Up")}
            </div>
          </Link>
          <Link style={{ textDecoration: "none" }} to={`/`}>
            <div
              style={{
                textAlign: "center",
                color: "#6FA4F2",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
              }}
            >
              {t("SignIn")}
            </div>
          </Link>
        </form>
      </div>
    </>
  );
};

export default RestorePage;
