import React from "react";
import DqLineChart from "./DqLineChart";

const DqCharts = ({ row }) => {
  return (
    <div
      style={{ diplay: "flex", flexFlow: "column", justifyContent: "center" }}
    >
      <div
        style={{
          textAlign: "center",
          color: "#46515D",
          fontWeight: 400,
          fontSize: "25px",
          lineHeight: "22px",
          marginBottom: "20px",
        }}
      >
        {row.criterias[0].titleCriteria}
      </div>
      {row.criterias.map((el) => {
        return (
          <>
            <div
              style={{
                textAlign: "center",
                color: "#46515D",
                fontWeight: 400,
                fontSize: "25px",
                lineHeight: "22px",
                marginBottom: "20px",
              }}
            >
              {el.title}
            </div>
            {el.checks.map((e) => {
              return <DqLineChart data={e} />;
            })}
          </>
        );
      })}
    </div>
  );
};

export default DqCharts;
