import {
  GET_ALL_USERS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
} from "../actionTypes";
import AppAPI from "@API/index";


//fetch one client
export const getUserRequest = () => {
  return {
    type: GET_USER_REQUEST,
  };
};

const getUserSuccess = (user) => {
  return {
    type: GET_USER_SUCCESS,
    payload: user,
  };
};

export const getUser = (id) => {
  return (dispatch) => {
    dispatch(getUserRequest());

    AppAPI.request({
      method: "get",
      url: `/api/Account/${id}`,
      errorText: 'snackbar.error'
    })
      .then((data) => {
      const user = {
        id: data?.id,
        userName: data?.userName,
        createdAt: data.createdAt,
        role: data?.role,
        email: data?.email,
        status: data?.status,
        updatedAt: data.updatedAt,
        clientName: data?.client?.name,
        customerName: data?.customer?.name,
        department: data?.department,
        password: "",
        fullName: data?.fullName
      };
      dispatch(getUserSuccess(user));
    })
      .catch((error) => {
        // do nothing
      });
  };
};

const getAllUsersAction = (payload) => {
  return {
    type: GET_ALL_USERS,
    payload: payload,
  };
};

export const geAlltUsersRequest = (id) => {
  return (dispatch) => {
    AppAPI.request({
      method: "get",
      url: `/api/Account`,
    })
      .then((data) => {
        dispatch(getAllUsersAction(data))
      })
      .catch((error) => {
        // do nothing
      });
  };
};
