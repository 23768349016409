import AppAPI from "@API/index";

export const changePassword = (body, history, errorText) => {
  const newBody = {
    id: body.id,
    currentPassword: body.oldpassword,
    newPassword: body.newpassword,
  };
  return () => {
    AppAPI.request({
      method: "put",
      url: `/api/Account/changepassword`,
      data: newBody,
    })
      .then((response) => {
        document.cookie = "token=";
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("userName");
        localStorage.removeItem("role");
        localStorage.removeItem("headerLogoImg");
        localStorage.removeItem("footerLogoImg");
        localStorage.removeItem("redirectUrl");
        window.location.reload();
      })
  };
};
