import { LoginSuccess, Logout } from "@State/actions/actionCreators";
import { Action } from "redux";
import { isAuth } from "@Security/stateHelpers";

export function auth(state = isAuth(), action: Action): AuthStatue {
  if (LoginSuccess.match(action)) {
    return true;
  }
  if (Logout.match(action)) {
    return false;
  }
  return state;
}

export type AuthStatue = boolean;
