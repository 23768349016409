import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next';

const DefaultPassword = ({ value, onChange, repeat }) => {


  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { t } = useTranslation()

  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormControl className={'PassInput'}>
      <InputLabel htmlFor="standard-adornment-password">{repeat ? t("inputs.Repeat password") : t("inputs.Password")}</InputLabel>
      <Input
        id="standard-adornment-password"
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {value.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  )
}

export default DefaultPassword