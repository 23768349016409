import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { useStyles } from "../../style/components/buttonStyle";

const ButtonDefault = ({
  children,
  onClick,
  fullWidth,
  textButton,
  textTransform,
  marginRight,
  disabled,
}) => {
  const classes = useStyles({
    textTransform,
    marginRight,
  });
  const { t } = useTranslation();
  return (
    <Button
      type="submit"
      // variant="contained"
      className={classes.button + ' ' + 'redesignBtn'}
      onClick={onClick}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {textButton ? t(textButton) : children}
    </Button>
  );
};

ButtonDefault.propTypes = {
  textButton: PropTypes.string,
  textTransform: PropTypes.string,
  marginRight: PropTypes.number,
  children: PropTypes.node,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ButtonDefault;
