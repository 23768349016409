import { Client, Customer } from "@Dao/responseTypes"
import { createAction } from "@reduxjs/toolkit"
import { ActionType } from "./actionTypes"

export const Init = createAction(ActionType.INIT)

export const CustomerRequest = createAction(ActionType.CUSTOMER_REQUEST)
export const CustomerSuccess = createAction<Customer>(ActionType.CUSTOMER_SUCCESS)
export const CustomerFailure = createAction(ActionType.CUSTOMER_FAILURE)

export const ClientRequest = createAction(ActionType.CLIENT_REQUEST)
export const ClientSuccess = createAction<Client>(ActionType.CLIENT_SUCCESS)
export const ClientFailure = createAction(ActionType.CLIENT_FAILURE)

export const LoginSuccess = createAction(ActionType.LOGIN_SUCCESS)

/** just set auth = false */
export const Logout = createAction(ActionType.LOGOUT)

export const Snackbar = createAction<{message: string, open: boolean, description?: string}>(ActionType.SET_SNACKBAR)