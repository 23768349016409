import { format } from "date-fns";
import i18n from "./i18n";

enum Lang {
  Ru = "ru",
  En = "en",
}

export function getLanguage(): Lang {
  return i18n.language as Lang.Ru | Lang.En;
}

export async function changeAppLanguage(lang: string) {
  lang = lang.toLowerCase();
  if (["en", "ru"].includes(lang) && i18n.language !== lang) {
    window.localStorage.setItem("appLang", lang);
    await i18n.changeLanguage(lang);
  }
}

export function formatDate(date: string | Date): string {
  const parsedDate =
    date === "string" || date instanceof String ? new Date(date) : date;

  if (parsedDate instanceof Date && isNaN(parsedDate.getTime())) {
    return i18n.t("Invalid Date");
  }

  switch (getLanguage()) {
    case Lang.Ru:
      return format(new Date(date), "dd.MM.yyyy");
    case Lang.En:
      return format(new Date(date), "MM/dd/yyyy");
  }
}
