import { TextField } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next';
import'./inputs.css'

const DefaultInput = ({label, value, onChange}) => {
    const { t } = useTranslation();

  return (
    <TextField
      className={'default__input'}
      onChange={(event) => onChange(event.target.value)}
      label={t(label)}
      value={value}
    />
  )
}

export default DefaultInput