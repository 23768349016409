import { Logout } from "@State/actions/actionCreators";
import { Dispatch } from "redux";
import {clearAuthSession} from "@State/effects/security/login";
import AppAPI from "@API/index";

export function logout() {
    return async (dispatch: Dispatch): Promise<void> => {
        await AppAPI.request({
            method: 'post',
            url: '/api/Auth/LogOut',
            suppressError: true
        });
        clearAuthSession();
        dispatch(Logout())
    }
}