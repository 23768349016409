import { getClientId, getCustomerId, getRole } from "@Security/stateHelpers";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { login } from "@State/effects/security/login";
import ModalTittle from "@View/components/ModalTittle";
import DefaultInput from "@View/components/formInputs/DefaultInput";
import ButtonSave from "@View/components/buttons/ButtonSave";
import Logo from "@View/components/svgComponents/Logo";
import DefaultPassword from "@View/components/formInputs/DefaultPassword";
import Localization from "@View/components/Localization";
import Loader from "@View/components/loader/Loader";

const box: any = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#FEFFFF",
  outline: "none",
  borderRadius: "9px",
  maxWidth: "360px",
  width: "100%",
  height: "auto",
  padding: " 20px 40px",
  boxSizing: "border-box",
};

const SigninPage = () => {
  const isAuth: boolean = useSelector(
    (state) => (state as any).auth as boolean
  );
  const history = useHistory();
  if (isAuth) {
    history.push(getRedirectUrl());
  }

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatchLogin = () =>
    login({ username: username, password: password })(dispatch);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    dispatchLogin();
  };

  const changeUsername = (value: any) => {
    setUsername(value);
  };

  const changePassword = (newValue: string) => {
    setPassword(newValue);
  };

  return (
    <>
      <Loader />
      <div style={box}>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div style={{ paddingTop: "30px" }}></div>
          <div style={{ position: "absolute", right: "5px", top: "0px" }}>
            <Localization />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <Logo width="126" height="24" />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <ModalTittle children2={""}>{t("h1.Enter")}</ModalTittle>
          </div>

          <DefaultInput
            label={"inputs.Name"}
            value={username}
            onChange={(v: any) => changeUsername(v)}
          />
          <DefaultPassword
            repeat={false}
            value={password}
            onChange={changePassword}
          />
          <div style={{ width: "270px" }}>
            <ButtonSave marginTop={null} marginBottom={"20px"}>
              buttons.Log In
            </ButtonSave>
          </div>
          <Link style={{ textDecoration: "none" }} to={`/signup`}>
            <div
              style={{
                textAlign: "center",
                color: "#6FA4F2",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
              }}
            >
              {t("Sign Up")}
            </div>
          </Link>
          <Link style={{ textDecoration: "none" }} to={`/reset-password`}>
            <div
              style={{
                textAlign: "center",
                color: "#6FA4F2",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                marginTop: "5px",
              }}
            >
              {t("ForgotPassword")}
            </div>
          </Link>
        </form>
      </div>
    </>
  );
};

export function getRedirectUrl(): string {
  let role;
  try {
    role = getRole();
  } catch (e) {
    return "/";
  }
  const clientId = getClientId();
  const customerId = getCustomerId();

  switch (role) {
    case "Admin":
      return "/customers";
    case "ClientUser":
    case "ClientGuest":
      return `/client/details/${clientId}`;
    case "ClientManager":
      return `/client/details/${clientId}`;
    case "CustomerUser":
    case "CustomerGuest":
      return `/customer/details/${customerId}`;
    case "CustomerManager":
      return `/customer/details/${customerId}`;
  }
}

export default SigninPage;
