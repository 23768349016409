import ModalTittle from "@View/components/ModalTittle";
import ModalWrapper from "@View/components/ModalWrapper";
import { AccordionDetails } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppAPI from "@API/index";
import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
} from "@View/pages/AdminPO/client/CreaditOpinion";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ColorButton } from "@Components/button/ColorButton";
import { formatDate } from "@Localization/utils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import DefaultSelect, {
  SelectIconComponent,
} from "@View/components/formInputs/DefaultSelect";
import DefaultInput from "@View/components/formInputs/DefaultInput";
import ButtonSave from "@View/components/buttons/ButtonSave";
import { MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";

const CreditRow = ({ index, name, v1, v2 = "", desc = "" }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "30px 320px auto",
        gap: "30px",
      }}
    >
      <div>{index}</div>
      <div style={{ paddingRight: "10px" }}>
        <div style={{ fontSize: "16px", fontWeight: "bold" }}>{name}</div>
        {desc && <div style={{ paddingTop: "10px" }}>{desc}</div>}
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "15px",
        }}
      >
        <div>{v1}</div>
        <div>{v2}</div>
      </div>
    </div>
  );
};

const CreditSection = ({ index, name, children, color = "" }) => {
  return (
    <AccordionStyled disableGutters className="acc-no-mb">
      <AccordionSummaryStyled expandIcon={<ArrowDropDownIcon />}>
        {index}. {name}
        {color && <ColorButton style={{ color }} disabled />}
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>
        <div
          style={{
            padding: "15px 15px 15px 50px",
            fontSize: "13px",
            color: "#1A1E24",
            display: "flex",
            flexDirection: "column",
            gap: 25,
          }}
        >
          {children}
        </div>
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};

const ActivityTypeOptions = [
  { value: "Sales", name: "AversCredit.ActivityTypeOptions.Sales" },
  {
    value: "Production",
    name: "AversCredit.ActivityTypeOptions.Production",
  },
];

const AversFirstForm = ({ clientId, updateData }) => {
  const [formData, setFormData] = React.useState({
    ActivityType: ActivityTypeOptions[0].value,
    RequestedLoan: 0,
  });

  const handleChange = (value, label) => {
    setFormData({ ...formData, [label]: value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (formData.RequestedLoan) {
      try {
        await AppAPI.request({
          url: `/api/client/${clientId}/avers-rating`,
          method: "post",
          data: formData,
        });
        updateData();
      } catch (e) {}
    }
  };

  return (
    <form onSubmit={(e) => submitForm(e)}>
      <DefaultSelect
        value={`${formData.ActivityType}`}
        label="AversCredit.ActivityType"
        dropdowns={ActivityTypeOptions}
        onChange={(value) => handleChange(value, "ActivityType")}
      />
      <DefaultInput
        value={formData.RequestedLoan}
        label="AversCredit.RequestedLoan"
        onChange={(value) => handleChange(value, "RequestedLoan")}
      />
      <ButtonSave>buttons.Save</ButtonSave>
    </form>
  );
};

const AversCategory = ({ item, updateItem, shouldShowError, level = 1 }) => {
  const subItems = item.items || item.subItems;

  const selectOption = (value) => {
    const newOptions = item.options.map((option) => ({
      ...option,
      selected: option.key === value,
    }));
    const newItem = {
      ...item,
      options: newOptions,
    };
    updateItem(item.key, JSON.parse(JSON.stringify(newItem)));
  };

  const selectedValue = Array.isArray(item.options)
    ? item.options.find((i) => i.selected)?.key ||
      // item.options.find((i) => (i.key || "").toLowerCase() === "no")?.key ||
      null
    : null;

  const shouldShowErrorLocally = shouldShowError && !selectedValue;

  if (level === 3) {
    return (
      <TableContainer component={Paper} sx={{ marginBottom: "1px" }}>
        <Table>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {item.displayName}
              </TableCell>
              {item.type === "Manual" && Array.isArray(item.options) ? (
                <TableCell component="th" align="right" sx={{ width: 150 }}>
                  <FormControl
                    className={`DefaultSelect selectFixedWidth no-margin ${
                      shouldShowErrorLocally ? "selectErrorState" : ""
                    }`}
                    variant="outlined"
                  >
                    {/*<InputLabel>{t("AversCredit.selectedItem")}</InputLabel>*/}
                    <Select
                      value={selectedValue}
                      onChange={(event) => selectOption(event.target.value)}
                      IconComponent={SelectIconComponent}
                    >
                      {item.options.map((optionItem, index) => (
                        <MenuItem value={optionItem.key} key={index}>
                          {t(optionItem.displayName)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              ) : (
                <>
                  <TableCell
                    component="th"
                    align="right"
                    style={{ width: "70px" }}
                  >
                    {item.value ?? "-"}
                  </TableCell>
                  <TableCell
                    component="th"
                    align="right"
                    style={{ width: "70px" }}
                  >
                    {item.score ?? "-"}
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  if (level === 2 && (!Array.isArray(subItems) || !subItems.length)) {
    return (
      <TableContainer
        component={Paper}
        sx={{
          marginBottom: "1px",
          background: "#EAEDF0",
          borderRadius: 0,
          boxShadow: "none",
        }}
      >
        <Table>
          <TableBody>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{
                  color: "#1A1E24",
                  fontWeight: 500,
                  paddingLeft: 50,
                  fontSize: "16px",
                  ...(item.value ? { borderBottom: "none" } : {}),
                }}
              >
                {item.displayName}
              </TableCell>
              <TableCell
                component="th"
                align="right"
                style={{ ...(item.value ? { borderBottom: "none" } : {}) }}
              >
                {item.score ?? "-"}
              </TableCell>
              {item.type === "Manual" && Array.isArray(item.options) ? (
                <TableCell
                  component="th"
                  align="right"
                  sx={{
                    width: 150,
                    ...(item.value ? { borderBottom: "none" } : {}),
                  }}
                >
                  <FormControl
                    className={`DefaultSelect no-margin selectFixedWidth ${
                      shouldShowErrorLocally ? "selectErrorState" : ""
                    }`}
                    variant="outlined"
                  >
                    {/*<InputLabel>{t("AversCredit.selectedItem")}</InputLabel>*/}
                    <Select
                      value={selectedValue}
                      onChange={(event) => selectOption(event.target.value)}
                      IconComponent={SelectIconComponent}
                    >
                      {item.options.map((optionItem, index) => (
                        <MenuItem value={optionItem.key} key={index}>
                          {t(optionItem.displayName)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              ) : null}
            </TableRow>
            {item.value ? (
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    color: "#1A1E24",
                    fontWeight: 400,
                    paddingLeft: 50,
                    paddingTop: 0,
                    fontSize: "14px",
                  }}
                  align="left"
                  colspan={
                    item.type === "Manual" && Array.isArray(item.options)
                      ? 3
                      : 2
                  }
                >
                  {item.value}
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <AccordionStyled
      disableGutters
      defaultExpanded
      className="acc-no-mb acc-no-content-border mbsx"
    >
      <AccordionSummaryStyled expandIcon={<ArrowDropDownIcon />}>
        {item.displayName}
      </AccordionSummaryStyled>
      <AccordionDetails style={{ paddingLeft: "50px", paddingRight: 0 }}>
        <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
          <Table>
            <TableBody>
              {/*<TableRow*/}
              {/*  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}*/}
              {/*>*/}
              {/*  <TableCell component="th" scope="row">*/}
              {/*    {t("AversCredit.value")}*/}
              {/*  </TableCell>*/}
              {/*  <TableCell component="th" align="right">*/}
              {/*    {item.value ?? "-"}*/}
              {/*  </TableCell>*/}
              {/*</TableRow>*/}
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {t("AversCredit.score")}
                </TableCell>
                <TableCell component="th" align="right">
                  {item.score ?? "-"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {item.type === "Manual" && Array.isArray(item.options) ? (
          <FormControl
            className={`DefaultSelect selectFixedWidth ${
              shouldShowErrorLocally ? "selectErrorState" : ""
            }`}
            variant="outlined"
          >
            <InputLabel>{t("AversCredit.selectedItem")}</InputLabel>
            <Select
              value={selectedValue}
              onChange={(event) => selectOption(event.target.value)}
              IconComponent={SelectIconComponent}
            >
              {item.options.map((optionItem, index) => (
                <MenuItem value={optionItem.key} key={index}>
                  {t(optionItem.displayName)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}

        {Array.isArray(subItems) ? (
          <div>
            {subItems.map((subItem) => (
              <AversCategory
                key={subItem.key || subItem.displayName}
                item={subItem}
                updateItem={updateItem}
                shouldShowError={shouldShowError}
                level={level + 1}
              />
            ))}
          </div>
        ) : null}
      </AccordionDetails>
    </AccordionStyled>
  );
};

const doesIntersectionsExist = (arr_1, arr_2) => {
  let isIntersected = false;
  arr_1.forEach((i) => {
    if (arr_2.includes(i)) {
      isIntersected = true;
    }
  });
  return isIntersected;
};

const getAversDataForSaving = (data) => {
  const returnData = [];
  data.categories.forEach((category) => {
    if (Array.isArray(category.items)) {
      category.items.forEach((categoryItem) => {
        if (
          categoryItem.type === "Manual" &&
          Array.isArray(categoryItem.options)
        ) {
          returnData.push({
            key: categoryItem.key,
            options: categoryItem.options.map((option) => ({
              key: option.key,
              selected: option.selected,
            })),
          });
        }
        if (Array.isArray(categoryItem.subItems)) {
          categoryItem.subItems.forEach((subItem) => {
            if (subItem.type === "Manual" && Array.isArray(subItem.options)) {
              returnData.push({
                key: subItem.key,
                options: subItem.options.map((option) => ({
                  key: option.key,
                  selected: option.selected,
                })),
              });
            }
          });
        }
      });
    }
  });
  return returnData;
};

const AversForm = ({ clientId, formData, setFormData }) => {
  const { t } = useTranslation();
  const [shouldShowError, setShouldShowError] = useState(false);

  const handleChange = (value, label) => {
    setFormData({ ...formData, [label]: value });
  };

  const unselectAllOptions = (options) =>
    options.map((optionItem) => ({
      ...optionItem,
      selected: false,
    }));

  const updateItem = (key, item) => {
    const selectedValue = item.options.find((i) => i.selected);
    const valueForRecalculation = "yes";

    const newCategories = JSON.parse(JSON.stringify(formData.categories)).map(
      (category) => {
        const doesKeyExistInCategoryItems = category.items.find(
          (categoryItem) => categoryItem.key === key
        );
        return {
          ...category,
          items: category.items.map((categoryItem) => {
            if (doesKeyExistInCategoryItems && categoryItem.key === key) {
              return item;
            } else {
              const doesKeyExistSubItems = categoryItem.subItems.find(
                (subItem) => subItem.key === key
              );
              const categoryItemNew = {
                ...categoryItem,
                subItems: categoryItem.subItems.map((subItem) => {
                  if (doesKeyExistSubItems && subItem.key === key) {
                    return item;
                  } else {
                    if (
                      doesKeyExistSubItems &&
                      selectedValue.key === valueForRecalculation &&
                      Array.isArray(subItem.options) &&
                      Array.isArray(subItem.group) &&
                      !doesIntersectionsExist(subItem.group, item.group)
                    ) {
                      subItem.options = unselectAllOptions(subItem.options);
                    }
                    return subItem;
                  }
                }),
              };
              if (
                doesKeyExistInCategoryItems &&
                selectedValue.key === valueForRecalculation &&
                Array.isArray(categoryItemNew.options) &&
                Array.isArray(categoryItemNew.group) &&
                !doesIntersectionsExist(categoryItemNew.group, item.group)
              ) {
                categoryItemNew.options = unselectAllOptions(
                  categoryItemNew.options
                );
              }

              return categoryItemNew;
            }
          }),
        };
      }
    );
    handleChange(newCategories, "categories");
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const items = getAversDataForSaving(formData);
    const notSelectedItems = items.filter(
      (i) => !i.options.find((j) => j.selected)
    );
    if (notSelectedItems.length) {
      setShouldShowError(true);
      return;
    } else {
      setShouldShowError(false);
    }

    const data = {
      ActivityType: formData.activityType,
      RequestedLoan: +formData.requestedLoan || 0,
      Items: getAversDataForSaving(formData),
    };
    try {
      await AppAPI.request({
        url: `/api/client/${clientId}/avers-rating`,
        method: "post",
        data,
      });
    } catch (e) {}
  };

  return (
    <>
      <form onSubmit={(e) => submitForm(e)}>
        <DefaultSelect
          value={`${formData.activityType}`}
          label="AversCredit.ActivityType"
          dropdowns={ActivityTypeOptions}
          onChange={(value) => handleChange(value, "activityType")}
        />
        <DefaultInput
          value={formData.requestedLoan}
          label="AversCredit.RequestedLoan"
          onChange={(value) => handleChange(value, "requestedLoan")}
        />
        <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
          <Table>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {t("creditScore.totalScore")}
                </TableCell>
                <TableCell component="th" align="right">
                  {formData.totalScore}
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {t("creditScore.formationDate")}
                </TableCell>
                <TableCell component="th" align="right">
                  {formatDate(formData.formationDate)}
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {t("creditScore.reportDate")}
                </TableCell>
                <TableCell component="th" align="right">
                  {formatDate(formData.reportDate)}
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {t("inputs.CreditScore")}
                </TableCell>
                <TableCell component="th" align="right">
                  {formData.ratingLevel}
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {t("AversCredit.ratingCategory")}
                </TableCell>
                <TableCell component="th" align="right">
                  {formData.ratingCategory}
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {t("AversCredit.financialCategory")}
                </TableCell>
                <TableCell component="th" align="right">
                  {formData.financialCategory}
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {t("AversCredit.reserveInterest")}
                </TableCell>
                <TableCell component="th" align="right">
                  {formData.reserveInterest}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {formData.categories.map((category) => (
          <AversCategory
            item={category}
            key={category.key || category.displayName}
            updateItem={updateItem}
            shouldShowError={shouldShowError}
            level={1}
          />
        ))}
        {shouldShowError ? (
          <div style={{ color: "red", marginTop: "10px" }}>
            {t("formErrors.notAllItemsSelected")}
          </div>
        ) : null}
        {/*<DefaultSelect*/}
        {/*  value={`${formData.ActivityType}`}*/}
        {/*  label="inputs.Status"*/}
        {/*  dropdowns={ActivityTypeOptions}*/}
        {/*  onChange={(value) => handleChange(value, "ActivityType")}*/}
        {/*/>*/}
        {/*<DefaultInput*/}
        {/*  value={formData.RequestedLoan}*/}
        {/*  label="inputs.External Id"*/}
        {/*  onChange={(value) => handleChange(value, "RequestedLoan")}*/}
        {/*/>*/}
        <ButtonSave>buttons.Save</ButtonSave>
      </form>
    </>
  );
};

const CreditScore = ({ clientId, data, setData }) => {
  const { t } = useTranslation();

  const recalculate = async () => {
    const data = await AppAPI.request({
      url: `/api/client/${clientId}/credit-rating?isNew=true`,
    });
    setData(data);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {t("creditScore.totalRatingWithWarningSignals")}:
              </TableCell>
              <TableCell component="th" align="right">
                {data.totalRatingWithWarningSignals}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {t("creditScore.totalScore")}
              </TableCell>
              <TableCell component="th" align="right">
                {data.totalScore}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ paddingBottom: 30, fontSize: 14 }}>{data.details}</div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 40,
        }}
      >
        <div style={{ fontSize: 14 }}>
          <div style={{ paddingBottom: 5 }}>
            {t("creditScore.formationDate")}
          </div>
          <div>{formatDate(data.formationDate)}</div>
        </div>
        <div style={{ fontSize: 14 }}>
          <div style={{ paddingBottom: 5 }}>{t("creditScore.reportDate")}</div>
          <div>{formatDate(data.reportEndDate)}</div>
        </div>
      </div>

      {Array.isArray(data.financialMetrics?.items) ? (
        <CreditSection index={1} name={t("creditScore.financialMetrics")}>
          {data.financialMetrics?.items.map((item, index) => (
            <CreditRow
              index={`1.${index + 1}`}
              name={item.name}
              v1={item.value}
              v2={item.score}
            />
          ))}
        </CreditSection>
      ) : null}
      {Array.isArray(data.businessReputation?.items) ? (
        <CreditSection index={2} name={t("creditScore.businessReputation")}>
          {data.businessReputation?.items.map((item, index) => (
            <CreditRow
              index={`2.${index + 1}`}
              name={item.name}
              v1={item.value}
              v2={item.score}
            />
          ))}
        </CreditSection>
      ) : null}
      {Array.isArray(data.managersReputation?.items) ? (
        <CreditSection index={3} name={t("creditScore.managersReputation")}>
          {data.managersReputation.items.map((item, index) => (
            <CreditRow
              index={`3.${index + 1}`}
              name={item.name}
              v1={item.value}
              v2={item.score}
            />
          ))}
        </CreditSection>
      ) : null}
      {Array.isArray(data.warningSignals?.items) ? (
        <CreditSection index={4} name={t("creditScore.warningSignals")}>
          {data.warningSignals.items.map((item, index) => (
            <CreditRow
              index={`4.${index + 1}`}
              name={item.name}
              v1={item.value}
              v2={item.score}
            />
          ))}
        </CreditSection>
      ) : null}
      {Array.isArray(data.dueDiligenceAnalysis?.items) ? (
        <CreditSection index={5} name={t("creditScore.dueDiligenceAnalysis")}>
          {data.dueDiligenceAnalysis?.items.map((item, index) => (
            <CreditRow
              index={`5.${index + 1}`}
              name={item.name}
              v1={item.value}
              v2={item.score}
            />
          ))}
        </CreditSection>
      ) : null}
      {Array.isArray(data.importantFacts?.danger) ? (
        <CreditSection
          index={"6.1"}
          name={t("creditScore.importantFacts")}
          color="red"
        >
          {data.importantFacts.danger.map((item, index) => (
            <CreditRow
              index={`6.1.${index + 1}`}
              name={item.name}
              desc={item.desc}
              v1={item.value}
            />
          ))}
        </CreditSection>
      ) : null}
      {/* {Array.isArray(data.importantFacts?.warning) ? (
            <CreditSection
              index={"6.2"}
              name={t("creditScore.importantFacts")}
              color="orange"
            >
              {data.importantFacts.warning.map((item, index) => (
                <CreditRow
                  index={`6.2.${index + 1}`}
                  name={item.name}
                  desc={item.desc}
                  v1={item.value}
                />
              ))}
            </CreditSection>
          ) : null} */}
      {Array.isArray(data.importantFacts?.success) ? (
        <CreditSection
          index={"6.2"}
          name={t("creditScore.importantFacts")}
          color="green"
        >
          {data.importantFacts.success.map((item, index) => (
            <CreditRow
              index={`6.3.${index + 1}`}
              name={item.name}
              desc={item.desc}
              v1={item.value}
            />
          ))}
        </CreditSection>
      ) : null}
      <div style={{ paddingTop: 20 }}>
        <Button onClick={recalculate} variant="contained">
          {t("creditScore.recalculate")}
        </Button>
      </div>
    </>
  );
};

const ClientScoring = ({ handleClose, clientId }) => {
  const { t } = useTranslation();
  const [data, setData] = React.useState();

  const isAvers = process.env.REACT_APP_CREDIT_RATING_AVERS === "true";

  const fetchAversData = async () => {
    try {
      const data = await AppAPI.request({
        url: `/api/client/${clientId}/avers-rating`,
        errorText: t("AversCredit.noData"),
      });
      setData(data);
    } catch (e) {}
  };

  useEffect(() => {
    (async () => {
      if (isAvers) {
        await fetchAversData();
      } else {
        const data = await AppAPI.request({
          url: `/api/client/${clientId}/credit-rating`,
        });
        setData(data);
      }
    })();
  }, [clientId]);

  return (
    <ModalWrapper
      maxWidth={"800px"}
      open={true}
      handleClose={handleClose}
      btnTxt={"Save"}
    >
      <ModalTittle fontSize={"20px"} children2={t("Scoring")}></ModalTittle>

      {isAvers ? (
        data ? (
          <AversForm
            clientId={clientId}
            formData={data}
            setFormData={setData}
          />
        ) : (
          <AversFirstForm clientId={clientId} updateData={fetchAversData} />
        )
      ) : data ? (
        <CreditScore clientId={clientId} data={data} setData={setData} />
      ) : (
        <div>{t("creditScore.loading")}</div>
      )}
    </ModalWrapper>
  );
};

export default ClientScoring;
