function createChooseFileWindow(settings) {
  const input = document.createElement("input");
  input.type = "file";
  if (settings.multiple) {
    input.multiple = true;
  }
  if (settings.accept) {
    input.accept = settings.accept; // ".txt";
  }
  input.style.display = "none";

  return document.body.appendChild(input);
}

export function useFileLoader() {
  return {
    openChooseFileWindow(
      settings = { accept: null, multiple: false },
      onFileSelected = () => {}
    ) {
      const input = createChooseFileWindow(settings);

      input.onchange = (e) => {
        const files = e.target?.files || [];
        if (files.length) {
          onFileSelected(files);
        }
        input.remove();
      };

      input.click();
    },
  };
}
