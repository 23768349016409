import debounce from "lodash/debounce";

/*  Re-calculate offset for second cell if it should be fixed   */
const reCalcSecondCellWidth = debounce((baseTable) => {
  const baseTableClass = (baseTable.classList.length > 1? '.':'') + [].join.call(baseTable.classList, '.');

  const firstCellList = [
    ...baseTable.querySelectorAll(`${baseTableClass} > thead > tr > th:nth-child(1)`),
    ...baseTable.querySelectorAll(`${baseTableClass} > tbody > tr > td:nth-child(1)`)
  ];

  if (!firstCellList.length) return;

  const firstCellFromFirstRow = firstCellList[0];
  const {width: firstCellWidth} = firstCellFromFirstRow.getBoundingClientRect();

  firstCellList.forEach(({nextElementSibling: secondCell}) => {
    if (secondCell) {
      secondCell.style.left = `${firstCellWidth}px`;
    }
  })
}, 50);

export const mutationOfTableHandlers = {
  last: null
}

export function handleMutationOfTable(baseTable) {
  mutationOfTableHandlers.last = (mutationsList, observer) => {
    if (Array.isArray(mutationsList)) {
      mutationsList.forEach((mutation) => {
        reCalcSecondCellWidth(baseTable);
      });
    } else reCalcSecondCellWidth(baseTable);
  };
  return mutationOfTableHandlers.last;
}