import React from "react";

const CSVIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#54606F" fill-opacity="0.04" />
      <path
        d="M7.32401 16.5293C7.32401 15.9349 6.85049 15.4543 6.26482 15.4543C5.67915 15.4543 5.20251 15.9349 5.20251 16.5293C5.20251 17.1238 5.67915 17.6043 6.26482 17.6043C6.85049 17.6043 7.32401 17.1238 7.32401 16.5293Z"
        fill="#1FB573"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.26479 17.8098C5.56697 17.8098 5 17.2375 5 16.5293C5 15.8211 5.56697 15.2488 6.26479 15.2488C6.9595 15.2488 7.52648 15.8211 7.52648 16.5293C7.52648 17.2375 6.9595 17.8098 6.26479 17.8098ZM6.26479 15.6598C5.79127 15.6598 5.40498 16.0487 5.40498 16.5293C5.40498 17.0099 5.79127 17.3988 6.26479 17.3988C6.73831 17.3988 7.12149 17.0099 7.12149 16.5293C7.12149 16.0487 6.73831 15.6598 6.26479 15.6598Z"
        fill="#54606F"
      />
      <path
        d="M14.0467 3.20557H8.88783C7.47973 3.20557 6.33643 4.36277 6.33643 5.79188V18.2081C6.33643 19.6372 7.47973 20.7944 8.88783 20.7944H16.2492C17.6573 20.7944 18.7975 19.6372 18.7975 18.2081V8.02724L14.0467 3.20557Z"
        fill="#FEFEFE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2492 21H8.88781C7.37068 21 6.13391 19.7479 6.13391 18.2082V5.79195C6.13391 4.25218 7.37068 3.00012 8.88781 3.00012H14.0467C14.0996 3.00012 14.1526 3.02225 14.19 3.06019L18.9408 7.88187C18.9782 7.91981 19 7.97356 19 8.02731V18.2082C19 19.7479 17.7663 21 16.2492 21ZM8.88781 3.41115C7.59186 3.41115 6.5389 4.47983 6.5389 5.79195V18.2082C6.5389 19.5203 7.59186 20.589 8.88781 20.589H16.2492C17.542 20.589 18.595 19.5203 18.595 18.2082V8.11268L13.9626 3.41115H8.88781Z"
        fill="#54606F"
      />
      <path
        d="M14.0468 3.20557L18.7975 8.02724H15.0592C14.5016 8.02724 14.0468 7.56563 14.0468 6.99967V3.20557Z"
        fill="#DBDCDD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.7975 8.23283H15.0592C14.3894 8.23283 13.8442 7.67952 13.8442 6.99974V3.20563C13.8442 3.12343 13.8941 3.04755 13.9688 3.01593C14.0436 2.98431 14.1308 3.00012 14.19 3.06019L18.9315 7.87239C18.9751 7.91033 19 7.96408 19 8.02731C19 8.14113 18.9097 8.23283 18.7975 8.23283ZM14.2492 3.70203V6.99974C14.2492 7.45187 14.6137 7.8218 15.0592 7.8218H18.3084L14.2492 3.70203Z"
        fill="#54606F"
      />
      <path
        d="M6.36138 10.3228C5.7321 10.3228 5.22119 10.8413 5.22119 11.48V16.3269H5.23989C5.34269 15.7926 5.80375 15.391 6.36138 15.391H17.8443V10.3228H6.36138Z"
        fill="#1FB573"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.23986 16.5324H5.22117C5.10902 16.5324 5.01868 16.4407 5.01868 16.3269V11.4799C5.01868 10.7274 5.61993 10.1172 6.36136 10.1172H17.8442C17.9564 10.1172 18.0467 10.2089 18.0467 10.3227V15.391C18.0467 15.5048 17.9564 15.5965 17.8442 15.5965H6.36136C5.91276 15.5965 5.52335 15.919 5.43924 16.368C5.42055 16.4628 5.33644 16.5324 5.23986 16.5324ZM6.36136 10.5282C5.84423 10.5282 5.42366 10.955 5.42366 11.4799V15.5744C5.66665 15.3309 5.99999 15.1855 6.36136 15.1855H17.6417V10.5282H6.36136Z"
        fill="#54606F"
      />
      <path
        d="M8.35825 13.9682L9.25544 12.811L8.40498 11.7233C8.38006 11.6886 8.35513 11.6506 8.35513 11.6064C8.35513 11.5273 8.42055 11.4578 8.50466 11.4578C8.56697 11.4578 8.60124 11.4894 8.63862 11.5368L9.44859 12.6023L10.2461 11.5463C10.2897 11.4957 10.3271 11.4578 10.3925 11.4578C10.4642 11.4578 10.5296 11.521 10.5296 11.6C10.5296 11.6411 10.514 11.6791 10.4891 11.7107L9.63239 12.8078L10.5171 13.9556C10.5483 13.9903 10.567 14.0251 10.567 14.0694C10.567 14.1516 10.5047 14.2211 10.4174 14.2211C10.3582 14.2211 10.324 14.1895 10.2835 14.1421L9.43613 13.026L8.60123 14.1326C8.55762 14.1832 8.52024 14.2211 8.45171 14.2211C8.38317 14.2211 8.31775 14.1579 8.31775 14.0757C8.31775 14.0378 8.33333 13.9998 8.35825 13.9682Z"
        fill="#FEFEFE"
      />
      <path
        d="M11.2025 11.6127C11.2025 11.5273 11.2711 11.4578 11.352 11.4578C11.4362 11.4578 11.5047 11.5273 11.5047 11.6127V13.9176H12.8972C12.972 13.9176 13.0343 13.984 13.0343 14.0599C13.0343 14.1389 12.972 14.2022 12.8972 14.2022H11.352C11.2711 14.2022 11.2025 14.1326 11.2025 14.0441V11.6127Z"
        fill="#FEFEFE"
      />
      <path
        d="M13.4798 13.8734C13.4456 13.8513 13.4207 13.807 13.4207 13.7564C13.4207 13.671 13.4861 13.6046 13.5671 13.6046C13.6045 13.6046 13.6387 13.6205 13.6605 13.6394C13.9129 13.8576 14.1652 13.9651 14.5079 13.9651C14.8661 13.9651 15.106 13.7691 15.106 13.5003V13.494C15.106 13.241 14.9721 13.0956 14.4082 12.9755C13.7883 12.8395 13.5048 12.6372 13.5048 12.1882V12.1819C13.5048 11.7519 13.8786 11.4388 14.3895 11.4388C14.7415 11.4388 14.997 11.5274 15.2431 11.7076C15.2742 11.7297 15.3085 11.774 15.3085 11.8309C15.3085 11.9131 15.2431 11.9795 15.1621 11.9795C15.1278 11.9795 15.0998 11.97 15.0749 11.9511C14.8474 11.7835 14.6294 11.7139 14.3801 11.7139C14.0312 11.7139 13.8101 11.9099 13.8101 12.1534V12.1629C13.8101 12.419 13.9471 12.5613 14.539 12.6909C15.1372 12.8237 15.4113 13.045 15.4113 13.4624V13.4687C15.4113 13.9366 15.0281 14.2402 14.4954 14.2402C14.1029 14.2402 13.7789 14.1168 13.4798 13.8734Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};

export default CSVIcon;
