import { makeStyles } from "@material-ui/core/styles";

//style of Details Page
export const useStyles = makeStyles(() => ({
  root: {
    display: "block",
    "MuiDataGrid-main": {
      width: "100%",
    },
  },
  columns: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  column: {
    alignSelf: "flex-start",
  },
  buttons: {
    display: "flex",
    justifyContent: 'flex-end'
  },
  fullTable: {
    width: "100%",
  },
  btn: {
    borderRadius: '6px', paddingTop: '5px', paddingBottom: '5px', paddingRight: '15px', paddingLeft: '15px', fontSize: '22px', lineHeight: '27px',
    marginRight: '0px', marginLeft: '0px'
  }
}));
