import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useStyles } from "../../style/components/formStyle";
import { TextValidator } from "react-material-ui-form-validator";

//component: label + default input

const FormNumRow = ({ label, onChange, value, big }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item className={`${big ? classes.rowBigRow : classes.row} ${classes.textableRow}`}>
      <Typography className={classes.label}>{t(label)}</Typography>
      <TextValidator
        className={classes.input}
        variant="outlined"
        value={value}
        onChange={(event) => onChange(event.target.value)}
        validators={["matchRegexp:^[+]*[0-9]*$", "required"]}
        errorMessages={["value is not valid", "this field is required"]}
      />
    </Grid>
  );
};

FormNumRow.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  big: PropTypes.string,
};

export default FormNumRow;
