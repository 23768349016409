import ButtonSave from "@View/components/buttons/ButtonSave";
import DefaultInput from "@View/components/formInputs/DefaultInput";
import ModalTittle from "@View/components/ModalTittle";
import ModalWrapper from "@View/components/ModalWrapper";
import React from "react";
import AppAPI from "@API/index";

const NewGroupModal = ({
  customerId,
  open,
  handleOpen,
  handleClose,
  handleUpdate,
}) => {
  const [newData, setNewData] = React.useState({
    name: "",
  });

  const handleChange = (value, label) => {
    setNewData({ ...newData, [label]: value });
  };

  const handleClick = (event) => {
    event.preventDefault();
    AppAPI.request({
      method: "post",
      url: `/api/Customer/${customerId}/groups`,
      data: newData,
      successText: "snackbar.success",
    }).then(() => {
      handleClose();
      handleUpdate();
    });
  };

  return (
    <ModalWrapper open={open} handleOpen={handleOpen} handleClose={handleClose}>
      <form onSubmit={(event) => handleClick(event)}>
        <ModalTittle>h1.NewGroup</ModalTittle>
        <DefaultInput
          value={newData.name}
          label="inputs.GroupName"
          onChange={(value) => handleChange(value, "name")}
        />
        <ButtonSave>buttons.Save</ButtonSave>
      </form>
    </ModalWrapper>
  );
};

export default NewGroupModal;
