import { useTranslation } from "react-i18next";
import { useHistory, useParams, useLocation } from "react-router-dom";
import React, { useCallback } from "react";
import Loader from "@View/components/loader/Loader";
import { Typography, Button, Grid, Box, useTheme } from "@mui/material";
import AppAPI from "@API/index";
import { useSelector } from "react-redux";
import WarningIcon from "@mui/icons-material/Warning";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { getCustomerId } from "@Security/stateHelpers";

const TariffCard = ({
  title,
  subtitle,
  price,
  buttonText,
  description,
  planId,
  buyPlan,
}) => {
  return (
    <Grid item xs={12} sm={4}>
      <Box
        sx={{
          border: "1px solid #ccc",
          padding: 4,
          borderRadius: 4,
          textAlign: "center",
        }}
      >
        <Typography
          variant="h6"
          color="text.primary"
          sx={{ minHeight: 140, color: "#8786d2", fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ minHeight: 50 }}
        >
          {subtitle}
        </Typography>
        <Typography
          variant="h4"
          color="text.primary"
          sx={{ minHeight: 50, fontWeight: "bold" }}
        >
          {price}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            minHeight: 150,
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {description}
        </Typography>
        <Button
          variant="contained"
          sx={{
            borderRadius: "30px",
            background: "#8786d2",
            textTransform: "none",
            padding: "15px 30px",
          }}
          onClick={() => buyPlan(planId)}
        >
          {buttonText}
        </Button>
      </Box>
    </Grid>
  );
};

const PaymentPage = () => {
  const { t } = useTranslation();
  const locationData = useLocation();
  const searchParams = new URLSearchParams(locationData.search);
  const customerId = searchParams.get("customerId") || getCustomerId();

  // eslint-disable-next-line no-restricted-globals
  const currentState = new URLSearchParams(location.search);
  const currentResult = currentState.get("result");

  const isPaymentFailed = currentResult === "fail";
  const isPaymentSuccess = currentResult === "success";

  const buyAction = useCallback(async (planId) => {
    // eslint-disable-next-line no-restricted-globals
    const baseUrl = new URL("/payment", location.origin);
    baseUrl.searchParams.set("result", "success");
    const successUrl = baseUrl.toString();
    baseUrl.searchParams.set("result", "fail");
    const failUrl = baseUrl.toString();
    const paymentLink = await AppAPI.request({
      url: "/api/Payment/robokassa_link/customer",
      method: "POST",
      data: {
        customerId: customerId,
        type: planId,
        count: 1,
        successURL: successUrl,
        failURL: failUrl,
      },
    });
    window.location.assign(paymentLink);
  }, []);

  if (isPaymentSuccess) {
    setTimeout(() => {
      // eslint-disable-next-line no-restricted-globals
      location.href = "/";
    }, 4000);

    return (
      <div
        style={{
          padding: "30px 10px",
          color: "#54606F",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "80vh",
        }}
      >
        <div>
          <div style={{ marginBottom: 20 }}>
            <ThumbUpIcon sx={{ color: "green", fontSize: 100 }} />
          </div>
          <Typography
            variant="h3"
            align="center"
            sx={{ fontWeight: "bold", color: "green" }}
          >
            {t("payment.success")}
          </Typography>
          <div style={{ marginTop: 20 }}>{t("payment.success_detail")}</div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        padding: "0 0 30px 0",
        color: "#54606F",
        display: "block",
        width: "100%",
      }}
    >
      <div>
        <Loader />
      </div>
      <Box sx={{ padding: 4, margin: "auto", maxWidth: 1200 }}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" sx={{ fontWeight: "bold" }}>
              {t("payment.title")}
            </Typography>
          </Grid>
          {isPaymentFailed && (
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 20,
                  color: "red",
                }}
              >
                <WarningIcon sx={{ color: "orange", fontSize: 40 }} />
                {t("payment.error")}
              </div>
            </Grid>
          )}
          <TariffCard
            buttonText={t("payment.item_1.action")}
            title={t("payment.item_1.name")}
            subtitle={t("payment.item_1.subtitle")}
            description={
              <>
                <div>{t("payment.item_1.desc_1")}</div>
              </>
            }
            price={t("payment.item_1.price")}
            planId={0}
            buyPlan={buyAction}
          />
          <TariffCard
            buttonText={t("payment.item_2.action")}
            title={t("payment.item_2.name")}
            subtitle={t("payment.item_2.subtitle")}
            description={
              <>
                <div>{t("payment.item_2.desc_1")}</div>
                <div>{t("payment.item_2.desc_2")}</div>
              </>
            }
            price={t("payment.item_2.price")}
            planId={1}
            buyPlan={buyAction}
          />
          <TariffCard
            buttonText={t("payment.item_3.action")}
            title={t("payment.item_3.name")}
            subtitle={t("payment.item_3.subtitle")}
            description={
              <>
                <div>{t("payment.item_3.desc_1")}</div>
                <div>{t("payment.item_3.desc_2")}</div>
                <div>{t("payment.item_3.desc_3")}</div>
              </>
            }
            price={t("payment.item_3.price")}
            planId={2}
            buyPlan={buyAction}
          />
        </Grid>
      </Box>
    </div>
  );
};

export default PaymentPage;
