import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*::-webkit-scrollbar": {
        width: "10px",
        height: "10px",
      },
      "*::-webkit-scrollbar-track": {
        borderRadius: "12px",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
        borderRadius: "12px",
        transition: '.2s linear'
      },
      "*::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#d6d6d6",
        transition: '.2s linear'
      },
    }
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
