import { Customer } from "@Dao/responseTypes";
import { CustomerFailure, CustomerRequest, CustomerSuccess, Logout } from "@State/actions/actionCreators";
import { Action } from "redux";

const InitialState: CustomerState = {
    data: null,
    loading: false,
    error: false,
}

export function customerReducer(state = InitialState, action: Action): CustomerState {
    if (CustomerRequest.match(action)) {
        return {
            data: null,
            loading: true,
            error: false
        }
    }
    if (CustomerSuccess.match(action)) {
        return {
            data: action.payload,
            loading: true,
            error: false
        }
    }
    if (CustomerFailure.match(action)) {
        return {
            data: null,
            loading: false,
            error: true
        }
    }
    if (Logout.match(action)) {
        return {
            data: null,
            loading: false,
            error: false
        } 
    }
    return state
}

export type CustomerState = {
    data: Customer | null,
    loading: boolean,
    error: boolean
}
