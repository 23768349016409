import { forwardRef } from "react";

function IconBigPlus({ className = "" }, ref) {
  return (
    <svg
      ref={ref}
      className={className}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.80005 17.9993C1.80061 10.2712 7.26016 3.61963 14.8399 2.11246C22.4196 0.60528 30.0085 4.66224 32.9655 11.8023C35.9225 18.9423 33.424 27.1769 26.9981 31.47C20.5721 35.7632 12.0084 34.9192 6.54406 29.4544C3.50622 26.4162 1.79974 22.2957 1.80005 17.9993Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.925 17.249C11.5108 17.249 11.175 17.5848 11.175 17.999C11.175 18.4133 11.5108 18.749 11.925 18.749V17.249ZM17.9997 18.749C18.4139 18.749 18.7497 18.4133 18.7497 17.999C18.7497 17.5848 18.4139 17.249 17.9997 17.249V18.749ZM18 17.249C17.5858 17.249 17.25 17.5848 17.25 17.999C17.25 18.4133 17.5858 18.749 18 18.749V17.249ZM24.0747 18.749C24.4889 18.749 24.8247 18.4133 24.8247 17.999C24.8247 17.5848 24.4889 17.249 24.0747 17.249V18.749ZM18.7491 17.9991C18.7491 17.5848 18.4133 17.2491 17.9991 17.2491C17.5849 17.2491 17.2491 17.5848 17.2491 17.9991H18.7491ZM17.2491 24.0737C17.2491 24.4879 17.5849 24.8237 17.9991 24.8237C18.4133 24.8237 18.7491 24.4879 18.7491 24.0737H17.2491ZM17.2491 17.999C17.2491 18.4133 17.5849 18.749 17.9991 18.749C18.4133 18.749 18.7491 18.4133 18.7491 17.999H17.2491ZM18.7491 11.9244C18.7491 11.5102 18.4133 11.1744 17.9991 11.1744C17.5849 11.1744 17.2491 11.5102 17.2491 11.9244H18.7491ZM11.925 18.749H17.9997V17.249H11.925V18.749ZM18 18.749H24.0747V17.249H18V18.749ZM17.2491 17.9991V24.0737H18.7491V17.9991H17.2491ZM18.7491 17.999V11.9244H17.2491V17.999H18.7491Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default forwardRef(IconBigPlus);
