import {
  GET_ALL_USERS,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
} from "../../actions/actionTypes";

const initialState = {
  user: {},
  error: "",
  users: {
    list: []
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return {
        ...state,
        user: {},
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        error: "",
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        user: {},
        error: action.payload,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          list: [...action.payload]
        }
      }
    default:
      return state;
  }
};

export default reducer;
