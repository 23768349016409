import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const PasswordIcon = ({ showPassword, setShowPassword }) => {
  return (
    <InputAdornment position="start">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
};

PasswordIcon.propTypes = {
  showPassword: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  setShowPassword: PropTypes.func.isRequired,
};

export default PasswordIcon;
