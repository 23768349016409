import { TableBody, TableCell, TableHead } from "@material-ui/core";
import {
  CollapseGroupRows,
  DefaultTable,
  FirstCell,
  NestedRow,
  NoCollapseGroupRows,
  TextCell,
} from "@View/common/table";
import i18next from "i18next";
import { getHighlightColorForLastIndex } from "@Utils/colorPicker";

const FinStatementEnTable = ({ isHorAnEn, row }) => {
  let datesFilter = row.dates.filter((el) => el.recordType === "Month");
  let filterByRecord = row.finData.filter((el) => el.recordType === "Month");
  row.finData = filterByRecord;
  let transformedData = TransformData(row, isHorAnEn, datesFilter);
  const t = i18next.getFixedT(
    null,
    null,
    "finDataRequest.tabs.FinancialStatements.tables.FinancialStatements"
  );

  return (
    <DefaultTable>
      <TableHead
        style={{
          background: "#F3F5F7",
          height: "46px",
        }}
      >
        <NestedRow>
          <TableCell
            style={{
              textTransform: "uppercase",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "19px",
              color: "#1A1E24",
              paddingLeft: "0em",
              textAlign: "left",
              border: "none",
            }}
          >
            {t("balanceSheet")}
          </TableCell>
          <TextCell>{row.currency.name}</TextCell>
          {transformedData.dates.map((el) => {
            return <TextCell key={el.columnName}>{el.columnName}</TextCell>;
          })}
          <TextCell></TextCell>
          <TextCell></TextCell>
        </NestedRow>
      </TableHead>

      <TableBody>
        {/* <NoCollapseGroupRows color={'#fff'} paddingLeft={'3em'} cellText={t("balanceSheet")}></NoCollapseGroupRows> */}
        <CollapseGroupRows
          colapse={true}
          isOpen={false}
          isHorAnEn={isHorAnEn}
          level={3}
          color={"#fff"}
          clickableCellText={"Assets"}
          otherCellsTexts={transformedData.assetss.total.map((el) => el)}
        >
          <CollapseGroupRows
            isHorAnEn={isHorAnEn}
            level={4}
            color={"#fff"}
            clickableCellText={t("currentAssets")}
            otherCellsTexts={transformedData.assetss.currentAssets.total.map(
              (el) => el
            )}
          >
            {row.finData.length
              ? Object.keys(row.finData[0].assets.currentAssets).map((key) => {
                  if (
                    key == "total" ||
                    key == "cash" ||
                    key == "isCorrectCalculated"
                  )
                    return null;
                  return (
                    <CollapseGroupRows
                      emptryArr={true}
                      isHorAnEn={isHorAnEn}
                      level={5}
                      color={"#fff"}
                      clickableCellText={t(key)}
                      otherCellsTexts={transformedData.assets.map(
                        (el) => el.currentAssets[key].total
                      )}
                    >
                      {Object.keys(
                        row.finData[0].assets.currentAssets[key]
                      ).map((key2) => {
                        if (key2 === "total") return;
                        return (
                          <NestedRow>
                            <FirstCell level={5}>{t(key2)}</FirstCell>
                            <TextCell></TextCell>
                            {transformedData.assets.map((el2, i, arr) => {
                              return (
                                <TextCell
                                  color={getHighlightColorForLastIndex(
                                    el2.currentAssets[key][key2],
                                    i,
                                    arr,
                                    isHorAnEn
                                  )}
                                >
                                  {el2.currentAssets[key][key2]}
                                </TextCell>
                              );
                            })}
                          </NestedRow>
                        );
                      })}
                    </CollapseGroupRows>
                  );
                })
              : null}

            <CollapseGroupRows
              emptryArr={true}
              isHorAnEn={isHorAnEn}
              level={5}
              color={"#fff"}
              clickableCellText={t("cash")}
              otherCellsTexts={transformedData.assets.map(
                (el) => el.currentAssets.cash.total
              )}
            >
              {row.finData.length
                ? Object.keys(row.finData[0].assets.currentAssets.cash).map(
                    (el) => {
                      if (el == "total") return null;
                      return (
                        <CollapseGroupRows
                          emptryArr={true}
                          isHorAnEn={isHorAnEn}
                          level={6}
                          color={"#fff"}
                          clickableCellText={t(el)}
                          otherCellsTexts={transformedData.assets.map(
                            (el2) => el2.currentAssets.cash[el].total
                          )}
                        >
                          {Object.keys(
                            row.finData[0].assets.currentAssets.cash[el]
                          ).map((e) => {
                            if (e == "total") return null;
                            return (
                              <NestedRow>
                                <FirstCell level={6}>{t(e)}</FirstCell>
                                <TextCell></TextCell>
                                {transformedData.assets.map((el3, i, arr) => {
                                  return (
                                    <TextCell
                                      color={getHighlightColorForLastIndex(
                                        el3.currentAssets.cash[el][e],
                                        i,
                                        arr,
                                        isHorAnEn
                                      )}
                                    >
                                      {el3.currentAssets.cash[el][e]}
                                    </TextCell>
                                  );
                                })}
                              </NestedRow>
                            );
                          })}
                        </CollapseGroupRows>
                      );
                    }
                  )
                : null}
            </CollapseGroupRows>
          </CollapseGroupRows>

          <CollapseGroupRows
            isHorAnEn={isHorAnEn}
            level={4}
            color={"#fff"}
            clickableCellText={t("longTermAssets")}
            otherCellsTexts={transformedData.assetss.longTermAssets.total.map(
              (el) => el
            )}
          >
            <CollapseGroupRows
              emptryArr={true}
              isHorAnEn={isHorAnEn}
              level={5}
              color={"#fff"}
              clickableCellText={t("accountsReceivables")}
              otherCellsTexts={transformedData.assets.map(
                (el) => el.longTermAssets.accountsReceivables.total
              )}
            >
              {row.finData.length
                ? Object.keys(
                    row.finData[0].assets.longTermAssets.accountsReceivables
                  ).map((e) => {
                    if (e == "total") return;
                    return (
                      <NestedRow>
                        <FirstCell level={5}>{t(e)}</FirstCell>
                        <TextCell></TextCell>
                        {transformedData.assets.map((el, i, arr) => {
                          return (
                            <TextCell
                              color={getHighlightColorForLastIndex(
                                el.longTermAssets.accountsReceivables[e],
                                i,
                                arr,
                                isHorAnEn
                              )}
                            >
                              {el.longTermAssets.accountsReceivables[e]}
                            </TextCell>
                          );
                        })}
                      </NestedRow>
                    );
                  })
                : null}
            </CollapseGroupRows>

            <CollapseGroupRows
              isHorAnEn={isHorAnEn}
              level={5}
              color={"#fff"}
              clickableCellText={t("fixedAssets")}
              otherCellsTexts={transformedData.assetss.longTermAssets.fixedAssets.total.map(
                (el) => el
              )}
            >
              {row.finData.length
                ? Object.keys(
                    row.finData[0].assets.longTermAssets.fixedAssets
                  ).map((e) => {
                    if (e == "total" || e == "advancesToFASuppliers") return;
                    return (
                      <CollapseGroupRows
                        isHorAnEn={isHorAnEn}
                        level={7}
                        color={"#fff"}
                        clickableCellText={t(e)}
                        otherCellsTexts={transformedData.assets.map(
                          (el) => el.longTermAssets.fixedAssets[e].total
                        )}
                      >
                        {Object.keys(
                          row.finData[0].assets.longTermAssets.fixedAssets[e]
                        ).map((el) => {
                          if (el == "total") return;
                          return (
                            <NestedRow>
                              <FirstCell level={7}>{t(el)}</FirstCell>
                              {transformedData.assets.map((el2, i, arr) => {
                                return (
                                  <TextCell
                                    color={getHighlightColorForLastIndex(
                                      el2.longTermAssets.fixedAssets[e][el],
                                      i,
                                      arr,
                                      isHorAnEn
                                    )}
                                  >
                                    {el2.longTermAssets.fixedAssets[e][el]}
                                  </TextCell>
                                );
                              })}
                            </NestedRow>
                          );
                        })}
                      </CollapseGroupRows>
                    );
                  })
                : null}

              <NestedRow>
                <FirstCell level={7}>{t("advancesToFASuppliers")}</FirstCell>
                {transformedData.assets.map((el2, i, arr) => {
                  return (
                    <TextCell
                      color={getHighlightColorForLastIndex(
                        el2.longTermAssets.fixedAssets.advancesToFASuppliers,
                        i,
                        arr,
                        isHorAnEn
                      )}
                    >
                      {el2.longTermAssets.fixedAssets.advancesToFASuppliers}
                    </TextCell>
                  );
                })}
              </NestedRow>
            </CollapseGroupRows>

            <CollapseGroupRows
              isHorAnEn={isHorAnEn}
              level={5}
              color={"#fff"}
              clickableCellText={t("intangibleAssets")}
              otherCellsTexts={transformedData.assetss.longTermAssets.intangibleAssets.total.map(
                (el) => el
              )}
            >
              {row.finData.length
                ? Object.keys(
                    row.finData[0].assets.longTermAssets.intangibleAssets
                  ).map((e) => {
                    if (e == "total" || e == "advancesToIASuppliers") return;
                    return (
                      <CollapseGroupRows
                        isHorAnEn={isHorAnEn}
                        level={7}
                        color={"#fff"}
                        clickableCellText={t(e)}
                        otherCellsTexts={transformedData.assets.map(
                          (el) => el.longTermAssets.intangibleAssets[e].total
                        )}
                      >
                        {Object.keys(
                          row.finData[0].assets.longTermAssets.intangibleAssets[
                            e
                          ]
                        ).map((el) => {
                          if (el == "total") return;
                          return (
                            <NestedRow>
                              <FirstCell level={7}>{t(el)}</FirstCell>
                              {transformedData.assets.map((el2, i, arr) => {
                                return (
                                  <TextCell
                                    color={getHighlightColorForLastIndex(
                                      el2.longTermAssets.intangibleAssets[e][
                                        el
                                      ],
                                      i,
                                      arr,
                                      isHorAnEn
                                    )}
                                  >
                                    {el2.longTermAssets.intangibleAssets[e][el]}
                                  </TextCell>
                                );
                              })}
                            </NestedRow>
                          );
                        })}

                        <NestedRow>
                          <FirstCell level={7}>
                            {t("advancesToFASuppliers")}
                          </FirstCell>
                          {transformedData.assets.map((el2, i, arr) => {
                            return (
                              <TextCell
                                color={getHighlightColorForLastIndex(
                                  el2.longTermAssets.fixedAssets
                                    .advancesToFASuppliers,
                                  i,
                                  arr,
                                  isHorAnEn
                                )}
                              >
                                {
                                  el2.longTermAssets.fixedAssets
                                    .advancesToFASuppliers
                                }
                              </TextCell>
                            );
                          })}
                        </NestedRow>
                      </CollapseGroupRows>
                    );
                  })
                : null}
            </CollapseGroupRows>

            <CollapseGroupRows
              emptryArr={true}
              isHorAnEn={isHorAnEn}
              level={5}
              color={"#fff"}
              clickableCellText={t("longTermInvestments")}
              otherCellsTexts={transformedData.assets.map(
                (el) => el.longTermAssets.longTermInvestments.total
              )}
            >
              {row.finData.length
                ? Object.keys(
                    row.finData[0].assets.longTermAssets.longTermInvestments
                  ).map((e) => {
                    if (e == "total") return;
                    return (
                      <NestedRow>
                        <FirstCell level={5.5}>{t(e)}</FirstCell>
                        <TextCell></TextCell>
                        {transformedData.assets.map((el, i, arr) => {
                          return (
                            <TextCell
                              color={getHighlightColorForLastIndex(
                                el.longTermAssets.longTermInvestments[e],
                                i,
                                arr,
                                isHorAnEn
                              )}
                            >
                              {el.longTermAssets.longTermInvestments[e]}
                            </TextCell>
                          );
                        })}
                      </NestedRow>
                    );
                  })
                : null}
            </CollapseGroupRows>

            <CollapseGroupRows
              emptryArr={true}
              isHorAnEn={isHorAnEn}
              level={5}
              color={"#fff"}
              clickableCellText={t("otherReceivables")}
              otherCellsTexts={transformedData.assets.map(
                (el) => el.longTermAssets.otherReceivables.total
              )}
            >
              <NestedRow>
                <FirstCell level={5}>{t("otherReceivables")}</FirstCell>
                <TextCell></TextCell>
                {transformedData.assets.map((el, i, arr) => {
                  return (
                    <TextCell
                      color={getHighlightColorForLastIndex(
                        el.longTermAssets.otherReceivables.otherReceivables,
                        i,
                        arr,
                        isHorAnEn
                      )}
                    >
                      {el.longTermAssets.otherReceivables.otherReceivables}
                    </TextCell>
                  );
                })}
              </NestedRow>
            </CollapseGroupRows>

            <NestedRow>
              <FirstCell level={5}>{t("investmentProperty")}</FirstCell>
              {transformedData.assetss.longTermAssets.investmentProperty.map(
                (el3, i, arr) => {
                  return (
                    <TextCell
                      color={getHighlightColorForLastIndex(
                        el3,
                        i,
                        arr,
                        isHorAnEn
                      )}
                    >
                      {el3}
                    </TextCell>
                  );
                }
              )}
            </NestedRow>
          </CollapseGroupRows>
        </CollapseGroupRows>

        <CollapseGroupRows
          colapse={true}
          isOpen={false}
          isHorAnEn={isHorAnEn}
          level={3}
          color={"#fff"}
          clickableCellText={t("liabilities")}
          otherCellsTexts={transformedData.liabilities.total.map((el) => el)}
        >
          {row.finData.length
            ? Object.keys(row.finData[0].liabilities).map((e) => {
                if (
                  e === "total" ||
                  e === "currentLiabilitiesVA" ||
                  e === "longTermLiabilitiesVA"
                )
                  return;
                return (
                  <CollapseGroupRows
                    emptryArr={true}
                    isHorAnEn={isHorAnEn}
                    level={4}
                    color={"#fff"}
                    clickableCellText={t(e)}
                    otherCellsTexts={transformedData.liabilities[e].total.map(
                      (el) => el
                    )}
                  >
                    {Object.keys(row.finData[0].liabilities[e]).map((el) => {
                      if (
                        el === "total" ||
                        el === "taxPayable" ||
                        el === "isCorrectCalculated" ||
                        el === "creditCards"
                      )
                        return;
                      // || el === "accountsPayableVA" || el === "loansPayableVA" || el === "allowancesVA" || el === "creditCardsVA" || el === 'taxPayableVA') return
                      return (
                        <CollapseGroupRows
                          emptryArr={true}
                          isHorAnEn={isHorAnEn}
                          level={5}
                          color={"#fff"}
                          clickableCellText={t(el)}
                          otherCellsTexts={transformedData.liabilities[e][
                            el
                          ].map((el2) => {
                            if (el2 === undefined || el2.length === 0) {
                              return;
                            } else {
                              return el2.total;
                            }
                          })}
                        >
                          {Object.keys(row.finData[0].liabilities[e][el]).map(
                            (el2) => {
                              if (el2 === "total") return;
                              return (
                                <NestedRow>
                                  <FirstCell level={5}>{t(el2)}</FirstCell>
                                  <TextCell></TextCell>
                                  {transformedData.liabilities[e][el].map(
                                    (el3, i, arr) => {
                                      if (el3 === undefined) return;
                                      return (
                                        <TextCell
                                          color={getHighlightColorForLastIndex(
                                            el3[el2],
                                            i,
                                            arr,
                                            isHorAnEn
                                          )}
                                        >
                                          {el3[el2]}
                                        </TextCell>
                                      );
                                    }
                                  )}
                                </NestedRow>
                              );
                            }
                          )}
                        </CollapseGroupRows>
                      );
                    })}

                    {e === "currentLiabilities" ? (
                      <>
                        <NestedRow>
                          <FirstCell level={5}>{t("creditCards")}</FirstCell>

                          {transformedData.liabilities.currentLiabilities.creditCards.map(
                            (el2, i, arr) => {
                              return (
                                <TextCell
                                  color={getHighlightColorForLastIndex(
                                    el2,
                                    i,
                                    arr,
                                    isHorAnEn
                                  )}
                                >
                                  {el2}
                                </TextCell>
                              );
                            }
                          )}
                        </NestedRow>

                        <NestedRow>
                          <FirstCell level={5}>{t("taxPayable")}</FirstCell>
                          {transformedData.liabilities.currentLiabilities.taxPayable.map(
                            (el2, i, arr) => {
                              return (
                                <TextCell
                                  color={getHighlightColorForLastIndex(
                                    el2,
                                    i,
                                    arr,
                                    isHorAnEn
                                  )}
                                >
                                  {el2}
                                </TextCell>
                              );
                            }
                          )}
                        </NestedRow>
                      </>
                    ) : null}
                  </CollapseGroupRows>
                );
              })
            : null}
        </CollapseGroupRows>

        {/* // */}
        <CollapseGroupRows
          emptryArr={true}
          colapse={true}
          isOpen={false}
          isHorAnEn={isHorAnEn}
          level={3}
          color={"#fff"}
          clickableCellText={t("equity")}
          otherCellsTexts={transformedData.equity.total.map((el) => el)}
        >
          <CollapseGroupRows
            emptryArr={true}
            isHorAnEn={isHorAnEn}
            level={4}
            color={"#fff"}
            clickableCellText={t("additionalCapital")}
            otherCellsTexts={transformedData.equity.additionalCapital.map(
              (el) => {
                if (el === undefined) {
                  return;
                } else {
                  return el.total;
                }
              }
            )}
          >
            {row.finData.length
              ? Object.keys(row.finData[0].equity.additionalCapital).map(
                  (el) => {
                    if (el === "total") return;
                    return (
                      <NestedRow>
                        <FirstCell level={4}>{t(el)}</FirstCell>
                        <TextCell></TextCell>
                        {transformedData.equity.additionalCapital.map(
                          (e, i, arr) => {
                            if (e === undefined) return;
                            return (
                              <TextCell
                                color={getHighlightColorForLastIndex(
                                  e[el],
                                  i,
                                  arr,
                                  isHorAnEn
                                )}
                              >
                                {e[el]}
                              </TextCell>
                            );
                          }
                        )}
                      </NestedRow>
                    );
                  }
                )
              : null}
          </CollapseGroupRows>

          <CollapseGroupRows
            emptryArr={true}
            isHorAnEn={isHorAnEn}
            level={4}
            color={"#fff"}
            clickableCellText={t("mainCapital")}
            otherCellsTexts={transformedData.equity.mainCapital.map((el) => {
              if (el === undefined) {
                return;
              } else {
                return el.total;
              }
            })}
          >
            {row.finData.length
              ? Object.keys(row.finData[0].equity.mainCapital).map((el) => {
                  if (el === "total") return;
                  return (
                    <NestedRow>
                      <FirstCell level={4}>{t(el)}</FirstCell>
                      <TextCell></TextCell>
                      {transformedData.equity.mainCapital.map((e, i, arr) => {
                        if (e === undefined) {
                          return;
                        }
                        return (
                          <TextCell
                            color={getHighlightColorForLastIndex(
                              e[el],
                              i,
                              arr,
                              isHorAnEn
                            )}
                          >
                            {e[el]}
                          </TextCell>
                        );
                      })}
                    </NestedRow>
                  );
                })
              : null}
          </CollapseGroupRows>

          {row.finData.length
            ? Object.keys(row.finData[0].equity).map((el) => {
                if (
                  el === "total" ||
                  el === "isCorrectCalculated" ||
                  el === "isCorrectCalculatedNetIncome" ||
                  el === "mainCapital" ||
                  el === "additionalCapital"
                )
                  return; // add  el === 'mainCapital' || el === 'additionalCapital'
                return (
                  <NestedRow>
                    <FirstCell level={4}>{t(el)}</FirstCell>
                    <TextCell></TextCell>
                    {transformedData.equity[el].map((e, i, arr) => {
                      return (
                        <TextCell
                          color={getHighlightColorForLastIndex(
                            e,
                            i,
                            arr,
                            isHorAnEn
                          )}
                        >
                          {e}
                        </TextCell>
                      );
                    })}
                  </NestedRow>
                );
              })
            : null}
        </CollapseGroupRows>

        <NoCollapseGroupRows
          color={"#fff"}
          paddingLeft={"2em"}
          cellText={t("Profit And Loss")}
        >
          {" "}
        </NoCollapseGroupRows>

        <CollapseGroupRows
          isHorAnEn={isHorAnEn}
          level={3}
          color={"#fff"}
          clickableCellText={t("revenue")}
          otherCellsTexts={transformedData.revenue.map((el) => {
            if (el === undefined) {
              return;
            } else {
              return el.total;
            }
          })}
        >
          {row.finData.length
            ? Object.keys(row.finData[0].revenue).map((el) => {
                if (el === "total") return;
                return (
                  <NestedRow>
                    <FirstCell level={3}>{t(el)}</FirstCell>
                    {transformedData.revenue.map((e, i, arr) => {
                      if (e === undefined) return;
                      return (
                        <TextCell
                          color={getHighlightColorForLastIndex(
                            e[el],
                            i,
                            arr,
                            isHorAnEn
                          )}
                        >
                          {e[el]}
                        </TextCell>
                      );
                    })}
                  </NestedRow>
                );
              })
            : null}
        </CollapseGroupRows>

        <CollapseGroupRows
          isHorAnEn={isHorAnEn}
          level={3}
          color={"#fff"}
          clickableCellText={t("cogs")}
          otherCellsTexts={transformedData.cogs.map((el) => {
            if (el === undefined) {
              return;
            } else {
              return el.total;
            }
          })}
        >
          {row.finData.length
            ? Object.keys(row.finData[0].cogs).map((el) => {
                if (el === "total") return;
                return (
                  <NestedRow>
                    <FirstCell level={3}>{t(el)}</FirstCell>
                    {transformedData.cogs.map((e, i, arr) => {
                      if (e === undefined) return;
                      return (
                        <TextCell
                          color={getHighlightColorForLastIndex(
                            e[el],
                            i,
                            arr,
                            isHorAnEn
                          )}
                        >
                          {e[el]}
                        </TextCell>
                      );
                    })}
                  </NestedRow>
                );
              })
            : null}
        </CollapseGroupRows>

        <NestedRow>
          <FirstCell level={3}>{t("grossProfit")}</FirstCell>
          <TextCell></TextCell>
          {transformedData.grossProfit.map((e, i, arr) => {
            return (
              <TextCell
                color={getHighlightColorForLastIndex(e, i, arr, isHorAnEn)}
              >
                {e}
              </TextCell>
            );
          })}
        </NestedRow>

        <CollapseGroupRows
          isHorAnEn={isHorAnEn}
          level={3}
          color={"#fff"}
          clickableCellText={t("gae")}
          otherCellsTexts={transformedData.gae.map((el) => {
            if (el === undefined) {
              return;
            } else {
              return el.total;
            }
          })}
        >
          {row.finData.length
            ? Object.keys(row.finData[0].gae).map((el) => {
                if (el === "total") return;
                return (
                  <NestedRow>
                    <FirstCell level={3}>{t(el)}</FirstCell>
                    {transformedData.gae.map((e, i, arr) => {
                      if (e === undefined) return;
                      return (
                        <TextCell
                          color={getHighlightColorForLastIndex(
                            e[el],
                            i,
                            arr,
                            isHorAnEn
                          )}
                        >
                          {e[el]}
                        </TextCell>
                      );
                    })}
                  </NestedRow>
                );
              })
            : null}
        </CollapseGroupRows>

        <CollapseGroupRows
          isHorAnEn={isHorAnEn}
          level={3}
          color={"#fff"}
          clickableCellText={t("sme")}
          otherCellsTexts={transformedData.sme.map((el) => {
            if (el === undefined) {
              return;
            } else {
              return el.total;
            }
          })}
        >
          {row.finData.length
            ? Object.keys(row.finData[0].sme).map((el) => {
                if (el === "total") return;
                return (
                  <NestedRow>
                    <FirstCell level={3}>{t(el)}</FirstCell>
                    {transformedData.sme.map((e, i, arr) => {
                      if (e === undefined) return;
                      return (
                        <TextCell
                          color={getHighlightColorForLastIndex(
                            e[el],
                            i,
                            arr,
                            isHorAnEn
                          )}
                        >
                          {e[el]}
                        </TextCell>
                      );
                    })}
                  </NestedRow>
                );
              })
            : null}
        </CollapseGroupRows>

        <CollapseGroupRows
          isHorAnEn={isHorAnEn}
          level={3}
          color={"#fff"}
          clickableCellText={t("otherIncomeAndExpences")}
          otherCellsTexts={transformedData.otherIncomeAndExpences.map((el) => {
            if (el === undefined) {
              return;
            } else {
              return el.total;
            }
          })}
        >
          {row.finData.length
            ? Object.keys(row.finData[0].otherIncomeAndExpences).map((el) => {
                if (el === "total") return;
                return (
                  <NestedRow>
                    <FirstCell level={3}>{t(el)}</FirstCell>
                    {transformedData.otherIncomeAndExpences.map((e, i, arr) => {
                      if (e === undefined) return;
                      return (
                        <TextCell
                          color={getHighlightColorForLastIndex(
                            e[el],
                            i,
                            arr,
                            isHorAnEn
                          )}
                        >
                          {e[el]}
                        </TextCell>
                      );
                    })}
                  </NestedRow>
                );
              })
            : null}
        </CollapseGroupRows>

        <NestedRow>
          <FirstCell level={3}>{t("ebitda")}</FirstCell>
          <TextCell></TextCell>
          {transformedData.ebitda.map((e, i, arr) => {
            return (
              <TextCell
                color={getHighlightColorForLastIndex(e, i, arr, isHorAnEn)}
              >
                {e}
              </TextCell>
            );
          })}
        </NestedRow>

        <CollapseGroupRows
          isHorAnEn={isHorAnEn}
          level={3}
          color={"#fff"}
          clickableCellText={t("depreciationAndAmortization")}
          otherCellsTexts={transformedData.depreciationAndAmortization.map(
            (el) => {
              if (el === undefined) {
                return;
              } else {
                return el.total;
              }
            }
          )}
        >
          {row.finData.length
            ? Object.keys(row.finData[0].depreciationAndAmortization).map(
                (el) => {
                  if (el === "total") return;
                  return (
                    <NestedRow>
                      <FirstCell level={3}>{t(el)}</FirstCell>
                      {transformedData.depreciationAndAmortization.map(
                        (e, i, arr) => {
                          if (e === undefined) return;
                          return (
                            <TextCell
                              color={getHighlightColorForLastIndex(
                                e[el],
                                i,
                                arr,
                                isHorAnEn
                              )}
                            >
                              {e[el]}
                            </TextCell>
                          );
                        }
                      )}
                    </NestedRow>
                  );
                }
              )
            : null}
        </CollapseGroupRows>

        <NestedRow>
          <FirstCell level={3}>{t("ebit")}</FirstCell>
          <TextCell></TextCell>
          {transformedData.ebit.map((e, i, arr) => {
            return (
              <TextCell
                color={getHighlightColorForLastIndex(e, i, arr, isHorAnEn)}
              >
                {e}
              </TextCell>
            );
          })}
        </NestedRow>

        <CollapseGroupRows
          isHorAnEn={isHorAnEn}
          level={3}
          color={"#fff"}
          clickableCellText={t("interest")}
          otherCellsTexts={transformedData.interest.map((el) => {
            if (el === undefined) {
              return;
            } else {
              return el.total;
            }
          })}
        >
          {row.finData.length
            ? Object.keys(row.finData[0].interest).map((el) => {
                if (el === "total") return;
                return (
                  <NestedRow>
                    <FirstCell level={3}>{t(el)}</FirstCell>
                    {transformedData.interest.map((e, i, arr) => {
                      if (e === undefined) return;
                      return (
                        <TextCell
                          color={getHighlightColorForLastIndex(
                            e[el],
                            i,
                            arr,
                            isHorAnEn
                          )}
                        >
                          {e[el]}
                        </TextCell>
                      );
                    })}
                  </NestedRow>
                );
              })
            : null}
        </CollapseGroupRows>

        <NestedRow>
          <FirstCell level={3}>{t("totalGrossProfit")}</FirstCell>
          <TextCell></TextCell>
          {transformedData.totalGrossProfit.map((e, i, arr) => {
            return (
              <TextCell
                color={getHighlightColorForLastIndex(e, i, arr, isHorAnEn)}
              >
                {e}
              </TextCell>
            );
          })}
        </NestedRow>

        <NestedRow>
          <FirstCell level={3}>{t("incomeTax")}</FirstCell>
          <TextCell></TextCell>
          {transformedData.incomeTax.map((e, i, arr) => {
            return (
              <TextCell
                color={getHighlightColorForLastIndex(e, i, arr, isHorAnEn)}
              >
                {e}
              </TextCell>
            );
          })}
        </NestedRow>

        <NestedRow>
          <FirstCell level={3}>{t("retainedEarnings")}</FirstCell>
          <TextCell></TextCell>
          {transformedData.retainedEarnings.map((e, i, arr) => {
            return (
              <TextCell
                color={getHighlightColorForLastIndex(e, i, arr, isHorAnEn)}
              >
                {e}
              </TextCell>
            );
          })}
        </NestedRow>
      </TableBody>
    </DefaultTable>
  );
};

//-------------------------//-------------------------//-------------------------//-------------------------
const TransformData = (finData, isHorAnEn, datesFilter) => {
  let hA = (data) => {
    if (isHorAnEn) {
      if (data === null) return [];
      let keys = Object.keys(data);

      if (keys.length > 0 && typeof data != `string`) {
        for (let e of keys) {
          data[e] = `${data[e]}%`;
        }
      } else if (typeof data == `string`) {
        if (data.length > 8) {
          return data;
        } else {
          return (data = `${data}%`);
        }
      }
    } else return [];
  };

  let filterData = (data) => {
    return data;
  };

  finData = filterData(finData);

  return {
    dates: [
      ...datesFilter.map((el) => el),
      ...(isHorAnEn ? [{ columnName: "Horizontal Analysis" }] : "⠀"),
    ],
    cogs: [
      [],
      ...finData.finData.map((el) => el.cogs),
      hA(finData.horizontalAnalyse.cogs),
    ],
    depreciationAndAmortization: [
      [],
      ...finData.finData.map((el) => el.depreciationAndAmortization),
      hA(finData.horizontalAnalyse.depreciationAndAmortization),
    ],
    gae: [
      [],
      ...finData.finData.map((el) => el.gae),
      hA(finData.horizontalAnalyse.gae),
    ],
    interest: [
      [],
      ...finData.finData.map((el) => el.interest),
      hA(finData.horizontalAnalyse.interest),
    ],
    otherIncomeAndExpences: [
      [],
      ...finData.finData.map((el) => el.otherIncomeAndExpences),
      hA(finData.horizontalAnalyse.otherIncomeAndExpences),
    ],
    revenue: [
      [],
      ...finData.finData.map((el) => el.revenue),
      hA(finData.horizontalAnalyse.revenue),
    ],
    sme: [
      [],
      ...finData.finData.map((el) => el.sme),
      hA(finData.horizontalAnalyse.sme),
    ],
    assets: [
      ...(isHorAnEn
        ? [
            ...finData.finData.map((el) => el.assets),
            finData.horizontalAnalyse.assets,
          ]
        : finData.finData.map((el) => el.assets)),
    ],
    assetss: {
      total: [
        [],
        ...finData.finData.map((el) => el.assets.total),
        hA(finData.horizontalAnalyse.assets.total),
      ],
      currentAssets: {
        total: [
          [],
          ...finData.finData.map((el) => el.assets.currentAssets.total),
          hA(finData.horizontalAnalyse.assets.currentAssets.total),
        ],
        accountsReceivables: [
          [],
          ...finData.finData.map(
            (el) => el.assets.currentAssets.accountsReceivables
          ),
          hA(
            finData.horizontalAnalyse.assets.currentAssets.accountsReceivables
          ),
        ],
        cash: {
          bankAccounts: [
            [],
            ...finData.finData.map(
              (el) => el.assets.currentAssets.cash.bankAccounts
            ),
            hA(
              finData.horizontalAnalyse.assets.currentAssets.cash.bankAccounts
            ),
          ],
          cashEquivalents: [
            [],
            ...finData.finData.map(
              (el) => el.assets.currentAssets.cash.cashEquivalents
            ),
            hA(
              finData.horizontalAnalyse.assets.currentAssets.cash
                .cashEquivalents
            ),
          ],
          pettyCash: [
            [],
            ...finData.finData.map(
              (el) => el.assets.currentAssets.cash.pettyCash
            ),
            hA(finData.horizontalAnalyse.assets.currentAssets.cash.pettyCash),
          ],
          total: [
            [],
            ...finData.finData.map((el) => el.assets.currentAssets.cash.total),
            hA(finData.horizontalAnalyse.assets.currentAssets.cash.total),
          ],
        },
        inventory: [
          [],
          ...finData.finData.map((el) => el.assets.currentAssets.inventory),
          hA(finData.horizontalAnalyse.assets.currentAssets.inventory),
        ],
        otherReceivables: [
          [],
          ...finData.finData.map(
            (el) => el.assets.currentAssets.otherReceivables
          ),
          hA(finData.horizontalAnalyse.assets.currentAssets.otherReceivables),
        ],
        shortTermInvestments: [
          [],
          ...finData.finData.map(
            (el) => el.assets.currentAssets.shortTermInvestments
          ),
          hA(
            finData.horizontalAnalyse.assets.currentAssets.shortTermInvestments
          ),
        ],
      },
      longTermAssets: {
        total: [
          [],
          ...finData.finData.map((el) => el.assets.longTermAssets.total),
          hA(finData.horizontalAnalyse.assets.longTermAssets.total),
        ],
        accountsReceivables: [
          [],
          ...finData.finData.map(
            (el) => el.assets.longTermAssets.accountsReceivables
          ),
          hA(
            finData.horizontalAnalyse.assets.longTermAssets.accountsReceivables
          ),
        ],
        fixedAssets: {
          buildings: [
            [],
            ...finData.finData.map(
              (el) => el.assets.longTermAssets.fixedAssets.buildings
            ),
            hA(
              finData.horizontalAnalyse.assets.longTermAssets.fixedAssets
                .buildings
            ),
          ],
          computers: [
            [],
            ...finData.finData.map(
              (el) => el.assets.longTermAssets.fixedAssets.computers
            ),
            hA(
              finData.horizontalAnalyse.assets.longTermAssets.fixedAssets
                .computers
            ),
          ],
          furniture: [
            [],
            ...finData.finData.map(
              (el) => el.assets.longTermAssets.fixedAssets
            ),
            hA(
              finData.horizontalAnalyse.assets.longTermAssets.fixedAssets
                .furniture
            ),
          ],
          land: [
            [],
            ...finData.finData.map(
              (el) => el.assets.longTermAssets.fixedAssets.land
            ),
            hA(
              finData.horizontalAnalyse.assets.longTermAssets.fixedAssets.land
            ),
          ],
          leaseHoldImprovements: [
            [],
            ...finData.finData.map(
              (el) => el.assets.longTermAssets.fixedAssets.leaseHoldImprovements
            ),
            hA(
              finData.horizontalAnalyse.assets.longTermAssets.fixedAssets
                .leaseHoldImprovements
            ),
          ],
          other: [
            [],
            ...finData.finData.map(
              (el) => el.assets.longTermAssets.fixedAssets.other
            ),
            hA(
              finData.horizontalAnalyse.assets.longTermAssets.fixedAssets.other
            ),
          ],
          total: [
            [],
            ...finData.finData.map(
              (el) => el.assets.longTermAssets.fixedAssets.total
            ),
            hA(
              finData.horizontalAnalyse.assets.longTermAssets.fixedAssets.total
            ),
          ],
          transport: [
            [],
            ...finData.finData.map(
              (el) => el.assets.longTermAssets.fixedAssets.transport
            ),
            hA(
              finData.horizontalAnalyse.assets.longTermAssets.fixedAssets
                .transport
            ),
          ],
        },
        intangibleAssets: {
          goodwill: [
            [],
            ...finData.finData.map(
              (el) => el.assets.longTermAssets.intangibleAssets.goodwill
            ),
            hA(
              finData.horizontalAnalyse.assets.longTermAssets.intangibleAssets
                .goodwill
            ),
          ],
          other: [
            [],
            ...finData.finData.map(
              (el) => el.assets.longTermAssets.intangibleAssets.other
            ),
            hA(
              finData.horizontalAnalyse.assets.longTermAssets.intangibleAssets
                .other
            ),
          ],
          patentsAndLicenses: [
            [],
            ...finData.finData.map(
              (el) =>
                el.assets.longTermAssets.intangibleAssets.patentsAndLicenses
            ),
            hA(
              finData.horizontalAnalyse.assets.longTermAssets.intangibleAssets
                .patentsAndLicenses
            ),
          ],
          software: [
            [],
            ...finData.finData.map(
              (el) => el.assets.longTermAssets.intangibleAssets.software
            ),
            hA(
              finData.horizontalAnalyse.assets.longTermAssets.intangibleAssets
                .software
            ),
          ],
          total: [
            [],
            ...finData.finData.map(
              (el) => el.assets.longTermAssets.intangibleAssets.total
            ),
            hA(
              finData.horizontalAnalyse.assets.longTermAssets.intangibleAssets
                .total
            ),
          ],
        },
        longTermInvestments: [
          [],
          ...finData.finData.map(
            (el) => el.assets.longTermAssets.longTermInvestments
          ),
          hA(
            finData.horizontalAnalyse.assets.longTermAssets.longTermInvestments
          ),
        ],
        otherReceivables: [
          [],
          ...finData.finData.map(
            (el) => el.assets.longTermAssets.otherReceivables
          ),
          hA(finData.horizontalAnalyse.assets.longTermAssets.otherReceivables),
        ],
        investmentProperty: [
          [],
          ...finData.finData.map(
            (el) => el.assets.longTermAssets.investmentProperty
          ),
          hA(
            finData.horizontalAnalyse.assets.longTermAssets.investmentProperty
          ),
        ],
      },
    },
    equity: {
      total: [
        ...finData.finData.map((el) => el.equity.total),
        hA(finData.horizontalAnalyse.equity.total),
      ],
      additionalCapital: [
        ...finData.finData.map((el) => el.equity.additionalCapital),
        hA(finData.horizontalAnalyse.equity.additionalCapital),
      ],
      mainCapital: [
        ...finData.finData.map((el) => el.equity.mainCapital),
        hA(finData.horizontalAnalyse.equity.mainCapital),
      ],
      netIncome: [
        ...finData.finData.map((el) => el.equity.netIncome),
        hA(finData.horizontalAnalyse.equity.netIncome),
      ],
      retainedEarnings: [
        ...finData.finData.map((el) => el.equity.retainedEarnings),
        hA(finData.horizontalAnalyse.equity.retainedEarnings),
      ],
      treasuryStock: [
        ...finData.finData.map((el) => el.equity.treasuryStock),
        hA(finData.horizontalAnalyse.equity.treasuryStock),
      ],
    },
    liabilities: {
      total: [
        [],
        ...finData.finData.map((el) => el.liabilities.total),
        hA(finData.horizontalAnalyse.liabilities.total),
      ],
      currentLiabilities: {
        accountsPayable: [
          ...finData.finData.map(
            (el) => el.liabilities.currentLiabilities.accountsPayable
          ),
          hA(
            finData.horizontalAnalyse.liabilities.currentLiabilities
              .accountsPayable
          ),
        ],
        allowances: [
          ...finData.finData.map(
            (el) => el.liabilities.currentLiabilities.allowances
          ),
          hA(
            finData.horizontalAnalyse.liabilities.currentLiabilities.allowances
          ),
        ],
        loansPayable: [
          ...finData.finData.map(
            (el) => el.liabilities.currentLiabilities.loansPayable
          ),
          hA(
            finData.horizontalAnalyse.liabilities.currentLiabilities
              .loansPayable
          ),
        ],
        creditCards: [
          ...finData.finData.map(
            (el) => el.liabilities.currentLiabilities.creditCards
          ),
          hA(
            finData.horizontalAnalyse.liabilities.currentLiabilities.creditCards
          ),
        ],
        taxPayable: [
          ...finData.finData.map(
            (el) => el.liabilities.currentLiabilities.taxPayable
          ),
          hA(
            finData.horizontalAnalyse.liabilities.currentLiabilities.taxPayable
          ),
        ],
        total: [
          ...finData.finData.map(
            (el) => el.liabilities.currentLiabilities.total
          ),
          hA(finData.horizontalAnalyse.liabilities.currentLiabilities.total),
        ],
      },
      longTermLiabilities: {
        accountsPayable: [
          ...finData.finData.map(
            (el) => el.liabilities.longTermLiabilities.accountsPayable
          ),
          hA(
            finData.horizontalAnalyse.liabilities.longTermLiabilities
              .accountsPayable
          ),
        ],
        loansPayable: [
          ...finData.finData.map(
            (el) => el.liabilities.longTermLiabilities.loansPayable
          ),
          hA(
            finData.horizontalAnalyse.liabilities.longTermLiabilities
              .loansPayable
          ),
        ],
        total: [
          ...finData.finData.map(
            (el) => el.liabilities.longTermLiabilities.total
          ),
          hA(finData.horizontalAnalyse.liabilities.longTermLiabilities.total),
        ],
      },
    },
    grossProfit: [
      ...finData.finData.map((el) => el.grossProfit),
      hA(finData.horizontalAnalyse.grossProfit),
    ],
    ebitda: [
      ...finData.finData.map((el) => el.ebitda),
      hA(finData.horizontalAnalyse.ebitda),
    ],
    ebit: [
      ...finData.finData.map((el) => el.ebit),
      hA(finData.horizontalAnalyse.ebit),
    ],
    totalGrossProfit: [
      ...finData.finData.map((el) => el.totalGrossProfit),
      hA(finData.horizontalAnalyse.totalGrossProfit),
    ],
    incomeTax: [
      ...finData.finData.map((el) => el.incomeTax),
      hA(finData.horizontalAnalyse.incomeTax),
    ],
    retainedEarnings: [
      ...finData.finData.map((el) => el.retainedEarnings),
      hA(finData.horizontalAnalyse.retainedEarnings),
    ],
  };
};

export default FinStatementEnTable;
