import React from "react";
import { useTranslation } from "react-i18next";
import { useButtonStyles } from "@View/pages/AdminPO/customer/customerDetails/bankStatement/styles";

export default function CommonError({ closeError }) {
  const { t } = useTranslation();
  const btnClasses = useButtonStyles();

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ fontSize: 20, fontWeight: 600 }}>
        {t("attach.errorTitle")}
      </div>
      <div style={{ color: "#54606F", padding: "30px 0" }}>
        {t("attach.errorDesc")}
      </div>
      <div>
        <button className={btnClasses.addBtn} onClick={() => closeError()}>
          {t("attach.attachNewFiles")}
        </button>
      </div>
    </div>
  );
}
