import { TableBody, TableCell, TableHead } from "@material-ui/core";
import {
  CollapseGroupRows,
  DefaultTable,
  FirstCell,
  NestedRow,
  TextCell,
} from "@View/common/table";
import React from "react";
import { useTranslation } from "react-i18next";
import pickColor from "@Utils/colorPicker";

const nestedTableCoverStyles = {
  width: "calc(100vw - 90px)",
  overflow: "auto",
  padding: "0 40px",
  boxSizing: "border-box",
};

const DqTable = ({ row }) => {
  const { t } = useTranslation();
  return (
    <DefaultTable>
      <TableBody>
        {row?.criterias?.map((cr) => {
          return (
            <CollapseGroupRows
              key={cr.title}
              isOpen={false}
              level={2}
              color={"#fff"}
              clickableCellText={cr.title}
            >
              {cr.checks.map((ch) => {
                return (
                  <CollapseGroupRows
                    key={ch.title}
                    isOpen={false}
                    color={"#fff"}
                    level={3}
                    clickableCellText={ch.title}
                  >
                    <div style={nestedTableCoverStyles}>
                      <DefaultTable nested={true}>
                        <TableHead
                          style={{
                            background: "#F3F5F7",
                            height: "46px",
                          }}
                        >
                          <NestedRow>
                            <TableCell
                              style={{
                                paddingLeft: "2em",
                                textAlign: "left",
                                border: "none",
                                fontWeight: 600,
                                fontSize: "13px",
                                lineHeight: "16px",
                                letterSpacing: "0.0075em",
                                color: "#54606F",
                              }}
                            >
                              {t("columnName")}
                            </TableCell>
                            {row.titleColumns.map((el, idx) => {
                              return <TextCell key={el}>{el}</TextCell>;
                            })}
                          </NestedRow>
                        </TableHead>
                        <TableBody>
                          {ch.rules.map((r, i) => {
                            return (
                              <NestedRow key={r.title + " " + i}>
                                <FirstCell paddingLeft={"2em"}>
                                  {r.title}
                                </FirstCell>
                                {r.values.map((v, i) => {
                                  return (
                                    <TextCell
                                      key={i}
                                      background={pickColor(v.color)}
                                    >
                                      {v.value}
                                    </TextCell>
                                  );
                                })}
                              </NestedRow>
                            );
                          })}
                        </TableBody>
                      </DefaultTable>
                    </div>
                  </CollapseGroupRows>
                );
              })}
            </CollapseGroupRows>
          );
        })}
      </TableBody>
    </DefaultTable>
  );
};

export default DqTable;
