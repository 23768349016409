import React from 'react'

const SearchIcon = () => {
    return (
        <div style={{display: 'flex'}}>
            <svg width="18" height="18" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 9L7.09524 7.09524M8.23809 4.61905C8.23809 6.61779 6.61779 8.23809 4.61905 8.23809C2.6203 8.23809 1 6.61779 1 4.61905C1 2.6203 2.6203 1 4.61905 1C6.61779 1 8.23809 2.6203 8.23809 4.61905Z" stroke="#8BA0B6" />
            </svg>
        </div>
    )
}

export default SearchIcon