import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const LinkCell = ({ path, onClick, className, textLink }) => {
  const { t } = useTranslation();
  return (
    <Link
      className={className}
      style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}
      to={path ? path : ""}
      onClick={onClick}
    >
      {t(textLink)}
    </Link>
  );
};

LinkCell.propTypes = {
  textLink: PropTypes.string.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default LinkCell;
