import { getCodeRequest } from "./actionGetCode";
import AppAPI from "@API/index";

export const editCode = (body, history) => {
  const trimmedBody = {
    id: body.id,
    status: body.status,
    requestAmount: body.requestAmount,
  };

  return (dispatch) => {
    AppAPI.request({
      method: "put",
      url: '/api/code',
      data: trimmedBody,
      suppressError: true
    }).then(() => {
        dispatch(getCodeRequest());
        history.goBack();
      })
  };
};
