import {
  GET_ALL_CUSTOMERS_FAILURE,
  GET_ALL_CUSTOMERS_REQUEST,
  GET_ALL_CUSTOMERS_SUCCESS,
} from "../../actions/actionTypes";

const initialState = {
  loading: false,
  customers: [],
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CUSTOMERS_REQUEST:
      return {
        ...state,
        customers: [],
      };
    case GET_ALL_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
        error: "",
      };
    case GET_ALL_CUSTOMERS_FAILURE:
      return {
        ...state,
        customers: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
