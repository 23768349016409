import SnackbarBase from "@material-ui/core/Snackbar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const Snackbar = () => {

  const { t } = useTranslation()
  const dispatch = useDispatch();
  const snackbar = useSelector(state => state.snackbar);
  const handleClose = () => {
    dispatch({
      type: "SET_SNACKBAR",
      payload: {
        ...snackbar,
        open: false,
      },
    });
  };

  const message = (snackbar.message && t(snackbar.message)) || snackbar.description || 'No description error';

  return (
    <SnackbarBase
      autoHideDuration={4000}
      message={message}
      open={snackbar.open}
      onClose={handleClose}
      TransitionComponent={snackbar.Transition}
    />
  );
};

export default Snackbar;
