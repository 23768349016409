import React from "react";
import Typography from "@material-ui/core/Typography";
import { getUserName } from "@Security/stateHelpers";

const UserName = () => {
  return (
    <Typography
      style={{
        color: "#1B1C30",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "22px",
      }}
    >
      {getUserName() || "You"}
    </Typography>
  );
};

export default UserName;
