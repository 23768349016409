import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useStyles } from "../../style/components/formStyle";
import MenuItem from "@material-ui/core/MenuItem";
import { SelectValidator } from "react-material-ui-form-validator";

//component: label + input with dropdown

const FormSelectRow = ({ label, onChange, dropdowns, value }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item className={`${classes.row} ${classes.textableRow}`}>
      <Typography component="p" className={classes.label}>
        {t(label)}
      </Typography>
      <SelectValidator
        variant="outlined"
        onChange={(event) => onChange(event.target.value)}
        className={classes.input}
        value={value}
        validators={["required"]}
        errorMessages={["this field is required"]}
      >
        {dropdowns.map((dropdown, index) => {
          return (
            <MenuItem value={dropdown.value} key={index}>
              {t(dropdown.value)}
            </MenuItem>
          );
        })}
      </SelectValidator>
    </Grid>
  );
};
FormSelectRow.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dropdowns: PropTypes.array.isRequired,
  value: PropTypes.any,
};
export default FormSelectRow;
