import { SET_SNACKBAR } from "@State/actions";
import { SET_CHECKBOX_DATA } from "@State/actions/actionTypes";
import { SET_EDIT_TABLE_DATA } from "@State/actions/actionTypes";

const initialState = {
  tableData: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EDIT_TABLE_DATA: 
        return {
            ...state,
            tableData: {...state.tableData, ...action.payload}
        };
    case SET_SNACKBAR:
      return {
        ...state,
        ...action.payload,
      };
    case SET_CHECKBOX_DATA:
      return {
        ...state,
        checkboxes: action.payload
      };
    default:
      return state;
  }
};

export default reducer;