import { createAction } from "@reduxjs/toolkit";

export const changeFiltersAction = createAction(
  "FinDataRequestFilters/CHANGE_FILTERS"
);
export const applyFiltersAction = createAction(
  "FinDataRequestFilters/APPLY_FILTERS"
);

export const modifyFinDataRequestFilters = (page, data) =>
  changeFiltersAction({ page, data });
export const applyFinDataRequestFilters = () => applyFiltersAction();
