import { Table } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { handleMutationOfTable } from "@Utils/tableUtils";

const netstedStyles = {
  // paddingTop: "14px",
  // paddingBottom: "14px",
  // paddingLeft: "40px",
  // paddingRight: "40px",
  background: "#FDFDFD",
};

const DefaultTable = (props) => {
  const useStyles = makeStyles((_theme) => ({
    defaultTable: {
      // display: 'block',
      "& > thead": {
        "& > tr": {
          borderBottom: "0px solid #CED7EC",
          "& > th": {
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "900",
            lineHeight: "16px",
            letterSpacing: "0em",
            textAlign: "right",
            color: "#354659",
            padding: "0.25em",
            borderBottom: "0px solid #000",

            ...(!props.unfixFirstCell
              ? {
                  "&:nth-child(1)": {
                    position: "sticky",
                    left: 0,
                    background: "#F3F5F7",
                  },
                }
              : {}),
            ...(props.fixTwoCell
              ? {
                  "&:nth-child(2)": {
                    position: "sticky",
                    background: "#F3F5F7",
                  },
                }
              : {}),
          },
          "& > .TextCell": {
            color: "#54606F",
            paddingLeft: "20px",
            paddingRight: "20px",
            whiteSpace: "nowrap",
            fontSize: "13px",
            textAlign: "center !important",
            fontWeight: 600,
            lineHeight: "16px",
            letterSpacing: "0.0075em",
          },
        },
      },
      "& > tbody": {
        "& > tr > .FirstCell": {
          transform: "none !important",
          // paddingLeft: 'calc(5em + 6px) !important'
        },
        "& * .DefaultTable__wrapper": {
          minWidth: "auto !important",
        },
        ...(!props.unfixFirstCell
          ? {
              "& > tr > td:nth-child(1)": {
                position: "sticky",
                left: 0,
                background: "white",
                zIndex: 10,
              },
            }
          : {}),
        ...(props.fixTwoCell
          ? {
              "& > tr > td:nth-child(2)": {
                position: "sticky",
                background: "white",
                zIndex: 10,
              },
            }
          : {}),
        ...(props.maxCellWidth
          ? {
              "& > tr > td > div": {
                maxWidth: props.maxCellWidth,
                whiteSpace: "normal",
              },
            }
          : {}),
        "& input": {
          fontWeight: 500,
          fontSize: "13px",
          lineHeight: "16px",
          letterSpacing: "0.0075em",
          color: "#1A1E24",
          minWidth: "32px",
        },
        "& > tr:not(.CollapseContainerRow):hover > td": {
          background: "#F9F9F9",
        },
        "& > tr": {
          borderBottom: "0px solid #CED7EC",
          paddingTop: "40px",
          paddingBottom: "40px",
          transition: "0.3s ease",
          background: "#fff",
          cursor: "pointer",
          "& > .FirstCell": {
            whiteSpace: "normal",
            minWidth: "15em",
            maxWidth: "15em",
            paddingTop: "0px",
            transform: "translateX(6px)",
          },
          "& > .TextCell": {
            textAlign: "right !important",
            paddingLeft: "20px",
            paddingRight: "20px",
          },
          "& > td": {
            fontWeight: 500,
            fontSize: "13px",
            lineHeight: "16px",
            letterSpacing: "0.0075em",
            padding: "0.25em",
            border: "0",
            whiteSpace: "nowrap",
            color: "#46515D",
            height: "50px",
            maxWidth: "400px",
          },
          "& > td:last-child": {
            paddingRight: "20px",
          },
          "& > .Container": {
            "& > button": {
              textTransform: "none",
              color: "inherit !important",
            },
          },
        },
        "& > .CollapseContainerRow": {
          borderTop: "1px solid #CED7EC",
          paddingBottom: "15px",
          marginBottom: "15px",
          borderBottom: "1px solid #CED7EC",
          "& > td": {
            // background: '#F5F7F9',
            // borderRadius: '12px',

            // borderBottom: '1px solid #CED7EC',
            // borderLeft: '1px solid #CED7EC',
            // paddingTop: '15px',
            // paddingBottom: '30px',
            "& > div": {
              "& > div": {
                // borderTop: '1px solid #CED7EC',
                // background: '#F5F7F9',
                borderRadius: "12px",
                paddingTop: "15px",
                paddingBottom: "15px",
                background: "#fff",
                // borderTop: '1px solid #CED7EC',
                // borderBottom: '1px solid #CED7EC',
                // borderRight: '1px solid #CED7EC'
              },
              "& > div > button": {
                fontSize: "12px",
                fontWeight: "900",
                lineHeight: "16px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#6FA4F2",
                textTransform: "none",
                padding: "0",
                "&:hover": {
                  background: "none",
                },
                "& > span": {
                  justifyContent: "start",
                },
              },
            },
          },
        },
        "& > .CollapseGroupRowsFooterRow": {
          background: "#f2f5fa", //#dfe5f0
          borderBottom: "0",
          "& > td > button": {
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "900",
            lineHeight: "16px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#6FA4F2",
            textTransform: "none",
            padding: "0",
            "&:hover": {
              background: "none",
            },
            "& > span": {
              justifyContent: "start",
            },
          },
        },
        "& > .NestedRow": {
          // background: '#f2f5fa' //dfe5f0
        },
        "& > .NoCollapseGroupRows": {
          opacity: ".7",
        },
      },
    },
  }));
  const classes = useStyles();

  const table = document.querySelector(
    ".MuiTable-root > tbody > tr > td:last-child"
  );
  if (table !== null) {
    table.scroll(10000, 0);
  }

  const [width, setWidth] = React.useState();

  useEffect(() => {
    setWidth(window.innerWidth - 90);
    function handleResize() {
      setWidth(window.innerWidth - 90);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const baseTableRef = useRef();

  useEffect(() => {
    if (props.fixTwoCell && baseTableRef.current && classes.defaultTable) {
      const observer = new MutationObserver(
        handleMutationOfTable(baseTableRef.current)
      );
      observer.observe(baseTableRef.current, {
        attributes: true,
        childList: true,
        subtree: true,
      });
      return () => {
        observer.disconnect();
      };
    }
  }, [baseTableRef, classes.defaultTable]);

  return (
    <div style={props.nested ? netstedStyles : null}>
      <div
        className="DefaultTable__wrapper"
        style={{
          background: "transparent",
          ...(props.nested ? {} : { marginTop: "25px", marginBottom: "14px" }),
        }}
      >
        <div style={{ width: "100%", background: "#fff", overflow: "auto" }}>
          <Table className={classes.defaultTable} ref={baseTableRef}>
            {props.children}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default DefaultTable;
