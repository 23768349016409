import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  DataGrid,
  GridToolbarContainer,
  GridOverlay,
} from "@material-ui/data-grid";

import NoDataPic from "../svgComponents/NoDataPic";
import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import SearchIcon from "../svgComponents/SearchIcon";
import { useDispatch } from "react-redux";
import { setCheckBoxData } from "@State/actions/actionsGet/actionGetCustomer";
import ClearIcon from "@mui/icons-material/Clear";

const useStyles = makeStyles((theme) => {
  return {
    dataGrid: {
      "&.MuiDataGrid-root": {
        border: "none",
        textDecoration: "none",
        paddingBottom: "40px",
      },
      "& .MuiDataGrid-row:hover": {
        backgroundColor: "#F5F5F5 !important",
      },
      "& .MuiDataGrid-cellWithRenderer": {
        justifyContent: "center",
      },
      "& .MuiDataGrid-row": {
        cursor: (props) => (props.shouldRowBeClickable ? "pointer" : "default"),
      },
      "& .MuiDataGrid-main": {
        order: 3,
      },
      "& .MuiDataGrid-footer": {
        padding: "15px 0",
        justifyContent: "start",
        flexDirection: "row-reverse",
      },
      "& .MuiDataGrid-virtualScroller": {
        marginTop: "50px", // Adjust this value as needed
      },
      "& .MuiDataGrid-cell": {
        borderBottom: 0,
        color: "#000",
        fontSize: "13px",
        lineHeight: "16px",
        fontWeight: 500,
      },
      "& .MuiCheckbox-root svg": {},
      "& .MuiCheckbox-root svg path": {},
      "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
        position: "absolute",
        display: "none",
        border: "2px solid #fff",
        borderTop: 0,
        borderLeft: 0,
        transform: "rotate(45deg) translate(-50%,-50%)",
        opacity: 1,
        transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
        content: '""',
        top: "50%",
        left: "39%",
        width: 5.71428571,
        height: 9.14285714,
      },
      "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
        {
          width: 8,
          height: 8,
          backgroundColor: "transparent",
          transform: "none",
          top: "39%",
          border: 0,
        },
      "& .MuiDataGrid-colCellSortable": {
        color: "#54606F",
        border: "none",
        fontWeight: 600,
        fontSize: "13px",
        lineHeight: "16px",
        letterSpacing: "0.0075em",
      },
      "& .MuiDataGrid-columnsContainer": {
        borderBottom: "none",
        backgroundColor: "transparent",
        display: "flex",
        alignItems: "center",
        height: "100%",
      },
      "& .MuiDataGrid-colCellWrapper": {
        height: "100%",
        paddingTop: "14px",
        paddingBottom: "14px",
      },
      "& .MuiTablePagination-caption": {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "23px",
        color: "#000",
      },
      "& .MuiIconButton-colorInherit": {
        color: "#2960EE",
      },
      "& .MuiTablePagination-input": {
        borderBottom: "2px solid rgb(111, 164, 242)",
        color: "rgb(111, 164, 242)",
        fontSize: "14px",
        fill: "rgb(111, 164, 242)",
      },
      "& .MuiTablePagination-input svg": {
        fill: "rgb(111, 164, 242)",
      },
      "& .MuiTablePagination-input .MuiTablePagination-select": {
        paddingBottom: "5px",
      },
      "& .MuiDataGrid-toolbar button": {
        color: "#5195E5",
      },
      "& .MuiDataGrid-columnSeparator": {
        color: "transparent",
      },
      "& .MuiDataGrid-row:first-child": {
        borderRadius: "10px 10px 0px 0px",
      },
      "& .MuiDataGrid-row:last-child": {
        borderRadius: "0px 0px 10px 10px",
      },
      "& .rendering-zone": {
        borderRadius: "10px",
        overflow: "hidden",
      },
      "& .MuiDataGrid-row.Mui-even": {
        backgroundColor: "#fff",
      },
      "& .MuiDataGrid-row.Mui-odd": {
        backgroundColor: "#fff",
      },
      "& .MuiIconButton-root": {
        color: "#000",
      },
      "& .MuiTablePagination-root": {
        margin: "0 auto",
        marginTop: "39px",
      },
    },
    label: {
      marginTop: theme.spacing(1),
    },
    toolbar: {
      background: "rgb(255, 255, 255)",
      borderRadius: "10px",
      // marginBottom: "20px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "stretch",
      padding: "12px 20px",
      gap: "15px",
      "& > *": {
        height: "58px",
      },
      // marginRight: getAppLang() ? "220px" : "260px",
    },
    root: {
      paddingTop: "0px",
      flexDirection: "column",
    },
    textField: {
      border: "0.5px solid #8BA0B6",
      color: "#8BA0B6",
      fontWeight: 500,
      fontSize: "8px",
      lineHeight: "16px",
      "&:placeholder": {
        color: "#000",
      },
    },
  };
});

const CustomCheckbox = () => {
  return (
    <GridToolbarContainer>
      <Checkbox
        size="small"
        color="red"
        inputProps={{ "aria-label": "primary checkbox" }}
      />
    </GridToolbarContainer>
  );
};

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const useStylesForPagination = makeStyles((theme) => ({
  inner: {
    display: "inline-grid",
    gridTemplateColumns: "auto auto",
    gap: "20px",
    width: "auto",
    alignItems: "center",
    textAlign: "left",
    "& .MuiSelect-select": {
      minWidth: "80px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E1E6F1",
      borderRadius: "10px",
    },
    "& .MuiInputBase-input": {
      fontSize: "13px",
      fontWeight: "500",
    },
  },
  text: {
    color: "#A1ADBA",
    fontSize: "13px",
    fontWeight: "500",
  },
}));

function CustomPagination(props) {
  const { t } = useTranslation();
  const styles = useStylesForPagination();
  const pageStartCountBase =
    props.state.pagination.page * props.state.pagination.pageSize;
  const pageStartCount = pageStartCountBase + 1;
  const pageEndCount =
    props.state.pagination.rowCount >
    pageStartCountBase + props.state.pagination.pageSize
      ? pageStartCountBase + props.state.pagination.pageSize
      : props.state.pagination.rowCount;

  const [currentPage, setCurrentPage] = useState(props.state.pagination.page);

  return (
    <div className={styles.inner}>
      <FormControl size="small">
        <Select
          value={currentPage}
          variant="outlined"
          onChange={(event, _) => {
            setCurrentPage(event.target.value);
            if (props?.api?.current) {
              props.api.current.setPage(event.target.value);
            }
          }}
        >
          {[...Array(props.state.pagination.pageCount)].map((_, p) => {
            return (
              <MenuItem key={p} value={p}>
                {p + 1}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <div className={styles.text}>
        {pageStartCount}-{pageEndCount}
        &nbsp;{t("filterToolbar.footerRowFromAll")}&nbsp;
        {props.state.pagination.rowCount}
      </div>
    </div>
  );
}

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.toolbar}>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={"searchTextField"}
        InputProps={{
          startAdornment: (
            <SearchIcon style={{ marginRight: "20px" }} fontSize="small" />
          ),
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{
                visibility: props.value ? "visible" : "hidden",
                position: "reltive",
              }}
              onClick={props.clearSearch}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
      {props.customButtons}
    </div>
  );
}

const CustomNoDataOverlay = () => {
  const classes = useStyles();

  return (
    <GridOverlay style={{ padding: "140px" }} className={classes.root}>
      <NoDataPic />
      <div className={classes.label}>No Data</div>
    </GridOverlay>
  );
};

const usePaginationStyle = makeStyles(() => ({
  footerCell: {
    background: "red",
  },
  panelFooter: {
    background: "red",
  },
}));

const DataTable = ({
  rows,
  columns,
  rowsPerPage,
  deleteComponents,
  checkboxSelection,
  hideFooterPagination,
  onRowClick = null,
  toolbarButtons = null,
}) => {
  const classes = useStyles({
    shouldRowBeClickable: true,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const paginationStyle = usePaginationStyle();

  const [searchText, setSearchText] = React.useState("");
  const [rows2, setRows] = React.useState(rows);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field] !== undefined && row[field] !== null) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setRows(filteredRows);
  };

  React.useEffect(() => {
    setRows(rows);
  }, [rows]);

  return (
    <DataGrid
      localeText={{
        toolbarFilters: t("filterToolbar.toolbarFilters"),
        filterPanelAddFilter: t("filterToolbar.filterPanelAddFilter"),
        filterPanelDeleteIconLabel: t(
          "filterToolbar.filterPanelDeleteIconLabel"
        ),
        filterPanelLinkOperator: t("filterToolbar.filterPanelLinkOperator"),
        filterPanelOperators: t("filterToolbar.filterPanelOperators"),
        filterPanelOperatorAnd: t("filterToolbar.filterPanelOperatorAnd"),
        filterPanelOperatorOr: t("filterToolbar.filterPanelOperatorOr"),
        filterPanelColumns: t("filterToolbar.filterPanelColumns"),
        filterPanelInputLabel: t("filterToolbar.filterPanelInputLabel"),
        filterPanelInputPlaceholder: t(
          "filterToolbar.filterPanelInputPlaceholder"
        ),
        filterOperatorContains: t("filterToolbar.filterOperatorContains"),
        filterOperatorEquals: t("filterToolbar.filterOperatorEquals"),
        filterOperatorStartsWith: t("filterToolbar.filterOperatorStartsWith"),
        filterOperatorEndsWith: t("filterToolbar.filterOperatorEndsWith"),
        filterOperatorIs: t("filterToolbar.filterOperatorIs"),
        filterOperatorNot: t("filterToolbar.filterOperatorNot"),
        filterOperatorAfter: t("filterToolbar.filterOperatorAfter"),
        filterOperatorOnOrAfter: t("filterToolbar.filterOperatorOnOrAfter"),
        filterOperatorBefore: t("filterToolbar.filterOperatorBefore"),
        filterOperatorOnOrBefore: t("filterToolbar.filterOperatorOnOrBefore"),
        filterOperatorIsEmpty: t("filterToolbar.filterOperatorIsEmpty"),
        filterOperatorIsNotEmpty: t("filterToolbar.filterOperatorIsNotEmpty"),
        filterOperatorIsAnyOf: t("filterToolbar.filterOperatorIsAnyOf"),
        footerPaginationRowsPerPage: t(
          "filterToolbar.footerPaginationRowsPerPage"
        ),
        footerRowSelected: (c) => t("filterToolbar.footerRowSelected") + c,
      }}
      className={classes.dataGrid}
      rows={rows2}
      columns={columns}
      classes={paginationStyle}
      pageSize={rowsPerPage ? rowsPerPage : 10}
      checkboxSelection={!!checkboxSelection}
      onSelectionModelChange={(newSelectionModel) => {
        dispatch(setCheckBoxData(newSelectionModel.selectionModel));
      }}
      onRowClick={onRowClick ? onRowClick : () => {}}
      disableColumnMenu={true}
      disableSelectionOnClick
      autoHeight
      rowHeight={50}
      sortingOrder={["asc", "desc"]}
      rowsPerPageOptions={[5, 10, 20]}
      hideFooterPagination={
        hideFooterPagination
          ? true
          : !(deleteComponents === true || rows2.length > 5)
      }
      hideFooterSelectedRowCount={false}
      components={
        deleteComponents
          ? ""
          : {
              Toolbar: QuickSearchToolbar,
              NoRowsOverlay: CustomNoDataOverlay,
              Checkbox: CustomCheckbox,
              Pagination: CustomPagination,
            }
      }
      componentsProps={{
        toolbar: {
          value: searchText,
          onChange: (event) => requestSearch(event.target.value),
          clearSearch: () => requestSearch(""),
          customButtons: toolbarButtons,
        },
      }}
    />
  );
};

DataTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

export default DataTable;
