import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import RevenueDynamicsChart from "./charts/RevenueDynamicsChart";
import DebtStructureChart from "./charts/DebtStructureChart";
import ProfitabilityChart from "./charts/ProfitabilityChart";
import { useParams } from "react-router-dom";
import { RequestTab } from "@Constants/enums";
import { useTranslation } from "react-i18next";
import NoData, { NoDataAdaptive } from "@View/style/components/NoData";
import { TableBody, TableCell, Typography } from "@material-ui/core";
import { RangeDatePicker } from "@View/common/form/control/RangeDatePicker";
import AppAPI from "@API/index";
import { DefaultTable, FirstCell, NestedRow } from "@Components/table";
import { customNumberFormat } from "@Utils/format";

const pageCellStyles = {
  background: "#fff",
  borderRadius: "10px",
};

const SummaryTab = () => {
  const { i18n } = useTranslation();
  const { id: finDataRequestId } = useParams();
  const [summary, setSummary] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    (async () =>
      setSummary(
        await (i18n.language === "ru"
          ? AppAPI.request({
              url: `/api/FinDataRequest/${finDataRequestId}/summary`,
            })
          : AppAPI.request({
              url: `/api/FinDataRequest/${finDataRequestId}/summary/en`,
            }))
      ))();
  }, [finDataRequestId, i18n.language]);

  const getDates = (arr = []) => {
    const sortedDates = arr.sort((a, b) => a.getTime() - b.getTime());

    let startDate = new Date(Date.parse(sortedDates[0]));
    startDate = startDate.setDate(1);
    startDate = new Date(startDate);

    let endDate = new Date(Date.parse(sortedDates[sortedDates.length - 1]));
    let lastDayOfMonthDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth() + 1,
      0
    );
    endDate = lastDayOfMonthDate;

    return [startDate, endDate];
  };
  const [minStartDate, setMinStartDate] = useState(null);
  const [maxEndDate, setMaxEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (summary?.revenueDynamics) {
      let dates = Object.keys(summary.revenueDynamics).map(
        (date) => new Date(date)
      );
      const [startMinValue, endMaxValue] = getDates(dates);
      setStartDate(startMinValue);
      setEndDate(endMaxValue);
      setMinStartDate(startMinValue);
      setMaxEndDate(endMaxValue);
    }
  }, [summary]);

  if (!summary || !minStartDate || !maxEndDate || !startDate || !endDate) {
    return (
      <div style={{ height: "100px", transform: "translateX(-20px)" }}>
        <NoData />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Grid container style={{ flexFlow: "column", gap: "20px" }}>
        <Grid
          item
          style={{
            background: "#FFFFFF",
            borderRadius: "10px 10px 0px 0px",
            padding: "15px 20px",
          }}
        >
          <RangeDatePicker
            views={["year", "month"]}
            label={t("Analysis period")}
            minValue={minStartDate}
            maxValue={maxEndDate}
            startValue={startDate}
            endValue={endDate}
            onChangeStart={setStartDate}
            onChangeEnd={setEndDate}
            vertical
          />
        </Grid>
        {i18n.language === "en" && (
          <Typography style={{ marginTop: "0px", marginLeft: "25px" }}>
            This is stub data
          </Typography>
        )}

        <Grid
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "20px",
            justifyContent: "space-between",
          }}
        >
          <Grid style={pageCellStyles}>
            <RevenueDynamicsChart data={summary} page={RequestTab.Summary} />
          </Grid>
          <Grid style={pageCellStyles}>
            <ProfitabilityChart data={summary} page={RequestTab.Summary} />
          </Grid>
          <Grid style={pageCellStyles}>
            <DebtStructureChart data={summary} />
          </Grid>
        </Grid>
      </Grid>

      <DefaultTable nested={false} id="DynamicsCurrencyTableDF">
        <TableBody>
          {Array.isArray(summary?.items) && summary.items.length ? (
            summary.items.map((item) => (
              <NestedRow key={"1"}>
                <FirstCell level={2}>{item.name}</FirstCell>
                <TableCell style={{ paddingRight: "20px" }} align="right">
                  {customNumberFormat(item.value)}
                </TableCell>
              </NestedRow>
            ))
          ) : (
            <tr>
              <td>
                <div style={{ height: "200px" }}>
                  <NoDataAdaptive />
                </div>
              </td>
            </tr>
          )}
        </TableBody>
      </DefaultTable>
    </React.Fragment>
  );
};

SummaryTab.propTypes = {};

export default SummaryTab;
