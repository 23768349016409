import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { ValidatorForm } from "react-material-ui-form-validator";
import Grid from "@material-ui/core/Grid";
import { changePassword } from "@State/index";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useStyles } from "@View/style/pages/loginStyle";
import { useTranslation } from "react-i18next";
import ButtonDefault from "@View/components/buttons/ButtonDefault";
import PasswordInput from "@View/components/passwordField/PasswordInput";
import { getUserId } from "@Security/stateHelpers";

//redux
const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (body, history, errorText) =>
      dispatch(changePassword(body, history, errorText)),
  };
};

const ChangePasswordPage = ({ changePassword }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let history = useHistory();
  const [body, setBody] = useState({
    id: getUserId(),
    oldpassword: "",
    newpassword: "",
    confirmPassword: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (body.newpassword === body.confirmPassword) {
      changePassword(body, history, t("ChangePassError"));
    } else alert("passwords mismatch");
  };

  const handleCancel = (event) => {
    event.preventDefault();
    history.goBack();
  };

  return (
    <Container maxWidth="xs">
      <ValidatorForm
        onSubmit={(event) => handleSubmit(event)}
        instantValidate={false}
      >
        <PasswordInput
          value={body.oldpassword}
          label={t("Old password")}
          onChange={(value) => setBody({ ...body, oldpassword: value })}
        />
        <PasswordInput
          value={body.newpassword}
          label={t("New password")}
          onChange={(value) => setBody({ ...body, newpassword: value })}
        />
        <PasswordInput
          value={body.confirmPassword}
          label={t("Confirm password")}
          onChange={(value) => setBody({ ...body, confirmPassword: value })}
        />
        <Grid className={classes.buttons}>
          <ButtonDefault fullWidth>{t("buttons.Ok")}</ButtonDefault>
        </Grid>
      </ValidatorForm>
      <Grid className={classes.buttons}>
        <ButtonDefault fullWidth onClick={(event) => handleCancel(event)}>
          {t("buttons.Cancel")}
        </ButtonDefault>
      </Grid>
    </Container>
  );
};

ChangePasswordPage.propTypes = {
  changePassword: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(ChangePasswordPage);
