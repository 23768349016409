import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import ListOfCustomersPage from "@View/pages/AdminPO/ListOfCustomersPage";
import CustomerDetailsPage from "@View/pages/AdminPO/customer/customerDetails/CustomerDetailsPage";
import ClientDetailsPage from "@View/pages/AdminPO/client/clientDetails/ClientDetailsPage";
import ClientDetailsRequestPage from "@View/pages/AdminPO/client/request/ClientDetailsRequestPage";
import EditCustomerPage from "@View/pages/AdminPO/customer/EditCustomerPage";
import NewCustomerPage from "@View/pages/AdminPO/customer/NewCustomerPage";
import NewClientPage from "@View/pages/AdminPO/client/NewClientPage";
import NewUserPage from "@View/pages/AdminPO/user/NewUserPage";
import SignupPage from "@View/pages/AdminPO/SignupPage";
import UserDetailsPage from "@View/pages/AdminPO/user/UserDetailsPage";
import EditClientPage from "@View/pages/AdminPO/client/EditClientPage";
import EditUserPage from "@View/pages/AdminPO/user/EditUserPage";
import NotFound from "@View/components/NotFound";
import ChangePasswordPage from "@View/pages/AdminPO/ChangePasswordPage";
import { FinDataResultPage } from "@View/pages/AdminPO/request/finDataResult/FinDataResultPage";
import FAQPage from "@View/pages/AdminPO/FAQPage";
import DefaultLayout from "@View/layouts/DefaultLayout";
import RouteController from "./RouteController";
import ListOfCodesPage from "@View/pages/AdminPO/codes/ListOfCodesPage";
import NewCodePage from "@View/pages/AdminPO/codes/NewCodePage";
import EditCodePage from "@View/pages/AdminPO/codes/EditCodePage";
import { useSelector } from "react-redux";
import { SecuredRoute } from "@Security/SecuredRoute";
import ListOfUsersPage from "@View/pages/AdminPO/ListOfUsersPage";
import SigninPage from "@View/pages/AdminPO/SigninPage";
import CreaditOpinion from "@View/pages/AdminPO/client/CreaditOpinion";
import SetupPassword from "@View/pages/SetupPassword";
import SignupCompanyPage from "@View/pages/AdminPO/SignupPageCompany";
import RestorePage from "@View/pages/AdminPO/RestorePage";
import PaymentPage from "@View/pages/AdminPO/PaymentPage";
import RiskSignals from "@View/pages/AdminPO/client/RiskSignals";

const AppRouter = () => {
  const isAuth = useSelector((state) => state.auth);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/404">
          <NotFound />
        </Route>
        <RouteController
          exact
          path="/"
          component={SigninPage}
          canProceed={!isAuth}
        />
        <Route exact path="/signup">
          <SignupPage />
        </Route>
        <Route exact path="/clientsignup">
          <SignupCompanyPage />
        </Route>
        <Route exact path="/reset-password">
          <RestorePage />
        </Route>
        <Route exact path="/user/reset-password">
          <SetupPassword />
        </Route>
        <Route>
          <DefaultLayout>
            <Switch>
              <Route exact path="/payment">
                <PaymentPage />
              </Route>
              <SecuredRoute exact path="/customers" roles={["Admin"]}>
                <ListOfCustomersPage />
              </SecuredRoute>
              <SecuredRoute exact path="/users" roles={["Admin"]}>
                <ListOfUsersPage />
              </SecuredRoute>
              <SecuredRoute exact path="/codes" roles={["Admin"]} nonWhiteLabel>
                <ListOfCodesPage />
              </SecuredRoute>
              <SecuredRoute
                exact
                path="/code/new"
                roles={["Admin"]}
                nonWhiteLabel
              >
                <NewCodePage />
              </SecuredRoute>
              <SecuredRoute
                exact
                path="/code/edit/:id"
                roles={["Admin"]}
                nonWhiteLabel
              >
                <EditCodePage />
              </SecuredRoute>
              <SecuredRoute
                exact
                path="/customer/details/:id"
                roles={[
                  "Admin",
                  "ClientUser",
                  "ClientGuest",
                  "CustomerUser",
                  "CustomerGuest",
                  "CustomerManager",
                ]}
              >
                <CustomerDetailsPage />
              </SecuredRoute>
              <SecuredRoute
                exact
                path="/customer/edit/:id"
                roles={[
                  "Admin",
                  "ClientUser",
                  "ClientGuest",
                  "CustomerUser",
                  "CustomerGuest",
                  "CustomerManager",
                ]}
              >
                <EditCustomerPage />
              </SecuredRoute>
              <SecuredRoute
                exact
                path="/customer/new"
                roles={[
                  "Admin",
                  "ClientUser",
                  "ClientGuest",
                  "CustomerUser",
                  "CustomerGuest",
                  "CustomerManager",
                ]}
              >
                <NewCustomerPage />
              </SecuredRoute>
              <SecuredRoute exact path="/client/details/:id">
                <ClientDetailsPage />
              </SecuredRoute>
              <SecuredRoute exact path="/request/details/:id">
                <ClientDetailsRequestPage />
              </SecuredRoute>
              <SecuredRoute exact path="/request-new/details/:id">
                <ClientDetailsRequestPage />
              </SecuredRoute>
              <SecuredRoute exact path="/client/edit/:id">
                <EditClientPage />
              </SecuredRoute>
              <SecuredRoute path="/client/new">
                <NewClientPage />
              </SecuredRoute>
              <SecuredRoute path="/user/details/:id">
                <UserDetailsPage />
              </SecuredRoute>
              <SecuredRoute exact path="/user/edit/:id">
                <EditUserPage />
              </SecuredRoute>
              <SecuredRoute exact path="/findatarequest/:id/data">
                <FinDataResultPage />
              </SecuredRoute>
              <SecuredRoute path="/user/new">
                <NewUserPage />
              </SecuredRoute>
              <SecuredRoute path="/user/changepassword">
                <ChangePasswordPage />
              </SecuredRoute>
              <SecuredRoute exact path="/faq">
                <FAQPage />
              </SecuredRoute>
              <SecuredRoute exact path="/creaditOpinion/:id">
                <CreaditOpinion />
              </SecuredRoute>
              <SecuredRoute exact path="/client/risk/:id">
                <RiskSignals />
              </SecuredRoute>
              <Route>
                <Redirect to="/404" />
              </Route>
            </Switch>
          </DefaultLayout>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
