import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { viewBigNumber } from "@Utils/format";

const CustomizedLegend = (props) => {
  const { payload } = props;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        gap: "15px",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {payload.map((entry) => (
        <div
          key={entry.value}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <div
            style={{
              minWidth: "8px",
              minHeight: "8px",
              borderRadius: "50%",
              background: entry.color,
            }}
          ></div>
          <div style={{ color: entry.color }}>{entry.value}</div>
        </div>
      ))}
    </div>
  );
};

const OneBarChart = ({ data, colors, Tittle }) => {
  const { i18n } = useTranslation();

  if (data.length === 0) return null;

  const keys = Object.keys(data[0]).filter((f) => f !== "date");

  return (
    <div
      style={{
        background: "#FFFFFF",
        borderRadius: "10px",
        position: "relative",
        padding: "20px",
      }}
    >
      <div
        style={{
          fontWeight: 400,
          fontSize: "18px",
          color: "#A1ADBA",
          textAlign: "center",
          paddingBottom: "40px",
        }}
      >
        {Tittle}
      </div>
      <div style={{ height: "500px" }}>
        <ResponsiveContainer>
          <BarChart data={data}>
            <XAxis dataKey="date" tickLine={false} />
            <YAxis
              tickFormatter={(number) => viewBigNumber(number, i18n.language)}
            />
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <Legend
              verticalAlign="top"
              content={<CustomizedLegend />}
              wrapperStyle={{
                top: -20,
              }}
            />
            <Tooltip
              formatter={(number) => viewBigNumber(number, i18n.language)}
            />
            {keys.map((e, i) => {
              return (
                <Bar
                  key={e}
                  barSize={30}
                  dataKey={e}
                  stackId={"stackId"}
                  fill={colors[i]}
                />
              );
            })}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default OneBarChart;
