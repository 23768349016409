import {styled} from "@mui/material/styles";
import {FormControlLabel, Switch} from "@material-ui/core";

export const UIToggle = styled(Switch)(() => ({
  padding: '0 12px',
  height: '20px',
  '& .MuiSwitch-thumb': {
    width: '18px',
    height: '18px',
  },
  '& .MuiSwitch-switchBase': {
    top: '1px',
    padding: '0',
    left: '13px',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    transform: 'translateX(14px)'
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: '#BFC1C7 !important',
    opacity: 1,
    borderRadius: '15px'
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#1CA46B !important',
    opacity: 1
  },
  '& .MuiSwitch-switchBase .MuiSwitch-thumb': {
    backgroundColor: 'white',
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="17" width="17" viewBox="0 0 24 24"><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="${encodeURIComponent('#BFC1C7')}"></path></svg>')`,
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
    backgroundColor: 'white',
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="17" width="17" viewBox="0 0 24 24"><path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill="${encodeURIComponent('#1CA46B')}"></path></svg>')`,
    }
  }
}));

export const UIToggleWithLabel = styled(FormControlLabel)(() => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
    color: '#54606F',
    fontWeight: '500'
  }
}));