import React from 'react'

const UserIcon = () => {
    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', border: '0.5px solid #46515D', borderRadius: '12px', width: '24px', height: '24px', marginRight: '8px'
        }}>
            <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 16.2286C1 12.9148 3.68629 10.2286 7 10.2286C10.3137 10.2286 13 12.9148 13 16.2286M10.4286 4.22856C10.4286 6.12211 8.89355 7.65713 7 7.65713C5.10645 7.65713 3.57143 6.12211 3.57143 4.22856C3.57143 2.33501 5.10645 0.799988 7 0.799988C8.89355 0.799988 10.4286 2.33501 10.4286 4.22856Z" stroke="#46515D" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    )
}

export default UserIcon