import { editUser } from "@State/index";
import { getUser } from "@State/index";
import { FetchOnceAutocomplete } from "@View/common/form/control/FetchOnceAutocomplete";
import ButtonSave from "@View/components/buttons/ButtonSave";
import DefaultInput from "@View/components/formInputs/DefaultInput";
import DefaultSelect from "@View/components/formInputs/DefaultSelect";
import ModalTittle from "@View/components/ModalTittle";
import ModalWrapper from "@View/components/ModalWrapper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { searchClients } from "@Utils/commonAPI";
import AppAPI from "@API/index";

const EditUserPage = ({ userId, open, handleOpen, handleClose }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user.user);
  const [newData, setNewData] = useState({});

  useEffect(() => {
    dispatch(getUser(userId));
  }, []);

  useEffect(() => {
    if (userId === user.id) {
      setNewData({ ...user });
    }

    if (Object.keys(user) > 0) {
      if (user.role === "CustomerUser" || user.role === "CustomerManager") {
        getDefCustomerId();
      }

      if (user.role === "ClientUser" || user.role === "ClientManager") {
        getDefClientId();
      }
    }
  }, [user]);

  let deleteObjFunc = (roleV) => {
    if (roleV === "Admin") {
      setNewData((prevData) => {
        let newData = { ...prevData };
        delete newData.CustomerId;
        delete newData.ClientId;
        delete newData.clientName;
        delete newData.customerName;
        return newData;
      });
    } else if (roleV === "CustomerUser" || roleV === "CustomerManager") {
      setNewData((prevData) => {
        let newData = { ...prevData };
        delete newData.ClientId;
        delete newData.clientName;
        return newData;
      });
    } else if (roleV === "ClientUser" || roleV === "ClientManager") {
      setNewData((prevData) => {
        let newData = { ...prevData };
        delete newData.CustomerId;
        delete newData.customerName;
        return newData;
      });
    }
  };

  const handleChange = (value, label) => {
    setNewData({ ...newData, [label]: value });
  };

  const handleClientChange = (value) => {
    let obj = {
      ClientId: value.id,
      clientName: value.name,
    };
    setNewData({ ...newData, ...obj });
  };

  const handleCustomerChange = (value) => {
    let obj = {
      CustomerId: value.id,
      customerName: value.name,
    };
    setNewData({ ...newData, ...obj });
  };

  const handleClick = async (event) => {
    event.preventDefault();
    handleClose();
    await dispatch(editUser(newData, history));
  };

  const types = [
    { value: "Admin" },
    { value: "ClientUser" },
    { value: "ClientGuest" },
    { value: "ClientManager" },
    { value: "CustomerUser" },
    { value: "CustomerGuest" },
    { value: "CustomerManager" },
  ];

  const [clientError, setClientError] = useState(false);

  const fetchCustomerOptions = () =>
    AppAPI.request({ url: "/api/Customer/search" }).then((result) =>
      result.map((e) => ({ id: e.guid, name: e.name }))
    );

  let getDefClientId = async () => {
    let res = await searchClients();
    let idx = res.findIndex((el) => el.name === newData.clientName);
    if (!idx) return;
    let obj = {
      ClientId: res[idx].id,
    };
    setNewData({ ...newData, ...obj });
  };

  let getDefCustomerId = async () => {
    let res = await fetchCustomerOptions();
    let idx = await res.findIndex((el) => el.name === newData.customerName);
    let obj = {
      CustomerId: res[idx].id,
    };
    setNewData({ ...newData, ...obj });
  };

  useEffect(() => {
    if (typeof newData.clientName === "string") {
      getDefClientId();
    }

    if (typeof newData.customerName === "string") {
      getDefCustomerId();
    }
  }, [newData.clientName, newData.customerName]);

  return (
    <ModalWrapper
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
      btnTxt={"Save"}
    >
      <ModalTittle children2={""}>h1.Edit User</ModalTittle>
      <form onSubmit={handleClick}>
        <DefaultInput label="Id" value={newData.id} onChange={() => null} />
        <DefaultInput
          value={newData.fullName}
          label="FullName"
          onChange={(event) => handleChange(event, "fullName")}
        />
        <DefaultInput
          value={newData.userName}
          label="Login"
          onChange={(event) => handleChange(event, "userName")}
        />
        <DefaultInput
          value={newData.email}
          label="inputs.Email"
          onChange={(event) => handleChange(event, "email")}
        />
        <DefaultSelect
          value={`${newData.role}`}
          label="inputs.Role"
          style={{ width: "100%" }}
          dropdowns={types}
          onChange={(value) => {
            handleChange(value, "role");
            deleteObjFunc(value);
          }}
        />

        {newData.role === "CustomerUser" ||
        newData.role === "CustomerGuest" ||
        newData.role === "CustomerManager" ? (
          <FetchOnceAutocomplete
            value={
              newData.customerName === undefined
                ? ""
                : `${newData.customerName}`
            }
            id="customer"
            label={t("Customer")}
            onChange={handleCustomerChange}
            fetchOptions={fetchCustomerOptions}
            error={clientError}
          />
        ) : null}

        {newData.role === "ClientUser" ||
        newData.role === "ClientGuest" ||
        newData.role === "ClientManager" ? (
          <FetchOnceAutocomplete
            id="client"
            value={
              newData.clientName === undefined ? "" : `${newData.clientName}`
            }
            label={t("Client")}
            onChange={handleClientChange}
            fetchOptions={searchClients}
            error={clientError}
          />
        ) : (
          ""
        )}
        <ButtonSave>buttons.Save</ButtonSave>
      </form>
    </ModalWrapper>
  );
};

export default EditUserPage;
