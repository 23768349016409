import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { isAfter, isBefore, parse } from "date-fns";
import { useTranslation } from "react-i18next";
import { DATE_RECORD_TYPES, FREQUENCY_TYPES } from "@View/constants";
import {
  FirstCell,
  TextCell,
  NoCollapseGroupRows,
  NestedRow,
  DefaultTable,
  PaddingContainer,
  CollapseGroupRows,
} from "@Components/table";
import React, { useEffect, useState } from "react";
import { Frequency } from "@Constants/enums";
import pickColor from "@Utils/colorPicker";

export function CoeffAnalTable({
  data = [],
  startDate = new Date(-8640000000000000),
  endDate = new Date(8640000000000000),
  frequency = FREQUENCY_TYPES.month,
}) {
  const { t } = useTranslation();

  const transformedData = filter(data, startDate, endDate, frequency);

  const transformDate = () => {
    let date = [];
    if (Array.isArray(transformedData) && transformedData.length) {
      const datesArr = [
        ...(transformedData[0].items[0]?.values.map((el) => el.date) || []),
      ];
      for (let i = 0; i < datesArr.length; i++) {
        let date2 = datesArr[i].split("-");

        let month = date2[1];
        let year = date2[0];
        let day = date2[2].split("T")[0];
        date.push(`${day}.${month}.${year}`);
      }
    }
    return date;
  };

  let [date, setDate] = useState([]);
  useEffect(() => {
    setDate(transformDate());
  }, [frequency]);

  if (date.length === 0) return <></>;

  return (
    <PaddingContainer top>
      <DefaultTable>
        <TableHead
          style={{
            background: "#F3F5F7",
            height: "46px",
          }}
        >
          <TableRow>
            <TableCell
              style={{
                paddingLeft: "0em",
                fontWeight: 600,
                fontSize: "13px",
                lineHeight: "19px",
                color: "#1A1E24",
                textAlign: "left",
                border: "none",
              }}
            >
              {t(appendI18nRoot("Coefficient Analyse"))}
            </TableCell>
            {date.map((el, index) => (
              <TextCell component="th" key={index}>
                {el}
              </TextCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {transformedData.map((el) => (
            <>
              <CollapseGroupRows
                key={el.name}
                paddingLeft={"2em"}
                cellStyles={{
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "19px",
                  paddingLeft: "15px",
                }}
                noClickableCellText={el.name}
              ></CollapseGroupRows>
              {el.items.map((el2) => (
                <NestedRow key={el2.name}>
                  <FirstCell level={1}>{el2.name}</FirstCell>
                  {el2.values.map((el3, index) => (
                    <TextCell key={index} color={pickColor(el3.color)}>
                      {el3.value}
                    </TextCell>
                  ))}
                </NestedRow>
              ))}
            </>
          ))}
        </TableBody>
      </DefaultTable>
    </PaddingContainer>
  );
}

// type CoeffAnalTableProps = {
//     data?: CoefficientAnalyse,
//     startDate?: Date,
//     endDate?: Date,
//     frequency: FREQUENCY_TYPES

// }

// function filter(data: CoefficientAnalyse, startDate: Date, endDate: Date, frequency?: FREQUENCY_TYPES): CoefficientAnalyse {
//     return data.filter(el => {
//         const date = parse(el.date, 'dd.MM.yyyy', new Date())
//         return !isBefore(date, startDate) && !isAfter(date, endDate)
//     })
// }

function filter(data, startDate, endDate, frequency) {
  const allowesRecordTypes =
    frequency === Frequency.Quarter
      ? [
          DATE_RECORD_TYPES.ThreeMonths,
          DATE_RECORD_TYPES.SixMonths,
          DATE_RECORD_TYPES.NineMonths,
          DATE_RECORD_TYPES.Year,
        ]
      : frequency === Frequency.Month
      ? [DATE_RECORD_TYPES.Month]
      : [DATE_RECORD_TYPES.Year];

  return data.map((el) => ({
    ...el,
    items: el.items.map((el2) => ({
      ...el2,
      values: el2.values.filter((el) => {
        const date = parse(el.date, "dd.MM.yyyy", new Date());
        const recordType = el.recordType;
        return (
          !isBefore(date, startDate) &&
          !isAfter(date, endDate) &&
          allowesRecordTypes.includes(recordType)
        );
      }),
    })),
  }));
}

function appendI18nRoot(path) {
  return `finDataRequest.tabs.FinancialStatements.tables.CoefficientAnalyse.${path}`;
}
