const AppActionsTypes = {
  START_LOADING: 'app/START_LOADING',
  END_LOADING: 'app/END_LOADING',
  SET_LOADING: 'app/SET_LOADING',
  SET_ERROR: 'app/SET_ERROR',
  SET_SUCCESS: 'app/SET_SUCCESS',
}

export const AppActions = {
  startLoading: (key = '') => ({
    type: AppActionsTypes.START_LOADING,
    payload: key
  }),
  endLoading: (key = '') => ({
    type: AppActionsTypes.END_LOADING,
    payload: key
  }),
  setLoading: (loadingStatus) => ({
    type: AppActionsTypes.SET_LOADING,
    payload: loadingStatus
  }),
  setError: (errorText) => ({
    type: AppActionsTypes.SET_ERROR,
    payload: errorText
  }),
  setSuccess: (errorText) => ({
    type: AppActionsTypes.SET_SUCCESS,
    payload: errorText
  }),
}

const initialState = {
  loading: false,
  error: '',
  success: ''
};

export const selectAppState = (state) => state.app;

const LoaderStack = new Set();

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AppActionsTypes.START_LOADING:
      if (action.payload) {
        LoaderStack.add(action.payload);
      }
      return {
        ...state,
        loading: true
      };
    case AppActionsTypes.END_LOADING:
      if (action.payload) {
        LoaderStack.delete(action.payload);
      }
      return {
        ...state,
        loading: !![...LoaderStack.keys()].length
      };
    case AppActionsTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case AppActionsTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload,
        success: ''
      };
    case AppActionsTypes.SET_SUCCESS:
      return {
        ...state,
        error: '',
        success: action.payload
      };
    default:
      return state;
  }
};

export default reducer;