import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const wrapper = {
  background: "#FFFFFF",
  borderRadius: "10px",
  position: "relative",
  padding: "0 20px 20px 0",
};

const CustomizedLegend = (props) => {
  const { t } = useTranslation();
  const { payload, dataType } = props;

  const incomePayload = payload.filter((e) => e.value.indexOf("income") > 0);
  const outgoingPayload = payload.filter(
    (e) => e.value.indexOf("outgoing") > 0
  );

  const descriptionItemsFontSize = dataType === "counterpartiesTable" ? 13 : 15;

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row",
        justifyContent: "space-evenly",
        gap: "15px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          gap: "15px",
        }}
      >
        <div style={{ color: "#606a74", fontWeight: 600, fontSize: 18 }}>
          {t("Charts.TwoBarChart.incomePayments")}
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "5px 15px",
          }}
        >
          {incomePayload.map((entry, index) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div
                style={{
                  minWidth: "8px",
                  minHeight: "8px",
                  borderRadius: "50%",
                  background: entry.color,
                }}
              ></div>
              <div
                style={{
                  color: entry.color,
                  fontSize: descriptionItemsFontSize,
                }}
                key={`item-${index}`}
              >
                {entry.value.split("-")[0]}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          gap: "15px",
        }}
      >
        <div style={{ color: "#606a74", fontWeight: 600, fontSize: 18 }}>
          {t("Charts.TwoBarChart.outgoingPayments")}
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "15px",
          }}
        >
          {outgoingPayload.map((entry, index) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div
                style={{
                  minWidth: "8px",
                  minHeight: "8px",
                  borderRadius: "50%",
                  background: entry.color,
                }}
              ></div>
              <div
                style={{
                  color: entry.color,
                  fontSize: descriptionItemsFontSize,
                }}
                key={`item-${index}`}
              >
                {entry.value.split("-")[0]}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    const keys = Object.keys(payload[0].payload);
    const incomeKeys = keys.filter((e) => e.indexOf("income") > 0);
    const outgoingKeys = keys.filter((e) => e.indexOf("outgoing") > 0);

    return (
      <div className="custom-tooltip">
        <span>{t("Charts.TwoBarChart.Income")}</span>
        {incomeKeys.map((e) => {
          let str = e.split("-");
          return (
            <p>
              {str[0]} : {payload[0].payload[e]}
            </p>
          );
        })}
        <span style={{ marginTop: "15px" }}>
          {t("Charts.TwoBarChart.Outgoing")}
        </span>
        {outgoingKeys.map((e) => {
          let str = e.split("-");
          return (
            <p>
              {str[0]} : {payload[0].payload[e]}
            </p>
          );
        })}
        <p
          style={{ textAlign: "center", marginTop: "5px", color: "#616161" }}
          className="label"
        >{`${label}`}</p>
      </div>
    );
  }

  return null;
};

const TwoBarChart = ({
  data2,
  colors,
  Tittle,
  wrapperWidth = "300px",
  dataType,
}) => {
  const { i18n } = useTranslation();

  if (data2.length === 0) return null;

  const keys2 = Object.keys(data2[0]).filter((f) => f !== "date");

  return (
    <div style={wrapper}>
      <div
        style={{
          position: "absolute",
          bottom: dataType === "affiliatedEntitiesTable" ? 22 : 42,
          left: String(data2[0][keys2[0]]).split(".")[0].length > 3 ? 65 : 20,
          fontWeight: 500,
          fontSize: "12px",
          lineHeight: "15px",
          letterSpacing: "0.0075em",
          color: "#A1ADBA",
        }}
      >
        {Tittle}
      </div>
      <ResponsiveContainer>
        <BarChart
          width={
            Number.isFinite(wrapperWidth) && wrapperWidth < 585
              ? 585
              : wrapperWidth
          }
          height={580}
          data={data2}
          margin={{
            top: 100,
            right: 20,
            left: 20,
            bottom: 0,
          }}
        >
          <XAxis dataKey="date" tickLine={false} />
          <YAxis
            axisLine={true}
            tickLine={true}
            width={
              String(data2[0][keys2[0]]).split(".")[0].length > 3 ? 100 : 55
            }
            tickFormatter={(number) => {
              if (i18n.language === "en")
                return new Intl.NumberFormat().format(number);
              else
                return new Intl.NumberFormat("ru", { style: "decimal" }).format(
                  number
                );
            }}
          />
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <Legend
            verticalAlign="top"
            content={<CustomizedLegend dataType={dataType} />}
            wrapperStyle={{
              top: 22,
            }}
          />
          <Tooltip
            formatter={(number) => {
              if (i18n.language === "en")
                return new Intl.NumberFormat().format(number);
              else
                return new Intl.NumberFormat("ru", { style: "decimal" }).format(
                  number
                );
            }}
            content={<CustomTooltip />}
          />
          {keys2.map((item, i) => {
            const stack = item.split("-").pop();
            return (
              <Bar
                barSize={30}
                dataKey={item}
                stackId={stack}
                fill={colors[i]}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TwoBarChart;
