import Slide from "@material-ui/core/Slide";
import { SET_SNACKBAR } from "@State/actions";
import { SET_CHECKBOX_DATA } from "@State/actions/actionTypes";

const initialState = {
  open: false,
  Transition: Slide,
  message: "",
  description: "",
  checkboxes: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SNACKBAR:
      return {
        ...state,
        ...action.payload,
      };
    case SET_CHECKBOX_DATA:
      return {
        ...state,
        checkboxes: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
