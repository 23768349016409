import { Customer } from "@Dao/index";
import {
  CustomerFailure,
  CustomerRequest,
  CustomerSuccess,
} from "@State/actions/actionCreators";
import { Dispatch } from "redux";
import AppAPI from "@API/index";

export function loadCustomer(customerId: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(CustomerRequest());
    try {
      const response: Customer = await AppAPI.request({
        url: `/api/Customer/${customerId}`,
      });
      dispatch(CustomerSuccess(response));
    } catch (e) {
      console.error("[loadCustomer]", e);
      dispatch(CustomerFailure());
    }
  };
}
