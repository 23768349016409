import ButtonSave from "@View/components/buttons/ButtonSave";
import DefaultInput from "@View/components/formInputs/DefaultInput";
import ModalTittle from "@View/components/ModalTittle";
import ModalWrapper from "@View/components/ModalWrapper";
import React from "react";
import AppAPI from "@API/index";
import { useTranslation } from "react-i18next";

const RequestDetailsLogPassModal = ({
  openSystem,
  handleSystemOpen,
  handleSystemClose,
  system,
  requestId,
}) => {
  const { t } = useTranslation();

  const [newData, setNewData] = React.useState({
    login: "",
    password: "",
  });

  const handleChange = (value, label) => {
    setNewData({ ...newData, [label]: value });
  };

  const onSubmitFunc = async (e) => {
    e.preventDefault();
    let res = await AppAPI.request({
      url: `/api/FinDataRequest/${requestId}/getFileFromAccountingSystem?redirectUrl=https://${window.location.hostname}/findatarequest/${requestId}/data&accountingSystem=${system}`,
      method: "POST",
      data: newData,
    });
    if (res) {
      window.open(res, "_blank");
    }
    handleSystemClose(true);
  };
  return (
    <ModalWrapper
      maxWidth={400}
      open={openSystem}
      handleOpen={handleSystemOpen}
      handleClose={() => handleSystemClose()}
    >
      <ModalTittle children2={""}>{t(`Request Details.${system}`)}</ModalTittle>
      <form onSubmit={onSubmitFunc}>
        <DefaultInput
          label={t(`Request Details.login`)}
          value={newData.login}
          onChange={(value) => handleChange(value, "login")}
        />
        <DefaultInput
          label={t(`Request Details.password`)}
          value={newData.password}
          onChange={(value) => handleChange(value, "password")}
        />
        <ButtonSave marginTop={"10px"}>buttons.Save</ButtonSave>
      </form>
    </ModalWrapper>
  );
};

export default RequestDetailsLogPassModal;
