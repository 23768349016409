import React from 'react'
import { useSelector } from 'react-redux'
import styles from './Loader.module.css'

const Loader = () => {
  const isVisible = useSelector(state => state.app.loading)
   
  return (
    <div style={{display: isVisible ? 'flex' : 'none'}} className={styles.backdrop}>
      <span className={styles.blur} ></span>
      <span className={styles.loader} ></span>
    </div>
  )
}

export default Loader