import {
  GET_FINDATA_FAILURE,
  GET_FINDATA_REQUEST,
  GET_FINDATA_SUCCESS,
  REQUEST_CLOSE_DETAILS,
  REQUEST_OPEN_DETAILS,
} from "../actionTypes";
import AppAPI from "@API/index";


//fetch one finDataRequest
const getFinDataRequestRequest = () => {
  return {
    type: GET_FINDATA_REQUEST,
  };
};

const getFinDataRequestSuccess = (finDataRequest) => {
  return {
    type: GET_FINDATA_SUCCESS,
    payload: finDataRequest,
  };
};

export const getFinDataRequest = (id) => {
  return (dispatch) => {
    dispatch(getFinDataRequestRequest());

    return AppAPI.request({
      method: "get",
      url: `/api/FinDataRequest/${id}`,
    })
      .then((data) => {
        const finDataRequest = {
          id: data.id,
          requestType: data.requestType,
          startDate: data.startDate.split("T")[0],
          endDate: data.endDate.split("T")[0],
          reportType: data.reportType,
          transferType: data.transferType,
          status: data.status,
          clientId: data.clientId,
          customerId: data.customerId,
          finDataFile: data.finDataFile,
          clientName: data.clientName,
          comment: data.comment ?? "",
          reportTemplateId: data?.reportTemplate?.id,
          clientExternalId: data.clientExternalId,
        };

        dispatch(getFinDataRequestSuccess(finDataRequest));
        return finDataRequest;
      })
      .catch((error) => {
        // do nothing
      });
  };
};

export const requestOpenDetailsAction = (payload) => {
  return {
    type: REQUEST_OPEN_DETAILS,
    payload: payload,
  };
};

export const requestCloseDetailsAction = (payload) => {
  return {
    type: REQUEST_CLOSE_DETAILS,
    payload: payload,
  };
};