import React, {useEffect} from "react";
import debounce from "lodash/debounce";

export function useWidthResize(diff = 0, delay = 500) {
  const [width, setWidth] = React.useState(window.innerWidth - diff)

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setWidth(window.innerWidth - diff)
    }, delay);
    debouncedHandleResize();

    window.addEventListener('resize', debouncedHandleResize)
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, []);

  return [width, setWidth];
}