import { makeStyles } from "@material-ui/core";

export const useButtonStyles = makeStyles((_theme) => ({
  addBtn: {
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "47px",
    borderRadius: "8px",
    fontSize: "15px",
    fontWeight: "600",
    textAlign: "center",
    border: "none",
    color: "white",
    padding: "5px",
    background: "#6FA4F2",
    cursor: "pointer",
    minWidth: "270px",
    margin: "0 auto",
    "&:disabled": {
      background: "#F3F5F7",
      color: "#54606F",
      cursor: "default",
    },
  },
}));
