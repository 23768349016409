import { Button } from "@material-ui/core";
import style from "./Buttons.module.css";
import React from "react";

const ButtonBB = (props) => {
  const dynamicProps = {};
  if (props.startIcon) {
    dynamicProps.startIcon = props.startIcon;
  }

  if (props.uploadFile) {
    return (
      <Button
        component="label"
        style={{ ...props.style }}
        disabled={props.disabled}
        onClick={props.onClick}
        className={style.ButtonBB}
        {...dynamicProps}
      >
        {props.children}
        <input
          type="file"
          hidden
          name="download"
          onChange={(e) => {
            if (e.target.files.length) {
              props.onChange(e.target.files);
            }
          }}
          accept={props.accept}
        />
      </Button>
    );
  }

  if (props.url) {
    return (
      <Button
        component="a"
        href={props.path || props.url || "#"}
        style={{ ...props.style }}
        disabled={props.disabled}
        onClick={props.onClick}
        className={style.ButtonBB}
        {...dynamicProps}
      >
        {props.children}
      </Button>
    );
  }

  return (
    <Button
      style={{ ...props.style }}
      disabled={props.disabled}
      onClick={props.onClick}
      className={style.ButtonBB}
      {...dynamicProps}
    >
      {props.children}
    </Button>
  );
};

export default ButtonBB;
