import { Client } from "@Dao/index";
import { ClientFailure, ClientRequest, ClientSuccess } from "@State/actions/actionCreators";
import { Dispatch } from "redux";
import AppAPI from "@API/index";

export function loadClient(clientId: string) {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(ClientRequest())
        try {
            const response: Client = await AppAPI.request<Client>({ url: `/api/Client/${clientId}` })
            dispatch(ClientSuccess(response))
        } catch (e) {
            dispatch(ClientFailure())
        }
    }
}