import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import PageTitle from "@View/components/PageTitle";
import { useTranslation } from "react-i18next";
import ButtonSave from "@View/components/buttons/ButtonSave";
import { connect } from "react-redux";
import { postNewCode } from "@State/index";
import PropTypes from "prop-types";
import FormDefaultRow from "@View/components/formRows/FormDefaultRow";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useHistory } from "react-router-dom";
import { useStyles } from "@View/style/pages/detailsStyle";
import FormNumRow from "@View/components/formRows/FormNumRow";

const T_ROOT = "newCode";

//redux
const mapDispatchToProps = (dispatch) => {
  return {
    postNewCode: (newData, history) =>
      dispatch(postNewCode(newData, history)),
  };
};

const NewCodePage = ({ postNewCode }) => {
  const { t } = useTranslation();
  const translate = (prop, useRoot = true) =>
    t(useRoot ? `${T_ROOT}.${prop}` : prop);
  const classes = useStyles();
  let history = useHistory();
  const [newData, setNewData] = useState({
    code: "",
    requestAmount: ""
  });

  const handleChange = (value, label) => {
    setNewData({ ...newData, [label]: value });
  };

  const handleClick = (event) => {
    event.preventDefault();
    postNewCode(newData, history);
  };
  return (
    <React.Fragment>
      <PageTitle>{translate("NewCode")}</PageTitle>
      <ValidatorForm
        onSubmit={(event) => handleClick(event)}
        instantValidate={false}
      >
        <Grid container>
           <Grid className={classes.columns}>
            <Grid container className={classes.column}>
              <FormDefaultRow
                  value={newData.code}
                  label={translate("Code")}
                  onChange={(value) => handleChange(value, "code")}
              />
              <FormNumRow
                value={newData.requestAmount}
                label={translate("RequestAmount")}
                onChange={(value) => handleChange(value, "requestAmount")}
              />
            </Grid>
          </Grid> 
        </Grid>
        <ButtonSave />
      </ValidatorForm>
    </React.Fragment>
  );
};

NewCodePage.propTypes = {
  postNewCode: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(NewCodePage);
