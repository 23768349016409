import { TableCell } from "@material-ui/core";

export function FirstCell({
  level = 0,
  colSpan,
  component,
  children,
  color,
  paddingLeft,
  minWidth = "",
}: FirstCellProps): JSX.Element {
  return (
    <TableCell
      component={component}
      style={{
        paddingLeft: level ? `calc(${level}em + 6px)` : paddingLeft || "0px",
        height: "50px",
        color: color,
        ...(minWidth ? { minWidth } : {}),
      }}
      colSpan={colSpan}
      className="FirstCell"
    >
      {children}
    </TableCell>
  );
}

type FirstCellProps = {
  level?: number;
  colSpan?: number;
  component?: "td" | "th";
  children?: string | JSX.Element;
  color?: any;
  paddingLeft?: any;
  minWidth?: string;
};
