import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Cell,
  Legend,
  Pie,
  PieChart as BasePieChart,
  Tooltip,
  Sector,
} from "recharts";
import { transformDataForChartInterface } from "../../../../utils/charts";
import Title from "../Title";
import CustomTooltip from "../Tooltip";
import { useTranslation } from "react-i18next";
import { viewBigNumber } from "@Utils/format";
import { NoDataAdaptive } from "@View/style/components/NoData";

const renderActiveShape = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
}) => {
  return (
    <Sector
      cx={cx}
      cy={cy}
      isAnimationActive={true}
      innerRadius={innerRadius}
      outerRadius={outerRadius + 5}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
  );
};

const PieChart = ({
  data,
  dataProps,
  title,
  chartStyle,
  useTooltip,
  currency,
}) => {
  const transformedData = transformDataForChartInterface(data, dataProps);
  const { t, i18n } = useTranslation();

  const CustomizedLegend = (props) => {
    const { payload } = props;

    return (
      <div style={{ display: "flex", flexWrap: "wrap", flexFlow: "column" }}>
        {payload.map((entry, index) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <div
              style={{
                background: entry.color,
                minWidth: "12px",
                height: "12px",
                borderRadius: "50%",
                display: "flex",
                marginRight: "15px",
              }}
            ></div>

            <div
              style={{
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "17px",
                letterSpacing: "0.0075em",
              }}
            >
              {entry.value}
            </div>
            <span>&nbsp;-&nbsp;</span>
            <div style={{ color: "#46515D", fontSize: "15px" }}>
              {currency
                ? viewBigNumber(
                    entry.payload.value || entry.payload.debtStructure
                  )
                : entry.payload.value || entry.payload.debtStructure}{" "}
              &nbsp;
              {currency || ""}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const [activeSector, setActiveSector] = useState(null);
  const pieHoverEffects = useTooltip
    ? {
        activeShape: renderActiveShape,
        activeIndex: activeSector,
        onMouseEnter: (_, index) => setActiveSector(index),
        onMouseLeave: () => setActiveSector(null),
      }
    : {};

  // if (transformedData.every((item) => !item[dataProps.keys[0]])) {
  //   return <NoDataAdaptive />;
  // }

  return (
    <div
      style={{
        background: "#fff",
        width: "100%",
        display: "flex",
        flexFlow: "column",
        borderRadius: "10px",
      }}
    >
      <Title value={title} />
      <BasePieChart
        width={(window.innerWidth - 200) / 3}
        height={chartStyle.height}
      >
        <Pie
          data={transformedData}
          dataKey={dataProps.keys[0]}
          innerRadius={60}
          outerRadius={80}
          nameKey={dataProps.name}
          cx="50%"
          cy="50%"
          blendStroke
          {...pieHoverEffects}
        >
          {transformedData.map((_entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                chartStyle.colors.pies[index % chartStyle.colors.pies.length]
              }
            />
          ))}
        </Pie>
        <Legend
          layout="vertical"
          verticalAlign="middle"
          align="right"
          iconType="circle"
          fontStyle="#354659"
          wrapperStyle={{
            top: 260,
            left: 23,
          }}
          // formatter={(value) => (
          //   <>
          //     <span style={{ fontWeight: 400, fontSize: '16px', lineHeight: '14px', color: '#46515d' }}>{value}</span><span> - </span>
          //     <span style={{ color: '#8BA0B6' }}>{data.debtStructure[value]} {currency}</span>
          //   </>

          // )}

          content={<CustomizedLegend />}
        />
        {useTooltip && (
          <Tooltip
            formatter={(number) => {
              if (i18n.language === "en")
                return new Intl.NumberFormat().format(number);
              else
                return new Intl.NumberFormat("ru", { style: "decimal" }).format(
                  number
                );
            }}
            content={
              <CustomTooltip formatLabel={({ payload }) => payload[0].name} />
            }
          />
        )}
      </BasePieChart>
      <div style={{ borderTop: "1px solid #E1E6F1", height: "134px" }}></div>
    </div>
  );
};

PieChart.propTypes = {
  data: PropTypes.object,
  dataProps: PropTypes.object,
  title: PropTypes.string,
  chartStyle: PropTypes.object,
  useTooltip: PropTypes.bool,
};

export default PieChart;
