import { getAllCustomers } from "../actionsGet/actionGetAllCustomers";
import AppAPI from "@API/index";

export const postNewCustomer = (body, history) => {
  const newBody = {
    externalId: body.externalId.trim(),
    type: body.type,
    name: body.name.trim(),
    email: body.email.trim(),
    subscriptionUntil: body.subscriptionUntil,
    ServerUrl: body.ServerUrl,
  };
  return (dispatch) => {
    AppAPI.request({
      method: "post",
      url: `/api/Customer`,
      data: newBody,
    })
      .then((res) => {
          if(res) {
            dispatch(getAllCustomers())
          }
        })
      .catch(() => {
        // do nothing
      });
  };
};
