import { getLanguage } from "@Localization/utils";

export const numberWithSpaces = (x) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const numberUsFormat = (x) =>
  x < 0
    ? `(${Math.abs(x)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`
    : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const viewBigNumber = (number, lang = "ru") => {
  // if (lang === "en")
  //   return new Intl.NumberFormat().format(number);
  // else
  //   return new Intl.NumberFormat("ru", { style: "decimal" }).format(
  //     number
  //   );
  const num = Math.abs(+number);
  if (num > 999999) {
    return Math.round(Math.abs(num) / 1000000) + (lang === "ru" ? "М" : "M");
  } else if (num > 999) {
    return Math.round(Math.abs(num) / 1000) + (lang === "ru" ? "К" : "K");
  }
  return num;
};

export const customNumberFormat = (number, lang = null) => {
  if ((lang || getLanguage()) === "en")
    return new Intl.NumberFormat().format(number);
  else
    return new Intl.NumberFormat("ru", {
      style: "decimal",
    }).format(number);
};
