import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { t } from "i18next";
import React from "react";

export const SelectIconComponent = () => {
  return (
    <div style={{ position: "relative", right: "18px" }}>
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.710051 2.21L3.30005 4.8C3.69005 5.19 4.32005 5.19 4.71005 4.8L7.30005 2.21C7.93005 1.58 7.48005 0.5 6.59005 0.5H1.41005C0.520051 0.5 0.0800515 1.58 0.710051 2.21Z"
          fill="#54606F"
        />
      </svg>
    </div>
  );
};

const DefaultSelect = ({
  label = "",
  value,
  onChange,
  dropdowns,
  disabled = false,
  noMargin = false,
}) => {
  const classList = ["DefaultSelect"];
  if (noMargin) {
    classList.push("noMargin");
  }

  return (
    <FormControl className={classList.join(" ")} variant="outlined">
      {label ? <InputLabel>{t(label)}</InputLabel> : null}
      <Select
        value={value}
        onChange={(event) => onChange(event.target.value)}
        IconComponent={SelectIconComponent}
        disabled={disabled}
      >
        {dropdowns.map((dropdown, index) => {
          return (
            <MenuItem value={dropdown.value} key={index}>
              {t(dropdown.name || dropdown.value)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default DefaultSelect;
