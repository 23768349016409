import { AppBar, Grid, Toolbar } from "@material-ui/core";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DropdownMenu } from "./DropdownMenu";
import { getRedirectUrl } from "@View/pages/AdminPO/SigninPage";
import { useSelector } from "react-redux";
import Logo from "../svgComponents/Logo";
import { SET_HEADER_LEFT_COMPONENT } from "@State/actions/actionTypes";
import BackArrow from "../svgComponents/BackArrow";
import { TabController } from "@Utils/useAppTab";
import { getHeaderLogoImg } from "@Security/stateHelpers";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#5195E6",
    color: "#fff",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

export const getHeaderLeftComponent = (payload) => {
  return {
    type: SET_HEADER_LEFT_COMPONENT,
    payload: payload,
  };
};

const HeaderMain = () => {
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();

  const LeftComponent = useSelector(
    (state) => state.headerReducer.leftComponent
  );

  const redirectToDefaultPage = () => {
    history.push(getRedirectUrl());
  };

  const handleClick = () => {
    TabController.isGoBack = true;
    history.goBack();
  };

  useEffect(() => {
    if (TabController.isGoBack) {
      TabController.isGoBack = false;
      if (typeof TabController.changeTab === "function") {
        const pathSearchParams = new URLSearchParams(search);
        const isTab = pathSearchParams.get(TabController.param) || "";
        TabController.changeTab({}, isTab, true);
      }
    }
  }, [search]);

  const customHeaderLogo = getHeaderLogoImg();

  return (
    <>
      <AppBar position="absolute" color="inherit" style={{ boxShadow: "none" }}>
        <Toolbar
          className={classes.toolbar}
          style={{
            minWidth: "1100px",
            minHeight: "61px",
            paddingRight: "40px",
            paddingLeft: "40px",
            background: "#F3F5F7",
            boxShadow: "none",
          }}
        >
          <Grid container direction="row" alignItems="center">
            <Grid
              onClick={handleClick}
              style={{
                border: "1px solid #E1E6F1",
                background: "#E1E6F1",
                borderRadius: "6px",
                width: "34px",
                height: "34px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              item
            >
              <BackArrow />
            </Grid>

            <Grid
              style={{ cursor: "pointer" }}
              onClick={redirectToDefaultPage}
              item
              className={classes.title}
            >
              <Link to={getRedirectUrl()}>
                {!customHeaderLogo ? (
                  <Logo width="126" height="24" />
                ) : (
                  <img style={{ width: "126px" }} src={customHeaderLogo} />
                )}
              </Link>
            </Grid>

            {typeof LeftComponent === "function" ? <LeftComponent /> : null}

            <div style={{ flexGrow: 1 }}></div>
            <Grid item>
              <Grid
                container
                direction="row"
                justifycontent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <DropdownMenu />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default HeaderMain;
