import { FormControl, TextField, Typography } from "@material-ui/core"
import { ChangeEventHandler } from "react"

export function Text({ id, label, value, disabled, error, helperText, onChange }: TextProps) {
    return (
        <FormControl style={{ display: "flex", flexDirection: "row", alignItems: "center", width: '100%' }}>
            <label htmlFor={id} style={{ minWidth: "200px", maxWidth: "200px" }}>
                <Typography>{label}</Typography>
            </label>
            <TextField id={id} variant="outlined" fullWidth disabled={disabled} value={value} error={error} helperText={helperText} onChange={onChange} />
        </FormControl>
    )
}

type TextProps = {
    id: string,
    label: string,
    value?: string | null,
    disabled?: boolean,
    error?: boolean,
    helperText?: string,
    onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}