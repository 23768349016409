import DefaultInput from "@View/components/formInputs/DefaultInput";
import ModalTittle from "@View/components/ModalTittle";
import ModalWrapper from "@View/components/ModalWrapper";
import React from "react";
import { formatDate } from "@Localization/utils";
import ButtonBB from "@View/components/buttons/ButtonBB";
import { t } from "i18next";
import EditCustomerPage from "../EditCustomerPage";
import { DeleteModal } from "@View/components/DeleteModal";
import { deleteCustomer } from "@State/index";
import { useDispatch } from "react-redux";
import { getRole } from "@Security/stateHelpers";
import { useCTAButtonStyle } from "@View/style/components/tooltipStyles";

const CustomerDetails = ({ customer, open, handleOpen, handleClose }) => {
  const isWhiteLabel = process.env.REACT_APP_IS_WHITE_LABEL === "true";

  const [openEdit, setOpenEdit] = React.useState(false);
  const dispatch = useDispatch();

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const [deletedId, setDeletedId] = React.useState(undefined);

  const handleOpenModal = (event, id) => {
    setDeletedId(customer.id);
  };

  const handleCloseModal = (event) => {
    event.preventDefault();
    setDeletedId();
  };

  const handleDelete = (event) => {
    event.preventDefault();
    dispatch(deleteCustomer(deletedId));
    setDeletedId();
  };

  const btnStyles = useCTAButtonStyle();

  if (Object.keys(customer).length === 0) return <></>;
  return (
    <>
      <ModalWrapper
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
      >
        <ModalTittle children2={customer.name}>h1.Details of</ModalTittle>
        <form>
          <DefaultInput
            label="inputs.External Id"
            value={customer.externalId}
            onChange={() => null}
          />
          <DefaultInput
            value={customer?.status}
            label="inputs.Status"
            onChange={() => null}
          />
          <DefaultInput
            label="inputs.Create Date"
            value={formatDate(customer.createdAt)}
            onChange={() => null}
          />
          <DefaultInput
            label="inputs.Email"
            value={customer.email}
            onChange={() => null}
          />
          <DefaultInput
            label="inputs.Updated At"
            value={formatDate(customer.updatedAt)}
            onChange={() => null}
          />
          {!isWhiteLabel && (
            <DefaultInput
              label="Requests left"
              value={String(customer.requestsLeft)}
              onChange={() => null}
            />
          )}
          <DefaultInput
            label="Total requests"
            value={String(customer.totalRequests)}
            onChange={() => null}
          />
          <DefaultInput
            label="inputs.defaultLanguage"
            value={customer.defaultLanguage}
            onChange={() => null}
          />
          <DefaultInput
            label="inputs.Subscription Until"
            value={formatDate(customer.subscriptionUntil)}
            onChange={() => null}
          />
          <DefaultInput
            label="inputs.Server url"
            value={customer.serverUrl}
            onChange={() => null}
          />
          {getRole() === "Admin" ? (
            <ButtonBB
              onClick={handleOpenEdit}
              className={btnStyles.default}
              style={{
                width: "100%",
                marginBottom: "10px",
                borderRadius: "8px",
                padding: "15px 20px",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "17px",
              }}
            >
              {t("buttons.Edit")}
            </ButtonBB>
          ) : null}
          {getRole() === "Admin" ? (
            <ButtonBB
              onClick={handleOpenModal}
              style={{
                width: "100%",
                background: "#A4241C",
                borderRadius: "8px",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "17px",
                color: "#F3F5F7",
              }}
            >
              {t("buttons.Delete")}
            </ButtonBB>
          ) : null}
        </form>
      </ModalWrapper>
      <DeleteModal
        handleDelete={handleDelete}
        openModal={deletedId !== undefined}
        handleCloseModal={handleCloseModal}
      />
      {openEdit === true ? (
        <EditCustomerPage
          open={openEdit}
          handleOpen={handleOpenEdit}
          handleClose={handleCloseEdit}
          customerId={customer.id}
        />
      ) : null}
    </>
  );
};

export default CustomerDetails;
