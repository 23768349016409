import React from "react";
import DataTable from "@View/components/table/DataTable";
import { useTranslation } from "react-i18next";
import { formatDate } from "@Localization/utils";
import { useDispatch, useSelector } from "react-redux";
import { usePageRoot } from "@View/hooks/usePageRoot";
import DeleteIcon from "@View/components/svgComponents/DeleteIcon";
import EditIcon from "@View/components/svgComponents/EditIcon";
import { geAlltUsersRequest } from "@State/actions/actionsGet/actionGetUser";
import { DeleteModal } from "@View/components/DeleteModal";
import { deleteUser } from "@State/index";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonAddNew from "@View/components/buttons/ButtonAddNew";
import DetailsIcon from "@View/components/svgComponents/DetailsIcon";
import NewUserPage from "./user/NewUserPage";
import UserDetailsPage from "./user/UserDetailsPage";
import EditUserPage from "./user/EditUserPage";
import { useTooltipStyle } from "@View/style/components/tooltipStyles";

const ListOfUsersPage = () => {
  const tooltipStyle = useTooltipStyle();
  let dispatch = useDispatch();
  const rows = useSelector((state) => state.user.users.list);
  React.useEffect(() => {
    dispatch(geAlltUsersRequest());
  }, []);

  const { t } = useTranslation();
  const pageRoot = usePageRoot();
  const [deletedId, setDeletedId] = React.useState();

  const handleOpenModal = (event, usetId) => {
    event.preventDefault();
    setDeletedId(usetId);
  };

  const handleCloseModal = (event) => {
    event.preventDefault();
    setDeletedId();
  };

  const handleDelete = (event) => {
    event.preventDefault();
    dispatch(deleteUser(deletedId));
    setDeletedId();
  };

  const [userId, setUserId] = React.useState();

  const [openDetails, setOpenDetails] = React.useState(false);

  const handleOpenDetails = () => {
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  const [openNew, setOpenNew] = React.useState(false);

  const handleOpenNew = () => {
    setOpenNew(true);
  };

  const handleCloseNew = () => {
    setOpenNew(false);
  };

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const headCellsCustomers = [
    {
      field: "fullName",
      headerName: t("FullName"),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "userName",
      headerName: t("Login"),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "email",
      headerName: t("inputs.Email"),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "createdAt",
      headerName: t("inputs.Create Date"),
      flex: 0.7,
      headerAlign: "left",
      align: "left",
      valueGetter: (cell) => formatDate(cell.value),
    },
    {
      field: "status",
      headerName: t("inputs.Status"),
      flex: 0.7,
      headerAlign: "left",
      align: "left",
      valueGetter: (cell) => t(cell.row.status),
    },
    {
      field: "actions",
      headerName: " ",
      flex: 0.5,
      sortable: false,
      filterable: false,
      headerAlign: "center",
      renderCell: (cell) => {
        return (
          <div className="tableIcons">
            <Tooltip title={t("buttons.Details")} classes={tooltipStyle}>
              <div
                onClick={() => {
                  setUserId(cell.row.id);
                  handleOpenDetails();
                }}
                className={tooltipStyle.element}
              >
                <DetailsIcon />
              </div>
            </Tooltip>

            <Tooltip title={t("buttons.Edit")} classes={tooltipStyle}>
              <div
                onClick={() => {
                  setUserId(cell.row.id);
                  handleOpenEdit();
                }}
                className={tooltipStyle.element}
              >
                <EditIcon />
              </div>
            </Tooltip>

            <Tooltip title={t("buttons.Delete")} classes={tooltipStyle}>
              <div
                onClick={(event) => handleOpenModal(event, cell.row.id)}
                className={tooltipStyle.element}
              >
                <DeleteIcon />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div className="listOfUsers__page">
      {openNew ? (
        <NewUserPage
          prevPage={"user"}
          open={openNew}
          handleOpen={handleOpenNew}
          handleClose={handleCloseNew}
        />
      ) : null}

      <UserDetailsPage
        userId={userId}
        open={openDetails}
        handleOpen={handleOpenDetails}
        handleClose={handleCloseDetails}
      />

      {openEdit ? (
        <EditUserPage
          userId={userId}
          open={openEdit}
          handleOpen={handleOpenEdit}
          handleClose={handleCloseEdit}
        />
      ) : null}

      <DataTable
        rows={rows}
        columns={headCellsCustomers}
        toolbarButtons={<ButtonAddNew onClick={handleOpenNew} />}
      />
      <DeleteModal
        handleDelete={handleDelete}
        openModal={deletedId !== undefined}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

export default ListOfUsersPage;
