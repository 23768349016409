export enum ReportType {
  Monitoring,
  All,
}

export enum TransferType {
  Json,
  File,
}

export enum RequestStatus {
  New,
  ReceivedByClient,
  RejectedByClient,
  SentByClient,
  ReceivedByCustomer,
  RejectedByCustomer,
  AcceptedByCustomer,
}

export enum RequestTab {
  FinStatements,
  DebtBurdens,
  Sales,
  Counterparties,
  Analysis,
  Summary,
  Dq,
  Dashboard,
}

export enum Frequency {
  Month,
  Quarter,
  Year,
}

export enum TypeOfUserEntities {
  Legal,
  Individual,
}

export enum RecordType {
  Month,
  ThreeMonths,
  SixMonths,
  NineMonths,
  Year,
  FirstQuarter,
  SecondQuarter,
  ThirdQuarter,
  FourthQuarter,
}

export enum FnsStatus {
  CreatedByAccounter,
  CreatedFromBills,
  Sent,
  Accepted,
  Rejected,
}

export enum DynamincsOfPaymentsFilters {
  Currency,
  Counterparties,
  Banks,
  AffiliatedEntities,
  typesOperationsTable,
  numberOfTransactionsByTypesOperationsTable,
  numberOfTransactionsByCounterpartiesTable,
  transactionMonitoringTable,
  numberOfUniqueCounterpartiesTable,
}

export enum RegularInflowsAndOutflowsFilters {
  InflowQuantity,
  OutflowQuantity,
}

export enum ChangeInPaymentProfileFilters {
  PaymentPurpose,
  Currency,
}

export enum DashboardFilters {
  Currency,
  Counterparties,
  Banks,
  AffiliatedEntities,
  Amount,
  Type,
}

export enum AppLanguagesList {
  Ru = "ru",
  En = "en",
  Base = "ru",
}
