import AppAPI from "@API/index";

export const deleteCode = (id) => {
  return (dispatch) => {
    AppAPI.request({
      method: "delete",
      url: `/api/code/${id}`,
    }).then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2000)
      })
  };
};
