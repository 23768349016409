import { postNewCustomer } from "@State/index";
import ButtonSave from '@View/components/buttons/ButtonSave';
import DefaultInput from '@View/components/formInputs/DefaultInput';
import DefaultSelect from '@View/components/formInputs/DefaultSelect';
import FormDateRow from '@View/components/formRows/FormDateRow';
import ModalTittle from '@View/components/ModalTittle';
import ModalWrapper from '@View/components/ModalWrapper';
import React from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const NewCustomerPage = ({ open, handleOpen, handleClose }) => {
  
  const types = [{ value: "Bank" }, { value: "Company" }];
  let endDate = new Date()
  let lastDayOfMonthDate = new Date(endDate.getFullYear() + 1, endDate.getMonth() + 1, 0);
  const [newData, setNewData] = React.useState({
    name: '',
    externalId: '',
    ServerUrl: '',
    email: '',
    type: types[0].value,
    subscriptionUntil: lastDayOfMonthDate,
  });
  const dispatch = useDispatch()
  let history = useHistory();
  const { i18n } = useTranslation()
  

  const handleChange = (value, label) => {
    if(label === 'subscriptionUntil') {
      let endDate;
      if(i18n.language === 'ru') {
        let split = value.split('.')
        endDate = new Date(split[2], split[1] - 1, split[0] )
      } else {
        endDate = new Date(value)
      }
      
      value = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
    }
    setNewData({ ...newData, [label]: value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    handleClose()
    dispatch(postNewCustomer(newData, history));
  };

  return (
    <ModalWrapper open={open} handleOpen={handleOpen} handleClose={handleClose} btnTxt={'Save'}>
      <ModalTittle children2={''}>h1.New Customer</ModalTittle>
      <form onSubmit={onSubmit}>
        <DefaultInput
          value={newData.name}
          label="inputs.CompanyName"
          onChange={(value) => handleChange(value, "name")} />
        <DefaultInput
          value={newData.externalId}
          label="inputs.External Id"
          onChange={(value) => handleChange(value, "externalId")} />
        <DefaultInput
          value={newData.ServerUrl}
          label="inputs.Server url"
          onChange={(value) => handleChange(value, "ServerUrl")} />
        <DefaultInput
          value={newData.email}
          label="inputs.Email"
          onChange={(value) => handleChange(value, "email")} />
          <DefaultSelect
          value={newData.type}
          label="inputs.Type"
          onChange={(value) => handleChange(value, "type")}
          dropdowns={types}
         
        />

        <FormDateRow
          value={newData.subscriptionUntil}
          label="inputs.Subscription Until"
          onChange={(value) => {
            handleChange(value, "subscriptionUntil")
          }}
        />
        
        <ButtonSave>buttons.Save</ButtonSave>
      </form>
    </ModalWrapper>
  )
}
export default NewCustomerPage