import { Role } from "@Constants/types";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { Path } from "typescript";
import { getRole } from "@Security/stateHelpers";

type SecuredRouteProps = {
  children: JSX.Element;
  exact?: boolean;
  path?: Path | readonly Path[];
  roles?: Array<Role>;
  nonWhiteLabel?: boolean;
};

export function SecuredRoute({
  children,
  exact,
  path,
  roles,
  nonWhiteLabel,
}: SecuredRouteProps): JSX.Element {
  const isAuth = useSelector((state) => (state as any).auth) as boolean;
  const isWhiteLabel = process.env.REACT_APP_IS_WHITE_LABEL === "true";
  return (
    <Route exact={exact} path={path}>
      {isAuth &&
      (roles === undefined || roles.includes(getRole())) &&
      (nonWhiteLabel === undefined ||
        !isWhiteLabel ||
        (isWhiteLabel && !nonWhiteLabel)) ? (
        children
      ) : (
        <Redirect to="/" />
      )}
    </Route>
  );
}
