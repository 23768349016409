import React from "react";
import { styled } from "@mui/material/styles";
import { IconButton } from "@material-ui/core";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export const ColorButton = styled((props) => (
  <span
    style={{
      position: "relative",
      marginLeft: 10,
      // @ts-ignore
      ...(props.customStyle || {}),
    }}
  >
    <IconButton {...props}>
      <FiberManualRecordIcon />
    </IconButton>
  </span>
))(() => ({
  position: "absolute",
  left: 0,
  top: "-13px",
}));
