import { isAfter, isBefore } from "date-fns";
import { recordTypeToEnum } from "@Constants/functions";
import { Frequency, RecordType } from "@Constants/enums";

export const addSubRowsToRows = (arr) => {
  const trsArr = [];
  arr.forEach((e) => {
    trsArr.push(e);
    // e.subRows.forEach(e2 => {
    //   trsArr.push(e2)
    // })
  });

  return trsArr;
};

export const TransformData = (data) => {
  return {
    currenciesTable: [...addSubRowsToRows(data.currenciesTable.rows)],
    counterpartiesTable: [...addSubRowsToRows(data.counterpartiesTable.rows)],
    banksTable: [...addSubRowsToRows(data.banksTable.rows)],
    affiliatedEntitiesTable: [
      ...addSubRowsToRows(data.affiliatedEntitiesTable.rows),
    ],
    typesOperationsTable: [...addSubRowsToRows(data.typesOperationsTable.rows)],
    numberOfTransactionsByTypesOperationsTable: [
      ...addSubRowsToRows(data.numberOfTransactionsByTypesOperationsTable.rows),
    ],
    numberOfTransactionsByCounterpartiesTable: [
      ...addSubRowsToRows(data.numberOfTransactionsByCounterpartiesTable.rows),
    ],
    numberOfUniqueCounterpartiesTable: [
      ...addSubRowsToRows(data.numberOfUniqueCounterpartiesTable.rows),
    ],
  };
};

export function filterFinData(finData, startDate, endDate, recordTypes) {
  return finData.filter((el) => {
    const date = new Date(el.date);
    const recordType = el.recordType;
    return (
      !isBefore(date, startDate) &&
      !isAfter(date, endDate) &&
      recordTypes.includes(recordTypeToEnum(recordType))
    );
  });
}

export const filterDataByRange = (
  newArrData,
  frequency,
  startDate,
  endDate
) => {
  const copiedArray = JSON.parse(JSON.stringify(newArrData));

  if (copiedArray === undefined) return [];

  copiedArray.forEach((el) => {
    el.rowValues = filterFinData(
      el.rowValues,
      startDate,
      endDate,
      getAllowedRecordTypes(frequency)
    );
    el.subRows = el.subRows.map((i) => {
      i.rowValues = filterFinData(
        i.rowValues,
        startDate,
        endDate,
        getAllowedRecordTypes(frequency)
      );
      return i;
    });
  });

  return copiedArray;
};

export function getAllowedRecordTypes(frequency) {
  switch (frequency) {
    case Frequency.Quarter:
      return [
        RecordType.FirstQuarter,
        RecordType.SecondQuarter,
        RecordType.ThirdQuarter,
        RecordType.FourthQuarter,
      ];
    case Frequency.Month:
      return [RecordType.Month];
    case Frequency.Year:
      return [RecordType.Year];
    default:
      return [RecordType.Month];
  }
}

export const getFrequencyName = (frequency) => {
  return ["monthly", "quarterly", "yearly"][frequency] || "monthly";
};

/**
 * frequency numeric
 * dates list of dates
 * */
export const filterByDateForCurrenciesTable = (dataList, dates) => {
  return dataList.filter(({ date }) =>
    dates.find(({ columnName }) => columnName === date)
  );
};

export const filterByDateForCircleChart = (dataList, dates) => {
  return dataList.filter(({ column }) =>
    dates.find(({ columnName }) => columnName === column)
  );
};
