export const getMinAndMaxDates = (arr) => {
  const sortedDates = arr.sort((a, b) => a.getTime() - b.getTime());

  const startDate = new Date(Date.parse(sortedDates[0]))
  startDate.setDate(1)

  const endDate = new Date(Date.parse(sortedDates[sortedDates.length - 1]));
  endDate.setMonth(endDate.getMonth() + 1);
  endDate.setDate(0);

  return [startDate, endDate];
}

export function compareDatesForSorting(v1, v2) {
  const vp1 = Date.parse(v1);
  const vp2 = Date.parse(v2);
  if (vp1 > vp2) {
    return 1;
  } else if(vp1 < vp2) {
    return -1;
  }
  return 0;
}