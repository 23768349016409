import AppAPI from "@API/index";

export const postNewCode = (body, history) => {
  const newBody = {
    code: body.code.trim(),
    RequestAmount: body.RequestAmount,
  };

  return (dispatch) => {
    AppAPI.request({
      method: "post",
      url: `/api/code`,
      data: newBody,
    })
      .then(() => {
        history.goBack();
      })
  };
};
