import AppAPI from "@API/index";

export const postNewClient = (body, history) => {
  const newBody = {
    externalId: body.externalId.trim(),
    type: body.type,
    name: body.name.trim(),
    email: body.email.trim(),
    customerId: body.customerId,
    role: body.role,
    customerGroupId: body.customerGroupId,
  };

  return (dispatch) => {
    AppAPI.request({
      method: "post",
      url: `/api/Client`,
      data: newBody,
      successText: "snackbar.success",
    }).then(() => {
      window.location.reload();
    });
  };
};
