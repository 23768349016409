import { FormControl, MenuItem, Select, Typography } from "@material-ui/core";
import React from "react";

const IconEl = () => {
  return (
    <React.Fragment>
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.710051 2.21L3.30005 4.8C3.69005 5.19 4.32005 5.19 4.71005 4.8L7.30005 2.21C7.93005 1.58 7.48005 0.5 6.59005 0.5H1.41005C0.520051 0.5 0.0800515 1.58 0.710051 2.21Z"
          fill="#54606F"
        />
      </svg>
    </React.Fragment>
  );
};

export default function Selector({
  id,
  label,
  value,
  values,
  onChange,
  style = {},
}) {
  return (
    <FormControl className={"Selector"} style={style}>
      <label htmlFor={id}>
        <Typography>{label}</Typography>
      </label>
      <Select
        IconComponent={IconEl}
        id={id}
        value={value}
        variant="outlined"
        fullWidth
        onChange={(event, _) => onChange(event.target.value)}
      >
        {Object.keys(values).map((key) => (
          <MenuItem key={values[key]} value={values[key]}>
            {key}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
