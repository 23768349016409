import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import BarLineChart from "@View/components/charts/BarLineChart/BarLineChart";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import formatData from "./formatData";
import RevenueDynamicsChart2 from "./RevenueDynamicsChart2.js";

const RevenueDynamicsChart = ({ data, page }) => {
  const { t } = useTranslation();
  const filters = useSelector((state) => state.finDataRequest.filters[page]);
  const title = t("Charts.RevenueDynamics.title");
  const legendProps = {
    currencyAmount: t("Charts.RevenueDynamics.legend.currencyAmount"),
  };
  const revenueDynamics = formatData(data["revenueDynamics"], filters);
  return (
    <>
      <RevenueDynamicsChart2
        currency={data.currency.localName}
        data={{
          [legendProps.currencyAmount]: revenueDynamics,
        }}
        dataProps={{
          name: "name",
          keys: [legendProps.currencyAmount],
        }}
        title={title}
      />
      {/* <BarLineChart
        data={{
          [legendProps.currencyAmount]: revenueDynamics,
        }}
        dataProps={{
          name: "name",
          keys: [legendProps.currencyAmount],
        }}
        title={title}
        chartStyle={chartStyleConfig}
        chartContainerClass={classes.container}
        useTooltip
      /> */}
    </>
  );
};

RevenueDynamicsChart.propTypes = {
  data: PropTypes.object,
};

export default RevenueDynamicsChart;
