import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getClient } from "@State/index";
import ClientFullTable from "./ClientFullTable";
import ButtonBB from "@View/components/buttons/ButtonBB";
import Scoring from "@View/components/Scoring";
import ClientScoring from "./ClientScoring";
import { downloadFileWithNotification } from "@State/effects";
import { getHeaderLeftComponent } from "@View/components/headers/HeaderMain";
import UploadFileModal from "../../customer/customerDetails/UploadFileModal";
import ClientsDetails from "./ClientsDeatils";
import { changeAppLanguage } from "@Localization/utils";
import { getRole } from "@Security/stateHelpers";
import BankStatementsModal from "@View/pages/AdminPO/customer/customerDetails/BankStatementsModal";
import BankStatements1CModal from "@View/pages/AdminPO/customer/customerDetails/BankStatements1CModal";
import { downloadFile } from "@Dao/api";
import CreditReportModal from "@View/pages/AdminPO/customer/customerDetails/CreditReportModal";

const ClientDetailsPage = () => {
  const { id: clientId } = useParams();

  const location = useLocation();

  useEffect(() => {
    if (location.state?.creditScoreOpen) {
      handleOpenScoring();
    }
  }, []);

  const dispatch = useDispatch();
  const client = useSelector((state) => state.client.client);

  useEffect(() => {
    dispatch(getClient(clientId));
  }, [getClient, clientId]);

  const { t, i18n } = useTranslation();

  const [openDetails, setOpenDetails] = React.useState(false);

  const handleOpenDetails = () => {
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  const [openScoring, setOpenScoring] = React.useState(false);

  const handleOpenScoring = () => {
    setOpenScoring(true);
  };

  const handleCloseScoring = () => {
    setOpenScoring(false);
  };
  React.useEffect(() => {
    if (client?.defaultLanguage) {
      changeAppLanguage(client.defaultLanguage);
    }
  }, [client]);

  const LeftComponent = () => {
    return (
      <div
        onClick={() => handleOpenDetails()}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          marginLeft: "24px",
        }}
      >
        <div
          style={{
            marginRight: "15px",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "19px",
            color: "#1A1E24",
          }}
        >
          {client.name}
        </div>
        <div style={{ display: "flex" }}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.25 4.25H8.75V5.75H7.25V4.25ZM8 11.75C8.4125 11.75 8.75 11.4125 8.75 11V8C8.75 7.5875 8.4125 7.25 8 7.25C7.5875 7.25 7.25 7.5875 7.25 8V11C7.25 11.4125 7.5875 11.75 8 11.75ZM8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5ZM8 14C4.6925 14 2 11.3075 2 8C2 4.6925 4.6925 2 8 2C11.3075 2 14 4.6925 14 8C14 11.3075 11.3075 14 8 14Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    dispatch(getHeaderLeftComponent(LeftComponent));
  }, [client]);

  const handleClick = async (id) => {
    await downloadFileWithNotification(id);
  };

  const [judgmentLoading, setJudgmentLoading] = React.useState(false);
  const downloadJudgmentFile = async (client) => {
    setJudgmentLoading(true);
    //if (process.env.REACT_APP_CREDIT_RATING_AVERS === "true") {
    //  await downloadFile(`/api/Client/${client.id}/avers-estimate-report`);
    //} else {
      await downloadFile(`/api/Client/${client.id}/professional-judgment/file`);
    //}
    setJudgmentLoading(false);
  };

  const [openUpload, setOpenUpload] = React.useState(false);

  const handleOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
  };

  const [isBankStatements1COpen, setIsBankStatements1COpen] =
    React.useState(false);

  const openBankStatements1C = () => {
    setIsBankStatements1COpen(true);
  };
  const closeBankStatements1C = () => {
    setIsBankStatements1COpen(false);
  };

  const [isBankStatementsOpen, setIsBankStatementsOpen] = React.useState(false);
  const openBankStatements = () => {
    setIsBankStatementsOpen(true);
  };
  const closeBankStatements = () => {
    setIsBankStatementsOpen(false);
  };

  const [isCreditReportModalOpen, setIsCreditReportModalOpen] =
    React.useState(false);

  if (Object.keys(client).length === 0) return null;

  return (
    <div style={{ width: "100%" }} className="clientDetails__page">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          position: "absolute",
          right: i18n.language === "en" ? "350px" : "380px",
        }}
      ></div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          right: "40px",
          zIndex: 10,
        }}
      >
        <div style={{ flexGrow: 1 }}></div>
        <Scoring onClick={handleOpenScoring} />
        {getRole() === "Admin" ||
        getRole() === "CustomerUser" ||
        getRole() === "CustomerManager" ? (
          <ButtonBB
            style={{ marginRight: "10px" }}
            onClick={() => setIsCreditReportModalOpen(true)}
          >
            {t("CreaditOpinion.btnName")}
          </ButtonBB>
        ) : null}
        {/*<ButtonBB*/}
        {/*  style={{ marginRight: "10px" }}*/}
        {/*  onClick={() => handleClick(client.fileId)}*/}
        {/*>*/}
        {/*  {t("Download integration file")}*/}
        {/*</ButtonBB>*/}
        <ButtonBB
          style={{ marginRight: "10px" }}
          disabled={judgmentLoading}
          onClick={() => downloadJudgmentFile(client)}
        >
          {t("ProfessionalJudgment")}
        </ButtonBB>
        <ButtonBB onClick={() => handleOpenUpload()}>
          {t("buttons.Upload Financial Data")}
        </ButtonBB>
      </div>
      <ClientsDetails
        client={client}
        open={openDetails}
        handleOpen={handleOpenDetails}
        handleClose={handleCloseDetails}
      />
      {openScoring ? (
        <ClientScoring handleClose={handleCloseScoring} clientId={clientId} />
      ) : null}

      <ClientFullTable client={client} />
      <UploadFileModal
        open={openUpload}
        client={client}
        handleOpen={handleOpenUpload}
        handleClose={handleCloseUpload}
        openBankStatements={openBankStatements}
        openBankStatements1C={openBankStatements1C}
      />
      <CreditReportModal
        open={isCreditReportModalOpen}
        client={client}
        handleOpen={() => setIsCreditReportModalOpen(true)}
        handleClose={() => setIsCreditReportModalOpen(false)}
      />
      <BankStatements1CModal
        open={isBankStatements1COpen}
        handleClose={closeBankStatements1C}
        client={client}
      />
      <BankStatementsModal
        open={isBankStatementsOpen}
        handleClose={closeBankStatements}
        client={client}
      />
    </div>
  );
};

export default ClientDetailsPage;
