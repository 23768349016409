import {
  ThemeProvider as Provider,
} from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import { enUS, ruRU } from "@material-ui/core/locale";
import { useTranslation } from "react-i18next";

const ThemeProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const theme = createMuiTheme({}, i18n.language === "ru" ? ruRU : enUS);
  return (
    <Provider theme={theme}>
      {children}
    </Provider>
  );
};

export default ThemeProvider;
