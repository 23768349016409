import { isAfter, isBefore } from "date-fns";
import { useTranslation } from "react-i18next";
import { numberWithSpaces } from "@Utils/format";
import { HorizontalAnalyse, Legend, FinData } from "@Dao/responseTypes";
import { TFunction } from "i18next";
import { Frequency, RecordType } from "@Constants/enums";
import { recordTypeToEnum } from "@Constants/functions";
import { useEffect, useState } from "react";
import FinTable2 from "./FinTable2";

export function FinTable({
  finData,
  horizontalAnalyse,
  legend,
  startDate = new Date(-8640000000000000),
  endDate = new Date(8640000000000000),
  frequency,
  isHorizontalAnalyseEnable = false,
  isVerticalAnalyseEnable = false,
  currency,
  isEdit,
  setIsEdit,
}: FinTableProps) {
  const { t } = useTranslation();
  let [transformedData, setTransformedData] = useState(null);
  let trsDataFunc = (newData: any): any => {
    return TransformData(
      t,
      newData,
      horizontalAnalyse,
      legend,
      startDate,
      endDate,
      frequency,
      isHorizontalAnalyseEnable,
      isVerticalAnalyseEnable
    );
  };
  useEffect(() => {
    setTransformedData(null);
    setTransformedData(
      TransformData(
        t,
        finData,
        horizontalAnalyse,
        legend,
        startDate,
        endDate,
        frequency,
        isHorizontalAnalyseEnable,
        isVerticalAnalyseEnable
      )
    );
  }, [
    frequency,
    finData,
    isVerticalAnalyseEnable,
    isHorizontalAnalyseEnable,
    startDate,
    endDate,
  ]);

  if (transformedData === null) return <></>;
  return (
    <FinTable2
      transformedData={transformedData}
      originalData={trsDataFunc}
      isHorAnEn={isHorizontalAnalyseEnable}
      noTransformData={finData}
      isVerticalAnalyseEnable={isVerticalAnalyseEnable}
      frequency={frequency}
      isEdit={isEdit}
      setIsEdit={setIsEdit}
      startDate={startDate}
      endDate={endDate}
    />
  );
}

type FinTableProps = {
  finData: Array<FinData>;
  horizontalAnalyse: HorizontalAnalyse;
  legend: Legend;
  startDate: Date;
  endDate: Date;
  frequency: Frequency;
  isHorizontalAnalyseEnable: boolean;
  isVerticalAnalyseEnable: boolean;
  trsData: any;
  noTransformData: any;
  currency: any;
  setIsEdit: any;
  isEdit: any;
};

const TransformData = (
  t: TFunction,
  finData2: Array<FinData> | any,
  horAn: HorizontalAnalyse,
  legend: Legend,
  startDate: Date,
  endDate: Date,
  frequency: Frequency,
  isHorAnEn: any,
  isVerAnEn: boolean
): any => {
  const fA = formatAmmount;
  const hA = horizontalAnalyseParserBuilder(isHorAnEn);
  const vA = verticalAnalyseParserBuilder(isVerAnEn);

  const data = filterFinData(
    finData2,
    startDate,
    endDate,
    getAllowedRecordTypes(frequency)
  );

  return {
    header: [
      "руб.",
      ...data.map((el) => el.columnName),
      ...(isHorAnEn ? [t("HorizontalAnalyse")] : "⠀"),
    ],
    balanceSheet: {
      assets: {
        total: [
          legend.Assets,
          ...data.map((el) => fA(el.balanceSheet?.assets?.total)),
          ...hA(horAn.assets.total),
        ],
        nonCurrentAssets: {
          total: [
            legend.NonCurrentAssets,
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.nonCurrentAssets.total,
                el.balanceSheet.assets.nonCurrentAssetsVA
              )
            ),
            ...hA(horAn.assets.nonCurrentAssets.total),
          ],
          intangibleAssets: [
            legend.IntangibleAssets,
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.nonCurrentAssets.intangibleAssets,
                el.balanceSheet.assets.nonCurrentAssets.intangibleAssetsVA
              )
            ),
            ...hA(horAn.assets.nonCurrentAssets.intangibleAssets),
          ],
          randDResults: [
            legend.RandDResults,
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.nonCurrentAssets.randDResults,
                el.balanceSheet.assets.nonCurrentAssets.randDResultsVA
              )
            ),
            ...hA(horAn.assets.nonCurrentAssets.randDResults),
          ],
          intangibleSearchAssets: [
            legend.IntangibleSearchAssets,
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.nonCurrentAssets.intangibleSearchAssets,
                el.balanceSheet.assets.nonCurrentAssets.intangibleSearchAssetsVA
              )
            ),
            ...hA(horAn.assets.nonCurrentAssets.intangibleSearchAssets),
          ],
          tangibleSearchAssets: [
            legend.TangibleSearchAssets,
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.nonCurrentAssets.tangibleSearchAssets,
                el.balanceSheet.assets.nonCurrentAssets.tangibleSearchAssetsVA
              )
            ),
            ...hA(horAn.assets.nonCurrentAssets.tangibleSearchAssets),
          ],
          fixedAssets: [
            legend.FixedAssets,
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.nonCurrentAssets.fixedAssets,
                el.balanceSheet.assets.nonCurrentAssets.fixedAssetsVA
              )
            ),
            ...hA(horAn.assets.nonCurrentAssets.fixedAssets),
          ],
          profitableInvestments: [
            legend.ProfitableInvestments,
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.nonCurrentAssets.profitableInvestments,
                el.balanceSheet.assets.nonCurrentAssets.profitableInvestmentsVA
              )
            ),
            ...hA(horAn.assets.nonCurrentAssets.profitableInvestments),
          ],
          financialInvestments: [
            legend.FinancialInvestments,
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.nonCurrentAssets.financialInvestments,
                el.balanceSheet.assets.nonCurrentAssets.financialInvestmentsVA
              )
            ),
            ...hA(horAn.assets.nonCurrentAssets.financialInvestments),
          ], //todo нет первой колонки
          deferredTax: [
            legend.DeferredTax,
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.nonCurrentAssets.deferredTax,
                el.balanceSheet.assets.nonCurrentAssets.deferredTaxVA
              )
            ),
            ...hA(horAn.assets.nonCurrentAssets.deferredTax),
          ],
          other: [
            legend.AssetsOther,
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.nonCurrentAssets.other,
                el.balanceSheet.assets.nonCurrentAssets.otherVA
              )
            ),
            ...hA(horAn.assets.nonCurrentAssets.other),
          ], //todo нет первой колонки
        },
        currentAssets: {
          total: [
            legend.CurrentAssets,
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.currentAssets.total,
                el.balanceSheet.assets.currentAssetsVA
              )
            ),
            ...hA(horAn.assets.currentAssets.total),
          ],
          stocks: [
            legend.Stocks,
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.currentAssets.stocks,
                el.balanceSheet.assets.currentAssets.stocksVA
              )
            ),
            ...hA(horAn.assets.currentAssets.stocks),
          ],
          valueAddedTaxOnAcquiredValuables: [
            legend.ValueAddedTaxOnAcquiredValuables,
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.currentAssets
                  .valueAddedTaxOnAcquiredValuables,
                el.balanceSheet.assets.currentAssets
                  .valueAddedTaxOnAcquiredValuablesVA
              )
            ),
            ...hA(horAn.assets.currentAssets.valueAddedTaxOnAcquiredValuables),
          ],
          receivables: [
            legend.Receivables,
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.currentAssets.receivables,
                el.balanceSheet.assets.currentAssets.receivablesVA
              )
            ),
            ...hA(horAn.assets.currentAssets.receivables),
          ],
          currentAssetFinancialInvestments: [
            legend.CurrentAssetFinancialInvestments,
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.currentAssets
                  .currentAssetFinancialInvestments,
                el.balanceSheet.assets.currentAssets
                  .currentAssetFinancialInvestmentsVA
              )
            ),
            ...hA(horAn.assets.currentAssets.currentAssetFinancialInvestments),
          ],
          cashEquivalents: [
            legend.CashEquivalents,
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.currentAssets.cashEquivalents,
                el.balanceSheet.assets.currentAssets.cashEquivalentsVA
              )
            ),
            ...hA(horAn.assets.currentAssets.cashEquivalents),
          ],
          currentAssetOther: [
            legend.CurrentAssetOther,
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.currentAssets.currentAssetOther,
                el.balanceSheet.assets.currentAssets.currentAssetOtherVA
              )
            ),
            ...hA(horAn.assets.currentAssets.currentAssetOther),
          ],
          s1231: [
            "1231",
            ...data.map((el) =>
              vA(
                el.balanceSheet.assets.currentAssets.s1231,
                el.balanceSheet.assets.currentAssets.s1231VA
              )
            ),
          ],
        },
      },
      liabilities: {
        total: [
          legend.Liabilities,
          ...data.map((el) => fA(el.balanceSheet.liabilities.total)),
          ...hA(horAn.liabilities.total),
        ],
        capitalAndReserves: {
          total: [
            legend.CapitalAndReserves,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.capitalAndReserves.total,
                el.balanceSheet.liabilities.capitalAndReservesVA
              )
            ),
            ...hA(horAn.liabilities.capitalAndReserves.total),
          ],
          authorizedCapital: [
            legend.AuthorizedCapital,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.capitalAndReserves
                  .authorizedCapital,
                el.balanceSheet.liabilities.capitalAndReserves
                  .authorizedCapitalVA
              )
            ),
            ...hA(horAn.liabilities.capitalAndReserves.authorizedCapital),
          ],
          ownShares: [
            legend.OwnShares,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.capitalAndReserves.ownShares,
                el.balanceSheet.liabilities.capitalAndReserves.ownSharesVA
              )
            ),
            ...hA(horAn.liabilities.capitalAndReserves.ownShares),
          ],
          revaluationOfNonCurrentAssets: [
            legend.RevaluationOfNonCurrentAssets,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.capitalAndReserves
                  .revaluationOfNonCurrentAssets,
                el.balanceSheet.liabilities.capitalAndReserves
                  .revaluationOfNonCurrentAssetsVA
              )
            ),
            ...hA(
              horAn.liabilities.capitalAndReserves.revaluationOfNonCurrentAssets
            ),
          ],
          additionalCapital: [
            legend.AdditionalCapital,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.capitalAndReserves
                  .additionalCapital,
                el.balanceSheet.liabilities.capitalAndReserves
                  .additionalCapitalVA
              )
            ),
            ...hA(horAn.liabilities.capitalAndReserves.additionalCapital),
          ],
          reserveCapital: [
            legend.ReserveCapital,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.capitalAndReserves.reserveCapital,
                el.balanceSheet.liabilities.capitalAndReserves.reserveCapitalVA
              )
            ),
            ...hA(horAn.liabilities.capitalAndReserves.reserveCapital),
          ],
          retainedEarnings: [
            legend.RetainedEarnings,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.capitalAndReserves.retainedEarnings,
                el.balanceSheet.liabilities.capitalAndReserves
                  .retainedEarningsVA
              )
            ),
            ...hA(horAn.liabilities.capitalAndReserves.retainedEarnings),
          ],
        },
        longTermDuties: {
          total: [
            legend.LongTermDuties,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.longTermDuties.total,
                el.balanceSheet.liabilities.longTermDutiesVA
              )
            ),
            ...hA(horAn.liabilities.longTermDuties.total),
          ],
          longTermBorrowedFunds: [
            legend.LongTermBorrowedFunds,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.longTermDuties
                  .longTermBorrowedFunds,
                el.balanceSheet.liabilities.longTermDuties
                  .longTermBorrowedFundsVA
              )
            ),
            ...hA(horAn.liabilities.longTermDuties.longTermBorrowedFunds),
          ],
          longTermDeferredTax: [
            legend.LongTermDeferredTax,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.longTermDuties.longTermDeferredTax,
                el.balanceSheet.liabilities.longTermDuties.longTermDeferredTaxVA
              )
            ),
            ...hA(horAn.liabilities.longTermDuties.longTermDeferredTax),
          ],
          longTermEstimated: [
            legend.LongTermEstimated,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.longTermDuties.longTermEstimated,
                el.balanceSheet.liabilities.longTermDuties.longTermEstimatedVA
              )
            ),
            ...hA(horAn.liabilities.longTermDuties.longTermEstimated),
          ],
          longTermOther: [
            legend.LongTermOther,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.longTermDuties.longTermOther,
                el.balanceSheet.liabilities.longTermDuties.longTermOtherVA
              )
            ),
            ...hA(horAn.liabilities.longTermDuties.longTermOther),
          ],
        },
        shortTermCommitments: {
          total: [
            legend.ShortTermCommitments,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.shortTermCommitments.total,
                el.balanceSheet.liabilities.shortTermCommitmentsVA
              )
            ),
            ...hA(horAn.liabilities.shortTermCommitments.total),
          ],
          shortTermBorrowedFunds: [
            legend.ShortTermBorrowedFunds,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.shortTermCommitments
                  .shortTermBorrowedFunds,
                el.balanceSheet.liabilities.shortTermCommitments
                  .shortTermBorrowedFundsVA
              )
            ),
            ...hA(
              horAn.liabilities.shortTermCommitments.shortTermBorrowedFunds
            ),
          ],
          accountsPayable: [
            legend.AccountsPayable,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.shortTermCommitments
                  .accountsPayable,
                el.balanceSheet.liabilities.shortTermCommitments
                  .accountsPayableVA
              )
            ),
            ...hA(horAn.liabilities.shortTermCommitments.accountsPayable),
          ],
          revenueOfTheFuturePeriods: [
            legend.RevenueOfTheFuturePeriods,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.shortTermCommitments
                  .revenueOfTheFuturePeriods,
                el.balanceSheet.liabilities.shortTermCommitments
                  .revenueOfTheFuturePeriodsVA
              )
            ),
            ...hA(
              horAn.liabilities.shortTermCommitments.revenueOfTheFuturePeriods
            ),
          ],
          shortTermEstimated: [
            legend.ShortTermEstimated,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.shortTermCommitments
                  .shortTermEstimated,
                el.balanceSheet.liabilities.shortTermCommitments
                  .shortTermEstimatedVA
              )
            ),
            ...hA(horAn.liabilities.shortTermCommitments.shortTermEstimated),
          ],
          shortTermOther: [
            legend.ShortTermOther,
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.shortTermCommitments.shortTermOther,
                el.balanceSheet.liabilities.shortTermCommitments
                  .shortTermOtherVA
              )
            ),
            ...hA(horAn.liabilities.shortTermCommitments.shortTermOther),
          ],
          s1531: [
            "1531",
            ...data.map((el) =>
              vA(
                el.balanceSheet.liabilities.shortTermCommitments.s1531,
                el.balanceSheet.liabilities.shortTermCommitments.s1531VA
              )
            ),
            ...hA(horAn.liabilities.shortTermCommitments.shortTermOther),
          ],
        },
      },
    },
    financialResults: {
      revenue: [
        legend.Revenue,
        ...data.map((el) => fA(el.financialResults.revenue)),
        ...hA(horAn.revenue),
      ],
      costOfSales: [
        legend.CostOfSales,
        ...data.map((el) => fA(el.financialResults.costOfSales)),
        ...hA(horAn.costOfSales),
      ],
      grossProfitTotal: [
        legend.GrossProfitTotal,
        ...data.map((el) => fA(el.financialResults.grossProfitTotal)),
        ...hA(horAn.grossProfitTotal),
      ],
      businessExpenses: [
        legend.BusinessExpenses,
        ...data.map((el) => fA(el.financialResults.businessExpenses)),
        ...hA(horAn.businessExpenses),
      ],
      administrativeExpenses: [
        legend.AdministrativeExpenses,
        ...data.map((el) => fA(el.financialResults.administrativeExpenses)),
        ...hA(horAn.administrativeExpenses),
      ],
      profitFromSalesTotal: [
        legend.ProfitFromSalesTotal,
        ...data.map((el) => fA(el.financialResults.profitFromSalesTotal)),
        ...hA(horAn.profitFromSalesTotal),
      ],
      incomeFromParticipation: [
        legend.IncomeFromParticipation,
        ...data.map((el) => fA(el.financialResults.incomeFromParticipation)),
        ...hA(horAn.incomeFromParticipation),
      ],
      interestReceivable: [
        legend.InterestReceivable,
        ...data.map((el) => fA(el.financialResults.interestReceivable)),
        ...hA(horAn.interestReceivable),
      ],
      percentageToBePaid: [
        legend.PercentageToBePaid,
        ...data.map((el) => fA(el.financialResults.percentageToBePaid)),
        ...hA(horAn.percentageToBePaid),
      ],
      otherIncome: [
        legend.OtherIncome,
        ...data.map((el) => fA(el.financialResults.otherIncome)),
        ...hA(horAn.otherIncome),
      ],
      otherExpenses: [
        legend.OtherExpenses,
        ...data.map((el) => fA(el.financialResults.otherExpenses)),
        ...hA(horAn.otherExpenses),
      ],
      profitBeforeTaxTotal: [
        legend.ProfitBeforeTaxTotal,
        ...data.map((el) => fA(el.financialResults.profitBeforeTaxTotal)),
        ...hA(horAn.profitBeforeTaxTotal),
      ],
      currentIncomeTax: [
        legend.CurrentIncomeTax,
        ...data.map((el) => fA(el.financialResults.currentIncomeTax)),
        ...hA(horAn.currentIncomeTax),
      ],
      permanentTax: [
        legend.PermanentTax,
        ...data.map((el) => fA(el.financialResults.permanentTax)),
        ...hA(horAn.permanentTax),
      ],
      changeTaxLiabilities: [
        legend.ChangeTaxLiabilities,
        ...data.map((el) => fA(el.financialResults.changeTaxLiabilities)),
        ...hA(horAn.changeTaxLiabilities),
      ],
      changeTaxAssets: [
        legend.ChangeTaxAssets,
        ...data.map((el) => fA(el.financialResults.changeTaxAssets)),
        ...hA(horAn.changeTaxAssets),
      ],
      netIncomeOther: [
        legend.NetIncomeOther,
        ...data.map((el) => fA(el.financialResults.netIncomeOther)),
        ...hA(horAn.netIncomeOther),
      ],
      netIncomeTotal: [
        legend.NetIncomeTotal,
        ...data.map((el) => fA(el.financialResults.netIncomeTotal)),
        ...hA(horAn.netIncomeTotal),
      ],
      resultFromAssets: [
        legend.ResultFromAssets,
        ...data.map((el) => fA(el.financialResults.resultFromAssets)),
        ...hA(horAn.resultFromAssets),
      ],
      resultFromOtherOperations: [
        legend.ResultFromOtherOperations,
        ...data.map((el) => fA(el.financialResults.resultFromOtherOperations)),
        ...hA(horAn.resultFromOtherOperations),
      ],
      financialResultTotal: [
        legend.FinancialResultTotal,
        ...data.map((el) => fA(el.financialResults.financialResultTotal)),
        ...hA(horAn.financialResultTotal),
      ],
      dilutedEarningsPerShare: [
        legend.DilutedEarningsPerShare,
        ...data.map((el) => fA(el.financialResults.dilutedEarningsPerShare)),
        ...hA(horAn.dilutedEarningsPerShare),
      ],
      basicEarningsPerShare: [
        legend.BasicEarningsPerShare,
        ...data.map((el) => fA(el.financialResults.basicEarningsPerShare)),
        ...hA(horAn.basicEarningsPerShare),
      ],
    },
    fnsStatus: [legend.FnsStatus, ...data.map((el) => el.fnsStatus), ""],
    taxationSystem: [
      legend.TaxationSystem,
      ...data.map((el) => el.taxationSystem),
      "",
    ],
  };
};

function filterFinData(
  finData: Array<FinData>,
  startDate: Date | any,
  endDate: Date | any,
  recordTypes: Array<RecordType>
): Array<FinData> {
  return finData.filter((el) => {
    const date = new Date(el.date);
    const recordType = el.recordType;
    return (
      !isBefore(date, startDate) &&
      !isAfter(date, endDate) &&
      recordTypes.includes(recordTypeToEnum(recordType))
    );
  });
}

function getAllowedRecordTypes(frequency: Frequency): Array<RecordType> {
  switch (frequency) {
    case Frequency.Quarter:
      return [
        RecordType.ThreeMonths,
        RecordType.SixMonths,
        RecordType.NineMonths,
        RecordType.Year,
      ];
    case Frequency.Month:
      return [RecordType.Month];
    case Frequency.Year:
      return [RecordType.Year];
  }
}

function formatAmmount(value: any): string {
  return value == null ? "0" : numberWithSpaces(value);
}

function verticalAnalyseParserBuilder(
  isVerAnEn: boolean
): (el: any, elVA: number) => Array<string | JSX.Element> {
  return function (el: any, elVA: number) {
    return isVerAnEn
      ? [
          <div
            key={1}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              fontWeight: 500,
              fontSize: "13px",
              lineHeight: "16px",
              letterSpacing: "0.0075em",
              color: "#1A1E24",
            }}
          >
            <div>{formatAmmount(el)}</div>
            <div
              style={
                elVA > 0
                  ? { color: "blue", minWidth: "4em" }
                  : { minWidth: "4em" }
              }
            >
              {elVA}%
            </div>
          </div>,
        ]
      : [formatAmmount(el)];
  };
}

function horizontalAnalyseParserBuilder(isHorAnEn: boolean): (el: any) => any {
  return function (el: any) {
    if (el !== null) {
      if (el.length > 8) {
        return isHorAnEn ? [formatAmmount(el)] : "⠀";
      } else {
        el = `${el}%`;
      }
    }

    return isHorAnEn ? [formatAmmount(el)] : "⠀";
  };
}

export default FinTable;
