import {
  FnsStatus as FnsStatusEnum,
  TypeOfUserEntities,
} from "@Constants/enums";
import { ReportType as ReportTypeEnum } from "@Constants/enums";
import { TransferType as TransferTypeEnum } from "@Constants/enums";
import { RequestStatus as RequestStatusEnum } from "@Constants/enums";
import { RequestTab as RequestTabEnum } from "@Constants/enums";
import { Frequency as FrequencyEnum } from "@Constants/enums";
import { RecordType as RecordTypeEnum } from "@Constants/enums";
import { RegularInflowsAndOutflowsFilters as RegularInflowsAndOutflowsFiltersEnum } from "@Constants/enums";
import { ChangeInPaymentProfileFilters as ChangeInPaymentProfileFiltersEnum } from "@Constants/enums";
import { DashboardFilters as DashboardFiltersEnum } from "@Constants/enums";
import { DynamincsOfPaymentsFilters as DynamincsOfPaymentsFiltersEnum } from "@Constants/enums";
import i18n from "@Localization/i18n";
import {
  DashboardFilters,
  ReportType,
  RequestStatus,
  TransferType,
  RequestTab,
  FnsStatus,
  RecordType,
  DynamincsOfPaymentsFilters,
  RegularInflowsAndOutflowsFilters,
  ChangeInPaymentProfileFilters,
} from "@Constants/types";

export function translatedReportTypes(): Record<string, ReportTypeEnum> {
  const reportTypeItems: Record<string, ReportTypeEnum> = {};
  reportTypeItems[i18n.t("Monitoring")] = ReportTypeEnum.Monitoring;
  reportTypeItems[i18n.t("All")] = ReportTypeEnum.All;
  return reportTypeItems;
}

export function translatedTransferTypes(): Record<string, TransferTypeEnum> {
  const transferTypeItems: Record<string, TransferTypeEnum> = {};
  transferTypeItems[i18n.t("File")] = TransferTypeEnum.File;
  transferTypeItems[i18n.t("Json")] = TransferTypeEnum.Json;
  return transferTypeItems;
}

export function translatedRequestStatus(): Record<string, RequestStatusEnum> {
  const requestStatusItems: Record<string, RequestStatusEnum> = {};
  requestStatusItems[i18n.t("New")] = RequestStatusEnum.New;
  requestStatusItems[i18n.t("ReceivedByClient")] =
    RequestStatusEnum.ReceivedByClient;
  requestStatusItems[i18n.t("RejectedByClient")] =
    RequestStatusEnum.RejectedByClient;
  requestStatusItems[i18n.t("SentByClient")] = RequestStatusEnum.SentByClient;
  requestStatusItems[i18n.t("ReceivedByCustomer")] =
    RequestStatusEnum.ReceivedByCustomer;
  requestStatusItems[i18n.t("RejectedByCustomer")] =
    RequestStatusEnum.RejectedByCustomer;
  requestStatusItems[i18n.t("AcceptedByCustomer")] =
    RequestStatusEnum.AcceptedByCustomer;
  return requestStatusItems;
}

export function translatedFrequencies(): Record<string, FrequencyEnum> {
  const frequencyItems: Record<string, FrequencyEnum> = {};
  frequencyItems[i18n.t("month")] = FrequencyEnum.Month;
  frequencyItems[i18n.t("quarter")] = FrequencyEnum.Quarter;
  frequencyItems[i18n.t("year")] = FrequencyEnum.Year;
  return frequencyItems;
}

export function translatedCompanyTypes(): Record<string, TypeOfUserEntities> {
  const frequencyItems: Record<string, TypeOfUserEntities> = {};
  frequencyItems[i18n.t("entities.legal")] = TypeOfUserEntities.Legal;
  frequencyItems[i18n.t("entities.individual")] = TypeOfUserEntities.Individual;
  return frequencyItems;
}

export function translatedFnsStatus(): Record<string, FnsStatusEnum> {
  const recordTypes: Record<string, FnsStatusEnum> = {};
  recordTypes[i18n.t("FnsStatus.CreatedByAccounter")] =
    FnsStatusEnum.CreatedByAccounter;
  recordTypes[i18n.t("FnsStatus.CreatedFromBills")] =
    FnsStatusEnum.CreatedFromBills;
  recordTypes[i18n.t("FnsStatus.Sent")] = FnsStatusEnum.Sent;
  recordTypes[i18n.t("FnsStatus.Accepted")] = FnsStatusEnum.Accepted;
  recordTypes[i18n.t("FnsStatus.Rejected")] = FnsStatusEnum.Rejected;
  return recordTypes;
}

export function translatedDynamincsOfPaymentsFilters(): Record<
  string,
  DynamincsOfPaymentsFiltersEnum
> {
  const recordTypes: Record<string, DynamincsOfPaymentsFiltersEnum> = {};
  recordTypes[i18n.t("DynamincsOfPaymentsFilters.Currency")] =
    DynamincsOfPaymentsFiltersEnum.Currency;
  recordTypes[i18n.t("DynamincsOfPaymentsFilters.Counterparties")] =
    DynamincsOfPaymentsFiltersEnum.Counterparties;
  recordTypes[i18n.t("DynamincsOfPaymentsFilters.Banks")] =
    DynamincsOfPaymentsFiltersEnum.Banks;
  recordTypes[i18n.t("DynamincsOfPaymentsFilters.AffiliatedEntities")] =
    DynamincsOfPaymentsFiltersEnum.AffiliatedEntities;
  recordTypes[i18n.t("DynamincsOfPaymentsFilters.typesOperationsTable")] =
    DynamincsOfPaymentsFiltersEnum.typesOperationsTable;
  recordTypes[
    i18n.t(
      "DynamincsOfPaymentsFilters.numberOfTransactionsByTypesOperationsTable"
    )
  ] = DynamincsOfPaymentsFiltersEnum.numberOfTransactionsByTypesOperationsTable;
  recordTypes[
    i18n.t(
      "DynamincsOfPaymentsFilters.numberOfTransactionsByCounterpartiesTable"
    )
  ] = DynamincsOfPaymentsFiltersEnum.numberOfTransactionsByCounterpartiesTable;
  recordTypes[
    i18n.t("DynamincsOfPaymentsFilters.numberOfUniqueCounterparties")
  ] = DynamincsOfPaymentsFiltersEnum.numberOfUniqueCounterpartiesTable;
  // recordTypes[i18n.t('DynamincsOfPaymentsFilters.transactionMonitoringTable')] = DynamincsOfPaymentsFiltersEnum.transactionMonitoringTable
  return recordTypes;
}

export function translatedDashboardFilters(): Record<
  string,
  DashboardFiltersEnum
> {
  const recordTypes: Record<string, DashboardFiltersEnum> = {};
  recordTypes[i18n.t("DashboardFilters.Currency")] =
    DashboardFiltersEnum.Currency;
  recordTypes[i18n.t("DashboardFilters.Counterparties")] =
    DashboardFiltersEnum.Counterparties;
  recordTypes[i18n.t("DashboardFilters.Banks")] = DashboardFiltersEnum.Banks;
  // recordTypes[i18n.t('DashboardFilters.AffiliatedEntities')] = DashboardFiltersEnum.AffiliatedEntities
  // recordTypes[i18n.t('DashboardFilters.Type')] = DashboardFiltersEnum.Type
  recordTypes[i18n.t("DashboardFilters.Amount")] = DashboardFiltersEnum.Amount;
  return recordTypes;
}

export function translatedRegularInflowsAndOutflowsFilters(): Record<
  string,
  RegularInflowsAndOutflowsFiltersEnum
> {
  const recordTypes: Record<string, RegularInflowsAndOutflowsFiltersEnum> = {};
  recordTypes[i18n.t("RegularInflowsAndOutflowsFilters.InflowQuantity")] =
    RegularInflowsAndOutflowsFiltersEnum.InflowQuantity;
  recordTypes[i18n.t("RegularInflowsAndOutflowsFilters.OutflowQuantity")] =
    RegularInflowsAndOutflowsFiltersEnum.OutflowQuantity;
  return recordTypes;
}

export function translatedChangeInPaymentProfileFilters(): Record<
  string,
  ChangeInPaymentProfileFiltersEnum
> {
  const recordTypes: Record<string, ChangeInPaymentProfileFiltersEnum> = {};
  recordTypes[i18n.t("ChangeInPaymentProfileFilters.Currency")] =
    ChangeInPaymentProfileFiltersEnum.Currency;
  recordTypes[i18n.t("ChangeInPaymentProfileFilters.PaymentPurpose")] =
    ChangeInPaymentProfileFiltersEnum.PaymentPurpose;
  return recordTypes;
}

export function reportTypeToEnum(reportType: ReportType): ReportTypeEnum {
  switch (reportType) {
    case "Monitoring":
      return ReportTypeEnum.Monitoring;
    case "All":
      return ReportTypeEnum.All;
  }
}

export function transferTypeToEnum(
  transferType: TransferType
): TransferTypeEnum {
  switch (transferType) {
    case "File":
      return TransferTypeEnum.File;
    case "Json":
      return TransferTypeEnum.Json;
  }
}

export function requestStatusToEnum(
  requestStatus: RequestStatus
): RequestStatusEnum {
  switch (requestStatus) {
    case "New":
      return RequestStatusEnum.New;
    case "ReceivedByClient":
      return RequestStatusEnum.ReceivedByClient;
    case "RejectedByClient":
      return RequestStatusEnum.RejectedByClient;
    case "SentByClient":
      return RequestStatusEnum.SentByClient;
    case "ReceivedByCustomer":
      return RequestStatusEnum.ReceivedByCustomer;
    case "RejectedByCustomer":
      return RequestStatusEnum.RejectedByCustomer;
    case "AcceptedByCustomer":
      return RequestStatusEnum.AcceptedByCustomer;
  }
}

export function recordTypeToEnum(recordType: RecordType): RecordTypeEnum {
  switch (recordType) {
    case "Month":
      return RecordTypeEnum.Month;
    case "ThreeMonths":
      return RecordTypeEnum.ThreeMonths;
    case "SixMonths":
      return RecordTypeEnum.SixMonths;
    case "NineMonths":
      return RecordTypeEnum.NineMonths;
    case "Year":
      return RecordTypeEnum.Year;
    case "FirstQuarter":
      return RecordTypeEnum.FirstQuarter;
    case "SecondQuarter":
      return RecordTypeEnum.SecondQuarter;
    case "ThirdQuarter":
      return RecordTypeEnum.ThirdQuarter;
    case "FourthQuarter":
      return RecordTypeEnum.FourthQuarter;
  }
}

export function requestTabFromEnum(requestTab: RequestTabEnum): RequestTab {
  switch (requestTab) {
    case RequestTabEnum.FinStatements:
      return "FinStatements";
    case RequestTabEnum.DebtBurdens:
      return "DebtBurdens";
    case RequestTabEnum.Sales:
      return "Sales";
    case RequestTabEnum.Counterparties:
      return "Counterparties";
    case RequestTabEnum.Analysis:
      return "Analysis";
    case RequestTabEnum.Summary:
      return "Summary";
    case RequestTabEnum.Dq:
      return "DQ";
    case RequestTabEnum.Dashboard:
      return "Dashboard";
  }
}

export function fnsStatusToEnum(fnsStatus: FnsStatus): FnsStatusEnum {
  switch (fnsStatus) {
    case "CreatedByAccounter":
      return FnsStatusEnum.CreatedByAccounter;
    case "CreatedFromBills":
      return FnsStatusEnum.CreatedFromBills;
    case "Sent":
      return FnsStatusEnum.Sent;
    case "Accepted":
      return FnsStatusEnum.Accepted;
    case "Rejected":
      return FnsStatusEnum.Rejected;
  }
}

export function dynamicsOfPaymentsFiltersToEnum(
  filter: DynamincsOfPaymentsFilters
): DynamincsOfPaymentsFiltersEnum {
  switch (filter) {
    case "Currency":
      return DynamincsOfPaymentsFiltersEnum.Currency;
    case "Counterparties":
      return DynamincsOfPaymentsFiltersEnum.Counterparties;
    case "Banks":
      return DynamincsOfPaymentsFiltersEnum.Banks;
    case "AffiliatedEntities":
      return DynamincsOfPaymentsFiltersEnum.AffiliatedEntities;
    case "typesOperationsTable":
      return DynamincsOfPaymentsFiltersEnum.typesOperationsTable;
    case "numberOfTransactionsByTypesOperationsTable":
      return DynamincsOfPaymentsFiltersEnum.numberOfTransactionsByTypesOperationsTable;
    case "numberOfTransactionsByCounterpartiesTable":
      return DynamincsOfPaymentsFiltersEnum.numberOfTransactionsByCounterpartiesTable;
    case "transactionMonitoringTable":
      return DynamincsOfPaymentsFiltersEnum.transactionMonitoringTable;
  }
}

export function dashboardsFiltersToEnum(
  filter: DashboardFilters
): DashboardFiltersEnum {
  switch (filter) {
    case "Currency":
      return DashboardFiltersEnum.Currency;
    case "Counterparties":
      return DashboardFiltersEnum.Counterparties;
    case "Banks":
      return DashboardFiltersEnum.Banks;
    case "AffiliatedEntities":
      return DashboardFiltersEnum.AffiliatedEntities;
    case "Amount":
      return DashboardFiltersEnum.Amount;
    case "Type":
      return DashboardFiltersEnum.Type;
  }
}

export function changeInPaymentProfileFiltersToEnum(
  filter: ChangeInPaymentProfileFilters
): ChangeInPaymentProfileFiltersEnum {
  switch (filter) {
    case "Currency":
      return ChangeInPaymentProfileFiltersEnum.Currency;
    case "PaymentPurpose":
      return ChangeInPaymentProfileFiltersEnum.PaymentPurpose;
  }
}

export function regularInflowsAndOutflowsFiltersToEnum(
  filter: RegularInflowsAndOutflowsFilters
): RegularInflowsAndOutflowsFiltersEnum {
  switch (filter) {
    case "InflowQuantity":
      return RegularInflowsAndOutflowsFiltersEnum.InflowQuantity;
    case "OutflowQuantity":
      return RegularInflowsAndOutflowsFiltersEnum.OutflowQuantity;
  }
}

export function fnsStatusFromEnum(fnsStatus: FnsStatusEnum): FnsStatus {
  switch (fnsStatus) {
    case FnsStatusEnum.CreatedByAccounter:
      return "CreatedByAccounter";
    case FnsStatusEnum.CreatedFromBills:
      return "CreatedFromBills";
    case FnsStatusEnum.Sent:
      return "Sent";
    case FnsStatusEnum.Accepted:
      return "Accepted";
    case FnsStatusEnum.Rejected:
      return "Rejected";
  }
}

export function dashboardFiltersFromEnum(
  filter: DashboardFiltersEnum
): DashboardFilters {
  switch (filter) {
    case DashboardFiltersEnum.Currency:
      return "Currency";
    case DashboardFiltersEnum.Counterparties:
      return "Counterparties";
    case DashboardFiltersEnum.Banks:
      return "Banks";
    case DashboardFiltersEnum.AffiliatedEntities:
      return "AffiliatedEntities";
    case DashboardFiltersEnum.Amount:
      return "Amount";
    case DashboardFiltersEnum.Type:
      return "Type";
  }
}

export function regularInflowsAndOutflowsFiltersFromEnum(
  filter: RegularInflowsAndOutflowsFiltersEnum
): RegularInflowsAndOutflowsFilters {
  switch (filter) {
    case RegularInflowsAndOutflowsFiltersEnum.InflowQuantity:
      return "InflowQuantity";
    case RegularInflowsAndOutflowsFiltersEnum.OutflowQuantity:
      return "OutflowQuantity";
  }
}

export function changeInPaymentProfileFiltersFromEnum(
  filter: ChangeInPaymentProfileFiltersEnum
): ChangeInPaymentProfileFilters {
  switch (filter) {
    case ChangeInPaymentProfileFiltersEnum.Currency:
      return "Currency";
    case ChangeInPaymentProfileFiltersEnum.PaymentPurpose:
      return "PaymentPurpose";
  }
}
