import { signup } from "@State/";
import ButtonSave from "@View/components/buttons/ButtonSave";
import DefaultInput from "@View/components/formInputs/DefaultInput";
import DefaultPassword from "@View/components/formInputs/DefaultPassword";
import Loader from "@View/components/loader/Loader";
import Localization from "@View/components/Localization";
import ModalTittle from "@View/components/ModalTittle";
import Logo from "@View/components/svgComponents/Logo";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

const box = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#FEFFFF",
  outline: "none",
  borderRadius: "9px",
  maxWidth: "360px",
  width: "100%",
  padding: "20px 40px",
  boxSizing: "border-box",
};

const SignupPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();

  const [body, setBody] = useState({
    username: "",
    password: "",
    email: "",
  });

  const [password, setPassword] = useState("");
  const changePassword = (newValue) => {
    setPassword(newValue);
    handleChange(newValue, "password");
  };

  const [password2, setPassword2] = useState("");
  const changePassword2 = (newValue) => {
    setPassword2(newValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(signup(body, history));
  };

  const handleChange = (event, key) => {
    setBody({ ...body, [key]: event });
  };

  return (
    <>
      <Loader />
      <div style={box}>
        <form
          onSubmit={(event) => handleSubmit(event)}
          style={{
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ marginTop: "20px" }}></div>
          <div style={{ position: "absolute", right: "5px", top: "0px" }}>
            <Localization />
          </div>

          <ModalTittle children2={""}>{t("h1.Registration")}</ModalTittle>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <Logo width="126" height="24" />
          </div>

          <DefaultInput
            label={"inputs.Name"}
            value={body.username}
            onChange={(v) => handleChange(v, "username")}
          />
          <DefaultInput
            label={"inputs.Email"}
            value={body.email}
            onChange={(v) => handleChange(v, "email")}
          />

          <DefaultPassword value={password} onChange={changePassword} />
          <DefaultPassword
            value={password2}
            onChange={changePassword2}
            repeat={true}
          />
          <div>
            <ButtonSave marginBottom={"20px"}>{t("Sign Up")}</ButtonSave>
          </div>
          <Link style={{ textDecoration: "none" }} to={`/`}>
            <div
              style={{
                textAlign: "center",
                color: "#6FA4F2",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
              }}
            >
              {t("buttons.AlrdyHave")}
            </div>
          </Link>
        </form>
      </div>
    </>
  );
};

export default SignupPage;
