import { getClient } from "@State/index";
import { editClient } from "@State/index";
import ButtonSave from "@View/components/buttons/ButtonSave";
import DefaultInput from "@View/components/formInputs/DefaultInput";
import DefaultSelect from "@View/components/formInputs/DefaultSelect";
import ModalTittle from "@View/components/ModalTittle";
import ModalWrapper from "@View/components/ModalWrapper";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ClientRolesForSelect } from "@Utils/types";

const EditClientPage = ({ clientId, open, handleOpen, handleClose }) => {
  let client = useSelector((state) => state.client.client);
  const [newData, setNewData] = React.useState(null);
  let dispatch = useDispatch();
  const status = [{ value: "Active" }, { value: "Disable" }];
  const groupsList = useSelector((state) => state.customer.groups);
  const groupsListValue = useMemo(
    () => groupsList.map((i) => ({ name: i.name, value: i.id })),
    [groupsList]
  );

  React.useEffect(() => {
    dispatch(getClient(clientId));
  }, [getClient, clientId]);

  React.useEffect(() => {
    console.log(JSON.stringify(client));
    setNewData({ ...client });
  }, [client]);

  let history = useHistory();

  const handleChange = (value, label) => {
    setNewData({ ...newData, [label]: value });
  };

  const handleClick = (event) => {
    event.preventDefault();
    dispatch(editClient(newData, history));
  };

  if (!newData || !newData.id) {
    return null;
  }

  return (
    <ModalWrapper open={open} handleOpen={handleOpen} handleClose={handleClose}>
      <form onSubmit={(event) => handleClick(event)}>
        <ModalTittle>h1.Edit Client</ModalTittle>
        <DefaultInput label="Id" value={client.id} onChange={() => null} />
        <DefaultInput
          value={newData.name}
          label="inputs.CompanyName"
          onChange={(value) => handleChange(value, "name")}
        />
        <DefaultInput
          value={newData.AccountingSystem}
          label="inputs.AccountingSystem"
          onChange={(value) => handleChange(value, "AccountingSystem")}
        />
        <DefaultSelect
          value={newData.role}
          label="inputs.role"
          dropdowns={ClientRolesForSelect}
          onChange={(value) => handleChange(value, "role")}
        />
        <DefaultSelect
          value={newData.customerGroupId}
          label="inputs.group"
          dropdowns={groupsListValue}
          onChange={(value) => handleChange(value, "customerGroupId")}
        />
        <DefaultSelect
          value={`${newData.status}`}
          label="inputs.Status"
          dropdowns={status}
          onChange={(value) => handleChange(value, "status")}
        />
        <DefaultInput
          value={newData.externalId}
          label="inputs.External Id"
          onChange={(value) => handleChange(value, "externalId")}
        />
        <DefaultInput
          value={newData.email}
          label="inputs.Email"
          onChange={(value) => handleChange(value, "email")}
        />
        <ButtonSave>buttons.Save</ButtonSave>
      </form>
    </ModalWrapper>
  );
};

export default EditClientPage;
