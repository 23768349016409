import React from "react";
import PropTypes from "prop-types";

const Logo = ({ width, height }) => {
  return (
    <div>
       <svg width={width}
        height={height} viewBox="0 0 205 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.4845 57.7051C12.9274 57.7051 9.95353 57.1027 7.56299 55.8979C5.19157 54.6931 3.44169 53.1345 2.31335 51.222C1.18502 49.3096 0.62085 47.2729 0.62085 45.1118H7.76379C7.76379 46.3358 8.10803 47.445 8.79651 48.4395C9.48499 49.4339 10.489 50.2276 11.8086 50.8204C13.1282 51.3942 14.6772 51.681 16.4558 51.681C22.2505 51.681 25.1478 49.7399 25.1478 45.8577C25.1478 44.6911 24.7845 43.7349 24.0577 42.989C23.3501 42.224 22.3557 41.6121 21.0743 41.1531C19.793 40.675 18.1579 40.2447 16.1689 39.8622C13.4342 39.2885 11.0341 38.6 8.96863 37.7968C6.9032 36.9935 5.16288 35.7983 3.74768 34.2109C2.33248 32.6236 1.62488 30.5773 1.62488 28.072C1.62488 25.8919 2.1508 23.9698 3.20264 22.306C4.25448 20.6231 5.87048 19.3035 8.05066 18.3473C10.2308 17.3719 12.9561 16.8843 16.2263 16.8843C19.401 16.8843 22.0879 17.3719 24.2872 18.3473C26.4865 19.3035 28.1408 20.6613 29.25 22.4208C30.3592 24.1802 30.9138 26.2361 30.9138 28.5884H23.7709C23.7709 26.9819 23.1302 25.6719 21.8489 24.6583C20.5675 23.6447 18.6647 23.1379 16.1403 23.1379C13.4437 23.1379 11.5409 23.6065 10.4316 24.5436C9.32243 25.4807 8.76782 26.6664 8.76782 28.1007C8.76782 29.1717 9.12162 30.0801 9.82922 30.8259C10.5368 31.5527 11.5982 32.1646 13.0134 32.6619C14.4286 33.14 16.2741 33.5607 18.5499 33.9241C22.7382 34.6891 26.0849 35.9799 28.5902 37.7968C31.1146 39.6136 32.3768 42.3101 32.3768 45.8864C32.3768 48.3725 31.7266 50.5049 30.4261 52.2834C29.1257 54.062 27.2802 55.4102 24.8896 56.3282C22.4991 57.2462 19.6974 57.7051 16.4845 57.7051Z" fill="#191919"/>
      <path d="M71.8973 26.4369C75.55 26.4369 78.3039 27.68 80.159 30.1661C82.0332 32.6523 82.9703 36.2381 82.9703 40.9236V56.8159H76.1142V41.067C76.1142 38.2557 75.5691 36.1808 74.479 34.842C73.4081 33.5033 71.8781 32.834 69.8892 32.834C68.6461 32.834 67.4795 33.2165 66.3895 33.9814C65.2994 34.7273 64.4196 35.7791 63.7503 37.137C63.1001 38.4948 62.7749 40.0343 62.7749 41.7555V56.8159H55.9189V41.067C55.9189 38.2557 55.3738 36.1808 54.2837 34.842C53.2128 33.5033 51.6924 32.834 49.7226 32.834C48.4795 32.834 47.3129 33.2165 46.2228 33.9814C45.1327 34.7273 44.253 35.7791 43.5837 37.137C42.9334 38.4948 42.6083 40.0343 42.6083 41.7555V56.8159H35.7522V27.3262H42.2354L42.3215 31.916C43.1056 30.3096 44.3391 28.9996 46.022 27.986C47.705 26.9533 49.6078 26.4369 51.7306 26.4369C54.1786 26.4369 56.2249 27.0011 57.8696 28.1294C59.5142 29.2577 60.7478 30.912 61.5701 33.0922C62.8132 30.8546 64.3049 29.1908 66.0452 28.1007C67.8047 26.9915 69.7553 26.4369 71.8973 26.4369Z" fill="#191919"/>
      <path d="M100.777 57.7051C98.176 57.7051 95.8142 57.0071 93.6914 55.611C91.5686 54.215 89.9048 52.3217 88.6999 49.9312C87.4951 47.5406 86.8927 44.9206 86.8927 42.0711C86.8927 39.2406 87.4951 36.6302 88.6999 34.2396C89.9239 31.8491 91.5877 29.9558 93.6914 28.5597C95.8142 27.1445 98.1665 26.4369 100.748 26.4369C103.158 26.4369 105.29 26.9628 107.145 28.0147C109.02 29.0474 110.435 30.4817 111.391 32.3176V27.3262H117.989V56.8159H111.391V51.8532C110.435 53.6891 109.02 55.1234 107.145 56.1561C105.29 57.1888 103.167 57.7051 100.777 57.7051ZM102.441 51.2794C104.028 51.2794 105.482 50.8778 106.801 50.0746C108.14 49.2522 109.192 48.143 109.957 46.747C110.722 45.3318 111.104 43.7731 111.104 42.0711C111.104 40.3881 110.712 38.8486 109.928 37.4525C109.163 36.0373 108.121 34.9185 106.801 34.0962C105.482 33.2738 104.028 32.8627 102.441 32.8627C100.853 32.8627 99.4 33.2547 98.0804 34.0388C96.7608 34.8038 95.709 35.8843 94.9249 37.2804C94.1599 38.6765 93.7774 40.2734 93.7774 42.0711C93.7774 43.9261 94.1695 45.5517 94.9536 46.9478C95.7377 48.3438 96.7895 49.4148 98.1091 50.1606C99.4287 50.9065 100.873 51.2794 102.441 51.2794Z" fill="#191919"/>
      <path d="M129.777 27.3262V32.6619C130.485 30.998 131.508 29.6976 132.847 28.7605C134.205 27.8043 135.821 27.3262 137.695 27.3262H140.85V33.293H138.068C136.48 33.293 135.084 33.6755 133.88 34.4404C132.675 35.2054 131.738 36.3242 131.068 37.7968C130.399 39.2693 130.064 41.0383 130.064 43.1038V56.8159H123.122V27.3262H129.777Z" fill="#191919"/>
      <path d="M159.296 56.8159C156.484 56.8159 154.151 56.0413 152.296 54.4923C150.441 52.9241 149.514 49.9598 149.514 45.5995V33.293H143.403V27.3262H149.514V18.6915L156.456 18.6342V27.3262H165.291V33.293H156.456V45.915C156.456 49.3383 157.881 51.0499 160.73 51.0499H165.291V56.8159H159.296Z" fill="#191919"/>
      <path d="M182.461 21.2553L180.78 17.1616H171.036L169.355 21.2553H165.261L174.071 0.0708618H177.745L186.555 21.2553H182.461ZM172.359 13.924H179.457L176.033 5.58099L175.939 5.06733H175.892L175.768 5.58099L172.359 13.924Z" fill="#1A1A1A"/>
      <path d="M192.516 5.25412V7.74457C192.983 6.85216 193.704 6.13615 194.68 5.59656C195.666 5.04658 196.766 4.77159 197.98 4.77159C200.107 4.77159 201.71 5.44609 202.789 6.79509C203.869 8.14408 204.408 10.0897 204.408 12.6321V21.2553H200.641V12.7099C200.641 11.1741 200.32 10.0482 199.676 9.33224C199.033 8.60585 198.12 8.24266 196.937 8.24266C196.179 8.24266 195.479 8.43463 194.835 8.81858C194.192 9.19215 193.673 9.74731 193.279 10.4841C192.895 11.2105 192.703 12.0769 192.703 13.0835V21.2553H188.936V5.25412H192.516Z" fill="#1A1A1A"/>
      </svg>
    </div>
  );
};

Logo.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default Logo;
