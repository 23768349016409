import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { FirstCell } from "../cells/FirstCell";

export function NoCollapseGroupRows({
  cellText = "",
  level = 0,
  children = null,
  colSpan,
  color,
  paddingLeft,
}: NoCollapseGroupRowsProps): JSX.Element {
  return (
    <React.Fragment>
      {cellText ? (
        <TableRow
          style={{ background: "#F3F5F7", textTransform: "uppercase" }}
          className="NoCollapseGroupRows"
        >
          {/* <FirstCell color={color} paddingLeft={paddingLeft} level={level} colSpan={colSpan ? colSpan : 200}>
                            {cellText}
                        </FirstCell> */}
          <TableCell
            style={{
              paddingLeft: level > 0 ? `${2 * level}em` : "",
              height: "50px",
              color: "#000",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "19px",
            }}
            colSpan={colSpan ? colSpan : 200}
            className="FirstCell"
          >
            {cellText}
          </TableCell>
        </TableRow>
      ) : null}
      {children}
    </React.Fragment>
  );
}

type NoCollapseGroupRowsProps = {
  cellText?: string;
  level?: number;
  children?: JSX.Element | JSX.Element[] | null;
  colSpan?: any;
  color?: any;
  paddingLeft?: any;
};
