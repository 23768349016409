import { createReducer } from "@reduxjs/toolkit";
import { ANALYSIS_TYPES, FREQUENCY_TYPES } from "@View/constants";
import { changeFiltersAction } from "../../actions/actionsModify/actionModifyFinDataRequestFilters";

const defaultFiltersValues = {
  frequency: FREQUENCY_TYPES.quart,
  minDate: null,
  maxDate: null,
  startDate: null,
  endDate: null,
  checkboxes: {
    [ANALYSIS_TYPES.horizontal]: false,
    [ANALYSIS_TYPES.vertical]: false,
    [ANALYSIS_TYPES.ratio]: false,
    showDashboard: false,
  }
};

const initialState = {
  FinStatements: defaultFiltersValues,
  DebtBurdens: defaultFiltersValues,
  Sales: defaultFiltersValues,
  Counterparties: defaultFiltersValues,
  Analysis: defaultFiltersValues,
  Summary: defaultFiltersValues,
};

const reducer = createReducer(initialState, {
  [changeFiltersAction]: (state, action) => {
    const {
      payload: { page, data },
    } = action;
    state[page] = data;
  },
});

export default reducer;
