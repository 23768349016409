import { getClient } from "../actionsGet/actionGetClient";
import { getCustomer } from "../actionsGet/actionGetCustomer";
import { geAlltUsersRequest } from "../actionsGet/actionGetUser";
import AppAPI from "@API/index";

export const postNewUser = (body, history) => {
  const prevPage = body.prevPage;
  const initiatorIdKey = `${prevPage}Id`;

  const newBody = {
    userName: body.userName.trim(),
    fullName: body.fullName.trim(),
    email: body.email.trim(),
    password: body.password.trim() || null,
    [initiatorIdKey]: body.initiatorId,

    ...body,
  };

  return (dispatch) => {
    AppAPI.request({
      method: "post",
      url: `/api/Account`,
      data: newBody,
    }).then(() => {
      if (window.location.href.indexOf("customer") != -1) {
        if (prevPage === "user") {
          dispatch(geAlltUsersRequest());
        } else {
          dispatch(getCustomer(body.initiatorId));
        }
      } else if (window.location.href.indexOf("client") != -1) {
        dispatch(getClient(body.initiatorId));
      } else {
        window.location.reload();
      }
    });
  };
};
