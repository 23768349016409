import { usePageRoot } from "@View/hooks/usePageRoot";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "@View/style/pages/detailsStyle";
import { Box, Grid, Paper, Tab } from "@material-ui/core";
import Tabs from "@material-ui/lab/TabList";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import ClientsTable from "./tables/ClientsTable";
import GroupsTable from "./tables/GroupsTable";
import RequestsTable from "./tables/RequestsTable";
import UsersTable from "./tables/UsersTable";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { deleteCustomer } from "@State/";
import { DeleteModal } from "@View/components/DeleteModal";
import { ApiKeyModal } from "./modals/ApiKeyModal";
import useAppTab from "@Utils/useAppTab";
import { useTabStyle } from "@View/style/components/tabStyle";
import { canViewUsers } from "@Security/stateHelpers";

//tabs of table
const tabs = ["Clients", "Requests", "Users", "Groups"];

const FullTable = ({ customer }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const tabClasses = useTabStyle();

  const [tab, setTab] = useAppTab({
    defaultTab: "Clients",
    tabList: tabs,
  });

  return (
    <TabContext value={tab}>
      <Grid className={classes.fullTable}>
        <Paper style={{ boxShadow: "none" }}>
          <Tabs onChange={setTab} indicatorColor="primary" textColor="primary">
            {tabs.map((tab, index) => {
              return (
                <Tab
                  classes={tabClasses}
                  label={t(tab)}
                  value={tab}
                  key={index}
                />
              );
            })}
          </Tabs>
        </Paper>
        <Box>
          <Paper>
            <TabPanel value="Clients" style={{ position: "relative" }}>
              <ClientsTable />
            </TabPanel>
            <TabPanel value="Requests" style={{ position: "relative" }}>
              <RequestsTable
                customerId={customer.id}
                state={{ prevPage: usePageRoot() }}
              />
            </TabPanel>
            <TabPanel value="Groups" style={{ position: "relative" }}>
              <GroupsTable />
            </TabPanel>
            {canViewUsers() && (
              <TabPanel value="Users" style={{ position: "relative" }}>
                <UsersTable customerId={customer.id} />
              </TabPanel>
            )}
          </Paper>
        </Box>
      </Grid>
    </TabContext>
  );
};

FullTable.propTypes = {
  customer: PropTypes.object.isRequired,
};

const CustomerExtendedDetails = ({ customer }) => {
  const [apiKeyModalOpen, setApiKeyModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleCloseModal = (event) => {
    event.preventDefault();
    setDeletedId();
  };

  const [deletedId, setDeletedId] = useState();

  const handleDelete = (event) => {
    event.preventDefault();
    dispatch(deleteCustomer(deletedId));
    setDeletedId();
  };

  return (
    <React.Fragment>
      <FullTable customer={customer} />
      <DeleteModal
        handleDelete={handleDelete}
        openModal={deletedId !== undefined}
        handleCloseModal={handleCloseModal}
      />
      <ApiKeyModal
        customer={customer}
        openModal={apiKeyModalOpen}
        handleCloseModal={() => setApiKeyModalOpen(false)}
      />
    </React.Fragment>
  );
};

export default CustomerExtendedDetails;
