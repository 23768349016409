import { makeStyles } from "@material-ui/core";
import ModalWrapper from "@View/components/ModalWrapper";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AppAPI from "@API/index";
import ModalTittle from "@View/components/ModalTittle";
import { downloadFinDataTemplateFileFunc } from "@Utils/downloadLinks";
import NButton from "@View/components/ui/NButton";
import FileUploader from "@View/components/ui/FileUploader";
import CommonError from "@View/pages/AdminPO/customer/customerDetails/bankStatement/CommonError";
import FileLoadingProcess from "@View/components/ui/FileLoadingProcess";
import RequestsTable from "@View/pages/AdminPO/customer/customerDetails/RequestsTable";
import { formatDate } from "@Localization/utils";
import { useButtonStyles } from "@View/pages/AdminPO/customer/customerDetails/bankStatement/styles";
import RequestSearcher from "@View/components/ui/RequestSearcher";

const acceptedFormats = ["xlsx", "xml", "txt"];
const maxFileSize = 4;

const useStyles = makeStyles((_theme) => ({
  cover: {
    color: "#54606F",
    fontSize: "13px",
    marginTop: "15px",
    "& > div + div": {
      marginTop: "10px",
    },
  },
  requests: {
    maxHeight: "300px",
    overflow: "auto",
    boxSizing: "border-box",
  },
  divider: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    fontWeight: 600,
    marginBottom: "25px",
  },
  goToAll: {
    cursor: "pointer",
    color: "#6FA4F2",
    fontWeight: 600,
  },
  selectedItem: {
    border: "1px solid #A1ADBA",
    borderRadius: "10px",
    padding: "10px 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
}));

const BankStatementsModal = ({ open, handleClose, client }) => {
  const userId = client.id;

  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(1);
  const [search, setSearch] = React.useState("");

  const classes = useStyles();
  const btnClasses = useButtonStyles();

  const [isError, setIsError] = React.useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const selectedItemsData = useMemo(() => {
    if (!selectedItems.length) return null;
    return selectedItems.map((id) =>
      client.finDataRequests.find((i) => i.id === id)
    );
  }, [selectedItems, client]);

  const tableItems = useMemo(() => {
    if (search.length < 2) return client.finDataRequests;

    return client.finDataRequests.filter((i) =>
      i.customerName.toLowerCase().includes(search.toLowerCase())
    );
  }, [client, search]);

  const submitForm = async () => {
    setIsLoading(true);

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("clientId", userId);
    if (selectedItems.length) {
      selectedItems.forEach((requestId) => {
        formData.append("requestId", requestId);
      });
    }

    try {
      await AppAPI.request({
        url: `/api/FinDataRequest/upload-documents`,
        method: "POST",
        data: formData,
      });
      handleClose();
    } catch (e) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  function goToFirstStep() {
    setCurrentStep(1);
    setSelectedItems([]);
  }
  function goToSecondStep() {
    setCurrentStep(2);
  }

  return (
    <ModalWrapper open={open} handleClose={handleClose} maxWidth={"720px"}>
      <ModalTittle>finData1C.title</ModalTittle>
      {isError ? (
        <CommonError closeError={() => setIsError(false)} />
      ) : isLoading ? (
        <FileLoadingProcess />
      ) : (
        <div className={classes.cover}>
          {currentStep === 1 ? (
            <>
              <RequestSearcher
                value={search}
                updateValue={setSearch}
                selectAction={goToSecondStep}
              />
              <div>{t("bankStatement.currentRequests")}</div>
              <div className={classes.requests}>
                <RequestsTable
                  selectedItems={selectedItems}
                  changeSelection={setSelectedItems}
                  items={tableItems}
                />
              </div>
              <div style={{ marginTop: "35px" }}>
                <button
                  className={btnClasses.addBtn}
                  disabled={!selectedItems.length}
                  onClick={() => goToSecondStep()}
                >
                  {t("bankStatement.add")}
                </button>
              </div>
              <div style={{ textAlign: "center", marginTop: "15px" }}>
                <a
                  href="#"
                  style={{
                    color: "rgba(111, 164, 242, 1)",
                    fontWeight: "600",
                    fontSize: "15px",
                    textDecoration: "none",
                  }}
                  onClick={() => downloadFinDataTemplateFileFunc(userId)}
                >
                  {t("attach.getSamples")}
                </a>
              </div>
            </>
          ) : (
            <>
              <div className={classes.divider}>
                <div style={{ color: "#A1ADBA" }}>
                  {selectedItems.length ? null : t("bankStatement.newRequest")}
                </div>
                <div>
                  <span
                    className={classes.goToAll}
                    onClick={() => goToFirstStep()}
                  >
                    {t("bankStatement.goToAll")}
                  </span>
                </div>
              </div>
              {selectedItems.length ? (
                <div style={{ marginBottom: "25px" }}>
                  {selectedItemsData.map((itemData) => (
                    <div className={classes.selectedItem}>
                      <div>
                        <div
                          style={{
                            color: "#1A1E24",
                            fontWeight: 600,
                            paddingBottom: "5px",
                          }}
                        >
                          {itemData.customerName}
                        </div>
                        <div style={{ color: "#54606F" }}>
                          {formatDate(itemData.startDate)} -{" "}
                          {formatDate(itemData.endDate)}
                        </div>
                      </div>
                      <div style={{ color: "#A1ADBA" }}>
                        {t("bankStatement.updated")}{" "}
                        {formatDate(itemData.updatedAt)}
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
              <div>{t("finData1C.text_1")}</div>
              <div>
                <ol>
                  <li>{t("finData1C.list.item_1")}</li>
                  <li>{t("finData1C.list.item_2")}</li>
                  <li>{t("finData1C.list.item_3")}</li>
                </ol>
              </div>
              <div>{t("finData1C.addition")}</div>
              <div>{t("finData1C.addition_bills")}</div>
              <FileUploader
                acceptedFormats={acceptedFormats}
                maxFileSize={maxFileSize}
                multiple
                onChange={(file) => setSelectedFiles(file)}
              />
              <div style={{ marginTop: "25px" }}>
                <NButton
                  disabled={!selectedFiles.length}
                  styles={{ maxWidth: "270px", margin: "0 auto" }}
                  onClick={() => submitForm()}
                >
                  {t("attach.upload")}
                </NButton>
              </div>
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <a
                  href="#"
                  style={{
                    color: "rgba(111, 164, 242, 1)",
                    fontWeight: "600",
                    fontSize: "15px",
                    textDecoration: "none",
                  }}
                  onClick={() => downloadFinDataTemplateFileFunc(userId)}
                >
                  {t("attach.getSamples")}
                </a>
              </div>
            </>
          )}
        </div>
      )}
    </ModalWrapper>
  );
};

export default BankStatementsModal;
