import AppAPI from "@API/index";

export function downloadFileByPath(href: string, name: string) {
  const link = document.createElement("a");
  link.setAttribute("download", name);
  link.href = href;
  document.body.appendChild(link);
  link.click();
}

export async function downloadFile(
  endpoint: string,
  throwOnError = false
): Promise<void> {
  try {
    const _fileSettings = { name: "" };
    const blob = await AppAPI.request({
      url: endpoint,
      responseType: "blob",
      _fileSettings,
    });

    const href = URL.createObjectURL(blob);
    downloadFileByPath(href, _fileSettings.name);
    URL.revokeObjectURL(href);
  } catch (e) {
    if (throwOnError) {
      throw e;
    }
  }
}
