import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomers, deleteCustomer } from "@State/index";
import { DeleteModal } from "@View/components/DeleteModal";
import Grid from "@material-ui/core/Grid";
import DataTable from "@View/components/table/DataTable";
import { usePageRoot } from "@View/hooks/usePageRoot";
import { Box, Paper, Tab, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import ButtonAddNew from "@View/components/buttons/ButtonAddNew";
import DeleteIcon from "@View/components/svgComponents/DeleteIcon";
import EditIcon from "@View/components/svgComponents/EditIcon";
import DetailsIcon from "@View/components/svgComponents/DetailsIcon";
import NewCustomerPage from "./customer/NewCustomerPage";
import EditCustomerPage from "./customer/EditCustomerPage";
import Tabs from "@material-ui/lab/TabList";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import ListOfUsersPage from "./ListOfUsersPage";
import { getHeaderLeftComponent } from "@View/components/headers/HeaderMain";
import useAppTab from "@Utils/useAppTab";
import { useTabStyle } from "@View/style/components/tabStyle";
import { useTooltipStyle } from "@View/style/components/tooltipStyles";

const ListOfCustomersPage = () => {
  const { t } = useTranslation();
  const [deletedId, setDeletedId] = useState();
  const pageRoot = usePageRoot();
  const dispatch = useDispatch();
  let customers = useSelector((state) => state.customers.customers);
  const tooltipStyle = useTooltipStyle();

  const tabs = ["Customers", "Users"];
  const [tab, setTab] = useAppTab({
    defaultTab: "Customers",
    tabList: tabs,
  });

  const [openNew, setOpenNew] = React.useState(false);

  const handleOpenNew = () => {
    setOpenNew(true);
  };

  const handleCloseNew = () => {
    setOpenNew(false);
  };

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const [id, setId] = React.useState();
  useEffect(() => {
    dispatch(getAllCustomers());
    dispatch(getHeaderLeftComponent(null));
  }, []);

  const handleOpenModal = (event, id) => {
    event.preventDefault();
    setDeletedId(id);
  };

  const handleCloseModal = (event) => {
    event.preventDefault();
    setDeletedId();
  };

  const handleDelete = (event) => {
    event.preventDefault();
    dispatch(deleteCustomer(deletedId));
    setDeletedId();
  };

  const handleOnClickEvent = (event, id) => {
    event();
    setId(id);
  };

  const headCellsCustomers = [
    {
      field: "externalId",
      headerName: t("inputs.External Id"),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "name",
      headerName: t("inputs.CompanyName"),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "status",
      headerName: t("inputs.Status"),
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (cell) => t(cell.row.status),
    },
    {
      field: "subscriptionUntil",
      headerName: t("inputs.Subscription Until"),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      headerName: " ",
      flex: 1,
      sortable: false,
      filterable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (cell) => {
        return (
          <div className="tableIcons">
            <Tooltip title={t("buttons.Details")} classes={tooltipStyle}>
              <Link
                style={{
                  maxWidth: "30px",
                  width: "100%",
                }}
                to={{
                  pathname: `/customer/details/${cell.row.id}`,
                  prevPage: pageRoot,
                }}
                className={tooltipStyle.element}
              >
                <DetailsIcon />
              </Link>
            </Tooltip>

            <Tooltip title={t("buttons.Edit")} classes={tooltipStyle}>
              <div
                onClick={() => handleOnClickEvent(handleOpenEdit, cell.row.id)}
                className={tooltipStyle.element}
              >
                <EditIcon />
              </div>
            </Tooltip>

            <Tooltip title={t("buttons.Delete")} classes={tooltipStyle}>
              <div
                style={{ display: "flex" }}
                onClick={(event) => handleOpenModal(event, cell.row.id)}
                className={tooltipStyle.element}
              >
                <DeleteIcon />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const tabClasses = useTabStyle();

  return (
    <div className="listOfCustomers__page">
      <TabContext value={tab}>
        <Grid>
          <Paper style={{ boxShadow: "none" }}>
            <Tabs
              onChange={setTab}
              indicatorColor="primary"
              textColor="primary"
            >
              {tabs.map((tab, index) => {
                return (
                  <Tab
                    classes={tabClasses}
                    label={t(tab)}
                    value={tab}
                    key={index}
                  />
                );
              })}
            </Tabs>
          </Paper>
          <Box>
            <Paper>
              <TabPanel value="Customers">
                <DataTable
                  rows={customers}
                  columns={headCellsCustomers}
                  toolbarButtons={<ButtonAddNew onClick={handleOpenNew} />}
                />
              </TabPanel>
              <TabPanel value="Users">
                <ListOfUsersPage />
              </TabPanel>
            </Paper>
          </Box>
        </Grid>
      </TabContext>
      <NewCustomerPage
        open={openNew}
        handleOpen={handleOpenNew}
        handleClose={handleCloseNew}
      />
      {id !== undefined ? (
        <EditCustomerPage
          open={openEdit}
          handleOpen={handleOpenEdit}
          handleClose={handleCloseEdit}
          customerId={id}
        />
      ) : null}
      <DeleteModal
        handleDelete={handleDelete}
        openModal={deletedId !== undefined}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

export default ListOfCustomersPage;
