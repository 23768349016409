import { Container, FormControl, FormLabel, Typography } from "@material-ui/core";
import { DatePicker, DatePickerView, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { useTranslation } from "react-i18next";
import DateIcon from "@View/components/svgComponents/DateIcon";
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";
import { useEffect, useState } from "react";

export function RangeDatePicker({
    label,
    startValue,
    endValue,
    minValue,
    maxValue,
    views,
    format,
    onChangeStart,
    onChangeEnd,
    vertical
}: RangeDatePickerProps) {
    const { i18n } = useTranslation()

    if (format === undefined) {
        format = i18n.language === "ru" ? "dd.MM.yyyy" : "MM/dd/yyyy"
    }

    const [startMaxValue, setStartMaxValue] = useState(new Date(endValue.getFullYear(), endValue.getMonth() + 1, 0))
    const [endMinValue, setEndMinValue] = useState(new Date(startValue.getFullYear(), startValue.getMonth() +1, 0))

    useEffect(() => {
        setStartMaxValue(new Date(endValue.getFullYear(), endValue.getMonth() +1, 0))
    }, [endValue])
    useEffect(() => {
        setEndMinValue(new Date(startValue.getFullYear(), startValue.getMonth() +1, 0))
    }, [startValue])

    const onChangeStartHandler = (date: MaterialUiPickersDate) => {
        if (date) {
            const newDate = new Date(date.getFullYear(), date.getMonth(), 1);
            onChangeStart(newDate);
        }
    }
    const onChangeEndHandler = (date: MaterialUiPickersDate) => {
        if (date) {
            const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            onChangeEnd(newDate)
        }
    }

    return <FormControl className={'RangeDatePicker'} style={vertical ? {} : { display: "flex", flexDirection: "row", alignItems: "center", width: '100%' }}>
        {vertical
            ? <FormLabel>
                <Typography>{label}</Typography>
            </FormLabel>
            : <label style={{ minWidth: "200px", maxWidth: "200px" }}>
                <Typography>{label}</Typography>
            </label>
        }
        <Container style={{ padding: "0", display: "flex" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === 'en' ? enLocale : ruLocale}>
                <div style={{ display: 'flex', border: '1px solid #E1E6F1', borderRadius: '8px', padding: '14px', boxSizing: 'border-box'}}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{
                            fontWeight: 500,
                            fontSize: '14px',
                            color: '#A1ADBA',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            c&nbsp;
                        </div>
                        <DatePicker
                             size="small"
                             views={views}
                             autoOk
                             variant="inline"
                             inputVariant="outlined"
                             format={format}
                             inputProps={{ readOnly: true }}
                             value={startValue}
                             minDate={minValue}
                             maxDate={startMaxValue}
                             onChange={onChangeStartHandler}
                       />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{
                            fontWeight: 500,
                            fontSize: '14px',
                            color: '#A1ADBA',
                        }}>
                            &nbsp;&nbsp;по&nbsp;
                        </div>
                        <DatePicker
                             views={views}
                             autoOk
                             variant="inline"
                             inputVariant="outlined"
                             format={format}
                             inputProps={{ readOnly: true }}
                             value={endValue}
                             minDate={endMinValue}
                             maxDate={maxValue}
                             onChange={onChangeEndHandler}
                        />
                    </div>
                </div>
            </MuiPickersUtilsProvider>
        </Container>
    </FormControl>

}

type RangeDatePickerProps = {
    label: string,
    startValue?: ParsableDate | any,
    endValue?: ParsableDate | any,
    minValue?: ParsableDate,
    maxValue?: ParsableDate,
    format?: string,
    views?: DatePickerView[],
    onChangeStart: (date: MaterialUiPickersDate) => void,
    onChangeEnd: (date: MaterialUiPickersDate) => void,
    vertical?: boolean
}