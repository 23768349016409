import { forwardRef } from "react";

function IconPlus({ className = "" }, ref) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 9.99972C1.00031 5.70632 4.0334 2.01101 8.24436 1.1737C12.4553 0.336375 16.6714 2.59024 18.3141 6.55693C19.9569 10.5236 18.5689 15.0984 14.9989 17.4834C11.4289 19.8685 6.6713 19.3997 3.63556 16.3636C1.94787 14.6758 0.999828 12.3866 1 9.99972Z"
        stroke="#6FA4F2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.625 9.24936C6.21079 9.24936 5.875 9.58515 5.875 9.99936C5.875 10.4136 6.21079 10.7494 6.625 10.7494V9.24936ZM9.9998 10.7494C10.414 10.7494 10.7498 10.4136 10.7498 9.99936C10.7498 9.58515 10.414 9.24936 9.9998 9.24936V10.7494ZM10 9.24936C9.58579 9.24936 9.25 9.58515 9.25 9.99936C9.25 10.4136 9.58579 10.7494 10 10.7494V9.24936ZM13.3748 10.7494C13.789 10.7494 14.1248 10.4136 14.1248 9.99936C14.1248 9.58515 13.789 9.24936 13.3748 9.24936V10.7494ZM10.7495 9.99937C10.7495 9.58515 10.4137 9.24937 9.99946 9.24937C9.58525 9.24937 9.24946 9.58515 9.24946 9.99937H10.7495ZM9.24946 13.3742C9.24946 13.7884 9.58525 14.1242 9.99946 14.1242C10.4137 14.1242 10.7495 13.7884 10.7495 13.3742H9.24946ZM9.24946 9.99936C9.24946 10.4136 9.58525 10.7494 9.99946 10.7494C10.4137 10.7494 10.7495 10.4136 10.7495 9.99936H9.24946ZM10.7495 6.62456C10.7495 6.21035 10.4137 5.87456 9.99946 5.87456C9.58525 5.87456 9.24946 6.21035 9.24946 6.62456H10.7495ZM6.625 10.7494H9.9998V9.24936H6.625V10.7494ZM10 10.7494H13.3748V9.24936H10V10.7494ZM9.24946 9.99937V13.3742H10.7495V9.99937H9.24946ZM10.7495 9.99936V6.62456H9.24946V9.99936H10.7495Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default forwardRef(IconPlus);
