import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import PieChart from "@View/components/charts/PieChart/PieChart";
import { useTranslation } from "react-i18next";

const chartStyleConfig = {
  width: 387,
  height: 240,
  margin: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  colors: {
    pies: ["#D73067", "#CEE4EA", "#6463B1"],
  },
  titlePosition: "center",
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: `${chartStyleConfig.width}px`,
    background: "#F5F7F9",
    borderRadius: "12px",
  },
}));

const DebtStructureChart = ({ data, filters }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const title = t("Charts.DebtStructure.title");
  const legendProps = {
    debtStructure: "debtStructure",
  };
  return (
    <>
      <PieChart
        data={{
          [legendProps.debtStructure]: data["debtStructure"],
        }}
        dataProps={{
          name: "name",
          keys: [legendProps.debtStructure],
        }}
        title={title}
        chartStyle={chartStyleConfig}
        chartContainerClass={classes.container}
        currency={data.currency.symbol}
      />
    </>
  );
};

DebtStructureChart.propTypes = {
  data: PropTypes.object,
};

export default DebtStructureChart;
