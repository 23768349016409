import { TableBody, TableCell, TableHead } from "@material-ui/core";
import {
  CollapseGroupRows,
  DefaultTable,
  FirstCell,
  NestedRow,
  TextCell,
} from "@View/common/table";
import React from "react";
import { useTranslation } from "react-i18next";

const AnalysisTable = ({ data, dates, curency }) => {
  const { i18n } = useTranslation();

  const formatCurrency = (number) => {
    return i18n.language === "en"
      ? new Intl.NumberFormat().format(number)
      : new Intl.NumberFormat("ru", {
          style: "decimal",
        }).format(number);
  };

  return (
    <DefaultTable nested={false} id="DynamicsCurrencyTable">
      <TableHead
        style={{
          background: "#F3F5F7",
          height: "46px",
        }}
      >
        <NestedRow>
          <TableCell
            style={{
              paddingLeft: "4em",
              textAlign: "left",
              border: "none",
              fontWeight: 600,
              fontSize: "13px",
              lineHeight: "16px",
              letterSpacing: "0.0075em",
              color: "#54606F",
            }}
          ></TableCell>
          <TextCell>{curency}</TextCell>
          {dates.map((el) => {
            return (
              <TextCell
                key={el}
                additionStyles={{ minWidth: "130px" }}
                importantTextAlign="right"
              >
                {el.columnName}
              </TextCell>
            );
          })}
        </NestedRow>
      </TableHead>
      <TableBody>
        {data.map((el) => {
          if (Array.isArray(el.subRows) && el.subRows.length) {
            return (
              <CollapseGroupRows
                level={2}
                clickableCellText={el.titleRow}
                otherCellsTexts={[""].concat(
                  el.rowValues.map((v) => formatCurrency(v.value))
                )}
              >
                {el.subRows.map((row) => {
                  return (
                    <NestedRow>
                      <FirstCell level={2}>{row.titleRow}</FirstCell>
                      <TextCell></TextCell>
                      {row.rowValues.map((cell) => {
                        return (
                          <TextCell>{formatCurrency(cell.value)}</TextCell>
                        );
                      })}
                    </NestedRow>
                  );
                })}
              </CollapseGroupRows>
            );
          }
          return (
            <>
              <NestedRow key={"1"}>
                <FirstCell level={2}>{el.titleRow}</FirstCell>
                <TextCell></TextCell>
                {el.rowValues.map((e) => {
                  return (
                    <TableCell align="right">
                      {formatCurrency(e.value)}
                    </TableCell>
                  );
                })}
              </NestedRow>
            </>
          );
        })}
      </TableBody>
    </DefaultTable>
  );
};

export default AnalysisTable;
