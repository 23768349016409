import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "@Localization/i18n";
import { Grid, Typography } from "@material-ui/core";
import NoData from "@View/style/components/NoData";
import DqTable from "./DqTable";
import DqCharts from "./DqCharts";
import AppAPI from "@API/index";
import { UIToggle, UIToggleWithLabel } from "@View/components/common/UIToggle";

export default function DQTab() {
  const [row, setRow] = useState();
  const [dashboard, setDashboard] = useState();

  const { id: finDataRequestId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setRow(
        await (i18n.language === "ru"
          ? AppAPI.request({
              url: `/api/FinDataRequest/${finDataRequestId}/dataquality/`,
            })
          : AppAPI.request({
              url: `/api/FinDataRequest/${finDataRequestId}/dataquality/en`,
            }))
      );
    })();
  }, [finDataRequestId, i18n.language]);

  if (!row)
    return (
      <div
        style={{
          marginTop: "200px",
          height: "100px",
          transform: "translateX(-20px)",
        }}
      >
        <NoData />
      </div>
    );

  return (
    <Grid>
      <Typography
        variant={"h5"}
        style={{ paddingTop: "0px", marginBottom: "15px" }}
      >
        {t("finDataRequest.tabs.DQ.overallDQScore")}: {row.overallDQScore}
      </Typography>
      <Grid
        style={{
          background: "#FFFFFF",
          borderRadius: "10px 10px 0px 0px",
          padding: "15px 20px",
        }}
      >
        <UIToggleWithLabel
          sx={{ fontSize: "14px" }}
          control={
            <UIToggle
              checked={dashboard}
              onChange={(event) => setDashboard(event.target.checked)}
            />
          }
          label={t("ShowDashboard")}
          labelPlacement="start"
        />
      </Grid>
      {i18n.language === "en" && (
        <Typography style={{ marginTop: "20px" }}>This is stub data</Typography>
      )}
      {dashboard ? <DqCharts row={row} /> : <DqTable row={row} />}
    </Grid>
  );
}
