import {
  GET_CODE_REQUEST,
  GET_CODE_SUCCESS,
} from "../actionTypes";
import AppAPI from "@API/index";

const urlCode = process.env.REACT_APP_BACKEND_URL + "/api/code";

//fetch one code
export const getCodeRequest = () => {
  return {
    type: GET_CODE_REQUEST,
  };
};

const getCodeSuccess = (code) => {
  return {
    type: GET_CODE_SUCCESS,
    payload: code,
  };
};

export const getCode = (id) => {
  return (dispatch) => {
    dispatch(getCodeRequest());
    AppAPI.request({
      url: `${urlCode}/${id}`,
      method: "get",
      errorText: 'snackbar.getCodeError',
      suppressError: true
    }).then((data) => {
      dispatch(getCodeSuccess(data));
    })
  };
};
