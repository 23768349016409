import { getRedirectUrl } from "@View/pages/AdminPO/SigninPage";
import React from "react";
import { Route, Redirect } from "react-router-dom";

const RouteController = ({
  component: Component,
  canProceed,
  ...routeProps
}) => {
  return (
    <Route
      {...routeProps}
      render={
        props => (
				  canProceed
            ? <Component {...props} />
            : <Redirect to={getRedirectUrl()} />
        )
      }
    />
  );
};

export default RouteController;
