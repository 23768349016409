import { TableContainer } from "@material-ui/core";

export function PaddingContainer({ top = false, bottom = false, left = false, right = false, children }: PaddingContainerProps): JSX.Element {
    return (
        <TableContainer style={{
            paddingTop: top ? '1em' : '0',
            paddingBottom: bottom ? '1em' : '0',
            paddingLeft: left ? '1em' : '0',
            paddingRight: right ? '1em' : '0'
        }}>
            {children}
        </TableContainer>
    )
}

type PaddingContainerProps = {
    top?: boolean,
    bottom?: boolean,
    left?: boolean,
    right?: boolean,
    children: JSX.Element[] | JSX.Element
}