import { useTranslation } from "react-i18next";
import { useStyles } from "@View/style/pages/detailsStyle";
import TabContext from "@material-ui/lab/TabContext";
import { Grid, Paper } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import Tabs from "@material-ui/lab/TabList";
import RequestsTable from "./tables/RequestsTable";
import UsersTable from "./tables/UsersTable";
import useAppTab from "@Utils/useAppTab";
import { useTabStyle } from "@View/style/components/tabStyle";

const tabs = ["Requests", "Users"];

const ClientFullTable = ({ client }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useAppTab({
    defaultTab: "Requests",
    tabList: tabs,
  });
  const tabClasses = useTabStyle();

  const classes = useStyles();

  return (
    <TabContext value={tab}>
      <Grid className={classes.fullTable}>
        <Paper>
          <Tabs onChange={setTab} indicatorColor="primary" textColor="primary">
            {tabs.map((tab, index) => {
              return (
                <Tab
                  classes={tabClasses}
                  label={t(tab)}
                  value={tab}
                  key={index}
                />
              );
            })}
          </Tabs>
        </Paper>
        <TabPanel value="Requests">
          <RequestsTable customerId={client.customerId} clientId={client.id} />
        </TabPanel>
        <TabPanel value="Users" style={{ position: "relative" }}>
          <UsersTable clientId={client.id} />
        </TabPanel>
      </Grid>
    </TabContext>
  );
};

export default ClientFullTable;
