export default function IconSearch() {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4 17.4L13.9714 13.9714M16.0286 9.51429C16.0286 13.112 13.112 16.0286 9.51429 16.0286C5.91654 16.0286 3 13.112 3 9.51429C3 5.91654 5.91654 3 9.51429 3C13.112 3 16.0286 5.91654 16.0286 9.51429Z"
        stroke="currentColor"
        strokeWidth="1.8"
      />
    </svg>
  );
}
