import axios, { AxiosRequestConfig } from "axios";
import { store } from "@State/store";
import { Snackbar } from "@State/actions/actionCreators";
import { AppActions } from "@State/reducers/getReducers/appReducer";
import { logout } from "@State/effects/security/logout";
import { getRedirectUrl } from "@View/pages/AdminPO/SigninPage";
import { getRandomKey } from "@Utils/math";
import { getAuthToken } from "@Security/stateHelpers";

const DEFAULT_ERROR_MESSAGE =
  "There was an error. Please try later or contact tech support.";

type TCreateAppAPI = {
  baseURL?: string;
};

export type TRequestConfig = AxiosRequestConfig & {
  suppressError?: boolean;
  successText?: string;
  errorText?: string;
  errorIfNotOk?: boolean;
  _fileSettings?: object;
};

const createAppAPI = (configs: TCreateAppAPI) => {
  const api = axios.create({
    baseURL: configs.baseURL,
  });

  api.interceptors.response.use(undefined, async (error) => {
    if (
      error.config.url === "/api/Auth/Login" ||
      error.config.url === "/api/Customer/selfRegistration"
    ) {
      return;
    }
    const status = error.response?.status;
    if (status === 401) {
      store.dispatch(Snackbar({ message: "snackbar.notAuth", open: true }));
      await logout()(store.dispatch);
      window.location.reload();
    } else if (status === 403) {
      store.dispatch(
        Snackbar({ message: "snackbar.notPermitted", open: true })
      );
      window.location.replace(getRedirectUrl());
    }
  });

  return {
    async request<T>({
      suppressError = false,
      successText = "",
      errorText = "",
      errorIfNotOk = true,
      method = "get",
      _fileSettings = {},
      ...config
    }: TRequestConfig): Promise<T> {
      let response = null;
      const LoadingKey = getRandomKey();
      store.dispatch(AppActions.startLoading(LoadingKey));

      try {
        response = await api.request<T>({
          method,
          ...config,
          headers: this.computeHeaders(config.headers || {}, config.data),
        });
        if (errorIfNotOk && !response) {
          throw new Error("API not OK response");
        }

        if (successText) {
          store.dispatch(Snackbar({ message: successText, open: true }));
          store.dispatch(AppActions.setSuccess(successText));
        } else if (method.toLowerCase() !== "get") {
          store.dispatch(Snackbar({ message: "snackbar.success", open: true }));
          store.dispatch(AppActions.setSuccess("snackbar.success"));
        }

        store.dispatch(AppActions.endLoading(LoadingKey));

        if (config.responseType === "blob" && "name" in _fileSettings) {
          const contentDisposition = response.headers["content-disposition"];
          const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
          // @ts-ignore
          _fileSettings.name = decodeURIComponent(
            utf8FilenameRegex.exec(contentDisposition ?? "")?.[1] ??
              "report.xlsx"
          );
        }
      } catch (e) {
        store.dispatch(AppActions.endLoading(LoadingKey));

        console.warn(`<<< API error for request ${config.url} >>> `);
        console.error(e);
        store.dispatch(AppActions.setError(errorText || DEFAULT_ERROR_MESSAGE));
        if (errorText) {
          store.dispatch(Snackbar({ message: errorText, open: true }));
        } else {
          store.dispatch(Snackbar({ message: "snackbar.error", open: true }));
        }
        if (!suppressError) {
          throw e;
        }
      }

      return response?.data as T;
    },

    computeHeaders(headers: object, data: any) {
      const token = getAuthToken();
      return {
        ...headers,
        ...(token ? { Authorization: `bearer ${token}` } : {}),
        ...(data instanceof FormData
          ? { "Content-Type": "multipart/form-data" }
          : {}),
      };
    },
  };
};

const AppAPI = createAppAPI({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

export default AppAPI;
