import { makeStyles } from "@material-ui/core";
import ModalWrapper from "@View/components/ModalWrapper";
import React from "react";
import { useTranslation } from "react-i18next";
import IconBigPlus from "@View/components/icons/IconBigPlus";
import IconEdit from "@View/components/icons/IconEdit";
import { downloadFile } from "@Dao/api";

const useStyles = makeStyles((_theme) => ({
  cover: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "10px",
  },
  item: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    border: "1px solid #F3F5F7",
    background: "rgba(243, 245, 247, 0.5)",
    color: "rgba(26, 30, 36, 1)",
    fontSize: "15px",
    fontWeight: "600",
    lineHeight: "20px",
    textAlign: "center",
    padding: "60px 8px",
    cursor: "pointer",
    "&:hover": {
      color: "rgba(111, 164, 242, 1)",
      borderColor: "rgba(111, 164, 242, 1)",
      background: "rgba(255, 255, 255, 1)",
    },
  },
  icon: {
    width: "36px",
    marginBottom: "10px",
  },
}));

const CreditReportModal = ({ open, handleOpen, handleClose, client }) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const loadReport = async (withFA) => {
    await downloadFile(
      `/api/Client/${client.id}/avers-credit-opinion?type=${
        withFA ? "full" : "change"
      }`
    );
    handleClose();
  };

  return (
    <ModalWrapper open={open} handleOpen={handleOpen} handleClose={handleClose}>
      <div style={{ height: 20 }}></div>
      <div className={classes.cover}>
        <div className={classes.item} onClick={() => loadReport(true)}>
          <div>
            <div>
              <IconBigPlus className={classes.icon} />
            </div>
            <div>{t("CreaditOpinion.withFA")}</div>
          </div>
        </div>
        <div className={classes.item} onClick={() => loadReport(false)}>
          <div>
            <div>
              <IconEdit className={classes.icon} />
            </div>
            <div>{t("CreaditOpinion.noFA")}</div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default CreditReportModal;
