import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next';



const ModalTittle = (props) => {

    const { t } = useTranslation();
    const style = {
      fontSize: props.fontSize ? props.fontSize : '20px',
      fontWeight: 600,
      lineHeight: '24px',
      color: '#1A1E24',
      textAlign: 'left',
      marginBottom: '30px',
      whiteSpace: 'noraml'
  }

  return (
    <Typography style={style} variant={'h2'}>{t(props.children) + ' '} {props.children2 ? props.children2 : ''}</Typography>
  )
}

export default ModalTittle