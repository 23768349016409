import {
  GET_CUSTOMER_FAILURE,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_SUCCESS,
  SET_CUSTOMER_GROUPS,
} from "../../actions/actionTypes";

const initialState = {
  loading: false,
  customer: {},
  groups: [],
  error: "",
  isLoaded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_REQUEST:
      return {
        ...state,
        customer: {},
        loading: true,
      };
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customer: { ...action.payload },
        error: "",
        isLoaded: true,
      };
    case SET_CUSTOMER_GROUPS:
      return {
        ...state,
        groups: [...action.payload],
      };
    case GET_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        customer: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
