import { forwardRef } from "react";

function IconEdit({ className = "" }, ref) {
  return (
    <svg
      ref={ref}
      className={className}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 3H13.5C6 3 3 6 3 13.5V22.5C3 30 6 33 13.5 33H22.5C30 33 33 30 33 22.5V19.5"
        stroke="currentColor"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.0601 4.52989L12.2401 16.3499C11.7901 16.7999 11.3401 17.6849 11.2501 18.3299L10.6051 22.8449C10.3651 24.4799 11.5201 25.6199 13.1551 25.3949L17.6701 24.7499C18.3001 24.6599 19.1851 24.2099 19.6501 23.7599L31.4701 11.9399C33.5101 9.89989 34.4701 7.52989 31.4701 4.52989C28.4701 1.52989 26.1001 2.48989 24.0601 4.52989Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.365 6.2251C23.37 9.8101 26.175 12.6151 29.775 13.6351"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default forwardRef(IconEdit);
