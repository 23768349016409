import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationRU from "./locales/ru/translation.json";
import { getAppLang } from "@Security/stateHelpers";

i18n.use(initReactI18next).init({
  lng: getAppLang(),
  resources: {
    ru: {
      translation: translationRU,
    },
    en: {
      translation: translationEN,
    },
  },
});

export default i18n;
