import ModalTittle from '@View/components/ModalTittle'
import ModalWrapper from '@View/components/ModalWrapper'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { editCustomer, getCustomer } from "@State/index";
import DefaultSelect from '@View/components/formInputs/DefaultSelect'
import DefaultInput from '@View/components/formInputs/DefaultInput'
import FormDateRow from '@View/components/formRows/FormDateRow'
import ButtonSave from '@View/components/buttons/ButtonSave'

const EditCustomerPage = (props) => {

  const { i18n } = useTranslation();
  const dispatch = useDispatch()
  const history = useHistory();

  const customer = useSelector(state => state.customer.customer)
  let addOneYear = (val) => {
    if(customer !== undefined) {
    let split = val.split('-')
    let addYEar = Number(split[0]) + 1
    split[0] = `${addYEar}`
    let join = split.join()
    return join
    }

    
  }
  const [newData, setNewData] = useState();

    useEffect(() => {
      if(Object.keys(customer).length > 0 ) {
      setNewData(
        { 
          ServerUrl: customer.ServerUrl,
          createdAt: customer.createdAt,
          defaultLanguage: customer.defaultLanguage,
          email: customer.email,
          externalId: customer.externalId,
          id: customer.id,
          name: customer.name,
          requestsLeft: customer.requestsLeft,
          subscriptionUntil: addOneYear(customer.subscriptionUntil),
          totalRequests: customer.totalRequests,
          type: customer.type,
          updatedAt: customer.updatedAt,
          // ...customer 
        }
      )
        }
    }, [customer])
  
  const status = [{ value: "Active" }, { value: "Disable" }];
  const types = [{ value: "Bank" }, { value: "Company" }];

  const handleChange = (value, label) => {
    setNewData({ ...newData, [label]: value });

    if (i18n.language === "ru" && label === "subscriptionUntil") {
      let sVal = value.split('.')
      let gDate = new Date(sVal[2], sVal[1] - 1, sVal[0])
      gDate = gDate.toISOString()
      setNewData({ ...newData, [label]: gDate });
    }

  };


  useEffect(() => {
    if(window.location.href.indexOf('customer/details') === - 1 && props.open === true) {
      dispatch(getCustomer(props.customerId));
    }
  }, [props.customerId]);

  useEffect(() => {
    setNewData({ ...customer })
  }, [customer])


  const handleClick = (event) => {
    event.preventDefault();
    props.handleClose()
    dispatch(editCustomer(newData, history));
  };

  if(newData === undefined || newData === null) return <></>
  return (
    <ModalWrapper open={props.open} handleOpen={props.handleOpen} handleClose={props.handleClose} btnTxt={'Save'}>
      <ModalTittle children2={''}>h1.Edit Customer</ModalTittle>
      <form onSubmit={(event) => handleClick(event)}>
        <DefaultSelect
          value={`${newData.status}`}
          label="inputs.Status"
          dropdowns={status}
          onChange={(value) => handleChange(value, "status")}
        />
        <DefaultInput
          label="Id" value={customer.id}
          onChange={() => null}
        />
        <DefaultInput
          value={newData.externalId}
          label="inputs.External Id"
          onChange={(value) => handleChange(value, "externalId")}
        />
        <DefaultInput
          value={newData.requestsLeft}
          label="Total requests"
          onChange={(value) => handleChange(value, "requestsLeft")}
        />
        <DefaultInput
          value={newData.email}
          label="inputs.Email"
          onChange={(value) => handleChange(value, "email")}
        />
        <DefaultInput
          value={newData.name}
          label="inputs.CompanyName"
          onChange={(event) => handleChange(event, "name")}
        />
        <DefaultInput
          value={newData.ServerUrl}
          label="inputs.Server url"
          onChange={(value) => handleChange(value, "ServerUrl")}
        />
        <FormDateRow
          dates
          value={newData.subscriptionUntil}
          label="inputs.Subscription Until"
          onChange={(value) => handleChange(value, "subscriptionUntil")}
        />
        <DefaultSelect
          value={`${newData.type}`}
          label="Type"
          dropdowns={types}
          onChange={(value) => handleChange(value, "type")}
        />
        <ButtonSave>buttons.Save</ButtonSave>
      </form>
    </ModalWrapper>
  )
}

export default EditCustomerPage