export const ClientRoles = [
  "Borrower",
  "Guarantor",
  "Pledger",
  "Surety",
  "Counterparty",
  "OtherRole",
];

export const ClientRolesForSelect = ClientRoles.map((i) => ({
  name: `ClientRoles.${i}`,
  value: i,
}));
