import React, { useState } from "react";
import { TableRow } from "@material-ui/core";
import { CollapseHeadClickableCell } from "../cells/CollapseHeadClickableCell";
import { FirstCell } from "../cells/FirstCell";
import { TextCell } from "../cells/TextCell";

export function CollapseContainerRow({
  clickableCellText,
  otherCellsTexts = [],
  level = 0,
  children,
  isHorAnEn,
  k,
  EditRow,
  isEdit,
}: CollapseContainerRowProps) {
  const [isCollapsed, setCollapsed] = useState(true);

  return (
    <React.Fragment>
      <TableRow
        style={{ height: "50px" }}
        className={level > 0 ? "NestedRow" : undefined}
      >
        <CollapseHeadClickableCell
          type="Container"
          text={clickableCellText}
          isCollapsed={isCollapsed}
          collapseFn={() => setCollapsed(!isCollapsed)}
          level={level}
        />
        {EditRow ? (
          <EditRow isEdit={isEdit} level={2} k={k} finData={otherCellsTexts} />
        ) : (
          otherCellsTexts.map((text, index, arr) => {
            let color = "";
            if (index + 1 === arr.length && isHorAnEn) {
              if (parseInt(text) > 0) {
                color = "#00dd00";
              } else if (parseInt(text) < 0) {
                color = "#ff0000";
              } else {
                color = "#000";
              }
            }
            return (
              <TextCell color={color} key={index}>
                {text}
              </TextCell>
            );
          })
        )}
      </TableRow>
      {!isCollapsed && (
        <React.Fragment>
          <TableRow className="CollapseContainerRow">
            <FirstCell level={level + 1} colSpan={otherCellsTexts.length + 1}>
              <div
                className="CollapseContainer"
                style={{
                  maxWidth: "85vw",
                  position: "sticky",
                  left: `calc(${level + 1}em + 6px)`,
                }}
              >
                {children}
              </div>
            </FirstCell>
          </TableRow>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

type CollapseContainerRowProps = {
  clickableCellText: string;
  otherCellsTexts?: string[];
  level?: number;
  children: string | JSX.Element;
  isHorAnEn: any;
  k: any;
  EditRow: any;
  isEdit: any;
};
