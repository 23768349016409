import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { useTranslation } from "react-i18next";
import ButtonDefault from "./buttons/ButtonDefault";
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

  },
  paper: {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '10px',
    display: 'flex',
    flexFlow: 'column',
    width: '300px',
    position: 'relative'
  },
  buttons: {
    display: "flex",
    flexFlow: 'column'
  },
}));

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export const DeleteModal = ({ openModal, handleCloseModal, handleDelete }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Modal
      onClose={handleCloseModal}
      className={classes.modal + ' ' + 'deleteModalClass'}
      open={openModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <Fade in={openModal}>
        <div className={classes.paper}>
          <div onClick={handleCloseModal} style={{ position: 'absolute', right: '30px', top: '30px', cursor: 'pointer' }}>
            <CloseIcon style={{ color: '#000000' }} />
          </div>
          <ErrorOutlineIcon style={{ fontSize: '20px', color: '#A4241C', marginBottom: '32px' }} />
          <h2 style={{ fontWeight: 600, fontSize: '20px', lineHeight: '24px', color: '#1A1E24', marginBottom: '10px', marginTop: '0px' }}>{t("deleteModal.title")}</h2>
          <p style={{ marginBottom: '0px', marginTop: '0px', fontWeight: 500, fontSize: '13px', lineHeight: '16px', letterSpacing: '0.0075em', color: '#54606F', }}>{t("deleteModal.text")}</p>
          <div style={{ width: '100%', marginTop: '30px' }} className={classes.buttons}>
            <div style={{cursor: 'pointer', background: '#A4241C', borderRadius: '8px', padding: '15px 20px', textAlign: 'center', fontWeight: 600, fontSize: '14px', lineHeight: '17px', color: '#F3F5F7', marginBottom: '5px'}} onClick={handleDelete}>{t("deleteModal.button2")}</div>
            <div style={{cursor: 'pointer', background: '#FFFFFF', border: '1px solid #E1E6F1', borderRadius: '8px', padding: '15px 20px', fontWeight: 600, fontSize: '14px', lineHeight: '17px', color: '#54606F', textAlign: 'center' }} onClick={handleCloseModal}>{t("deleteModal.button1")}</div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
DeleteModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
