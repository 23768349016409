import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LinkButton as NewLinkButton } from "@View/common/button/LinkButton"

/**
 * @deprecated @see{@link NewLinkButton}
 */
const LinkButton = ({ path, textLink }) => {
  const { t } = useTranslation();
  return (
    <Link style={{ textDecoration: "none", color: 'unset'}} to={path}>
      {t(textLink)}
    </Link>
  );
};

LinkButton.propTypes = {
  textLink: PropTypes.string.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default LinkButton;
