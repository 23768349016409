import React from "react";
import Grid from "@material-ui/core/Grid";
import HeaderMain from "@View/components/headers/HeaderMain";
import CookieModal from "./CookieModal";
import Loader from "@View/components/loader/Loader";

const DefaultLayout = ({ children }) => {
  return (
    <>
      <Loader />
      <HeaderMain />
      <Grid
        container
        direction="row"
        justifycontent="center"
        alignItems="center"
        className='MuiToolbar-gutters'
        style={{marginTop: '64px', paddingTop: '20px', paddingBottom: '0px',}}
      >
        <div style={{ flexGrow: 1 }}></div>
      </Grid>
      <Grid container>
        {children}
      </Grid>
      <CookieModal />
    </>
  );
};

export default DefaultLayout;
