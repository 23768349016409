import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { useSpring, animated } from "react-spring/web.cjs";
import { useTranslation } from "react-i18next";
import ButtonDefault from "@View/components/buttons/ButtonDefault";
import { FormGroup, TextField } from "@material-ui/core";
import AppAPI from "@API/index";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

const guidRegex =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i;

export const ApiKeyModal = ({ customer, openModal, handleCloseModal }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [validationError, setValidationError] = useState(false);
  const onInputValueChange = (e) => {
    const { value } = e.target;
    const isValidGuid = !guidRegex.test(String(value));
    setInputValue(value);
    setValidationError(isValidGuid);
  };
  const handleSubmit = () => {
    AppAPI.request({
      method: "post",
      url: "/api/customer/enterCode",
      data: {
        CustomerId: customer.id,
        Code: inputValue,
      },
    }).then(() => {
      window.location.reload();
    });
  };
  return (
    <Modal
      className={classes.modal}
      open={openModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <Fade in={openModal}>
        <div className={classes.paper}>
          <h2 id="spring-modal-title">{t("Enter code for requests")}</h2>
          <FormGroup>
            <TextField
              style={{ marginBottom: "20px" }}
              id="standard-basic"
              label={t("Code")}
              value={inputValue}
              error={validationError}
              onChange={onInputValueChange}
            />
            <div className={classes.buttons}>
              <ButtonDefault onClick={handleSubmit} disabled={validationError}>
                {t("buttons.Ok")}
              </ButtonDefault>
              <ButtonDefault onClick={handleCloseModal}>
                {t("buttons.Cancel")}
              </ButtonDefault>
            </div>
          </FormGroup>
        </div>
      </Fade>
    </Modal>
  );
};

ApiKeyModal.propTypes = {
  customer: PropTypes.object,
  openModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
};
