import { Client } from "@Dao/responseTypes";
import { ClientRequest, ClientSuccess, ClientFailure, Logout } from "@State/actions/actionCreators";
import { Action } from "redux";

const InitialState: ClientState = {
    data: null,
    loading: false,
    error: false,
}

export function clientReducer(state = InitialState, action: Action): ClientState {
    if (ClientRequest.match(action)) {
        return {
            data: null,
            loading: true,
            error: false
        }
    }
    if (ClientSuccess.match(action)) {
        return {
            data: action.payload,
            loading: true,
            error: false
        }
    }
    if (ClientFailure.match(action)) {
        return {
            data: null,
            loading: false,
            error: true
        }
    }
    if (Logout.match(action)) {
        return {
            data: null,
            loading: false,
            error: false
        } 
    }
    return state
}

export type ClientState = {
    data: Client | null,
    loading: boolean,
    error: boolean
}
