import { GET_CLIENT_REQUEST, GET_CLIENT_SUCCESS } from "../actionTypes";
import AppAPI from "@API/index";

//fetch one client
export const getClientRequest = () => {
  return {
    type: GET_CLIENT_REQUEST,
  };
};

const getClientSuccess = (client) => {
  return {
    type: GET_CLIENT_SUCCESS,
    payload: client,
  };
};

export const getClient = (id) => {
  return (dispatch) => {
    dispatch(getClientRequest());
    AppAPI.request({
      method: "get",
      url: `/api/Client/${id}`,
    }).then((data) => {
      let client = {
        users: [],
        finDataRequests: [],
        id: data.id,
        name: data.name,
        externalId: data.externalId,
        createdAt: data.createdAt,
        type: data.type,
        email: data.email,
        status: data.status,
        updatedAt: data.updatedAt,
        scoringResult: data.scoringResult,
        apiKey: data.apiKey,
        fileId: data.oneSIntegration?.id,
        customerId: data.customerId,
        customerName: data.customerName,
        defaultLanguage: data.defaultLanguage,
        role: data.role,
        customerGroupId: data.customerGroup?.id,
      };
      data.users?.forEach((user, index) => {
        client.users[index] = {};
        client.users[index].id = user.id;
        client.users[index].status = user.status;
        client.users[index].name = user.userName;
        client.users[index].createdAt = user.createdAt;
      });
      data.finDataRequests?.forEach((request, index) => {
        client.finDataRequests[index] = {};
        client.finDataRequests[index].id = request.id;
        client.finDataRequests[index].customerName = request?.customerName;
        client.finDataRequests[index].updatedAt = request.updatedAt;
        client.finDataRequests[index].createdAt = request.createdAt;
        client.finDataRequests[index].status = request.status;
        client.finDataRequests[index].finDataFile = request.finDataFile;
        client.finDataRequests[index].transferType = request.transferType;
        client.finDataRequests[index].startDate = request.startDate;
        client.finDataRequests[index].endDate = request.endDate;
      });
      dispatch(getClientSuccess(client));
    });
  };
};
