import { FormLabel } from "@material-ui/core";
import { downloadSheetWithNotification } from "@State/effects";
import { Checkbox } from "@View/common/form/control/Checkbox";
import ButtonBB from "@View/components/buttons/ButtonBB";
import NoData from "@View/style/components/NoData";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FinStatementEnTable from "./FinStatementEnTable";
import AppAPI from "@API/index";

const FinStatemantsEn = () => {
  const [horizontal, setHorizontal] = useState(false);
  const { t } = useTranslation();
  const [row, setRow] = useState();

  const { id: finDataRequestId } = useParams();

  const exportToClick = () => {
    downloadSheetWithNotification(finDataRequestId);
  };

  useEffect(() => {
    (async () => {
      setRow(
        await AppAPI.request({
          url: `/api/FinDataRequest/${finDataRequestId}/financialStatements/en`,
        })
      );
    })();
  }, [finDataRequestId]);

  if (!row) {
    return (
      <div style={{ height: "100px", transform: "translateX(-20px)" }}>
        <NoData />
      </div>
    );
  }

  let row2 = JSON.parse(JSON.stringify(getFiniteValue(row)));

  if (!row2)
    return (
      <div
        style={{
          marginTop: "200px",
          height: "100px",
          transform: "translateX(-20px)",
        }}
      >
        <NoData />
      </div>
    );

  return (
    <>
      <div style={{ display: "flex" }}>
        {row.horizontalAnalyse.assets.total ? (
          <div style={{ display: "inline-flex", flexDirection: "column" }}>
            <FormLabel
              style={{
                color: "#8BA0B6",
                fontSize: "14px",
                marginBottom: "14px",
                lineHeight: "20px",
                fontWeight: 500,
              }}
            >
              {t("Analysis types")}
            </FormLabel>
            <div
              style={{ maxWidth: "fit-content" }}
              className="Checkbox__wrapper"
            >
              <Checkbox
                label={t("Horizontal analysis")}
                value={horizontal}
                onChange={setHorizontal}
              />
            </div>
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            background: "#fff",
            borderRadius: "10px 10px 0px 0px",
            padding: "15px 20px",
            width: "100%",
          }}
        >
          <div style={{ marginLeft: "15px" }}>
            <ButtonBB disabled={false} height={"36px"} onClick={exportToClick}>
              {t("buttons.ExportToExcel")}
            </ButtonBB>
          </div>
        </div>
      </div>

      <FinStatementEnTable row={row2} isHorAnEn={horizontal} />
    </>
  );
};

function getFiniteValue(obj) {
  getProp(obj);

  function getProp(o) {
    for (var prop in o) {
      if (typeof o[prop] === "object") {
        getProp(o[prop]);
      } else {
        if (prop.indexOf("VA") !== -1) {
          delete o[prop];
        }
      }
    }
  }

  return obj;
}

export default FinStatemantsEn;
