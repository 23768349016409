import { Typography } from '@material-ui/core'
import NoDataPic from '@View/components/svgComponents/NoDataPic'
import React from 'react'
import {useTranslation} from "react-i18next";

const NoData = (props) => {
  const { t } = useTranslation();

  return (
    <div style={{ position: 'absolute', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', ...(props.style || {}) }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <NoDataPic style={{ padding: '20px' }} />
            <Typography variant={'body1'} style={{ color: '#000', marginTop: '15px', fontSize: '20px', textAlign: 'center' }}>
              { t("NoData") }
            </Typography>
        </div>
    </div>
  )
}

export default NoData

export const NoDataAdaptive = () => {
  const { t } = useTranslation();

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <NoDataPic style={{ padding: '20px' }} />
        <Typography variant={'body1'} style={{ color: '#000', marginTop: '15px', fontSize: '20px', textAlign: 'center' }}>
          { t("NoData") }
        </Typography>
      </div>
    </div>
  )
}
