import { combineReducers } from "redux";
//get
import getAllCustomersReducer from "./getReducers/getAllCustomersReducer";
import getCustomerReducer from "./getReducers/getCustomerReducer";
import getClientReducer from "./getReducers/getClientReducer";
import getUserReducer from "./getReducers/getUserReducer";
import getFinDataReducer from "./getReducers/getFinDataReducer";
import getFinDataRequestFilters from "./getReducers/getFinDataFiltersReducer";
import snackbar from "./getReducers/snackbar";
import editTableReducer from "./getReducers/editTableReducer";
import getAllCodesReducer from "./getReducers/getAllCodesReducer";
import getCodeReducer from "./getReducers/getCodeReducer";
import { customerReducer } from "./getReducers/customerReducer";
import { auth } from "./getReducers/auth";
import { clientReducer } from "./getReducers/clientReducer";
import { getFinDataPageDataReducer } from "./getReducers/getFinDataPageDataReducer";
import headerReducer from "@View/components/headers/headerReducer";
import appReducer from "./getReducers/appReducer";

const rootReducer = combineReducers({
  auth: auth,
  currentCustomer: customerReducer,
  currentClient: clientReducer,
  customers: getAllCustomersReducer,
  customer: getCustomerReducer,
  client: getClientReducer,
  user: getUserReducer,
  finDataRequest: combineReducers({
    general: getFinDataReducer,
    pageData: getFinDataPageDataReducer,
    filters: getFinDataRequestFilters,
  }),
  codes: getAllCodesReducer,
  code: getCodeReducer,
  snackbar,
  editTableReducer,
  headerReducer,
  app: appReducer
});

export default rootReducer;
