import { useTranslation } from "react-i18next";
import { TableBody, TableCell, TableHead } from "@material-ui/core";
import {
  DefaultTable,
  FirstCell,
  NestedRow,
  TextCell,
} from "@Components/table";
import { NoDataAdaptive } from "@View/style/components/NoData";
import React from "react";

const trsMonitoringCellNameStyle = {
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "16px",
  letterSpacing: "0.0075em",
  color: "#54606F",
};

export const TransactionalMonitoring = ({ trsMonitoring }) => {
  const { t } = useTranslation();

  return (
    <DefaultTable nested={false} id="DynamicsCurrencyTable">
      <TableHead
        style={{
          background: "#F3F5F7",
          height: "46px",
        }}
      >
        <NestedRow>
          <TableCell
            style={{
              paddingLeft: "1.8em",
              textAlign: "left",
              ...trsMonitoringCellNameStyle,
            }}
          >
            {t(
              "finDataRequest.tabs.TransactionAnalysis.tables.TransactionMonitoring.tableTitle"
            )}
          </TableCell>
          <TextCell></TextCell>
          <TextCell></TextCell>
        </NestedRow>
        <NestedRow>
          <TableCell
            style={{
              paddingLeft: "2.30em",
              paddingTop: "15px",
              paddingBottom: "10px",
              textAlign: "left",
              ...trsMonitoringCellNameStyle,
              fontSize: "13px",
            }}
          >
            {t(
              "finDataRequest.tabs.TransactionAnalysis.tables.TransactionMonitoring.legend.param"
            )}
          </TableCell>
          <th
            style={{
              ...trsMonitoringCellNameStyle,
              fontSize: "13px",
              textAlign: "right",
            }}
          >
            {t(
              "finDataRequest.tabs.TransactionAnalysis.tables.TransactionMonitoring.legend.value"
            )}
          </th>
          <TextCell>
            {t(
              "finDataRequest.tabs.TransactionAnalysis.tables.TransactionMonitoring.legend.risk"
            )}
          </TextCell>
        </NestedRow>
      </TableHead>
      <TableBody>
        {trsMonitoring !== null ? (
          trsMonitoring.rows.map((el) => (
            <NestedRow key={"1"}>
              <FirstCell level={2}>{el.titleRow}</FirstCell>
              {el.rowValues.length ? (
                <>
                  <TableCell style={{ paddingRight: "20px" }} align="right">
                    {el.rowValues[0].value}
                  </TableCell>
                  <TableCell
                    style={{
                      background: el.rowValues[0].color,
                      width: "40px",
                    }}
                    align="right"
                  ></TableCell>
                </>
              ) : null}
            </NestedRow>
          ))
        ) : (
          <tr>
            <td colSpan={3}>
              <div style={{ height: "200px" }}>
                <NoDataAdaptive />
              </div>
            </td>
          </tr>
        )}
      </TableBody>
    </DefaultTable>
  );
};
