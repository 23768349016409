import { getAllCustomers } from "../actionsGet/actionGetAllCustomers";
import { getCustomer } from "@State/index";
import AppAPI from "@API/index";

export const editCustomer = (body, history) => {
  const trimmedBody = {
    id: body.id,
    name: body.name.trim(),
    externalId: body.externalId.trim(),
    type: body.type,
    email: body.email.trim(),
    status: body.status.trim(),
    subscriptionUntil: body.subscriptionUntil,
    ServerUrl: body.ServerUrl,
  };

  return (dispatch) => {
    AppAPI.request({
      method: "put",
      url: `/api/Customer`,
      data: trimmedBody,
    })
      .then(() => {
        dispatch(getCustomer(body.id))
        dispatch(getAllCustomers())
      })
  };
};
