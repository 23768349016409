import { downloadFile } from "@Dao/api";
import {
  FormControlLabel,
  IconButton,
  makeStyles,
  Switch,
  TextField,
} from "@material-ui/core";
import ButtonBB from "@View/components/buttons/ButtonBB";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { styled } from "@mui/material/styles";
import AppAPI from "@API/index";
import NoData from "@View/style/components/NoData";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { UIToggle, UIToggleWithLabel } from "@View/components/common/UIToggle";
import { ColorButton } from "@Components/button/ColorButton";
import { useCTAButtonStyle } from "@View/style/components/tooltipStyles";

const useStylesForEditRow = makeStyles(() => ({
  input: {
    background: "inherit",
    paddingTop: "0px !important",
    "& .MuiFormControl-root ": {
      whiteSpace: "normal",
      height: "100%",
    },
    "& .MuiFormControl-root .MuiInputBase-root": {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "inherit",
    },
    "& .MuiFormControl-root .Mui-focused": {
      outline: "rgb(25, 118, 210) solid 1px",
      outlineffset: "-1px",
    },
    "& textarea": {
      textAlign: "left !important",
      fontSize: "13px",
      letterSpacing: "0.0075em",
      lineHeight: "16px",
      fontWeight: 500,
      color: "#1A1E24",
    },
    "& .MuiInput-root:not(.Mui-disabled) textarea": {
      textDecoration: "underline",
    },
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none",
    },
    "& .MuiInput-root input": {
      color: "inherit",
    },
    "& .cp-second-p textarea": {
      color: "#54606F",
    },
  },
}));

const downloadFileFunc = async (clientId) => {
  await downloadFile(`/api/Client/${clientId}/creditOpinion/file`);
};

export const AccordionSummaryStyled = styled(AccordionSummary)(() => ({
  flexDirection: "row-reverse",
  background: "#EAEDF0",
  gap: 10,
  "& .MuiAccordionSummary-content": {
    color: "#1A1E24",
    fontSize: "16px",
    fontWeight: "500",
  },
}));

const AccordionSummaryPlainStyled = styled(AccordionSummary)(() => ({
  flexDirection: "row",
  gap: 10,
  justifyContent: "start",
  "& .MuiAccordionSummary-content": {
    color: "#1A1E24",
    fontSize: "16px",
    fontWeight: "500",
    flexGrow: 0,
  },
}));

export const AccordionStyled = styled(Accordion)(() => ({}));

export const AccordionDetailsStyled = styled(AccordionDetails)(() => ({
  padding: 0,
}));

const AccordionDetailsPlainStyled = styled(AccordionDetails)(() => ({
  padding: 15,
  background: "#fff",
  borderRadius: "10px",
  fontSize: "13px",
  color: "#54606F",
  display: "flex",
  flexDirection: "column",
  gap: "15px",
}));

const colorOptions = ["Green", "Yellow", "Red", "Grey"];

const CreaditOpinion = () => {
  const { t } = useTranslation();
  const [row, setRow] = React.useState();
  const [isEdit, setIsEdit] = React.useState(false);
  const { id: clientId } = useParams();
  const classes = useStylesForEditRow();
  const btnStyles = useCTAButtonStyle();

  React.useEffect(() => {
    (async () => {
      const data = await AppAPI.request({
        url: `/api/Client/${clientId}/creditOpinion`,
      });
      setRow(data);
    })();
  }, []);

  const onSubmit = async () => {
    if (!isEdit) return;
    await AppAPI.request({
      url: `/api/client/${clientId}/creditOpinion`,
      method: "PUT",
      data: row,
    });
  };

  const [colorCircleAnchor, setColorCircleAnchor] = useState(null);
  const [colorCircleItem, setColorCircleItem] = useState(null);

  if (!row) return <NoData />;

  return (
    <div style={{ marginBottom: "100px" }} className="listOfCustomers__page">
      <div style={{ background: "#fff", borderRadius: "10px" }}>
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
            padding: "15px 20px",
          }}
        >
          <ButtonBB
            style={{
              width: "162px",
              opacity: isEdit ? "1" : "0.2",
              cursor: isEdit ? "pointer" : "default",
            }}
            className={btnStyles.default}
            onClick={onSubmit}
          >
            {t("buttons.Save")}
          </ButtonBB>
          <ButtonBB onClick={() => downloadFileFunc(clientId)}>
            {t("buttons.ExportToExcel")}
          </ButtonBB>
        </div>
        <div
          style={{
            borderTop: "1px solid #E1E6F1",
            padding: "15px 20px",
            textAlign: "left",
          }}
        >
          <UIToggleWithLabel
            sx={{ fontSize: "14px" }}
            control={
              <UIToggle
                checked={isEdit}
                onChange={(event) => setIsEdit(event.target.checked)}
              />
            }
            label={t("buttons.EditMode")}
            labelPlacement="start"
          />
        </div>
      </div>

      <Box
        sx={{
          textTransform: "uppercase",
          color: "#000",
          fontWeight: 600,
          fontSize: "16px",
          padding: "3em 0 0 3em",
        }}
      >
        {t("Кредитное заключение")}
      </Box>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto 360px",
          gap: "30px",
          marginTop: 20,
        }}
      >
        <div
          style={{
            background: "#fff",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          {row.categories.map((categoryItem, categoryIndex) => (
            <AccordionStyled
              key={categoryItem.title + categoryIndex}
              disableGutters
              className="acc-no-mb"
            >
              <AccordionSummaryStyled expandIcon={<ArrowDropDownIcon />}>
                {categoryIndex + 1}.{categoryItem.title}
                <ColorButton
                  style={{ color: categoryItem.color || "Grey" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setColorCircleAnchor(e.currentTarget);
                    setColorCircleItem(categoryItem);
                  }}
                  disabled={!isEdit}
                />
              </AccordionSummaryStyled>
              <AccordionDetailsStyled>
                {categoryItem.expertAssessment ? (
                  <div
                    style={{
                      padding: "20px 50px",
                      borderBottom: "1px solid #E1E6F1",
                      fontSize: "13px",
                      color: "#1A1E24",
                    }}
                  >
                    {categoryItem.expertAssessment}
                  </div>
                ) : null}
                {Array.isArray(categoryItem.parameters) &&
                categoryItem.parameters.length ? (
                  <div
                    style={{
                      padding: "30px 50px",
                      fontSize: "13px",
                      color: "#1A1E24",
                      display: "flex",
                      flexDirection: "column",
                      gap: 25,
                    }}
                  >
                    {categoryItem.parameters.map(
                      (parameterItem, parameterIndex) => (
                        <div
                          key={parameterItem.title + parameterIndex}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "35px auto 300px",
                            gap: "30px 0px",
                          }}
                        >
                          <div>
                            {categoryIndex + 1}.{parameterIndex + 1}
                          </div>
                          <div style={{ paddingRight: "10px" }}>
                            {parameterItem.title}
                          </div>
                          <div>
                            <div className={classes.input}>
                              <TextField
                                multiline
                                style={{
                                  display: "block",
                                  fontWeight: 500,
                                }}
                                disabled={!isEdit}
                                defaultValue={parameterItem.value}
                                onChange={(event) => {
                                  setRow((row) => {
                                    row.categories[categoryIndex].parameters[
                                      parameterIndex
                                    ].value = event.target.value;
                                    return { ...row };
                                  });
                                }}
                              />
                            </div>
                            <div className={classes.input}>
                              <TextField
                                className="cp-second-p"
                                multiline
                                style={{
                                  display: "block",
                                  fontWeight: 500,
                                }}
                                disabled={!isEdit}
                                defaultValue={parameterItem.analytics}
                                onChange={(event) => {
                                  setRow((row) => {
                                    row.categories[categoryIndex].parameters[
                                      parameterIndex
                                    ].analytics = event.target.value;
                                    return { ...row };
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ) : null}
              </AccordionDetailsStyled>
            </AccordionStyled>
          ))}
        </div>
        <div>
          <AccordionStyled disableGutters className="acc-no-mb">
            <AccordionSummaryPlainStyled expandIcon={<ArrowDropDownIcon />}>
              {t("CreaditOpinion.aboutBorrower")}
            </AccordionSummaryPlainStyled>
            <AccordionDetailsPlainStyled>
              <div>
                <div>{t("CreaditOpinion.potentialBorrower")}</div>
                <div className={classes.input}>
                  <TextField
                    multiline
                    style={{
                      display: "block",
                      fontWeight: 500,
                    }}
                    disabled={!isEdit}
                    defaultValue={row.potentialBorrower}
                    onChange={(event) => {
                      setRow((row) => {
                        row.potentialBorrower = event.target.value;
                        return { ...row };
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <div>{t("CreaditOpinion.inn")}</div>
                <div className={classes.input}>
                  <TextField
                    multiline
                    style={{
                      display: "block",
                      fontWeight: 500,
                    }}
                    disabled={!isEdit}
                    defaultValue={row.inn}
                    onChange={(event) => {
                      setRow((row) => {
                        row.inn = event.target.value;
                        return { ...row };
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <div>{t("CreaditOpinion.belongingToGC")}</div>
                <div className={classes.input}>
                  <TextField
                    multiline
                    style={{
                      display: "block",
                      fontWeight: 500,
                    }}
                    disabled={!isEdit}
                    defaultValue={row.belongingToGC}
                    onChange={(event) => {
                      setRow((row) => {
                        row.belongingToGC = event.target.value;
                        return { ...row };
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <div>{t("CreaditOpinion.activityTypeOfCompany")}</div>
                <div className={classes.input}>
                  <TextField
                    multiline
                    style={{
                      display: "block",
                      fontWeight: 500,
                    }}
                    disabled={!isEdit}
                    defaultValue={row.activityTypeOfCompany}
                    onChange={(event) => {
                      setRow((row) => {
                        row.activityTypeOfCompany = event.target.value;
                        return { ...row };
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <div>{t("CreaditOpinion.address")}</div>
                <div className={classes.input}>
                  <TextField
                    multiline
                    style={{
                      display: "block",
                      fontWeight: 500,
                    }}
                    disabled={!isEdit}
                    defaultValue={row.address}
                    onChange={(event) => {
                      setRow((row) => {
                        row.address = event.target.value;
                        return { ...row };
                      });
                    }}
                  />
                </div>
              </div>
            </AccordionDetailsPlainStyled>
          </AccordionStyled>
          <AccordionStyled disableGutters defaultExpanded className="acc-no-mb">
            <AccordionSummaryPlainStyled expandIcon={<ArrowDropDownIcon />}>
              {t("CreaditOpinion.aboutCredit")}
            </AccordionSummaryPlainStyled>
            <AccordionDetailsPlainStyled>
              <div>
                <div>{t("CreaditOpinion.requestedLoanAmount")}</div>
                <div className={classes.input}>
                  <TextField
                    multiline
                    style={{
                      display: "block",
                      fontWeight: 500,
                    }}
                    disabled={!isEdit}
                    defaultValue={row.requestedLoanAmount}
                    onChange={(event) => {
                      setRow((row) => {
                        row.requestedLoanAmount = event.target.value;
                        return { ...row };
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <div>{t("CreaditOpinion.purposeOfLoan")}</div>
                <div className={classes.input}>
                  <TextField
                    multiline
                    style={{
                      display: "block",
                      fontWeight: 500,
                    }}
                    disabled={!isEdit}
                    defaultValue={row.purposeOfLoan}
                    onChange={(event) => {
                      setRow((row) => {
                        row.purposeOfLoan = event.target.value;
                        return { ...row };
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <div>{t("CreaditOpinion.materialityOfDebt")}</div>
                <div className={classes.input}>
                  <TextField
                    multiline
                    style={{
                      display: "block",
                      fontWeight: 500,
                    }}
                    disabled={!isEdit}
                    defaultValue={row.materialityOfDebt}
                    onChange={(event) => {
                      setRow((row) => {
                        row.materialityOfDebt = event.target.value;
                        return { ...row };
                      });
                    }}
                  />
                </div>
              </div>
            </AccordionDetailsPlainStyled>
          </AccordionStyled>
          <AccordionStyled disableGutters defaultExpanded className="acc-no-mb">
            <AccordionSummaryPlainStyled expandIcon={<ArrowDropDownIcon />}>
              {t("CreaditOpinion.conclusions")}
            </AccordionSummaryPlainStyled>
            <AccordionDetailsPlainStyled>
              <div className={classes.input}>
                <TextField
                  multiline
                  style={{
                    display: "block",
                    fontWeight: 500,
                  }}
                  disabled={!isEdit}
                  defaultValue={
                    row.categories[row.categories.length - 1].expertAssessment
                  }
                  onChange={(event) => {
                    setRow((row) => {
                      row.categories[
                        row.categories.length - 1
                      ].expertAssessment = event.target.value;
                      return { ...row };
                    });
                  }}
                />
              </div>
            </AccordionDetailsPlainStyled>
          </AccordionStyled>
        </div>
      </div>

      <Menu
        anchorEl={colorCircleAnchor}
        open={!!colorCircleAnchor}
        onClose={() => setColorCircleAnchor(null)}
      >
        {colorOptions.map((option) => (
          <MenuItem
            key={option}
            onClick={() => {
              setRow((row) => {
                const item = row.categories.find((c) => c === colorCircleItem);
                if (item) {
                  row.categories.splice(
                    row.categories.findIndex((c) => c === colorCircleItem),
                    1,
                    { ...item, color: option }
                  );
                }
                setColorCircleAnchor(null);
                setColorCircleItem(null);
                return { ...row };
              });
            }}
          >
            <FiberManualRecordIcon style={{ color: option }} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CreaditOpinion;
