import { formatDate } from "@Localization/utils";

const mergeAndTransformData = (data) =>
  Object.entries(data).reduce((acc, [entryKey, entryData]) => {
    Object.entries(entryData).forEach(([key, data]) => {
      acc[key] = {
        ...acc[key],
        [entryKey]: data,
      };
    });
    return acc;
  }, {});

export const transformDataForChartInterface = (data, dataKeys) => {
  return Object.entries(mergeAndTransformData(data)).map(
    ([entryKey, entryValue]) => {
      const date = new Date(entryKey);
      const formattedValue = date instanceof Date && !isNaN(date.valueOf())
        ? formatDate(date)
        : entryKey;
      return {
        [dataKeys.name]: formattedValue,
        ...entryValue,
      };
    }
  );
};
