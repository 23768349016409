import { getAllCustomers } from "../actionsGet/actionGetAllCustomers";
import { geAlltUsersRequest } from "../actionsGet/actionGetUser";
import AppAPI from "@API/index";

export const deleteCustomer = (id) => {
  return dispatch => {
    AppAPI.request({
      method: "delete",
      url: `/api/Customer/${id}`,
    })
      .then(() => {
        dispatch(getAllCustomers())
        if(window.location.href.indexOf('customers') === -1) {
          dispatch(geAlltUsersRequest())
        }
      })
  };
};
