export const ROLES = {
  CustomerUser: "CustomerUser",
  ClientUser: "ClientUser",
  Admin: "Admin",
};

export const ROLE_PAGES = {
  customer: "customer",
  client: "client",
  user: "user",
  users: 'users'
};

export const ROLES_TO_PAGES_MAP = {
  [ROLE_PAGES.customer]: ROLES.CustomerUser,
  [ROLE_PAGES.client]: ROLES.ClientUser,
  ADMIN: ROLES.Admin,
};

export const ANALYSIS_TYPES = {
  vertical: "Vertical analysis",
  horizontal: "Horizontal analysis",
  ratio: "Ratio analysis",
};

export const FILTER_TYPES = {
  frequency: "frequency",
  date: "date",
  ...ANALYSIS_TYPES,
};

export const FILTERS_BUTTONS = {
  showDashboardButton: "showDashboardButton",
  exportButton: "exportButton",
};

export const DATE_RECORD_TYPES = {
  Month: "Month",
  ThreeMonths: "ThreeMonths",
  SixMonths: "SixMonths",
  NineMonths: "NineMonths",
  Year: "Year",
};

export enum FREQUENCY_TYPES {
  month = 'Month',
  quart = 'Quarter',
  year = 'Year',
}

export const FREQUENCY_TYPES_MAPPING = {
  Month: 0,
  Quarter: 1,
  Year: 2
};

export const RECORD_TYPE_ALLOWED_BY_FREQ = {
  [FREQUENCY_TYPES.month]: DATE_RECORD_TYPES.Month,
  [FREQUENCY_TYPES.quart]: Object.keys(DATE_RECORD_TYPES).filter(k => k !== DATE_RECORD_TYPES.Month),
  [FREQUENCY_TYPES.year]: [DATE_RECORD_TYPES.Year],
};

export const CHARTS_COLOR_PALETTE = [
  "#a6c0ff",
  "#855cff",
  "#1d52db", //#1d52db <- #789eff
  "#7ea3ff",
  "#426cd5",
  "#5183ff",
  "#708fdb",
  "#ffc78a",
  "#ffbfb2",
  "#ff9680",
  "#cd8c7f",
  "#b98a80",
  "#ff795b",
  "#F3C051",
  "#8CA788",
  "#7D99B8",
  "#2C636C",
  "#7F1E82",
  "#646467",
  "#1F4971",
  "#CEE4EA",
  "#6463B1",
  "#D73067",
  "#22AD6A",
  "#EFAF41",
  "#203CBC",
  "#DEF3E8",
  "#354659",
  "#509fc5",
  "#9d50c5",
  "#c56650",
  "#40f99b",
  "#599385",
  "#61707d",
  "#7f6d90",
  "#9d69a3",
  "#c9b2c9",
  "#f5fbef",
  "#efacb2",
  "#e85d75",
  "#f6511d",
  "#ffb400",
  "#00a6ed",
  "#7fb800",
  "#0d2c54",
  "#80ffe8",
  "#253d5b",
  "#474954",
  "#104f55",
  "#32746d",
  "#9ec5ab",
  "#dd614a",
  "#f48668",
  "#f4a698",
  "#ffd9da",
  "#ea638c",
  "#151e3f",
  "#030027",
  "#f2f3d9",
  "#dc9e82",
  "#c16e70",
  "#474a2c",
  "#636940",
  "#da7635",
  "#e24e1b",
  "#ffa0fd",
  "#fabc3c",
  "#ffb238",
  "#f19143",
  "#ff773d",
  "#f55536",
  "#054a91",
  "#3e7cb1",
  "#81a4cd",
  "#dbe4ee",
  "#464655",
  "#a3e7fc",
  "#26c485",
  "#32908f",
  "#553a41",
  "#2f0601",
  "#f2c14e",
  "#f78154",
  "#b4436c",
  "#c60f7b",
  "#f90093",
  "#504746",
  "#b89685",
  "#bfada3",
  "#fbb7c0",
  "#b6244f",
  "#84dccf",
  "#d664be",
  "#b191ff",
  "#b0f2b4",
  "#4381c1",
  "#2e5266",
  "#6e8898",
  "#9fb1bc",
  "#d3d0cb",
  "#e2c044",
  "#dbd053",
  "#c89933",
  "#fbacbe",
  "#faa916",
  "#96031a",
]
