import { failureActionType, requestActionType, successActionType } from "@State/actions/actionTypes";

const initialState = {
  summary: {},
  counterparies: {},
  sales: {},
  DebtBurden: {},
  loading: false,
  error: {},
};

export const getFinDataPageDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case requestActionType:
      return {
        ...state,
        loading: true,
      };
    case successActionType:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case failureActionType:
      return {
        ...state,
        error: action.error,
        loading: false
      }
    default:
      return state;
  }
}

