import React from "react";
import { useTranslation } from "react-i18next";

let style = {
  display: "flex",
  flexFlow: "row",
  alignItems: "center",
  background: "#43A56C",
  color: "#FDFDFD",
  cursor: "pointer",
  padding: "15px 20px",
  borderRadius: "8px",
  boxSizing: "border-box",
  height: "49px",
  marginRight: "10px",
};

let p = {
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "22px",
  margin: 0,
  whiteSpace: "nowrap",
};

let span = {
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "22px",
  whiteSpace: "nowrap",
};

const Scoring = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div onClick={(e) => onClick(e)} style={style}>
      <span style={span}>{t("Scoring")}</span>
    </div>
  );
};

export default Scoring;
