import { transformDataForChartInterface } from "@Utils/charts";
import Title from "@View/components/charts/Title";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

export default function RevenueDynamicsChart2({
  data,
  dataProps,
  chartContainerClass,
  title,
  chartStyle,
  currency,
}) {
  const [top, setTop] = useState(20);
  useEffect(() => {
    setTimeout(() => {
      setTop(29);
    }, 10);
  }, []);

  const { i18n } = useTranslation();
  let d = transformDataForChartInterface(data, dataProps);

  let CustomizedLabel = (props) => {
    return (
      <text
        fontSize="12px"
        fontWeight="500"
        fill="#A1ADBA"
        offset="5"
        x="55"
        y="105"
        className="recharts-text recharts-label"
        textAnchor="start"
      >
        <tspan x="40" dy="100">
          {currency}
        </tspan>
      </text>
    );
  };

  return (
    <div
      style={{
        background: "#fff",
        width: "100%",
        display: "flex",
        flexFlow: "column",
        borderRadius: "10px",
      }}
    >
      <Title value={title} />
      <div style={{ padding: "15px" }}>
        <ResponsiveContainer width="100%" height={240}>
          <LineChart
            width={150}
            height={40}
            data={d}
            margin={{
              top: top,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <XAxis dataKey={dataProps.name} tickLine={false} />
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <YAxis
              axisLine={true}
              tickLine={true}
              // label={{ value: `${currency}`, angle: -90, position: 'insideLeft' }}
              label={<CustomizedLabel />}
              width={95}
              tickFormatter={(number) => {
                if (i18n.language === "en")
                  return new Intl.NumberFormat().format(number);
                else
                  return new Intl.NumberFormat("ru", {
                    style: "decimal",
                  }).format(number);
              }}
            />
            <Tooltip
              formatter={(number) => {
                if (i18n.language === "en")
                  return new Intl.NumberFormat().format(number);
                else
                  return new Intl.NumberFormat("ru", {
                    style: "decimal",
                  }).format(number);
              }}
            />
            {Object.keys(d[0]).map((e) => {
              if (e === "name") return;
              return (
                <Line
                  type="monotone"
                  dataKey={e}
                  key={`data-${e}-line`}
                  stroke="#8CA788"
                  activeDot={{ r: 8 }}
                  strokeWidth={3}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
