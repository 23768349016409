import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FinTable from "./FinTable";
import { downloadSheetWithNotification } from "@State/effects";
import { FinancialStatements } from "@Dao/responseTypes";
import { downloadFile } from "@Dao/api";
import { useParams } from "react-router-dom";
import { Frequency } from "@Constants/enums";
import { translatedFrequencies } from "@Constants/functions";
import { useTranslation } from "react-i18next";
import { FormControl, FormLabel, Grid } from "@material-ui/core";
import { RangeDatePicker } from "@View/common/form/control/RangeDatePicker";
import { Checkbox } from "@View/common/form/control/Checkbox";
import { Snackbar } from "@State/actions/actionCreators";
import ButtonBB from "@View/components/buttons/ButtonBB";
import NoData from "@View/style/components/NoData";
import Selector from "@View/common/form/control/Selector";
import { CoeffAnalTable } from "./CoeffAnalTable";
import AppAPI from "@API/index";

export function FinStatementsTab() {
  const { t } = useTranslation();
  const { id: finDataRequestId } = useParams<{ id: string }>();
  const filters = useSelector(
    (state: { finDataRequest: { filters: any } }) =>
      state.finDataRequest.filters.FinStatements
  );

  const [financialStatements, setFinancialStatements] =
    useState<FinancialStatements | null>(null);

  const [isEdit, setIsEdit] = useState(false);
  const [startDate, setStartDate] = useState<Date | any>(null);
  const [endDate, setEndDate] = useState<Date | any>(null);
  const [minStartDate, setMinStartDate] = useState<Date | null>(null);
  const [maxEndDate, setMaxEndDate] = useState<Date | null>(null);
  const [frequency, setFrequency] = useState<any>(Frequency.Quarter);

  const [horizontal, setHorizontal] = useState<boolean>(false);
  const [vertical, setVertical] = useState<boolean>(false);
  const [ratio, setRatio] = useState<boolean>(false);
  const coeffAnalTable = useRef<HTMLDivElement>(null);
  const openCoeffTable = (v: boolean) => {
    setRatio(v);
    if (v) {
      setTimeout(() => {
        if (coeffAnalTable.current) {
          coeffAnalTable.current.scrollIntoView({ behavior: "smooth" });
        }
      });
    }
  };

  const exportToClick = () => {
    downloadSheetWithNotification(
      finDataRequestId,
      startDate,
      endDate,
      frequency
    );
  };
  const onExportOSVClick = async () => {
    await downloadFile(`/api/FinDataRequest/${finDataRequestId}/osvfile`);
  };
  useEffect(() => {
    if (isEdit) {
      setFrequency(Frequency.Month);
    }
  }, [isEdit]);

  useEffect(() => {
    (async () => {
      const response = await AppAPI.request<FinancialStatements>({
        url: `/api/FinDataRequest/${finDataRequestId}/financialStatements?isOriginal=true`,
      });
      await setFinancialStatements(response);
    })();
  }, [finDataRequestId]);

  useEffect(() => {
    if (financialStatements !== null) {
      const dates = getDatesRange(financialStatements);
      setMinStartDate(dates[0]);
      setMaxEndDate(dates[dates.length - 1]);
      if (startDate === null) {
        setStartDate(dates[0]);
      }
      if (endDate === null) {
        setEndDate(dates[dates.length - 1]);
      }
    }
  }, [financialStatements]);

  let frequencyItems: any = translatedFrequencies();

  let scrollWhenHorFunc = () => {
    setTimeout(() => {
      let table = document.querySelector(".DefaultTable__wrapper");

      if (table !== null && horizontal === false) {
        table.scroll(20000, 0);
      }
    }, 1000);
  };

  if (!financialStatements || !startDate || !endDate) {
    return (
      <div
        style={{
          marginTop: "200px",
          height: "100px",
          transform: "translateX(-20px)",
        }}
      >
        <NoData />
      </div>
    );
  }

  return (
    <>
      <FormControl
        fullWidth
        margin="normal"
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "0px",
          marginTop: "0px",
        }}
      >
        <Grid
          style={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginBottom: "28px",
            background: "#fff",
            borderRadius: "10px 10px 0px 0px",
            padding: "15px 20px",
            margin: "0px",
            width: "100%",
          }}
          container
          spacing={2}
        >
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div
              style={{ maxWidth: "153px", width: "100%", marginRight: "10px" }}
            >
              {/* <Selector id="frequency" label={t("Frequency")} value={frequency} values={frequencyItems} onChange={setFrequency} vertical /> */}
              <Selector
                id="frequency"
                label={t("Frequency")}
                value={frequency}
                values={frequencyItems}
                onChange={setFrequency}
              />
            </div>

            <div
              style={{ maxWidth: "1000px", width: "100%", marginRight: "30px" }}
            >
              <RangeDatePicker
                views={["year", "month"]}
                label={t("Analysis period")}
                minValue={minStartDate}
                maxValue={maxEndDate}
                startValue={startDate}
                endValue={endDate}
                onChangeStart={setStartDate}
                onChangeEnd={setEndDate}
                vertical
              />
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "10px",
                }}
              >
                <FormLabel
                  style={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                    letterSpacing: "0.0075em",
                    color: "#54606F",
                    marginBottom: "10px",
                  }}
                >
                  {t("Analysis types")}
                </FormLabel>
                <div className="Checkbox__wrapper">
                  <Checkbox
                    label={t("Vertical analysis")}
                    value={vertical}
                    onChange={setVertical}
                  />
                  {financialStatements.horizontalAnalyse.assets?.total ? (
                    <div onClick={() => scrollWhenHorFunc()}>
                      <Checkbox
                        label={t("Horizontal analysis")}
                        value={horizontal}
                        onChange={setHorizontal}
                      />
                    </div>
                  ) : null}
                  <Checkbox
                    label={t("Ratio analysis")}
                    value={ratio}
                    onChange={openCoeffTable}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ marginRight: "15px" }}>
              <ButtonBB
                disabled={false}
                style={{ whiteSpace: "nowrap" }}
                onClick={onExportOSVClick}
              >
                {t("buttons.ExportOsv")}
              </ButtonBB>
            </div>
            <div>
              <ButtonBB
                disabled={false}
                style={{ whiteSpace: "nowrap" }}
                onClick={exportToClick}
              >
                {t("buttons.ExportToExcel")}
              </ButtonBB>
            </div>
          </div>
        </Grid>
      </FormControl>

      <FinTable
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        finData={financialStatements.finData}
        horizontalAnalyse={financialStatements.horizontalAnalyse}
        legend={financialStatements.legend}
        currency={financialStatements.currency}
        startDate={startDate}
        endDate={endDate}
        frequency={frequency}
        isHorizontalAnalyseEnable={horizontal}
        isVerticalAnalyseEnable={vertical}
        trsData={undefined}
        noTransformData={undefined}
      />

      <div ref={coeffAnalTable}></div>
      {filters.startDate !== undefined &&
        ratio && ( //fixme
          <CoeffAnalTable
            data={financialStatements.coefficientAnalysis.categories || []}
            startDate={startDate}
            endDate={endDate}
            frequency={frequency}
          />
        )}
    </>
  );
}

function getDatesRange(finStatements: FinancialStatements): Date[] {
  return finStatements.finData
    .map((el) => new Date(el.date))
    .sort((l, r) => l.getMilliseconds() - r.getMilliseconds());
}

export default FinStatementsTab;
