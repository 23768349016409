import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DeleteModal } from "@View/components/DeleteModal";
import { connect } from "react-redux";
import DataTable from "@View/components/table/DataTable";
import { Tooltip } from "@material-ui/core";
import EditIcon from "@View/components/svgComponents/EditIcon";
import DeleteIcon from "@View/components/svgComponents/DeleteIcon";
import ButtonAddNew from "@View/components/buttons/ButtonAddNew";
import NewGroupModal from "@View/pages/AdminPO/customer/customerDetails/modals/NewGroupModal";
import EditGroupModal from "@View/pages/AdminPO/customer/customerDetails/modals/EditGroupModal";
import { useTooltipStyle } from "@View/style/components/tooltipStyles";
import { canEditEverything } from "@Security/stateHelpers";
import AppAPI from "@API/index";
import { getCustomerGroups } from "@State/actions/actionsGet/actionGetCustomer";

const mapStateToProps = (state) => {
  return {
    customer: state.customer.customer,
    groups: state.customer.groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    refreshGroups: (customerId) => dispatch(getCustomerGroups(customerId)),
  };
};

const GroupsTable = ({ customer, groups, refreshGroups }) => {
  const { id: customerId } = useParams();

  const [deletedItem, setDeletedItem] = useState();
  const [selectedItem, setItem] = useState();

  const refreshItems = async () => {
    refreshGroups(customer.id);
  };

  const deleteItem = async (item) => {
    await AppAPI.request({
      method: "delete",
      url: `/api/Customer/${customerId}/groups/${item.id}`,
    });
    refreshItems();
    setDeletedItem();
  };

  useEffect(() => {
    refreshItems();
  }, [customerId]);

  const { t } = useTranslation();
  const tooltipStyle = useTooltipStyle();

  const confirmDeletionModal = (event, item) => {
    event.preventDefault();
    setDeletedItem(item);
  };

  const handleCloseModal = (event) => {
    event.preventDefault();
    setDeletedItem();
  };

  const handleDelete = (event) => {
    event.preventDefault();
    deleteItem(deletedItem);
  };

  const [openNew, setOpenNew] = React.useState(false);

  const handleOpenNew = () => {
    setOpenNew(true);
  };

  const handleCloseNew = () => {
    setOpenNew(false);
  };

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const tableHeader = [
    {
      field: "name",
      headerName: t("inputs.Label"),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      headerName: " ",
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (cell) => {
        return (
          <div className="tableIcons">
            {canEditEverything() && (
              <>
                <Tooltip title={t("buttons.Edit")} classes={tooltipStyle}>
                  <div
                    onClick={() => {
                      setItem(cell.row);
                      handleOpenEdit();
                    }}
                    className={tooltipStyle.element}
                  >
                    <EditIcon />
                  </div>
                </Tooltip>
                <Tooltip title={t("buttons.Delete")} classes={tooltipStyle}>
                  <div
                    onClick={(event) => confirmDeletionModal(event, cell.row)}
                    className={tooltipStyle.element}
                  >
                    <DeleteIcon />
                  </div>
                </Tooltip>
              </>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <NewGroupModal
        customerId={customerId}
        open={openNew}
        handleOpen={handleOpenNew}
        handleClose={handleCloseNew}
        handleUpdate={refreshItems}
      />
      {selectedItem ? (
        <EditGroupModal
          group={selectedItem}
          customerId={customerId}
          open={openEdit}
          handleOpen={handleOpenEdit}
          handleClose={handleCloseEdit}
          handleUpdate={refreshItems}
        />
      ) : null}
      <DataTable
        rows={groups}
        columns={tableHeader}
        toolbarButtons={<ButtonAddNew onClick={handleOpenNew} />}
      />
      <DeleteModal
        handleDelete={handleDelete}
        openModal={!!deletedItem}
        handleCloseModal={handleCloseModal}
      />
    </React.Fragment>
  );
};

GroupsTable.propTypes = {
  clients: PropTypes.array.isRequired,
  deleteClient: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsTable);
