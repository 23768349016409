import { getUser } from "../actionsGet/actionGetUser";
import AppAPI from "@API/index";

export const editUser = (body, history) => {
  const trimmedBody = {
    ...body
  };
  return (dispatch) => {
    AppAPI.request({
      method: "put",
      url: `/api/Account`,
      data: trimmedBody,
    })
      .then(() => {
        dispatch(getUser(body.id));
      })
  };
};
