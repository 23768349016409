import React from "react";
import InAndOutChart from "./charts/InAndOutChart";
import TwoBarChart from "./charts/TwoBarChart";
import { CHARTS_COLOR_PALETTE } from "@View/constants";
import { useTranslation } from "react-i18next";
import NoData from "@View/style/components/NoData";
import {
  filterByDateForCircleChart,
  filterByDateForCurrenciesTable,
  getFrequencyName,
} from "@Utils/dashboardUtils";
import { useWidthResize } from "@Utils/useWidthResize";
import i18n from "@Localization/i18n";

const transformCircle = (data, type) => {
  if (data === undefined) return undefined;
  //type 'income' || 'outgoing'

  const income = i18n.t("Charts.income");
  const outgoing = i18n.t("Charts.outgoing");

  return Object.keys(data)
    .filter((f) => f != "date" && f.includes(type))
    .map((key) => ({
      name: key.replace(/income/g, income).replace(/outgoing/g, outgoing),
      value: Number(data[key]),
    }));
};

function getFirstAndLastDataForInAndOutChart(data) {
  if (!Array.isArray(data) || !data.length) {
    throw new Error(`Not correct data for view`);
  }
  const rangeType = data[0].recordType;

  const lastElement = data[data.length - 1];
  const lastElementDate = new Date(lastElement.date);
  let firstElement;

  if (rangeType === "Month") {
    const firstElementDate = new Date(lastElementDate);
    firstElementDate.setFullYear(firstElementDate.getFullYear() - 1);
    firstElement = data.find((el) => {
      const currentDate = new Date(el.date);
      return (
        currentDate.getFullYear() === firstElementDate.getFullYear() &&
        currentDate.getMonth() === firstElementDate.getMonth()
      );
    });
  } else if (rangeType.includes("Quarter")) {
    firstElement = data.find((el) => {
      const currentDate = new Date(el.date);
      return (
        el.recordType === lastElement.recordType &&
        currentDate.getFullYear() === lastElementDate.getFullYear() - 1
      );
    });
  } else {
    firstElement = data.find((el) => {
      const currentDate = new Date(el.date);
      return currentDate.getFullYear() === lastElementDate.getFullYear() - 1;
    });
  }
  if (!firstElement) {
    firstElement = data[0];
  }
  return {
    first: firstElement,
    last: lastElement,
  };
}

const AnalysisDashboard = ({ row, dates, frequency, dynamicFilter, range }) => {
  const { t } = useTranslation();

  const [data, setData] = React.useState();
  const [dataType, setDataType] = React.useState("");
  const [width] = useWidthResize(600);

  const [centerDataForIncomingChart, setCenterDataForIncomingChart] =
    React.useState(null);
  const [centerDataForOutgoingChart, setCenterDataForOutgoingChart] =
    React.useState(null);

  const [graphDataForIncomingStartChart, setGraphDataForIncomingStartChart] =
    React.useState(null);
  const [graphDataForIncomingEndChart, setGraphDataForIncomingEndChart] =
    React.useState(null);
  const [graphDataForOutgoingStartChart, setGraphDataForOutgoingStartChart] =
    React.useState(null);
  const [graphDataForOutgoingEndChart, setGraphDataForOutgoingEndChart] =
    React.useState(null);

  React.useEffect(() => {
    if (!row) return;

    let dataList = null;
    let dataType = null;

    if (dynamicFilter === 0 || dynamicFilter === "Currency") {
      dataList = row.currenciesTable.graphs[getFrequencyName(frequency)];
      dataType = "currenciesTable";
    } else if (dynamicFilter === 1 || dynamicFilter === "Counterparties") {
      dataList = row.counterpartiesTable.graphs[getFrequencyName(frequency)];
      dataType = "counterpartiesTable";
    } else if (dynamicFilter === 2 || dynamicFilter === "Banks") {
      dataList = row.banksTable.graphs[getFrequencyName(frequency)];
      dataType = "banksTable";
    } else if (dynamicFilter === 3 || dynamicFilter === "AffiliatedEntities") {
      dataList =
        row.affiliatedEntitiesTable.graphs[getFrequencyName(frequency)];
      dataType = "affiliatedEntitiesTable";
    } else if (
      dynamicFilter === 4 ||
      dynamicFilter === "typesOperationsTable"
    ) {
      dataList = row.typesOperationsTable.graphs[getFrequencyName(frequency)];
      dataType = "typesOperationsTable";
    } else if (
      dynamicFilter === 5 ||
      dynamicFilter === "numberOfTransactionsByTypesOperationsTable"
    ) {
      dataList =
        row.numberOfTransactionsByTypesOperationsTable.graphs[
          getFrequencyName(frequency)
        ];
      dataType = "numberOfTransactionsByTypesOperationsTable";
    } else if (
      dynamicFilter === 6 ||
      dynamicFilter === "numberOfTransactionsByCounterpartiesTable"
    ) {
      dataList =
        row.numberOfTransactionsByCounterpartiesTable.graphs[
          getFrequencyName(frequency)
        ];
      dataType = "numberOfTransactionsByCounterpartiesTable";
    } else if (
      dynamicFilter === 8 ||
      dynamicFilter === "numberOfUniqueCounterpartiesTable"
    ) {
      dataList =
        row.numberOfUniqueCounterpartiesTable.graphs[
          getFrequencyName(frequency)
        ];
      dataType = "numberOfUniqueCounterpartiesTable";
    }

    if (dataList && dataType) {
      const dataListFilteredByRange = filterByDateForCurrenciesTable(
        dataList,
        dates
      );
      setData(dataListFilteredByRange);
      setDataType(dataType);

      const centerDataForIncomingChart = {
        ...row[dataType].rows[0],
        rowValues:
          Array.isArray(row[dataType].rows) && row[dataType].rows.length
            ? filterByDateForCircleChart(row[dataType].rows[0].rowValues, dates)
            : [],
      };
      const centerDataForOutgoingChart = {
        ...row[dataType].rows[1],
        rowValues:
          Array.isArray(row[dataType].rows) && row[dataType].rows.length
            ? filterByDateForCircleChart(row[dataType].rows[1].rowValues, dates)
            : [],
      };

      if (centerDataForIncomingChart.rowValues.length) {
        setCenterDataForIncomingChart(centerDataForIncomingChart);
      }
      if (centerDataForOutgoingChart.rowValues.length) {
        setCenterDataForOutgoingChart(centerDataForOutgoingChart);
      }

      const frequencyName = getFrequencyName(frequency);
      const graphs = row[dataType].graphs[frequencyName];

      if (graphs.length) {
        const lastData = graphs[graphs.length - 1];
        const lastDataDay = row.dates.find(
          (el) => el.columnName === lastData.date
        );
        const lastDay = new Date(lastDataDay.date);
        const firstDataDay =
          row.dates.find((el) => {
            const currentDate = new Date(el.date);
            return (
              el.recordType === lastDataDay.recordType &&
              ((frequencyName === "monthly" &&
                currentDate.getMonth() === lastDay.getMonth()) ||
                frequencyName !== "monthly") &&
              currentDate.getFullYear() === lastDay.getFullYear() - 1
            );
          }) ||
          row.dates.find((el) => el.recordType === lastDataDay.recordType);
        const firstData =
          graphs.find((el) => el.date === firstDataDay.columnName) || graphs[0];

        setGraphDataForIncomingStartChart(transformCircle(firstData, "income"));
        setGraphDataForIncomingEndChart(transformCircle(lastData, "income"));

        setGraphDataForOutgoingStartChart(
          transformCircle(firstData, "outgoing")
        );
        setGraphDataForOutgoingEndChart(transformCircle(lastData, "outgoing"));
      }
    }
  }, [row, frequency, dynamicFilter, dates]);

  if (!data || !dataType) return <NoData />;

  const isCountChart =
    dynamicFilter === 5 ||
    dynamicFilter === "numberOfTransactionsByTypesOperationsTable" ||
    dynamicFilter === 6 ||
    dynamicFilter === "numberOfTransactionsByCounterpartiesTable" ||
    dynamicFilter === 8 ||
    dynamicFilter === "numberOfUniqueCounterpartiesTable";

  const incomeDataChart = getFirstAndLastDataForInAndOutChart(
    centerDataForIncomingChart.rowValues
  );
  const outgoingDataChart = getFirstAndLastDataForInAndOutChart(
    centerDataForOutgoingChart.rowValues
  );

  return (
    <div
      style={{
        display: "grid",
        marginTop: "30px",
        gridTemplateColumns: "70% calc(30% - 15px)",
        gap: "15px",
        minHeight: "500px",
      }}
    >
      {width > 0 && data.length > 0 ? (
        <TwoBarChart
          dataType={dataType}
          Tittle={row.currency.name}
          data2={data}
          colors={CHARTS_COLOR_PALETTE}
        />
      ) : (
        <NoData />
      )}
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          gap: "15px",
          justifyContent: "space-between",
        }}
      >
        {graphDataForIncomingStartChart &&
        graphDataForIncomingEndChart &&
        graphDataForOutgoingStartChart &&
        graphDataForOutgoingEndChart &&
        centerDataForIncomingChart &&
        centerDataForOutgoingChart ? (
          <>
            <InAndOutChart
              dynamicFilter={dynamicFilter}
              tittle={t("Charts.InAndOutChart.incoming")}
              symbol={isCountChart ? "" : row.currency.symbol}
              colors={CHARTS_COLOR_PALETTE}
              centerDataFirst={incomeDataChart.first.value}
              centerDataLast={incomeDataChart.last.value}
              dateFirst={incomeDataChart.first.column}
              dateLast={incomeDataChart.last.column}
              graphFirst={graphDataForIncomingStartChart}
              graphLast={graphDataForIncomingEndChart}
            />
            <InAndOutChart
              dynamicFilter={dynamicFilter}
              tittle={t("Charts.InAndOutChart.outgoing")}
              symbol={isCountChart ? "" : row.currency.symbol}
              colors={CHARTS_COLOR_PALETTE}
              centerDataFirst={outgoingDataChart.first.value}
              centerDataLast={outgoingDataChart.last.value}
              dateFirst={outgoingDataChart.first.column}
              dateLast={outgoingDataChart.last.column}
              graphFirst={graphDataForOutgoingStartChart}
              graphLast={graphDataForOutgoingEndChart}
            />
          </>
        ) : (
          <>
            <NoData style={{ position: "static" }} />
            <NoData style={{ position: "static" }} />
          </>
        )}
      </div>
    </div>
  );
};

export default AnalysisDashboard;
