import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import {
  CollapseGroupRows,
  DefaultTable,
  FirstCell,
  NestedRow,
  NoCollapseGroupRows,
  PaddingContainer,
  TextCell,
} from "@View/common/table";
import {
  FormLabel,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from "@material-ui/core";
import { DebtBurden } from "@Dao/responseTypes";
import { format, isAfter, isBefore, compareAsc } from "date-fns";
import { numberWithSpaces, numberUsFormat } from "@Utils/format";
import { i18n, TFunction } from "i18next";
import { useParams } from "react-router-dom";
import { Checkbox } from "@View/common/form/control/Checkbox";
import NoData from "@View/style/components/NoData";
import { RangeDatePicker } from "@View/common/form/control/RangeDatePicker";
import AppAPI from "@API/index";
import { getHighlightColorForLastIndex } from "@Utils/colorPicker";
import ButtonBB from "@View/components/buttons/ButtonBB";
import { downloadFile } from "@Dao/api";

export function DebtBurdens() {
  const { t, i18n } = useTranslation();
  const { id: finDataRequestId } = useParams<{ id: string }>();
  const [debtBurden, setDebtBurden] = useState<
    DebtBurden | null | any | number
  >(null);
  const [horizontal, setHorizontal] = useState(false);
  const [vertical, setVertical] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  useEffect(() => {
    if (debtBurden?.dates.length > 0) {
      const debtBurdenDates = debtBurden?.dates.map(
        ({ date }: any) => new Date(date)
      );
      const getDates = (arr: any[]) => {
        const sortedDates: any = arr.sort((a, b) => a.getTime() - b.getTime());

        let startDate: any = new Date(Date.parse(sortedDates[0]));
        startDate = startDate.setDate(1);
        startDate = new Date(startDate);

        let endDate = new Date(Date.parse(sortedDates[sortedDates.length - 1]));
        let lastDayOfMonthDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth() + 1,
          0
        );
        endDate = lastDayOfMonthDate;

        return [startDate, endDate];
      };
      const [minDate, maxDate] = getDates(debtBurdenDates);

      setMinDate(minDate);
      setMaxDate(maxDate);

      setStartDate(minDate);
      setEndDate(maxDate);
    }
  }, [debtBurden]);

  useEffect(() => {
    (async () => {
      setDebtBurden(
        await (i18n.language === "ru"
          ? AppAPI.request({
              url: `/api/FinDataRequest/${finDataRequestId}/DebtBurden`,
            })
          : AppAPI.request({
              url: `/api/FinDataRequest/${finDataRequestId}/DebtBurden/en`,
            }))
      );
    })();
  }, [finDataRequestId, i18n.language]);

  if (
    !debtBurden ||
    minDate === null ||
    maxDate === null ||
    startDate === null ||
    endDate === null
  ) {
    return (
      <div
        style={{
          marginTop: "200px",
          height: "100px",
          transform: "translateX(-20px)",
        }}
      >
        <NoData />
      </div>
    );
  }

  const transformedDebtBurden = transformData(
    debtBurden,
    startDate,
    endDate,
    horizontal,
    vertical,
    t,
    i18n
  );

  const getExpressGuarantee = async () => {
    await downloadFile(
      `/api/findatarequest/${finDataRequestId}/express-warranty/file`
    );
  };

  return (
    <React.Fragment>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          background: "#FFFFFF",
          borderRadius: "10px 10px 0px 0px",
          padding: "15px 20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <div style={{ marginRight: "10px" }}>
            <RangeDatePicker
              views={["year", "month"]}
              label={t("Analysis period")}
              minValue={minDate}
              maxValue={maxDate}
              startValue={startDate}
              endValue={endDate}
              onChangeStart={setStartDate}
              onChangeEnd={setEndDate}
              vertical
            />
          </div>
          <div style={{ display: "inline-flex", flexDirection: "column" }}>
            <FormLabel
              style={{
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "16px",
                letterSpacing: "0.0075em",
                color: "#54606F",
                marginBottom: "10px",
              }}
            >
              {t("Analysis types")}
            </FormLabel>
            <div className="Checkbox__wrapper">
              <Checkbox
                label={t("Vertical analysis")}
                value={vertical}
                onChange={setVertical}
              />
              <Checkbox
                label={t("Horizontal analysis")}
                value={horizontal}
                onChange={setHorizontal}
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <ButtonBB
            disabled={false}
            style={{ whiteSpace: "nowrap" }}
            onClick={getExpressGuarantee}
          >
            {t("buttons.ExpressGuarantee")}
          </ButtonBB>
        </div>
      </Grid>
      {i18n.language === "en" && (
        <Typography style={{ marginTop: "20px" }}>This is stub data</Typography>
      )}
      <PaddingContainer top>
        <DefaultTable>
          <TableHead
            style={{
              background: "#F3F5F7",
              height: "46px",
            }}
          >
            <NestedRow>
              <TableCell
                style={{
                  paddingLeft: "2em",
                  textAlign: "left",
                  border: "none",
                  fontWeight: 600,
                  fontSize: "13px",
                  lineHeight: "16px",
                  letterSpacing: "0.0075em",
                  color: "#54606F",
                }}
              ></TableCell>
              <TextCell>{debtBurden.currency.localName}</TextCell>
              <TextCell>
                {t("finDataRequest.tabs.DebtBurden.table.columns.contractDate")}
              </TextCell>
              <TextCell>
                {t(
                  "finDataRequest.tabs.DebtBurden.table.columns.contractNumber"
                )}
              </TextCell>
              {transformedDebtBurden.dates.map((el, index) => (
                <TextCell textAlign={"center"} key={index}>
                  {el}
                </TextCell>
              ))}
            </NestedRow>
          </TableHead>

          <TableBody>
            {Array.isArray(transformedDebtBurden.longTerm) &&
            transformedDebtBurden.longTerm.length ? (
              <CollapseGroupRows
                level={2}
                clickableCellText={t(
                  "finDataRequest.tabs.DebtBurden.table.rows.longTerm"
                )}
                otherCellsTexts={transformedDebtBurden.longTermSum}
              >
                {transformedDebtBurden.longTerm.map((longTerm, index) => (
                  <NestedRow key={index}>
                    <FirstCell level={2}>{longTerm[0]}</FirstCell>
                    <TextCell></TextCell>
                    {longTerm.slice(1).map((debt, index, arr) => (
                      <TextCell
                        color={getHighlightColorForLastIndex(
                          debt,
                          index,
                          arr,
                          horizontal
                        )}
                        textColor={getHighlightColorForLastIndex(
                          debt,
                          index,
                          arr,
                          horizontal,
                          true
                        )}
                        key={index}
                      >
                        {debt}
                      </TextCell>
                    ))}
                  </NestedRow>
                ))}
              </CollapseGroupRows>
            ) : null}
            {Array.isArray(transformedDebtBurden.shortTerm) &&
            transformedDebtBurden.shortTerm.length ? (
              <CollapseGroupRows
                level={2}
                clickableCellText={t(
                  "finDataRequest.tabs.DebtBurden.table.rows.shortTerm"
                )}
                otherCellsTexts={transformedDebtBurden.shortTermsSum}
              >
                {transformedDebtBurden.shortTerm.map((shortTerm, index) => (
                  <NestedRow key={index}>
                    <FirstCell level={2}>{shortTerm[0]}</FirstCell>
                    <TextCell></TextCell>
                    {shortTerm.slice(1).map((debt, index, arr) => (
                      <TextCell
                        color={getHighlightColorForLastIndex(
                          debt,
                          index,
                          arr,
                          horizontal
                        )}
                        textColor={getHighlightColorForLastIndex(
                          debt,
                          index,
                          arr,
                          horizontal,
                          true
                        )}
                        key={index}
                      >
                        {debt}
                      </TextCell>
                    ))}
                  </NestedRow>
                ))}
              </CollapseGroupRows>
            ) : null}
            <CollapseGroupRows
              paddingLeft={"2em"}
              level={2}
              clickableCellText={t(
                "finDataRequest.tabs.DebtBurden.table.rows.offBalance.title"
              )}
              otherCellsTexts={[
                ...new Array(
                  transformedDebtBurden.offBalance.leasedProperty.length + 3
                ),
              ].map((e) => "")}
            >
              <NestedRow>
                <FirstCell level={2}>
                  {t(
                    "finDataRequest.tabs.DebtBurden.table.rows.offBalance.data.leasedProperty.title"
                  )}
                </FirstCell>
                <TextCell></TextCell>
                <TextCell></TextCell>
                <TextCell></TextCell>
                {transformedDebtBurden.offBalance.leasedProperty.map(
                  (prop, index, arr) => (
                    <TextCell
                      color={getHighlightColorForLastIndex(
                        prop,
                        index,
                        arr,
                        horizontal
                      )}
                      textColor={getHighlightColorForLastIndex(
                        prop,
                        index,
                        arr,
                        horizontal,
                        true
                      )}
                      key={index}
                    >
                      {prop}
                    </TextCell>
                  )
                )}
              </NestedRow>
              <NestedRow>
                <FirstCell level={2}>
                  {t(
                    "finDataRequest.tabs.DebtBurden.table.rows.offBalance.data.writtenOffDebts.title"
                  )}
                </FirstCell>
                <TextCell></TextCell>
                <TextCell></TextCell>
                <TextCell></TextCell>
                {transformedDebtBurden.offBalance.writtenOffDebts.map(
                  (prop, index, arr) => (
                    <TextCell
                      color={getHighlightColorForLastIndex(
                        prop,
                        index,
                        arr,
                        horizontal
                      )}
                      textColor={getHighlightColorForLastIndex(
                        prop,
                        index,
                        arr,
                        horizontal,
                        true
                      )}
                      key={index}
                    >
                      {prop}
                    </TextCell>
                  )
                )}
              </NestedRow>
              <NestedRow>
                <FirstCell level={2}>
                  {t(
                    "finDataRequest.tabs.DebtBurden.table.rows.offBalance.data.offBalanceSheetCommitments.title"
                  )}
                </FirstCell>
                <TextCell></TextCell>
                <TextCell></TextCell>
                <TextCell></TextCell>
                {transformedDebtBurden.offBalance.sheetCommitments.map(
                  (prop, index, arr) => (
                    <TextCell
                      color={getHighlightColorForLastIndex(
                        prop,
                        index,
                        arr,
                        horizontal
                      )}
                      textColor={getHighlightColorForLastIndex(
                        prop,
                        index,
                        arr,
                        horizontal,
                        true
                      )}
                      key={index}
                    >
                      {prop}
                    </TextCell>
                  )
                )}
              </NestedRow>
              <NestedRow>
                <FirstCell level={2}>
                  {t(
                    "finDataRequest.tabs.DebtBurden.table.rows.offBalance.data.obligationsAndPaymentsIssued.title"
                  )}
                </FirstCell>
                <TextCell></TextCell>
                <TextCell></TextCell>
                <TextCell></TextCell>
                {transformedDebtBurden.offBalance.obligationsAndPaymentsIssued.map(
                  (prop, index, arr) => (
                    <TextCell
                      color={getHighlightColorForLastIndex(
                        prop,
                        index,
                        arr,
                        horizontal
                      )}
                      textColor={getHighlightColorForLastIndex(
                        prop,
                        index,
                        arr,
                        horizontal,
                        true
                      )}
                      key={index}
                    >
                      {prop}
                    </TextCell>
                  )
                )}
              </NestedRow>
            </CollapseGroupRows>
          </TableBody>
        </DefaultTable>
      </PaddingContainer>
    </React.Fragment>
  );
}

function transformData(
  debtBurden: DebtBurden,
  startDate: Date,
  endDate: Date,
  isHorAnEn: boolean,
  isVerAnEn: boolean,
  t: TFunction,
  i18n: i18n
) {
  const filteredDates = debtBurden.dates
    .map((el) => el.date)
    .filter(
      (date) =>
        !isBefore(new Date(date), startDate) &&
        !isAfter(new Date(date), endDate)
    )
    .sort((l, r) => {
      const lDate = new Date(l);
      const rDate = new Date(r);
      return compareAsc(lDate, rDate);
    });

  const lastDate = filteredDates[filteredDates.length - 1];

  const longTermSum = debtBurden.longTerm.find((i) => !i.externalId);
  const sortedLongTerms = debtBurden.longTerm
    .filter((i) => i.externalId)
    .sort((l, r) => {
      const lastDateValL: number = l.debts[lastDate] ?? 0;
      const lastDateValR: number = r.debts[lastDate] ?? 0;
      return lastDateValR - lastDateValL;
    });

  const shortTermsSum = debtBurden.shortTerm.find((i) => !i.externalId);
  const sortedShortTerms = debtBurden.shortTerm
    .filter((i) => i.externalId)
    .sort((l, r) => {
      const lastDateValL: number = l.debts[lastDate] ?? 0;
      const lastDateValR: number = r.debts[lastDate] ?? 0;
      return lastDateValR - lastDateValL;
    });

  const vA = verticalAnalyseParserBuilder(isVerAnEn, i18n.language);
  const hA = horizontalAnalyseParserBuilder(isHorAnEn, i18n.language);
  return {
    dates: [
      ...filteredDates.map((date) => formatDate(new Date(date), i18n.language)),
      ...(isHorAnEn ? [t("HorizontalAnalyse")] : []),
    ],
    longTermSum: longTermSum
      ? [
          "",
          "",
          "",
          ...filteredDates.map((date) =>
            vA(longTermSum.debts[date], longTermSum.verticalAnalysisValue[date])
          ),
          ...hA(longTermSum.horizontalAnalysisValue),
        ]
      : [],
    longTerm: sortedLongTerms.map((company) => [
      company.companyName,
      company.contractDate
        ? format(new Date(company.contractDate), "dd.MM.yyyy")
        : "",
      company.contractNumber,
      ...filteredDates.map((date) =>
        vA(company.debts[date], company.verticalAnalysisValue[date])
      ),
      ...hA(company.horizontalAnalysisValue),
    ]),
    shortTermsSum: shortTermsSum
      ? [
          "",
          "",
          "",
          ...filteredDates.map((date) =>
            vA(
              shortTermsSum.debts[date],
              shortTermsSum.verticalAnalysisValue[date]
            )
          ),
          ...hA(shortTermsSum.horizontalAnalysisValue),
        ]
      : [],
    shortTerm: sortedShortTerms.map((company) => [
      company.companyName,
      company.contractDate
        ? format(new Date(company.contractDate), "dd.MM.yyyy")
        : "",
      company.contractNumber,
      ...filteredDates.map((date) =>
        vA(company.debts[date], company.verticalAnalysisValue[date])
      ),
      ...hA(company.horizontalAnalysisValue),
    ]),
    offBalance: {
      leasedProperty: [
        ...filteredDates.map((date) =>
          vA(
            debtBurden.offBalance.leasedProperty[date],
            debtBurden.offBalance.leasedPropertyVA[date]
          )
        ),
        ...hA(debtBurden.offBalance.leasedPropertyHA),
      ],
      sheetCommitments: [
        ...filteredDates.map((date) =>
          vA(
            debtBurden.offBalance.offBalanceSheetCommitments[date],
            debtBurden.offBalance.offBalanceSheetCommitmentsVA[date]
          )
        ),
        ...hA(debtBurden.offBalance.offBalanceSheetCommitmentsHA),
      ],
      obligationsAndPaymentsIssued: [
        ...filteredDates.map((date) =>
          vA(
            debtBurden.offBalance.obligationsAndPaymentsIssued[date],
            debtBurden.offBalance.obligationsAndPaymentsIssuedVA[date]
          )
        ),
        ...hA(debtBurden.offBalance.obligationsAndPaymentsIssuedHA),
      ],
      writtenOffDebts: [
        ...filteredDates.map((date) =>
          vA(
            debtBurden.offBalance.writtenOffDebts[date],
            debtBurden.offBalance.writtenOffDebtsVA[date]
          )
        ),
        ...hA(debtBurden.offBalance.writtenOffDebtsHA),
      ],
    },
  };
}

function formatDate(date: Date, language: string) {
  return format(date, language === "ru" ? "dd.MM.yyyy" : "MM/dd/yyyy");
}

function formatAmmount(value: number, language: string) {
  return Number.isNaN(Number(value)) || value == null
    ? "0"
    : language === "ru"
    ? numberWithSpaces(value)
    : numberUsFormat(value);
}

function verticalAnalyseParserBuilder(
  isVerAnEn: boolean,
  language: string
): (el: number, elVA: number) => JSX.Element | string {
  return (el: number, elVA = 0) =>
    isVerAnEn ? (
      <div
        key={1}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          width: "100px",
        }}
      >
        <div>{formatAmmount(el, language)}</div>
        <div
          style={
            elVA > 0 ? { color: "blue", minWidth: "4em" } : { minWidth: "4em" }
          }
        >
          {elVA}%
        </div>
      </div>
    ) : (
      formatAmmount(el, language)
    );
}

function horizontalAnalyseParserBuilder(
  isHorAnEn: boolean,
  language: string
): (el?: string | null) => Array<string> {
  return (el: any): any => {
    if (el.length < 8 && el.length > 0) {
      el = `${el}%`;
    }

    const elOr0 = el === null || el === undefined ? "0" : el;
    return isHorAnEn
      ? [language === "ru" ? numberWithSpaces(elOr0) : numberUsFormat(elOr0)]
      : [];
  };
}
