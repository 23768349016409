import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PlusIcon from "../svgComponents/PlusIcon";
import style from "./Buttons.module.css";

const ButtonAddNew = (props) => {
  const { t } = useTranslation();
  return (
    <Button
      component="a"
      href={props.path ? props.path : "#"}
      onClick={props.onClick}
      startIcon={<PlusIcon />}
      className={style.ButtonAddNew}
    >
      {t("buttons.Add New")}
    </Button>
  );
};

export default ButtonAddNew;
