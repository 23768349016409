import i18n from "@Localization/i18n";
import { downloadFile } from "@Dao/api";
import { format } from "date-fns";

export async function downloadSheetWithNotification(id: string, startDate?: Date, endDate?: Date, frequency?: Frequency | any) {
    const urlParameters = ["dataType=0", `language=${i18n.language}`];
    if (startDate !== undefined && startDate !== null) {
        urlParameters.push(`startDate=${formatDate(startDate)}`);
    }
    if (endDate !== undefined && endDate !== null ) {
        urlParameters.push(`endDate=${formatDate(endDate)}`);
    }
    // if (frequency !== undefined && frequency !== null) {
    //     urlParameters["frequency"] = frequency.toString()
    // }
    return await downloadFile(`/api/FinDataRequest/${id}/file?${urlParameters.join('&')}`);
}

const formatDate = (date: Date | any) => format(date, "yyyy-MM-dd")

export enum Frequency {
    Month = 0,
    Quarter = 1,
    Year = 2
}