import { makeStyles } from "@material-ui/core";

export const useTabStyle = makeStyles(() => ({
  root: {
    border: "1px solid #E1E6F1",
    color: "#1A1E24 !important",
    "text-transform": "inherit",
    "font-size": "16px",
    "&:not(.Mui-selected)": {
      background: "#F3F5F7 !important",
      "font-weight": "500",
    },
    "&.Mui-selected": {
      background: "#fff !important",
      "font-weight": "600",
      "border-color": "#fff",
    },
  },
}));
